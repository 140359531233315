import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

import Select from '../common/controls/Select';
import { Constants } from '../common/constants';
import { EN } from '../common/translations';

function getListFromObject(source) {
    const ret = [];
    for (let key of Object.keys(source)) {
        const status = source[key];
        ret.push(<MenuItem key={key} value={status}>{status}</MenuItem>);
    }
    return ret;
}

class EmpoloyeesFilters extends React.PureComponent {
    constructor(props) {
        super(props);
        this.contractTypesList = getListFromObject(Constants.ContractTypes);
        this.statusesList = getListFromObject(Constants.UserStatusMapping);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onSearch();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Grid container style={{ flext: 1, justifyContent: 'space-between', marginLeft: '10px' }} >
                    <Grid item lg={11} container spacing={16} style={{margin: 0, marginTop: -10, width: "100%"}}>
                        <Grid item lg={2} sm={3} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label={EN.employeesPage.filters.supervisor}
                                    onChange={this.props.onSupervisorChange}
                                    value={this.props.search.supervisor}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} sm={3} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label={EN.employeesPage.filters.employee}
                                    onChange={this.props.onEmployeeChange}
                                    value={this.props.search.employee}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} sm={3} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>{EN.employeesPage.filters.contractType}</InputLabel>
                                <Select
                                    value = {this.props.search.contractType}
                                    multiple
                                    onChange={this.props.onContractTypeChange}>
                                    {this.contractTypesList}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} sm={3} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>{EN.employeesPage.filters.status}</InputLabel>
                                <Select
                                    value = {this.props.search.status}
                                    onChange={this.props.onStatusChange}>
                                    {this.statusesList}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                color="primary"
                                type="submit">
                                <Search />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default EmpoloyeesFilters;
