const PAGE_SIZE = 10;

const TOGGLE_ONLY_MY_TEAM_SWITCH = "TOGGLE_ONLY_MY_TEAM_SWITCH";

const PENDING_APPLICATION_CLICK = "PENDING_APPLICATION_CLICK";
const SETTLED_EMPLOYEES_CLICK = "SETTLED_EMPLOYEES_CLICK";
const UNSETTLED_EMPLOYEES_CLICK = "UNSETTLED_EMPLOYEES_CLICK";

const GET_PENDING_APPLICATION_TABLE = "GET_PENDING_APPLICATION_TABLE";
const GET_SETTLED_EMPLOYEES_TABLE = "GET_SETTLED_EMPLOYEES_TABLE";
const GET_UNSETTLED_EMPLOYEES_TABLE = "GET_UNSETTLED_EMPLOYEES_TABLE";

const TEAM_TIMESHEET_COUNT_CHANGE = "TEAM_TIMESHEET_COUNT_CHANGE";
const GET_UNUSED_TIMESHEET_PROPOSAL = "GET_UNUSED_TIMESHEET_PROPOSAL";
const GET_TIMESHEET_PROPOSALS = "GET_TIMESHEET_PROPOSALS";

const CHANGE_MAIL_DIALOG = "CHANGE_MAIL_DIALOG";
const ON_SEND_MAIL_TEAM_TIMESHEET = "ON_SEND_MAIL_TEAM_TIMESHEET";

const CHANGE_TABLE_DIALOG_TEAM_TIMESHEET = "CHANGE_TABLE_DIALOG_TEAM_TIMESHEET";

const SETTLED_EMPLOYEES_DIALOG = "SETTLED_EMPLOYEES_DIALOG";

const OPEN_REJECT_DIALOG_TEAM_TIMESHEET = "OPEN_REJECT_DIALOG_TEAM_TIMESHEET";
const CLOSE_REJECT_DIALOG_TEAM_TIMESHEET = "CLOSE_REJECT_DIALOG_TEAM_TIMESHEET";
const REJECT_TEAM_TIMESHEET = "REJECT_TEAM_TIMESHEET";
const ACCEPT_TEAM_TIMESHEET = "ACCEPT_TEAM_TIMESHEET";

export default {
    PAGE_SIZE,
    PENDING_APPLICATION_CLICK,
    SETTLED_EMPLOYEES_CLICK,
    UNSETTLED_EMPLOYEES_CLICK,
    GET_PENDING_APPLICATION_TABLE,
    GET_SETTLED_EMPLOYEES_TABLE,
    GET_UNSETTLED_EMPLOYEES_TABLE,
    GET_TIMESHEET_PROPOSALS,
    GET_UNUSED_TIMESHEET_PROPOSAL,
    TEAM_TIMESHEET_COUNT_CHANGE,
    CHANGE_MAIL_DIALOG,
    ON_SEND_MAIL_TEAM_TIMESHEET,
    CHANGE_TABLE_DIALOG_TEAM_TIMESHEET,
    OPEN_REJECT_DIALOG_TEAM_TIMESHEET,
    CLOSE_REJECT_DIALOG_TEAM_TIMESHEET,
    REJECT_TEAM_TIMESHEET,
    ACCEPT_TEAM_TIMESHEET,
    SETTLED_EMPLOYEES_DIALOG,
    TOGGLE_ONLY_MY_TEAM_SWITCH
};

 