//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import moment from 'moment';
import {EN} from '../../common/translations';
import {onLoadingStart, onLoadingEnd} from '../../../store/Preloader';
import _ from 'lodash';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import {onShowNotification, onSelectHandler, onNotificationsDelete} from '../../../store/Notifications';


class NotificationsDialog extends React.PureComponent {
    constructor(props){
        super(props);
        this.selectedNotifications = [];
        this.openNotification = this.openNotification.bind(this);
        this.deleteNotifications = this.deleteNotifications.bind(this);
    }

    openNotification(row, column){
        if(column === -1){
            let notId = this.props.notifications[row].id;
            let prepareSelectedNot = [];
            if(_.find(this.selectedNotifications, function(i) { return i === notId; }) === undefined){
                prepareSelectedNot = [...this.selectedNotifications, notId];
            }
            else{
                prepareSelectedNot = _.reject([...this.selectedNotifications], function(i) { return i === notId; });
            }
            this.selectedNotifications = prepareSelectedNot;
            this.props.onSelectHandler(this.selectedNotifications);
        }
        else{
            this.props.onShowNotification(row, column);
        }
    }

    deleteNotifications(){
        if(this.selectedNotifications.length === 0) return;
        this
            .props
            .onLoadingStart('Deleting notifications');
        this.props.onNotificationsDelete(this.selectedNotifications)
            .then(res => {
                this
                    .props
                    .onLoadingEnd();
            })
            .catch(err => {
                this
                    .props
                    .onLoadingEnd();
            });
    }

    render() {

        const {notifications, onCloseClick, isDialogOpen} = this.props;
        let doesNotificationsExists = notifications.length !== 0;

        let actions = [
            <FlatButton key = "delete" label = "Delete" style={{color: 'red'}} primary onClick = {
                () => this.deleteNotifications()
            }/>,
            <FlatButton key = "close" label = "Close" primary onClick = {
                onCloseClick
            }/>,
        ];

        let tableBody = (
            notifications
                .map((row, index) => (
                    <TableRow key={index} className="hoverRowClass" style={(row.isRead ? null : {color: "#ffc558"})}>
                        <TableRowColumn>{moment.utc(row.addedDate).local().format("YYYY-MM-DD")}</TableRowColumn>
                        <TableRowColumn>{row.subject.length > 20 ? row.subject.substring(0,20) + "..." : row.subject}</TableRowColumn>
                        <TableRowColumn>{row.body.length > 20 ? row.body.substring(0,20) + "..." : row.body}</TableRowColumn>
                    </TableRow>
                ))
        );

        if(!doesNotificationsExists){
            tableBody = <TableRow><TableRowColumn colSpan={3} style={{textAlign: 'center'}}>0 notifications</TableRowColumn></TableRow>;
        }

        return (
            <Dialog
                title={EN.notifications.title}
                actions={actions}
                modal={false}
                open={isDialogOpen}
                bodyStyle={{ paddingBottom: '0px' }}
                autoScrollBodyContent
            >
                <Table

                    className={'clearFix'}
                    onCellClick={(!doesNotificationsExists) ? null : (row, column) => this.openNotification(row, column)}
                    selectable = {(doesNotificationsExists)}
                    multiSelectable = {(doesNotificationsExists)}
                    adjustForCheckbox = {(doesNotificationsExists)}
                    >
                    <TableHeader
                        displaySelectAll={false}
                        enableSelectAll={false}
                        adjustForCheckbox
                    >
                        <TableRow>
                            <TableHeaderColumn style={{color: "white"}}>
                                {EN.notifications.date}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={{color: "white"}}>
                                {EN.notifications.subject}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={{color: "white"}}>
                                {EN.notifications.body}
                            </TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody
                        displayRowCheckbox = {(doesNotificationsExists)}
                    >
                        {tableBody}
                    </TableBody>
                </Table>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onShowNotification,
        onSelectHandler,
        onNotificationsDelete,
        onLoadingStart,
        onLoadingEnd
    }, dispatch);
}

export default ReactRedux.connect(null, mapDispatchToProps)(NotificationsDialog);