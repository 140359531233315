import {colors} from '../common/styles.js';

const employeesStyles = {
    position:'relative',  
    paddingLeft: 5,    
    paddingRight: 5,  
    iconStyle: {
        verticalAlign: 'bottom',
        cursor: 'pointer'
    },
    
    inlineBlockStyle: {
        display: 'inline-block'
    },
    
    // STYLES //
    tableNameRowStyle: {
        whiteSpace: 'normal'
    },
    
    tableDivStyle: {
        width: '100%'
    },
    
    tableHeaderStyle: {
        borderBottom: 'none ' + colors.nexioOrange,
        color: colors.nexioOrange,
        fontSize: '1.2e',
        fontWeight: 'bold',
        textAlign: 'center',
        colSpan: '4'
    },
    
    textAreaStyle: {
        field: {
            width: 200,
            marginRight: 50
        },
        floatingLabelFocusStyle: {
            fontSize: 12,
            fontWeight: 'normal',
            color: 'white'
        },
        underlineStyle: {
            borderColor: colors.nexioOrange
        }
    },
    
    headerColStyle: {
        // borderBottom: '1px solid #222222',
        color: 'white',
        textAlign: 'center'
    },
    
    commentsColStyle: {
        whiteSpace: 'normal'
    },
    
    employeesColStyle: {
        textAlign: 'center',
        borderBottom: '2px solid #222222',
        color: '#c5c5c5'
    },
    
    snackStyle: {
        backgroundColor: 'black',
        color: colors.nexioOrange
    },

    divCheckboxStyle:{
        margin: '0px 5px 0px 5px', 
        fontWeight: 'normal', 
        fontSize: '12px'
    }
};

export default employeesStyles;