import React, { useEffect, useState } from "react";
import * as Redux from "redux";
import * as ReactRedux from "react-redux";
import {
  fetchWhitelist,
  addMailWhitelist,
  removeMailsWhitelist,
  replaceMailWhitelist,
} from "../../store/Whitelist";
import EnhancedTable from "./EnhancedTable.js";
import { EN } from "../common/translations";
import MailDialog from "./MailDialog";

const MailWhitelist = ({
  fetchWhitelist,
  addMailWhitelist,
  replaceMailWhitelist,
  removeMailsWhitelist,
  mails,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editMail, setEditMail] = useState(null);

  useEffect(() => {
    fetchWhitelist();
  }, []);

  const handleDelete = (mails) => {
    removeMailsWhitelist(mails);
  };

  const handleAdd = () => {
    setEditMail(null);
    setIsDialogOpen(true);
  };

  const handleEdit = (mail) => {
    setEditMail(mail);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setEditMail(null);
  };

  const handleDialogSubmit = (newMail) => {
    setIsDialogOpen(false);
    if (editMail) {
      replaceMailWhitelist(editMail, newMail);
    } else {
      addMailWhitelist(newMail);
    }
    setEditMail(null);
  };

  return (
    <div>
      <MailDialog
        isOpen={isDialogOpen}
        mail={editMail}
        onCloseClick={handleDialogClose}
        onSubmitClick={handleDialogSubmit}
      />
      <EnhancedTable
        headerTitle={EN.whitelist.headerTitle}
        rows={mails}
        onDeleteHandler={handleDelete}
        onAddHandler={handleAdd}
        onEditHandler={handleEdit}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    mails: state.whitelist.mails,
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return Redux.bindActionCreators(
    {
      fetchWhitelist,
      addMailWhitelist,
      removeMailsWhitelist,
      replaceMailWhitelist,
    },
    dispatch
  );
}

export default ReactRedux.connect(
  mapStateToProps,
  mapDispatchToProps
)(MailWhitelist);
