import React from 'react';
import _ from 'lodash';
import * as ReactRedux from 'react-redux';

import { HolidayPanelStyles } from '../../HolidayStyles';
import { getHolidayColor } from './HolidayColors';
import { colors } from '../../../common/styles.js';
import { dateToIso, isDayOff } from '../../../common/helpers/date';
import { Tooltip } from 'react-tippy';
import { Constants } from '../../../common/constants';
import moment from 'moment';

function getLinearGradient(colors) {
    let args = ['to bottom right'];
    for (let i = 0; i < colors.length; i++) {
        // 2 % difference between adjecant colors to fix rendering in chrome 
        // https://stackoverflow.com/questions/35897967/how-to-make-gradients-in-chrome-smooth
        args.push(`${colors[i]} ${i / colors.length * 100 + 1}%`);
        args.push(`${colors[i]} ${(i + 1) / colors.length * 100 - 1}%`);
    }
    return `linear-gradient(${args.join(', ')})`;
}

//Clamps num to [0, 1), fixes possible rounding errors
function clamp(num) {
    return Math.min(
        Math.max(num, 0),
        1 - Number.EPSILON / 2); // largest number < 1
}
const borderStyle = `medium solid ${colors.nexioOrange}`;
const todayBorderStyle = `1px solid ${colors.nexioOrange}`;

function applyBorderStyle(holidays, highlighted, date, style) {
    const holiday = _.find(holidays, (h) => h.id == highlighted);
    if (!holiday) {
        const newDate = moment(date);
        if (newDate.isSame(new Date(), "day")) {
            const newStyle = {
                ...style,
                borderLeft: todayBorderStyle,
                borderRight: todayBorderStyle
            };
            return newStyle;
        }
        else
            return style;
    }

    const { border, ...styleWithoutBorder } = style;
    const newStyle = {
        ...styleWithoutBorder,
        borderTop: borderStyle,
        borderBottom: borderStyle,
        borderLeft: border,
        borderRight: border
    };
    if (dateToIso(date).substr(0, 19) === holiday.dateFrom.substr(0, 19))
        newStyle.borderLeft = borderStyle;

    if (dateToIso(date).substr(0, 19) === holiday.dateTo.substr(0, 19))
        newStyle.borderRight = borderStyle;
    return newStyle;
}

class Day extends React.PureComponent {

    // Calculate selected holiday according to mouse position in cell
    // To imagine it extend top border to the right for twice its size, split it
    // between holidays from left to right and move the coursor position via a line 
    // parallel to bottom left -> top right diagonal up to this extended top border.
    getSelectedHolidayId(e) {
        const { holidays } = this.props;
        if (holidays.length == 0) {
            return undefined;
        }
        const x = e.clientX;
        const y = e.clientY;
        const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
        const dx = (y - top) / height * width;
        const relX = (x - left + dx) / (2 * width);
        const index = Math.floor(holidays.length * clamp(relX));
        return holidays[index].id;
    }

    getBackground() {
        const { date, holidays, focusedHoliday, highlighted, publicHolidays, index } = this.props;
        if (highlighted) {
            return colors.nexioOrange;
        }
        const backgroundColors = [];
        if (isDayOff(date, publicHolidays)) {
            backgroundColors.push('#444');
        }
        for (const holiday of holidays) {
            backgroundColors.push(getHolidayColor(holiday.type, holiday.status, holiday.id === focusedHoliday));
        }
        let background = '';
        if (index != null && index % 2 !== 0)
            background = '#2f2f2f';
        if (backgroundColors.length > 1)
            background = backgroundColors[1];
        else if (backgroundColors.length == 1)
            background = backgroundColors[0];
        return background;
    }

    render() {
        const { date, holidays, highlightedHoliday } = this.props;
        const background = this.getBackground();
        const div = (
            <div
                style={applyBorderStyle(holidays, highlightedHoliday, date,
                    { ...HolidayPanelStyles.dayStyles, background })}
                onMouseEnter={(e) => this.props.onMouseEnter(this.getSelectedHolidayId(e), date)}
                onMouseMove={(e) => this.props.onMouseMove(this.getSelectedHolidayId(e), date)}
                onMouseLeave={() => this.props.onMouseLeave(date)}
                onMouseDown={(e) => this.props.onMouseDown(e, this.getSelectedHolidayId(e), date)}
                onMouseUp={(e) => this.props.onMouseUp(e, this.getSelectedHolidayId(e), date)} />
        );

        if (holidays.length > 0 && holidays[0].type === Constants.HolidayTypes.REMOTE) {
            return (
                <Tooltip
                    title={(holidays[0].timeFrom != null ? holidays[0].timeFrom : '') + '-' + (holidays[0].timeTo != null ? holidays[0].timeTo : '')}
                    position="top"
                    followCursor
                >
                    {div}
                </Tooltip>
            );
        }
        else
            return div;
    }
}

function mapStateToProps(state) {
    return {
        publicHolidays: state.publicHolidays.wtPublicHolidays
    };
}

Day.defaultProps = {
    onClick: () => null
};

export default ReactRedux.connect(
    mapStateToProps
)(Day);