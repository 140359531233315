import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Components
import BodyDay from './BodyDay';
//3rd party
import _ from 'lodash';
import moment from 'moment';
//translations
import {EN} from '../../../common/translations';
import { calendarPanel } from '../../../common/styles';
import { dateFormat } from '../../../common/helpers/date';

const translations = EN.timesheetPage.calendarPanel;
const style = calendarPanel.calendar.body;

const propTypes = {
    weekNumber: PropTypes.number,
    date: PropTypes.object,
    selectedYear: PropTypes.number,
    selectedMonth: PropTypes.number, 
    selectedDay: PropTypes.object,
    selectedDays: PropTypes.array,
    selectedProject:PropTypes.object,
    issue: PropTypes.object,
    selectionEnabled:PropTypes.bool,
    selectMode:PropTypes.string,
    selectDay:PropTypes.func.isRequired,
    selectRange:PropTypes.func.isRequired,
    showComments:PropTypes.func.isRequired,
    hideComments:PropTypes.func.isRequired,
    onDaySelectStart:PropTypes.func.isRequired,
    onDaySelectEnd:PropTypes.func.isRequired
};

const defaultProps = {
 
};

const BodyWeek = (props) => {
   
    const dayNames = translations.days;
    let days = [];

    let { 
        weekNumber,
        date,
        issue,
        selectedYear, 
        selectedMonth, 
        selectedDay,
        selectedDays,
        selectedProject, 
        selectionEnabled,
        selectMode,
        selectDay,
        selectRange,
        showComments,
        hideComments,
        onDaySelectStart,
        onDaySelectEnd,
        hasHolidayRequestEnabled,
        isProject,
        isTimesheetSent
    } = props;

    const newDate = moment({month: selectedMonth, year: selectedYear});
    
    for (let i = 0; i < 7; i++) {
        let currentDayTimesheets = (date.month() === newDate.month()) ? 
                            issue.timesheetRows.filter( (timesheet) => timesheet.date === date.format(dateFormat)) : //date issue must be solved on server side
                            [];

        let isSelected = undefined;
        if(selectedDays.length > 0){
            isSelected = (date.month() === newDate.month()) ? 
                        selectedDays.some( day => day === new Date(date)) : //date issue must be solved on server side
                        false;
        }
                
        const dayProps = {
            dayId: currentDayTimesheets[0] && currentDayTimesheets[0].id || -1,
            hours: currentDayTimesheets[0] && currentDayTimesheets[0].hours || 0,
            comments: currentDayTimesheets[0] && currentDayTimesheets[0].comments || "",
            commentsColor: currentDayTimesheets[0] && currentDayTimesheets[0].commentsColor ? 
                            currentDayTimesheets[0].commentsColor : selectedProject.color
        };
        if(currentDayTimesheets.length > 1) {
            dayProps.hours = currentDayTimesheets.reduce((prevVal, currVal) => prevVal + (currVal.hours || 0), 0);
            dayProps.comments = currentDayTimesheets.reduce((prevVal, currVal, idx) => {
                prevVal += idx > 0 && currVal.comments ? ";   " : "";
                prevVal += currVal.comments || "";
                return prevVal;
            }, "");
        }

        let day = {
            date: date,
            name: dayNames[date.day()],
            month: newDate.month(),
            number: date.date(),
            isCurrentMonth: date.month() === newDate.month(),
            isNextMonth: date.month() > newDate.month() ? "next-month" : "",
            isPrevMonth: date.month() < newDate.month() ? "prev-month" : "",
            isToday: date.isSame(new Date(), "day"),
            isSelected,
            issue: issue,
            weekNumber:weekNumber,
            ...dayProps
        };
        
        days.push(day);
        date = date.clone();
        date.add(1, "day");
    }
    
    return (
        <div className="week">
            {days.map( day => {
                return (
                    <BodyDay 
                        key={_.uniqueId('day_')} 
                        day={day}
                        selectedProject={selectedProject}
                        selectedDay={selectedDay}
                        selectDay={selectDay}
                        selectRange={selectRange}
                        selectMode={selectMode}
                        selectionEnabled={selectionEnabled}
                        showComments={showComments}
                        hideComments={hideComments}
                        onDaySelectStart={onDaySelectStart}
                        onDaySelectEnd={onDaySelectEnd}
                        hasHolidayRequestEnabled={hasHolidayRequestEnabled}
                        isProject={isProject}
                        isTimesheetSent={isTimesheetSent}
                    />
                );
            })}
        </div>
    );
};

BodyWeek.propTypes = propTypes;
BodyWeek.defaultProps = defaultProps;

export default BodyWeek;
