import axios from 'axios';
import { identityServer } from '../../components/common/cfg';
import setAuthToken from '../../components/login/helpers/setHeaderAuthToken';
import { store } from '../../index';
import { onLoginRequest, setTokens } from '../Login';
let refreshPromise = null;
export const getRefreshPromise = () => {
    if (refreshPromise) return refreshPromise;
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {refreshPromise = null; return Promise.reject("No refresh token");}
    const formData = new FormData();
    formData.append('client_id', 'Mobile');
    formData.append('client_secret', 'secret');
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);
    refreshPromise = axios
        .post(`${identityServer}/connect/token`, formData)
        .then(response => {
            setAuthToken(response.data.access_token);
            setTokens(response.data.access_token, response.data.refresh_token);
            store.dispatch(onLoginRequest(""));
            return response;
        })
        .finally(() => refreshPromise = null);
    return refreshPromise;
};

export const applyAxiosInterceptors = () => axios.interceptors.response.use((response) => response, async (error) => {
    const originalRequest = error.config;
    if (!error.response || error.response.status != 401 || originalRequest._retry)
        throw error;
    const currentAuthHeader = axios.defaults.headers.common.Authorization;
    originalRequest._retry = true;
    if (originalRequest.headers.Authorization != currentAuthHeader) {
        originalRequest.headers.Authorization = currentAuthHeader;
        return axios(originalRequest);
    }
    try {
        const response = await getRefreshPromise();
        originalRequest.headers.Authorization = "Bearer " + response.data.access_token;
        return axios(originalRequest);
    } catch(refreshTokenError) {
        // eslint-disable-next-line no-console
        console.error(refreshTokenError);
        throw(error);
    }
});