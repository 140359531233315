import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import FormDialog from '../../common/controls/formik/FormDialog';
import HolidaysHistoryTable from '../HolidaysHistoryTable';
import Checkbox from 'material-ui/Checkbox';
import { closeHistoryDialog, setHistoryShowInitial } from '../../../store/Holidays';
import { FormButton } from '../../common/controls/formik/FormControls';
import { EN } from '../../common/translations';

class HolidaysHistoryDialog extends React.PureComponent {
  constructor(props) {
      super(props);
  }

  actions = (props) => {
    return (<>
      <Checkbox
          key={0}
          className="holidaysHistoryShowInitialCheckbox"
          label={EN.holidaysPage.showInitial}
          checked={props.showInitial}
          onCheck={() => props.onChangeShowInitial(!props.showInitial)}
      />
      <FormButton key={1} onClick={props.onClose}>Close</FormButton>
    </>);
  };

  render() {
      return (
      <>
        <FormDialog
            actions={() => this.actions(this.props)}
            open={this.props.open}
            initialValues={{}}
            onClose={this.props.onClose}
            onSubmit={null}
            fullWidth
            maxWidth={false}>
            <>
              <HolidaysHistoryTable/>
            </>
        </FormDialog>
      </>);
  }
}

function mapStateToProps(state) {
  return {
      showInitial: state.holidays.dialogHistoryShowInitial,
      open: state.holidays.dialogHistoryOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return Redux.bindActionCreators({
      onClose: closeHistoryDialog,
      onChangeShowInitial: (showInitial) => setHistoryShowInitial(showInitial),
  }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HolidaysHistoryDialog);