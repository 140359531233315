import React, { Component } from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dateToIso } from "../../../../common/helpers/date";
import { displayHours } from "../../helpers/helpers"

const propTypes = {
  rowId:PropTypes.number,
  weekNumber:PropTypes.number,
  day:PropTypes.object
};

const defaultProps = {
//   classes: '',
//   day: null
};

class FooterDay extends React.Component {

  constructor(props){
    super(props);
  }

  render(){
    const {
      rowId,
      weekNumber,
      day,
      day: {
        name,
        month,
        date,
        isCurrentMonth,
        isNextMonth,
        isPrevMonth,
        isToday,
        number,
        dayId,
        totalHours
      },
      publicHolidays,
      isTeamTimesheet,
      isDailyFooter
    } = this.props;
    
    const isPublicHoliday = publicHolidays.has(dateToIso(day.date.toDate()).substr(0,10));

    const teamTimesheetStyle = {
      visibility: isPublicHoliday ? "hidden"
      : (totalHours > 0 || !["Sat", "Sun"].includes(day.name)) ? 'visible' : 'hidden',
      color: (totalHours != 8) ? "red" : "white"
    };

    const style={
      visibility: (totalHours > 0) ? 'visible' :'hidden',
      fontSize: isDailyFooter ? "8px" : "11px",
      paddingTop : isDailyFooter ? "3px" : "0px",
      verticalAlign: 'top',
    };

    const dayStyle = isTeamTimesheet ? teamTimesheetStyle : style;

    return (
      <div 
          data-day-id={dayId}
          className={
            "day current-month "
            + (isCurrentMonth ? "" : " different-month ") 
            + (isNextMonth ? " next-month" : "")
            + (isPrevMonth ? " prev-month" : "") 
          }>
          <div className="day-content" style={dayStyle}>{displayHours(totalHours)}</div>
      </div>
    );
  }
}
  
function mapStateToProps(state) {
  return {
    publicHolidays: state.publicHolidays.wtPublicHolidays,
  };
}

function mapDispatchToProps(dispatch){
  return Redux.bindActionCreators({},dispatch);
}
  
FooterDay.propTypes = propTypes;
FooterDay.defaultProps = defaultProps;

export default ReactRedux.connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterDay);