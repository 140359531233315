import { host, identityServer } from '../components/common/cfg';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import setHeaderAuthToken from '../components/login/helpers/setHeaderAuthToken';

export const types = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    LOGIN_USER: 'LOGIN_USER',
    LOGIN_FAILED: 'LOGIN_FAILED',
    PASSWORD_EMAIL: 'PASSWORD_EMAIL',
    LOGOUT_USER: 'LOGOUT_USER',
    UPDATE_AVAILABLE_HOLIDAYS: 'UPDATE_AVAILABLE_HOLIDAYS',
    UPDATE_USER_ROLES: 'UPDATE_USER_ROLES',
    FAIL_LOAD_USER: 'FAIL_LOAD_USER',
    CROSS_DOMAIN_LOGIN: 'CROSS DOMAIN LOGIN'
};

const ROLES_CLAIM = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
const TEAM_ROLES_CLAIM = 'NexioTeamRole';

const getClaimArray = (claims, claimType) => {
    const value = claims.map((a) => {
        if (a.type === claimType || a.Type === claimType) {
            return a.value ? a.value : a.Value;
        }
    });
    if (!value) return [];
    if (Array.isArray(value)) return value;
    return [value];
};

const getTeamRoles = (teamRolesData) => {
    const teamRoles = {};
    const teamRolesArray = getClaimArray(teamRolesData, TEAM_ROLES_CLAIM);
    teamRolesArray.forEach(x => {
        const [teamId, roleId] = x.split(':');
        teamRoles[roleId] = teamRoles[roleId] || [];
        teamRoles[roleId].push(+teamId);
    });
    return teamRoles;
};

/** ACTIONS START */
export const onLoginSuccess = (data) => ({ type: types.LOGIN_USER, data });

export const onLoginFailed = (message) => ({ type: types.LOGIN_FAILED, message });

export const onSendResetEmail = (message) => ({ type: types.PASSWORD_EMAIL, message });


export const onLogin = (username, password, rememberMe) => {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('client_id', 'Mobile');
        formData.append('client_secret', 'secret');
        formData.append('grant_type', 'password');
        formData.append('username', username);
        formData.append('password', password);
        formData.append('scope', 'openid profile apiTms offline_access');

        return axios
            .post(`${identityServer}/connect/token`, formData)
            .then(response => {
                localStorage.setItem("rememberMe", rememberMe);
                dispatch(onLoginSuccess(response.data));
            })
            .catch(error => {
                dispatch(onLoginFailed(''));
                if (error.response.status === 400) {
                    dispatch(onLoginFailed(error.response.data.error_description));
                } else {
                    dispatch(onLoginFailed('Server error'));
                }
            });
    };
};


export const onSetCurrentUser = (user) => ({ type: types.SET_CURRENT_USER, user });

export const onLoginRequestFailure = () => ({ type: types.FAIL_LOAD_USER });

export const onCrossDomainLogin = (data) => ({type: types.CROSS_DOMAIN_LOGIN, data});

export const onLoginRequest = (userId) => {
    return (dispatch) => {
        return axios
            .get(`${host}/api/user/userById?userId=${userId}`)
            .then(response => {
                const teamRoles = getTeamRoles(response.data.teamRoles);
                const roles = response.data.roles;
                const activeReplacements = response.data.replacements;
                const allWorkgroups = response.data.allWorkgroups;
                const isEnabledSupervisorManualTimesheetApproval = response.data.isEnabledSupervisorManualTimesheetApproval;
                const user = {
                    ...response.data.user,
                    roles,
                    teamRoles,
                    activeReplacements,
                    allWorkgroups,
                    isEnabledSupervisorManualTimesheetApproval
                };
                dispatch(onSetCurrentUser(user));
            }, () => {
                dispatch(onLoginRequestFailure());
            });
    };
};


export const onLogOut = () => {
    localStorage.removeItem('notifications');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('rememberMe');
    sessionStorage.removeItem('notifications');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    setHeaderAuthToken(false);
    return { type: types.LOGOUT_USER };
};

export const clearResetEmailMesage = () => {
    return (dispatch) => {
        dispatch(onSendResetEmail(''));
    };
};

export const sendResetEmail = (email) => {
    return (dispatch) => {
        return axios
            .post(`${identityServer}/connect/Account/ForgotPasswordRest`, { email })
            .then(response => {
                dispatch(clearResetEmailMesage());
                dispatch(onSendResetEmail(response.data.message));
            })
            .catch(() => {
                dispatch(onSendResetEmail('Server error'));
            });
    };
};

export const onGetAvailableHolidaysDispatch = (data) => ({ type: types.UPDATE_AVAILABLE_HOLIDAYS, data });

export const onGetAvailableHolidays = (year) => {
    return (dispatch) => {
        let headers = {
            'headers': {
                'Content-Type': 'application/json;',
            }
        };
        return axios
            .get(`${host}/api/timesheet/AvailableHolidays?year=${year}`)
            .then(response => response);
    };
};

export const onResetPassword = params => {
    return () => {
        let headers = {
            headers: {
                'Content-Type': 'application/json;'
            }
        };
        return axios.post(`${host}/api/user/reset`, params, headers);
    };
};

export const onGenerateUniqueLink = email => {
    return dispatch => {
        let headers = {
            headers: {
                'Content-Type': 'application/json;'
            }
        };
        return axios.post(`${host}/api/user/GenerateLink?email=${email}`, headers);
    };
};

export const onChangePassword = passwords => {
    return dispatch => {
        let headers = {
            headers: {
                'Content-Type': 'application/json;'
            }
        };
        return axios.post(`${host}/api/user/change`, passwords, headers);
    };
};

export const onUserRolesChange = (userRoles) => ({ type: types.UPDATE_USER_ROLES, data: userRoles });

export const loginFromDifferentDomain = (accessToken, refreshToken) => {
    setTokens(accessToken, refreshToken);
    setHeaderAuthToken(accessToken);

    return (dispatch) => {
        dispatch(onCrossDomainLogin({ access_token: accessToken }));
    };
};

export const setTokens = (accessToken, refreshToken) => {
    if (localStorage.getItem('rememberMe') === 'true') {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
    }

    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('refreshToken', refreshToken);
};

export const getAccessToken = () => {
    if (localStorage.getItem('rememberMe') === 'true') {
        return localStorage.getItem('accessToken');
    }
    return sessionStorage.getItem('accessToken');
};

export const getRefreshToken = () => {
    if (localStorage.getItem('rememberMe') === 'true' ){
        return localStorage.getItem('refreshToken');
    }
    return sessionStorage.getItem('refreshToken');
};


/** ACTIONS END */

/** REDUCER START */
const initialState = {
    isAuthenticated: false,
    user: null,
    initialUserFinished: false
};

export const login = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_USER:
            return {
                ...state,
                loginData: action.data,
                errorMessage: ''
            };
        case types.LOGIN_FAILED:
            return {
                ...state,
                errorMessage: action.message
            };
        case types.PASSWORD_EMAIL:
            return {
                ...state,
                emailPasswordResetMessage: action.message
            };
        case types.SET_CURRENT_USER:
            let userInfo = action.user;
            const roles = userInfo.roles;
            const flagRoleSupervisior = userInfo.activeReplacements.activeReplacementsFlag;
            if (flagRoleSupervisior) {
                const newRole = roles.filter(item => item === 'Supervisor');
                if (newRole.length === 0) {
                    userInfo.roles = [...userInfo.roles, "Supervisor"];
                    userInfo.isSupervisor = true;
                }
            }
            return {
                isAuthenticated: !isEmpty(action.user),
                user: userInfo,
                initialUserFinished: true
            };
        case types.FAIL_LOAD_USER:
            return {
                isAuthenticated: false,
                user: null,
                initialUserFinished: true
            };
        case types.UPDATE_AVAILABLE_HOLIDAYS: {
            const user = {
                ...state.user,
                availableHolidays: action.data
            };
            return {
                ...state,
                user
            };
        }
        case types.UPDATE_USER_ROLES: {
            const teamRoles = getTeamRoles(action.data.teamRoles);
            const user = {
                ...state.user,
                roles: action.data.roles,
                teamRoles: teamRoles
            };
            return {
                ...state,
                user
            };
        }
        case types.LOGOUT_USER:
            return{
                ...state,
                isAuthenticated: false
            };
        case types.CROSS_DOMAIN_LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                loginData: action.data
            };
        default:
            return state;
    }
};
/** REDUCER END */
