const changeLogsStyles = {
    addBtn: {
        boxSizing:'border-box',
        width:'100%', 
        position:'relative',
        display:'block',
        height: 44,
        padding: '4px 0px'
    },
    changeLogColStyle: {
        textAlign: 'center',
        borderBottom: '2px solid #222222',
        color: '#c5c5c5'
    },
    headerColStyle: {
        color: 'white',
        textAlign: 'center'
    },
    tableDivStyle: {
        width: '100%'
    }
};

export default changeLogsStyles;