import React from 'react';
import PropTypes from 'prop-types';
//MDL
import Select from '../../common/controls/Select';
import MenuItem from '@material-ui/core/MenuItem';
//translations
import { EN } from '../../common/translations';
const translations = EN.timesheetPage.calendarPanel;

const controlsPropTypes = {
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    reportView: PropTypes.string,
    searchYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
};

class CalendarControls extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onPrevMonth = this.onPrevMonth.bind(this);
        this.onNextMonth = this.onNextMonth.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onPrevYear = this.onPrevYear.bind(this);
        this.onNextYear = this.onNextYear.bind(this);
    }

    onPrevMonth() {
        if (this.props.month !== 0) {
            this.props.onChange(this.props.month - 1, this.props.year);
        } else {
            this.props.onChange(11, this.props.year - 1);
        }
    }

    onNextMonth() {
        if (this.props.month !== 11) {
            this.props.onChange(this.props.month + 1, this.props.year);
        } else {
            this.props.onChange(0, this.props.year + 1);
        }
    }

    onMonthChange(event) {
        const month = event.target.value;
        if (month != this.props.month) {
            this.props.onChange(month, this.props.year);
        }
    }

    onPrevYear() {
        this.props.onChange(this.props.month, this.props.year - 1);
    }

    onNextYear() {
        this.props.onChange(this.props.month, this.props.year + 1);
    }

    render() {
        const months = translations.months;
        const labelStyle = {
            fontSize: "0.8em",
            width: '146px',
            bottom: '-5px',
        };
        const validationControl = 'monthly';
        const viewOrginal = this.props.viewOrginal === false ? this.props.viewOrginal : true;
        return (
            <span>
                <button className="control controls-button arrow-btn" onClick={this.onPrevYear}>
                    &lt;&lt;
                </button>
                {this.props.reportView === validationControl || viewOrginal ? <button className="control controls-button arrow-btn" onClick={this.onPrevMonth}>
                    &lt;
                </button> : null}
                {viewOrginal ? <div className="control year-txt">{this.props.year}</div> : null}
                <div className="control year-txt">{this.props.searchYear}</div>

                {(this.props.reportView === validationControl) || viewOrginal ? <Select
                    className="control month-btn"
                    value={this.props.month}
                    onChange={this.onMonthChange}
                    disableUnderline
                    style={labelStyle}
                >
                    {months.map((month, i) => {
                        return <MenuItem style={{ height: "auto", fontSize: "0.8em" }} key={"month_" + i} value={i}>{month}</MenuItem>;
                    })}
                </Select> : null}
                {this.props.reportView === validationControl || viewOrginal ? <button className="control controls-button arrow-btn" onClick={this.onNextMonth}>
                    &gt;
                </button> : null}
                <button className="control controls-button arrow-btn" onClick={this.onNextYear}>
                    &gt;&gt;
                </button>
            </span>
        );
    }
}

CalendarControls.propTypes = controlsPropTypes;

export default CalendarControls;
