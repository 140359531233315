import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import memoize from "memoize-one";
import Pagination from '../common/partials/pagination/pagination';
import { EN } from '../common/translations';
import DataTable from '../common/DataTable';
import { HolidayPanelStyles, HolidayStyles } from './HolidayStyles';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import History from '@material-ui/icons/History';
import { fetchTableData, openAddDialog, openEditDialog, openHistoryDialog } from '../../store/Holidays';
import { countDayOff } from '../common/helpers/date';
import AttachmentIcon from '../common/holidays/AttachmentIcon';

class HolidaysTable extends React.PureComponent  {
    constructor(props) {
        super(props);
        this.columns = this.getColumns();
    }

    componentDidMount() {
        this.props.fetchTableData(this.props.tablePage);
    }

    onEditClick(id) {
        this.props.openEditDialog(id);
    }

    onHistoryClick(id) {
        this.props.openHistoryDialog(id);
    }

    getColumns() {
        return [
            {
                header: EN.holidaysPage.type,
                getValue: holiday => <AttachmentIcon holidayRequest={holiday} text={x => x.type} />
            },
            {
                header: EN.holidaysPage.days,
                valueProperty: 'daysCount'
            },
            {
                header: EN.holidaysPage.dateFrom,
                getValue: (row) => row.dateFrom.substring(0, 10) + (row.type === 'Remote work' ? ' ' + (row.timeFrom != null ? row.timeFrom : '') : '')
            },
            {
                header: EN.holidaysPage.dateTo,
                getValue: (row) => row.dateTo.substring(0, 10) + (row.type === 'Remote work' ? ' ' + (row.timeTo != null ? row.timeTo : '') : '')
            },
            {
                header: EN.holidaysPage.accepted,
                getValue: (row) => row.userAcceptedId > 0 ? row.userAcceptedFirstName + " " + row.userAcceptedLastName : row.acceptedBy
            },
            {
                header: EN.holidaysPage.status,
                valueProperty: 'status'
            },
            {
                header: EN.holidaysPage.comment,
                valueProperty: 'comment'
            },
            {
                header: (
                    <IconButton
                        onClick={() => this.props.openAddDialog(false, { userId: this.props.userId }, false)}
                        color="primary">
                        <AddBox />
                    </IconButton>),
                getValue: row => (
                    <>
                        <IconButton onClick={() => this.onEditClick(row.id)} color="primary">
                            <Edit />
                        </IconButton>
                        <IconButton onClick={() => this.onHistoryClick(row.id)} color="primary">
                            <History />
                        </IconButton>
                    </>)
            }
        ];
    }

    render() {
        return (
            <div>
                <DataTable
                    columns={this.columns}
                    cellStyle={HolidayStyles.headerColStyle}
                    headerStyle={HolidayStyles.headerColStyle}
                    tableStyle={HolidayPanelStyles.table}
                    data={this.props.tableData}
                />

                <Pagination
                    onChange={this.props.fetchTableData}
                    value={this.props.tablePage}
                    count={this.props.tableCount} />
            </div>);
    }
}

const getTableHolidays = memoize((holidays, holidayIds, publicHolidays) => {
    return holidayIds
        .map(x => holidays[x])
        .filter(x => x !== undefined && x !== null)
        .map(x => ({ ...x, daysCount: countDayOff(x.dateFrom, x.dateTo, publicHolidays) }));
});

function mapStateToProps(state) {
    return {
        tablePage: state.holidays.page,
        tableData: getTableHolidays(state.holidays.data, state.holidays.tableIds, state.publicHolidays.wtPublicHolidays),
        tableCount: state.holidays.pageCount,
        userId: state.login.user.id
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        fetchTableData,
        openAddDialog,
        openEditDialog,
        openHistoryDialog,
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HolidaysTable);
