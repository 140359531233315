import PropTypes from 'prop-types';
import {colors} from '../common/styles.js';
import { black } from 'material-ui/styles/colors';

const pricingCommonRowStyle = {
    width:'50px',
    textAlign: 'center',
    borderLeft: '1px solid #787878',
    borderRight: '1px solid #787878',
};

const backgroundColorAnalyst = "rgb(79, 53, 80, 0.5)";
const backgroundColorDeveloper = "rgba(53, 107, 84, 0.79)";
const backgroundColorClient = "rgb(49, 69, 80, 0.5)";
const backgroundColorClientTotal = "rgba(50, 84, 103, 0.83)";

const pricingStyles = {
    iconStyle: {
        verticalAlign: 'bottom',
        cursor: 'pointer'
    },
    expandButton:{
        width:'24px', 
        height:'24px', 
        padding:'0px'
    },
    inlineBlockStyle: {
        display: 'inline-block'
    },

    propTypes: {
        login: PropTypes.object,
        sideMenu: PropTypes.object,
        projects: PropTypes.array,
        selectedProject: PropTypes.object,
        onLoadingStart: PropTypes.func,
        onLoadingEnd: PropTypes.func,
        onLoadingError: PropTypes.func,
        onSetIssues: PropTypes.func
    },
    
    // STYLES TD COLUMN//
    pricingCheckBoxRowStyle: {
        width: '60px',
        textAlign: 'center',
        borderRight: '1px solid #787878'
    },
    pricingCheckBoxRowStyleWithoutBorder:{
        width: '60px',
    },
    tableJiraRowStyle: {
        width:'140px',
        whiteSpace: 'normal',
        textAlign: 'left'
    },
    tableRedmineRowStyle: {
        width:'140px',
        whiteSpace: 'normal'
    },
    tableNameRowStyle: {
        width:'280px',
        whiteSpace: 'normal'
    },
    tableFullNameRowStyle: {
        width:'180px',
        whiteSpace: 'normal',
    },
    tableAnalystEstimationColStyle: {
        backgroundColor: backgroundColorAnalyst
    },
    tableDeveloperEstimationColStyle: {
        backgroundColor: backgroundColorDeveloper
    },
    tableClientEstimationColStyle: {
        backgroundColor: backgroundColorClient
    },
    tableTotalEstimationColStyle: {
        backgroundColor: backgroundColorClientTotal
    },
    pricingAnalystRowStyle:{
        ...pricingCommonRowStyle,
        backgroundColor: backgroundColorAnalyst
    },
    pricingDeveloperRowStyle:{
        ...pricingCommonRowStyle,
        backgroundColor: backgroundColorDeveloper
    },
    pricingClientRowStyle:{
        ...pricingCommonRowStyle,
        backgroundColor: backgroundColorClient
    },
    pricingTotalRowStyle:{
        ...pricingCommonRowStyle,
        backgroundColor: backgroundColorClientTotal
    },
    commentsRowStyle: {
        width: '100%',
        whiteSpace: 'normal'
    },
    // STYLES TH COLUMN//
    headerColStyle: {
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
        textAlign: 'center',
    },
    
    pricingCheckBoxColStyle: {
        width: '60px',
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
        textAlign: 'center'
    },
    tableJiraColStyle: {
        width:'140px',
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
        textAlign: 'left',
    },
    tableRedmineColStyle: {
        width:'140px',
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
        textAlign: 'left'
    },
    tableNameColStyle: {
        width:'280px',
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
    },
    tableFullNameColStyle: {
        width:'180px',
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
        textAlign: 'left',
        paddingLeft: '11px !important',
    },
    pricingAnalystColspan4ColStyle:{
        width: '200px',
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
        textAlign: 'center',
    },
    pricingAnalystColspan2ColStyle:{
        width: '100px',
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
        textAlign: 'center',
    },
    commentsColStyle: {
        width: '80%',
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: 'white',
        textAlign: 'center',
    },
    scrollBarColStyle: {
        width: '20%',
        borderBottom: '1px solid ' + colors.nexioOrange,
    },
    // STYLES ROTATE COLUMN//
    headerDoubleColStyle: {
        borderBottom: '1px solid ' + colors.nexioOrange,
        paddingLeft: '0px !important',
        paddingRight: '0px !important'
    },
    headerChildTopRowStyle: {
        display: 'block',
        borderBottom: 'none',
        height: '20px',
        marginTop: '10px'
    },
    headerChildDownRowStyle: {
        display: 'flex',
        borderBottom: 'none',
    },

    tableHeaderStyle: {
        borderBottom: '1px solid ' + colors.nexioOrange,
        color: colors.nexioOrange,
        fontSize: '1.2e',
        fontWeight: 'bold',
        textAlign: 'center',
        colSpan: '4'
    },
    textAreaStyle: {
        field: {
            width: 150,
            marginRight: 50
        },
        floatingLabelFocusStyle: {
            fontSize: 12,
            fontWeight: 'normal',
            color: 'white'
        },
        underlineStyle: {
            borderColor: colors.nexioOrange
        }
    },
    headerNumberColStyle: {
        borderBottom: 'none',
        color: 'white',
        writingMode: 'tb-rl'
    },
    snackStyle: {
        backgroundColor: 'black',
        color: colors.nexioOrange
    },
    differentPrice:{
        ...pricingCommonRowStyle,
        backgroundColor: 'palevioletred'
    }
};

export default pricingStyles;