//CSS
import './assets/bootstrap_dark.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles/index.scss';
//Babel
import '@babel/polyfill';
//React
import React from 'react';
import reactDOM from 'react-dom';
//Redux
import {Provider} from 'react-redux';
import initStore from './store';
import { OidcProvider } from 'redux-oidc';
import userManager from './authentication/user-manager';
//App
import Routes from './routes';
//Actions
import moment from 'moment';
import setHeaderAuthToken from './components/login/helpers/setHeaderAuthToken';
import { applyAxiosInterceptors } from './store/config/axiosconfig';
import { getAccessToken } from './store/Login';
export const store = initStore();

const token = getAccessToken();
if (token) setHeaderAuthToken(token);

applyAxiosInterceptors();

// From moment documentation
// ISO-8601, Europe
moment.updateLocale("en", { week: {
    dow: 1, // First day of week is Monday
    doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
  }});

reactDOM.render(    
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <Routes />
    </OidcProvider>  
  </Provider>, document.getElementById('app'));