import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUnsettledEmployees } from '../../../../store/teamTimeSheet/operations';
import Pagination from '../../../common/partials/pagination/pagination';
import CalendarControls from '../../../timeSheet/calendarPanel/CalendarControls';
import { TableStyles, HeaderStyles, SwitchStyles } from '../../style';
import DataTable from '../../../common/DataTable';
import { EN } from '../../../common/translations';
import actions from '../../../../store/teamTimeSheet/actions';
//Material
import MailIcon from '@material-ui/icons/Mail';
import IconButton from 'material-ui/IconButton';
import {Switch, FormControlLabel } from '@material-ui/core';
import SendMailDialog from './sendMailFormDialog';
import { checkRolesAndTeamRoles } from '../../../common/helpers/authorization';
import { Constants } from '../../../common/constants';

class UnsettledEmployees extends Component {
    constructor(props) {
        super(props);
        this.columns = this.getColumns();
    }

    componentDidMount() {
        this.props.getUnsettledEmployees(1, this.props.month, this.props.year, this.props.isOnlyMyTeamEnabled);
    }

    getColumns() {
        return [
            {
                header: EN.teamTimesheet.unsettledEmployees.employeeColumn,
                valueProperty: "employee"
            },
            {
                header: (
                    <>
                        <span style={{lineHeight:5}}>{EN.teamTimesheet.unsettledEmployees.actionColumn}</span>
                        <IconButton tooltip={EN.tooltip.sendMailToAllEmployees} onClick={this.props.openDialogMailToAll}>
                            <MailIcon />
                        </IconButton>
                    </>),
                getValue: row => (
                    <>
                        <IconButton tooltip={EN.tooltip.sendMailToEmployee}  onClick={() => this.props.openDialogMailToEmployee(row.id, row.employee)}>
                            <MailIcon />
                        </IconButton>
                    </>)
            },
        ];
    }

    handleOnlyMyTeamChange() {
        this.props.toggleOnlyMyTeamSwitch();
        this.props.getUnsettledEmployees(1, this.props.month, this.props.year, !this.props.isOnlyMyTeamEnabled);
    }

    render() {
        const calendarControlsProps = {
            month: this.props.month,
            year: this.props.year,
            page: this.props.tablePage,
            onChange: (month, year) => this.props.getUnsettledEmployees(this.props.tablePage, month, year, this.props.isOnlyMyTeamEnabled),
        };
        const isSupervisorAndHr = checkRolesAndTeamRoles(this.props.user,[Constants.Roles.supervisor], null) 
            && checkRolesAndTeamRoles(this.props.user,[Constants.Roles.hr], null);

        return (<>
            <div style={SwitchStyles}>
                {isSupervisorAndHr && <FormControlLabel
                    control={<Switch color="primary" />}
                    label={"Only my team"}
                    checked={this.props.isOnlyMyTeamEnabled}
                    onChange={() => this.handleOnlyMyTeamChange()}
                    />}
                <h3 style={HeaderStyles}>{EN.teamTimesheet.unsettledEmployees.title}</h3>
            </div>
            <div className="calendar-panel">
                <div className="calendar">
                    <div className="calendar-controls">
                        <CalendarControls {...calendarControlsProps}/>
                    </div>
                </div>
            </div>
            <DataTable
                    columns={this.columns}
                    cellStyle={TableStyles.headerColStyle}
                    headerStyle={TableStyles.headerColStyle}
                    tableStyle={TableStyles.table}
                    data={this.props.tableData}
                />
            <Pagination
                onChange={(page) => this.props.getUnsettledEmployees(page, this.props.month, this.props.year, this.props.isOnlyMyTeamEnabled)}
                value={this.props.tablePage}
                count={this.props.tableCount}
            />
            <SendMailDialog/>
        </>);
    }
}

const mapStateToProps = state => ({
    tableData: state.teamTimesheet.unsettledEmployees.dataTable,
    tablePage: state.teamTimesheet.unsettledEmployees.page,
    tableCount: state.teamTimesheet.unsettledEmployees.count,
    year: state.teamTimesheet.teamTimesheetDate.year,
    month: state.teamTimesheet.teamTimesheetDate.month,
    isOnlyMyTeamEnabled: state.teamTimesheet.isOnlyMyTeamEnabled,
    user: state.login.user,
});

const mapDispatchToProps = dispatch => ({
    getUnsettledEmployees: (page, month, year, isOnlyMyTeamEnabled) => dispatch(getUnsettledEmployees(page, month, year, isOnlyMyTeamEnabled)),
    openDialogMailToAll: () => dispatch(actions.openMailDialog(0, true, "")),
    openDialogMailToEmployee: (id, selectedEmployee) => dispatch(actions.openMailDialog(id, false, selectedEmployee)),
    toggleOnlyMyTeamSwitch: () => dispatch(actions.toggleOnlyMyTeamSwitch())
});

export default connect(mapStateToProps, mapDispatchToProps)(UnsettledEmployees);