import _ from 'lodash';

export function hasAnyRole(user, roles) {
    return _.intersection(roles, user.roles).length > 0;
}

export function hasAnyTeamRole(user, teamRoles) {
    return teamRoles.map(x => user.teamRoles[x]).some(x => x && x.length > 0);
}

export function checkRolesAndTeamRoles(user, roles, teamRoles) {
    return !roles && !teamRoles ||
        roles && hasAnyRole(user, roles) ||
        teamRoles && hasAnyTeamRole(user, teamRoles);
}

export function getAvailableTeams(user, teamRoles) {
    const teams = _.flatten(teamRoles.map(x => user.teamRoles[x]).filter(x => x));
    return _.sortedUniq(_.sortBy(teams, x => x));

}