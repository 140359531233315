import React from 'react';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import WorkOutline from '@material-ui/icons/WorkOutline';
import _ from 'lodash';

export const CornerHeader = (props) => {
  return (
    <React.Fragment>
      <div className="report-filters-background-container">
        <div className="report-filters-background"/>
        <div className="report-filters-background-2"/>
      </div>
      <div className="report-filters-background-lines" >
        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%' }} version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100">
          <line x1="0" y1="0" x2="85" y2="72" strokeWidth="2" stroke="#E38A09" vectorEffect="non-scaling-stroke" />
          <line x1="85" y1="72" x2="100" y2="105" strokeWidth="2" stroke="#E38A09" vectorEffect="non-scaling-stroke" />
        </svg>
      </div>
      <div className="report-table-filters">
        <PeopleOutline className="report-filter-icon worker-icon" />
        <WorkOutline className="report-filter-icon project-icon" />
        {
          props.onUserDialogClick ?
            <Button className="btn worker-button" onClick={props.onUserDialogClick}>
              <SearchIcon /> Workers
        </Button> : <span className="worker-text">Workers</span>
        }
        {
          props.onProjectDialogClick ?
            <Button className="btn project-button" onClick={props.onProjectDialogClick}>
              <SearchIcon /> Projects
        </Button> : <span className="project-text">Projects</span>
        }

      </div>
    </React.Fragment>
  );
};