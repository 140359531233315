import { host } from '../components/common/cfg';
import axios from 'axios';
import moment from 'moment';
import { onLoadingStart, onLoadingEnd, onLoadingError } from './Preloader';
import { onSetIssues } from './Issuses';
import { httpReqErrorHandler } from '../components/common/helpers/errorHandler';
import { dateFormat } from '../components/common/helpers/date';
import { onAddFlashMessage } from './FlashMessage';

const types = {
    GET_ISSUES_AJAX: 'GET_ISSUES_AJAX',
};

// ASYNC ACTIONS

export const onGetIssuesAjax = (projectId, startDate, endDate, buildGrouppedIssues) => {
    return dispatch => {

        dispatch(onLoadingStart(`Loading Timesheet`));
        let params = { projectId, startDate, endDate };
         
        axios.get(`${host}/api/timesheet`, { params })
            .then(response => {            
                dispatch(getIssuesAjax(response.data));
                dispatch(onLoadingEnd());
                let issues = [...response.data];
                issues = issues.map(issue => {
                    issue.timesheetRows = issue.timesheetRows.map(timesheetRow => {
                        timesheetRow.date = moment(timesheetRow.date).format(dateFormat).toString();
                        return timesheetRow;
                    });
                    return issue;
                });

                dispatch(onSetIssues(issues));
                buildGrouppedIssues();
            })
            .catch(error => {
                dispatch(onLoadingError(error.response ? error.response.data.error : error));
                dispatch(onAddFlashMessage(httpReqErrorHandler(error)));
            });
    };
};

// END ASYNC ACTIONS

//ACTIONS

export const getIssuesAjax = data => {
    return {
        type: types.GET_ISSUES_AJAX,
        data: data
    };
};

// END ACTIONS


//REDUX

const initialState = {
    issuesAjax: [],
};

export const issuesNew = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ISSUES_AJAX:
            return {
                ...state,
                issuesAjax: action.data
            };

    }
    return state;
};