import React from 'react';
import {colors} from '../styles.js';

class OrangeText extends React.Component{
    render(){
        return(
            <span style={{color: colors.nexioOrange}}>{this.props.children}</span>
        );
    }
}

export default OrangeText;