import React, { useRef, useCallback, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import UserTasksList from './UserTasksList';
import Tooltip from '@material-ui/core/Tooltip';

const transitionProps = { mountOnEnter: true, unmountOnExit: true };

const UserTasksDialog = ({ onClose, open, data }) => (
    <Dialog fullWidth onClose={onClose} open={open} maxWidth="md" transitionProps={transitionProps}>
        {data && <DialogTitle>
            {data.firstname} {data.lastname} tasks
        </DialogTitle>}
        {data && <DialogContent>
            <UserTasksList userId={data.id} />
        </DialogContent>}
    </Dialog>
);

export default UserTasksDialog;