/*React Libs*/
import React from 'react';
import PropTypes from 'prop-types';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
/*Store*/
import { onLoadingStart, onLoadingEnd, onLoadingError } from '../../store/Preloader';
import { onAddFlashMessage, onClearFlashMessages } from '../../store/FlashMessage';
import { onEditOrAddTask, getTeamTasks, getAllUsers, getDevelopersByTeam, onDeleteTask } from '../../store/PricingStore';
import { calculateTopBarHeight } from '../common/helpers/storeUtils';
/*Material*/
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import Snackbar from 'material-ui/Snackbar';
import Checkbox from 'material-ui/Checkbox';
import IconButton from 'material-ui/IconButton';
//Common
import { colors } from '../common/styles.js';
import { EN } from '../common/translations';
import {httpReqErrorHandler} from '../common/helpers/errorHandler';
import { ConfirmContext, deleteMessage } from '../confirm/ConfirmDialog';
//Custom
import TaskDialog from './taskDialogPanel/TaskDialog';
import PricingFilter from './filterPanel/PricingFilter';
import pricingStyles from './pricingStyles';
//Icons
import ContentAdd from '@material-ui/icons/Add';
import FilterIcon from '@material-ui/icons/FilterList';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
//3rd party
import _ from 'lodash';
import RaisedButton from 'material-ui/RaisedButton/RaisedButton';
import { grey500 } from 'material-ui/styles/colors';
import { jiraTaskUrl, redmineIssueUrl } from '../common/helpers/urls';

// STYLES // CONST // FUNCTIONS //
// display only 100 characters
const Comments = (props) => {
    if (props.value && props.value.length > props.maxLength) {
        return (<span>{props
            .value
        .substring(0, props.maxLength) + '...'}</span>);
    }
    return <span>{props.value}</span>;
};

const parentKeyClassName = 'parent-key-';
const idKey = 'id-key-';

class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fixedHeader: true,
            showRowHover: true,
            showCheckboxes: false,
            openModal: false,
            selectable: false,
            editData: {},
            sorting: 'jiraDesc',
            tableData: [],
            snackMessage: '',
            snackOpen: false,
            developerList: [],
            developersList: [],
            analystList: [],
            openFilter: false,
            previousTeamId: 1,
            searchData:{
                jiraTaskId:'',
                redmineTaskId:'',
                analyst:'',
                developRealizator:'',
                developEstimator:'',
                onlyNotSolved:true,
                onlyNotEstimated:false
            }
        };


        this.filterTasks = this.filterTasks.bind(this);
        this.getUserByRole = this.getUserByRole.bind(this);
        this.onTextFieldChange = this.onTextFieldChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.DeleteTask = this.DeleteTask.bind(this);
        this.UpdateTask = this.UpdateTask.bind(this);
        this.onAddClickHandler = this.onAddClickHandler.bind(this);
        this.onCellClickHandler = this.onCellClickHandler.bind(this);
        this.onFilterClickOpenHandler = this.onFilterClickOpenHandler.bind(this);
        this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
        this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.getErrorText = this.getErrorText.bind(this);
        this.onShowChildButtonClick = this.onShowChildButtonClick.bind(this);
        this.dataCopy = [];
        this.oldSolvedFilter = true;
        this.editedTaskId = -1;
    }
    
    componentDidMount() {
        this
            .props
            .onLoadingStart('Loading tasks.');
        this.props.getTeamTasks(this.state.searchData.onlyNotSolved, this.props.teamId).then(response => {
                this.dataCopy = response.data;
                this.filterTasks();
                this
                    .props
                    .onLoadingEnd();
            });
        this.props.getAllUsers(this.state.previousTeamId).then(response => {
                let developers = this.getUserByRole(response.data, 'developer');
                let analysts = this.getUserByRole(response.data, 'analyst');
                this.setState({ developerList: developers, analystList: analysts});
            });
        this.props.getDevelopersByTeam(this.props.teamId).then(response => {
            let developersList = this.getDevelopersList(response.data);
            this.setState({developersList: developersList});
        });
    }

    componentDidUpdate() {
        if (this.state.previousTeamId !== this.props.teamId) {
            const teamId = this.props.teamId;
            this.props.getTeamTasks(this.state.searchData.onlyNotSolved, teamId).then(response => {
                        this.setState({ tableData: response.data });
                    });
            //eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                previousTeamId: teamId,
            });
            this.props.getAllUsers(this.props.teamId).then(response => {
                    let developers = this.getUserByRole(response.data, 'developer');
                    let analysts = this.getUserByRole(response.data, 'analyst');
                    this.setState({ developerList: developers, analystList: analysts});
                });
            this.props.getDevelopersByTeam(this.props.teamId).then(response => {
                let developersList = this.getDevelopersList(response.data);
                this.setState({developersList: developersList});
            });
        }
    }
    
    //FILTER TABLE FUNCTIONS
    filterTasks() {
        let tableDataCopy = this.dataCopy.slice();
        if (this.state.searchData.jiraTaskId)
            tableDataCopy = _.filter(tableDataCopy, x => (x.jiraTaskId.toUpperCase().lastIndexOf(this.state.searchData.jiraTaskId.toUpperCase()) !== -1));
        if (this.state.searchData.redmineTaskId)
            tableDataCopy = _.filter(tableDataCopy, x => (x.redmineTaskId && x.redmineTaskId.toString().toUpperCase().lastIndexOf(this.state.searchData.redmineTaskId.toString().toUpperCase()) !== -1));
        if (this.state.searchData.analyst)
            tableDataCopy = _.filter(tableDataCopy, x => (x.analyst && x.analyst.toUpperCase().lastIndexOf(this.state.searchData.analyst.toUpperCase()) !== -1));
        if (this.state.searchData.developEstimator)
            tableDataCopy = _.filter(tableDataCopy, x => (x.developEstimator && x.developEstimator.toUpperCase().lastIndexOf(this.state.searchData.developEstimator.toUpperCase()) !== -1));
        if (this.state.searchData.developRealizator)
            tableDataCopy = _.filter(tableDataCopy, x => (x.developRealizator && x.developRealizator.toUpperCase().lastIndexOf(this.state.searchData.developRealizator.toUpperCase()) !== -1));
        if (this.state.searchData.onlyNotEstimated)
            tableDataCopy = _.filter(tableDataCopy, x => (x.developerPricing === null || x.analystDevPricingForClient === null));
        this.setState({ tableData: tableDataCopy });
    }

    //ADITIONAL FUNCTIONS
    //get user list by role
    getUserByRole(users, role) {
        return users.filter(function (user) {
            if (!user.teamRoles) {
                return false;
            }
            return user.teamRoles.indexOf(role) !== -1;
        }).map(function (user) {
            return user.firstname + ' ' + user.lastname;
        });
    }

    //get developer list
    getDevelopersList(users) {
        return users.map(function (user) {
            return {id: user.id, name: user.firstname + ' ' + user.lastname, status:user.status};
        });
    }

    //TASK DIALOG PANEL EVENT LOGIC
    onTextFieldChange(field, value) {
        let editDataCopy = this.state.editData;
        editDataCopy[field] = value;
        this.setState({ editData: editDataCopy });
    }
    getErrorText(field, value)
    {
        if(_.filter(this.dataCopy, x => (x[field] == value)).length > 0)
            return EN.pricingPage.warningTaskExists;
    }
    handleClose () {
        this.setState({ openModal: false });
    }
    handleDelete() {
        const confirm = this.context(deleteMessage);
        confirm (() => {
            this.DeleteTask(this.state.editData.id);
            this.setState({ openModal: false });
        });
    }
    handleSubmit(editedTask) {
        if(!editedTask.name || (editedTask.child && !editedTask.redmineTaskId))
        {
            this.props.onAddFlashMessage({type: 'error', text: 'Please fill in all required fields.'});
            setTimeout(() => {this.props.onClearFlashMessages();}, 1000);
            return;
        }
        let task = {
            ...editedTask,
            id: editedTask.id === '' ? 0 : editedTask.id,
            solved: editedTask.solved === '' ? false : editedTask.solved,
            parentJira: editedTask.child ? editedTask.jiraTaskId : '',
            teamId: this.props.teamId
        };
        this.UpdateTask(task, false);
    }

    //INVOKE API FUNCTIONS
    DeleteTask(id) {
        this
            .props
            .onLoadingStart('Deleting task.');
        this.props.onDeleteTask(id).then((response) => {
            this.props.getTeamTasks(this.state.searchData.onlyNotSolved, this.props.teamId).then(response => {
                        this.setState({ tableData: response.data, snackMessage: EN.pricingPage.successDeleteMessage, snackOpen: true });
                        this.props.onLoadingEnd();
                    });
            }, (reject) => {
                this.props.onLoadingError(reject.response ? reject.response.data.error : reject);
                this.props.onAddFlashMessage(httpReqErrorHandler(reject));
                setTimeout(() => {this.props.onClearFlashMessages();}, 5000);
            });
    }

    UpdateTask(task, solvedChange) {
        this
            .props
            .onLoadingStart('Updating task.');
        this.props.onEditOrAddTask(task).then((response) => {
            this.editedTaskId = response.data.id;
            this.setState({ snackMessage: solvedChange ? EN.pricingPage.successSolvedMessage : (EN.pricingPage.successMessage + response.data.warning), snackOpen: true });
                this.props.getTeamTasks(this.state.searchData.onlyNotSolved, this.props.teamId).then(response => {
                        this.dataCopy = response.data;
                        this.filterTasks();
                        this.props.onLoadingEnd();
                        const elem = document.getElementsByClassName("newRow")[0];
                        if(elem)
                        {
                            elem.scrollIntoView({block: 'center'});             
                            elem.classList.add("highlight");
                        }
                        this.setState({ openModal: false });
                    });
            }, (reject) => {
                this.props.onLoadingError(reject.response ? reject.response.data.error : reject);
                this.props.onAddFlashMessage(httpReqErrorHandler(reject));
                setTimeout(() => {this.props.onClearFlashMessages();}, 5000);
            });
    }

    //TABLE EVENT FUNCTIONS
    onShowChildButtonClick(id){
        let childTr = document.querySelectorAll("." + parentKeyClassName + id);
        let display;
        let parentElement = document.querySelector("." + idKey + id).parentNode;
        childTr.forEach(function(tr) {
            parentElement.parentNode.insertBefore(tr, parentElement.nextSibling);
            if(!display)
                display = tr.getAttribute('style').includes("display: none") ? 'table-row' : 'none';
            tr.style.display = display;
            tr.style.backgroundColor = '#4c4c4c';
        });
        document.querySelector("." + idKey + id + " > span:nth-child(1) > button > div > svg:nth-child(" + (display == 'none' ? 3 : 2) + ")").classList.add("displayNone");
        document.querySelector("." + idKey + id + " > span:nth-child(1) > button > div > svg:nth-child(" + (display == 'none' ? 2 : 3) + ")").classList.remove("displayNone");
    }

    onAddClickHandler() {
        let editData = {
            id: '',
            redmineTaskId: '',
            jiraTaskId: '',
            name: '',
            analyst: '',
            developEstimator: '',
            developRealizator: '',
            developRealizatorId: '',
            analystPricing: '',
            analystDevPricing: '',
            testerPricing: '',
            developerPricing: '',
            developerMd: '',
            analystPricingForClient: '',
            analystDevPricingForClient: '',
            testerPricingForClient: '',
            child: false,
            solved: '',
            description: '',
            teamId: '',
            taskProgresses: []
        };
        this.setState({ openModal: true, editData: editData });
    }
    onCellClickHandler(row, cell) {
        if (cell != 1 && cell != 2) {
            let dataToEdit = this.state.tableData[row];
            let editData = {
                id: dataToEdit.id,
                redmineTaskId: dataToEdit.redmineTaskId,
                jiraTaskId: dataToEdit.jiraTaskId,
                name: dataToEdit.name,
                analyst: dataToEdit.analyst,
                developEstimator: dataToEdit.developEstimator,
                developRealizator: dataToEdit.developRealizator,
                developRealizatorId: dataToEdit.developRealizatorId,
                analystPricing: dataToEdit.analystPricing,
                analystDevPricing: dataToEdit.analystDevPricing,
                testerPricing: dataToEdit.testerPricing,
                developerPricing: dataToEdit.developerPricing,
                developerMd: dataToEdit.developerMd,
                analystPricingForClient: dataToEdit.analystPricingForClient,
                analystDevPricingForClient: dataToEdit.analystDevPricingForClient,
                testerPricingForClient: dataToEdit.testerPricingForClient,
                solved: dataToEdit.solved,
                child: dataToEdit.child,
                description: dataToEdit.description,
                taskProgresses: dataToEdit.taskProgresses,
                teamId: dataToEdit.teamId,
                teams: this.props.teams
            };
            if(cell == 0)
            {
                editData.solved = !editData.solved;
                this.setState({ editData: editData });
                this.UpdateTask(editData, true);
            }
            else{
                this.setState({ editData: editData, snackOpen: false, openModal: true });
            }
        }
    }
    onFilterClickOpenHandler(){
        this.setState({openFilter: !this.state.openFilter});
    }

    // FILTER PANEL EVENT LOGIC
    onSearchFieldChange(field, value){
        let searchDataCopy = this.state.searchData;
        if(field == '')
            this.oldSolvedFilter = true;
        searchDataCopy[field] = value;
        this.setState({ searchData: searchDataCopy });
    }
    onSearchButtonClick(){
       this.props.onLoadingStart('Filter task');
       this.setState({openFilter: false});
       if(this.oldSolvedFilter != this.state.searchData.onlyNotSolved){
            this.props.getTeamTasks(this.state.searchData.onlyNotSolved, this.props.teamId).then(response => {
                this.dataCopy = response.data;
                this.filterTasks();
                this.oldSolvedFilter = this.state.searchData.onlyNotSolved;
                this.props.onLoadingEnd();
            });
        }
       else{
            this.filterTasks();
            this.props.onLoadingEnd();
       }
    }
    
    
    onCloseButtonClick = () => {
        this.setState({ openFilter: false });
    }

    // SNACKBAR EVENT FUNCTIONS
    handleRequestClose (){
        this.setState({
            snackOpen: false,
        });
      }

    // RENDER COMPONENTS
    render() {
        return (
            <>
                <TaskDialog
                    bodyStyle={{ maxWidth: '680px' }}
                    isOpened={this.state.openModal}
                    onCancelClick={this.handleClose}
                    onSubmitClick={this.handleSubmit}
                    onDeleteClick={this.handleDelete}
                    data={this.state.editData}
                    developerList={this.state.developerList}
                    developersList={this.state.developersList}
                    availalbeTeams={this.props.teams}
                    analystList={this.state.analystList}
                    getErrorText={(field, value) =>this.getErrorText(field, value)}
                    textFieldChange={(field, value) => this.onTextFieldChange(field, value)} />
                <Table
                    className="table table-header-rotated"
                    height={`calc(100vh - ${this.props.topBarHeight}px - 150px)`}
                    fixedHeader={this.state.fixedHeader}
                    fixedFooter={this.state.fixedFooter}
                    selectable={this.state.selectable}
                    headerStyle={{minWidth: "1908px"}}
                    bodyStyle={{ overflowY: "scroll", minWidth: "1908px" }}
                    onCellClick={(row, cell) => this.onCellClickHandler(row, cell)}>
                    <TableHeader
                        displaySelectAll={this.state.showCheckboxes}
                        adjustForCheckbox={this.state.showCheckboxes}
                        enableSelectAll={this.state.enableSelectAll}>
                        <TableRow>
                            <TableHeaderColumn style={pricingStyles.pricingCheckBoxColStyle} className="pricing-rotate-45"><div><span>{EN.pricingPage.doneCol}</span></div></TableHeaderColumn>
                            <TableHeaderColumn className="pricingJiraCol" style={pricingStyles.tableJiraColStyle}>{EN.pricingPage.jiraCol}</TableHeaderColumn>
                            <TableHeaderColumn style={pricingStyles.tableRedmineColStyle}>{EN.pricingPage.redmineTaskIdCol}</TableHeaderColumn>
                            <TableHeaderColumn className="pricingNameCol" style={pricingStyles.tableNameColStyle}>{EN.pricingPage.nameCol}</TableHeaderColumn>
                            <TableHeaderColumn className="pricingFullNameCol" style={pricingStyles.tableFullNameColStyle}>{EN.pricingPage.analystCol}</TableHeaderColumn>
                            <TableHeaderColumn className="pricingEstimCol" style={pricingStyles.tableFullNameColStyle}>{EN.pricingPage.estimationsDeveloperCol}</TableHeaderColumn>
                            <TableHeaderColumn className="pricingRealizationCol" style={pricingStyles.tableFullNameColStyle}>{EN.pricingPage.realizationsDeveloperCol}</TableHeaderColumn>
                            <TableHeaderColumn colSpan="4" style={pricingStyles.pricingAnalystColspan4ColStyle}>
                                <div className="estimationName" style={pricingStyles.headerChildTopRowStyle}><span>{EN.pricingPage.analystsEstimationCol}</span></div>
                                <div style={pricingStyles.headerChildDownRowStyle}>
                                    <div className="rotate child"><div style={pricingStyles.tableAnalystEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.analysisCol}</span></div></div>
                                    <div className="rotate child"><div style={pricingStyles.tableAnalystEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.developmentCol}</span></div></div>
                                    <div className="rotate child"><div style={pricingStyles.tableAnalystEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.testingCol}</span></div></div>
                                    <div className="rotate child"><div style={pricingStyles.tableAnalystEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.total}</span></div></div>
                                </div>
                            </TableHeaderColumn>
                            <TableHeaderColumn colSpan="2" style={pricingStyles.pricingAnalystColspan2ColStyle}>
                                <div style={pricingStyles.headerChildDownRowStyle}>
                                    <div className="rotate child"><div style={pricingStyles.tableDeveloperEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.developersEstimationCol}</span></div></div>
                                    <div className="rotate child"><div style={pricingStyles.tableDeveloperEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.developersMdCol}</span></div></div>
                                </div>
                            </TableHeaderColumn>
                            <TableHeaderColumn colSpan="4" style={pricingStyles.pricingAnalystColspan4ColStyle}>
                                <div className="estimationName" style={pricingStyles.headerChildTopRowStyle}><span>{EN.pricingPage.estimationForClientCol}</span></div>
                                <div style={pricingStyles.headerChildDownRowStyle}>
                                    <div className="rotate child"><div style={pricingStyles.tableClientEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.analysisCol}</span></div></div>
                                    <div className="rotate child"><div style={pricingStyles.tableClientEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.developmentCol}</span></div></div>
                                    <div className="rotate child"><div style={pricingStyles.tableClientEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.testingCol}</span></div></div>
                                    <div className="rotate child withRightBorder"><div style={pricingStyles.tableTotalEstimationColStyle}><span className='rotatedSpan'>{EN.pricingPage.total}</span></div></div>
                                </div>
                            </TableHeaderColumn>
                            <TableHeaderColumn style={pricingStyles.commentsColStyle}>{EN.pricingPage.commentsCol}</TableHeaderColumn>
                            <TableHeaderColumn style={pricingStyles.scrollBarColStyle}>
                                <div style={{width: '30px', float: 'right'}}>
                                    <RaisedButton
                                        style={{float: 'right',
                                            minWidth: '35px',
                                            marginRight: '10px'}}
                                        backgroundColor={colors.nexioOrange}
                                        onClick={() => this.onAddClickHandler()}>
                                        <ContentAdd style={{marginTop: '6px'}}/>
                                    </RaisedButton>
                                    <RaisedButton
                                        style={{float: 'right',
                                            marginRight: '10px',
                                            minWidth: '35px',
                                            marginTop: '5px'}}
                                        backgroundColor={grey500}
                                        onClick={() => this.onFilterClickOpenHandler()}>
                                        <FilterIcon style={{marginTop: '6px'}}/>
                                    </RaisedButton>
                                </div>
                            </TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody
                        className="tableBody"
                        displayRowCheckbox={this.state.showCheckboxes}
                        deselectOnClickaway={this.state.deselectOnClickaway}
                        showRowHover={this.state.showRowHover}
                        stripedRows={this.state.stripedRows}>
                        {this
                            .state
                            .tableData
                            .map((row) => (
                                <TableRow key={row.id} id={row.id} className={"hoverRowClass" + (row.child ? " " + parentKeyClassName + row.parentId : "") + (row.parent ? " parent" : "") + (row.id === this.editedTaskId ? " newRow" : "")} style={{display:row.child?'none':'table-row'}}>
                                    <TableRowColumn className="pricing" style={!row.child ? pricingStyles.pricingCheckBoxRowStyle : pricingStyles.pricingCheckBoxRowStyleWithoutBorder}>{row.child ? '' : <Checkbox checked={row.solved}/>}</TableRowColumn>
                                    <TableRowColumn className={row.parent? 'parent ' + idKey + row.id:''} style={pricingStyles.tableJiraRowStyle}>
                                        {row.parent ?
                                            <span>
                                            <IconButton 
                                                style={pricingStyles.expandButton}
                                                onClick={() => this.onShowChildButtonClick(row.id)}>
                                                <ExpandMore/> 
                                                <ExpandLess className="displayNone"/> 
                                            </IconButton></span> : ''
                                        }
                                        <span style={row.parent ?{position:'relative',top:'-8px',paddingLeft:'3px'}:{top:'2px', paddingLeft:'28px'}}><a style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" href={jiraTaskUrl(row.jiraTaskId)}>{row.jiraTaskId}</a></span>
                                    </TableRowColumn>
                                    <TableRowColumn style={pricingStyles.tableRedmineRowStyle}><a style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" href={redmineIssueUrl(row.redmineTaskId)}>{row.redmineTaskId}</a></TableRowColumn>
                                    <TableRowColumn style={pricingStyles.tableNameRowStyle}>{row.name}</TableRowColumn>
                                    <TableRowColumn style={pricingStyles.tableFullNameRowStyle}>{row.analyst}</TableRowColumn>
                                    <TableRowColumn style={pricingStyles.tableFullNameRowStyle}>{row.developEstimator}</TableRowColumn>
                                    <TableRowColumn style={pricingStyles.tableFullNameRowStyle}>{row.developRealizator}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={pricingStyles.pricingAnalystRowStyle}>{row.analystPricing}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={pricingStyles.pricingAnalystRowStyle}>{row.analystDevPricing}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={pricingStyles.pricingAnalystRowStyle}>{row.testerPricing}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={pricingStyles.pricingAnalystRowStyle}>{row.analystPricing + row.analystDevPricing + row.testerPricing}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={pricingStyles.pricingDeveloperRowStyle}>{row.developerPricing}</TableRowColumn>
                                    <TableRowColumn style={row.developerMd != undefined && row.analystDevPricingForClient != undefined && row.developerMd != row.analystDevPricingForClient ? pricingStyles.differentPrice : pricingStyles.pricingDeveloperRowStyle} className="pricing">{row.developerMd}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={pricingStyles.pricingClientRowStyle}>{row.analystPricingForClient}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={row.developerMd != undefined && row.analystDevPricingForClient != undefined  && row.developerMd != row.analystDevPricingForClient ? pricingStyles.differentPrice : pricingStyles.pricingClientRowStyle}>{row.analystDevPricingForClient}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={pricingStyles.pricingClientRowStyle}>{row.testerPricingForClient}</TableRowColumn>
                                    <TableRowColumn className="pricing" style={pricingStyles.pricingTotalRowStyle}>{row.analystPricingForClient + row.analystDevPricingForClient + row.testerPricingForClient}</TableRowColumn>
                                    <TableRowColumn style={pricingStyles.commentsRowStyle}><Comments value={row.description} maxLength={100} /></TableRowColumn>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <Snackbar
                    open={this.state.snackOpen}
                    message={this.state.snackMessage}
                    autoHideDuration={6000}
                    onRequestClose={this.handleRequestClose}
                    bodyStyle={pricingStyles.snackStyle}
                    contentStyle={pricingStyles.snackStyle}/>
                <PricingFilter  
                    searchData={this.state.searchData}
                    searchFieldChange={this.onSearchFieldChange}
                    onSearchButtonClick={this.onSearchButtonClick}
                    onCloseButtonClick = {this.onCloseButtonClick}
                    developerList={this.state.developerList}
                    analystList={this.state.analystList}
                    openFilter = {this.state.openFilter}/>
            </>
        );
    }
}

Pricing.propTypes={
    onLoadingStart: PropTypes.func,
    onLoadingEnd: PropTypes.func,
    onLoadingError: PropTypes.func,
    onAddFlashMessage: PropTypes.func,
    onClearFlashMessages: PropTypes.func,
    onDeleteTask: PropTypes.func,
    getTeamTasks: PropTypes.func,
    onEditOrAddTask: PropTypes.func
};

Pricing.contextType = ConfirmContext;

function mapStateToProps(state) {
    return { 
        login: state.login, 
        sideMenu: state.sideMenu, 
        projects: state.projects,
        selectedProject: state.selectedProject, 
        issues: state.issues,
        teamId: state.teams.selectedTeam,
        availalbeTeamIds: state.teams.availableTeamIds,
        teams: Object.entries(state.teams.teams).filter(r => (state.teams.availableTeamIds).includes(r[1].id)),
        topBarHeight: calculateTopBarHeight(state)
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onLoadingStart,
        onLoadingEnd,
        onLoadingError,
        onAddFlashMessage,
        onEditOrAddTask,
        onDeleteTask,
        getTeamTasks,
        onClearFlashMessages,
        getAllUsers,
        getDevelopersByTeam
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Pricing);
