import React from 'react';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow } from 'material-ui/Table';
import _ from 'lodash';
import { getHeader, getUsers, setupScrollableTable } from '../helpers';
import { CornerHeader } from './CornerHeader';


class ReportTable extends React.Component {
	componentDidMount() {
		setupScrollableTable(this, '.sync-scroll-base', '.sync-scroll-follow');
	}

	shouldComponentUpdate(prevProps) {
		return prevProps.users !== this.props.users ||
			prevProps.userActiveItems !== this.props.userActiveItems ||
			prevProps.topBarHeight !== this.props.topBarHeight ||
			prevProps.projectsTime !== this.props.projectsTime ||
			prevProps.projects !== this.props.projects ||
			prevProps.unit !== this.props.unit ||
			prevProps.projectActiveItems !== this.props.projectActiveItems;
	}

	render() {
		const props = this.props;
		return (
			<Table
				className="table report-table-header-rotated table-header-rotaded-ext"
				height={`calc(100vh - ${props.topBarHeight}px - 96px)`}
				wrapperStyle={{ overflow: 'visible' }}
				fixedHeader
				bodyStyle={{ height: 'calc(100vh - 300px)', overflow: 'auto', scrollbarWidth: 'thin' }}
				headerStyle={{ overflow: 'hidden' }}
			>
				<TableHeader
					displaySelectAll={false}
					adjustForCheckbox={false}
					className="sync-scroll-follow"
					style={{ marginTop: "50px" }}>
					<TableRow style={{ position: "relative" }}>
						<TableHeaderColumn className="report-table-corner-header">
							<CornerHeader
								onUserDialogClick={props.onUserDialogClick}
								onProjectDialogClick={props.onProjectDialogClick} />
						</TableHeaderColumn>
						{getHeader(props.projects, props.projectActiveItems, props.onHeaderClick)}
					</TableRow>
				</TableHeader>
				<TableBody className="sync-scroll-base"
					displayRowCheckbox={false}>
					{getUsers(props.projectsTime, props.users, props.userActiveItems, props.projectActiveItems, props.onHeaderClick, props.unit, props.overtimes, props.holidaysInYear, props.isYearly)}
				</TableBody>
			</Table>
		);
	}
}

export default ReportTable;