import { host } from '../components/common/cfg';
import axios from 'axios';

export const types = {
    INTRANET_NOTIFICATIONS_FETCHED: 'INTRANET_NOTIFICATIONS_FETCHED',
    INTRANET_NOTIFICATIONS_CLEARED: 'INTRANET_NOTIFICATIONS_CLEARED' 
};

/** ACTIONS START */
export const onNotificationFetched = (data) => ({ type: types.INTRANET_NOTIFICATIONS_FETCHED, data });
export const onNotificationClear = (data) => ({ type: types.INTRANET_NOTIFICATIONS_CLEARED, data });

export const fetchIntranetNotifications = () => {
    return (dispatch) => {

        let headers = {
            'headers': {
                'Content-Type': 'application/json;text/plain;text/json',
                'Accept': 'application/json'
            }
        };

        return axios
            .get(`${host}/api/IntranetNotification/get`, headers)
            .then(response => {
                dispatch(onNotificationFetched(response.data));
            });
    };
};

export const clearIntranetNotifications = () => {
    return (dispatch) => {
        dispatch(onNotificationClear());
    };
};

/** REDUCER START */
const initialState = {
    intranetTablesExists: false,
    intranetNotificationsCount: 0,
};

export const intranetNotificationStore = (state = initialState, action) => {
    let user = null;
    switch (action.type) {
        case types.INTRANET_NOTIFICATIONS_FETCHED: {
            return {
                ...state,
                intranetTablesExists: action.data.intranetTablesExists,
                intranetNotificationsCount: action.data.intranetNotificationsCount,
            };
        }
        case types.INTRANET_NOTIFICATIONS_CLEARED: {
            return {
                ...state,
                intranetNotificationsCount: 0,
            };
        }
        default:
            return state;
    }
};
/** REDUCER END */
