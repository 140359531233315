import { API } from './types';

const types={
    ON_FETCH_SETTINGSVALUES: "SETTINGS_ON_FETCH_SETTINGSVALUES"
};

const headers = {
    'headers': {
        'Content-Type': 'application/json;text/plain;text/json',
        'Accept': 'application/json'
    }
};

export function fetchSettingsValues() {
    return (dispatch) => {
        dispatch({
            type: API,
            payload: {
                method: "GET",
                url: "settings/settingsValues",
                onSuccess: (response) => ({type: types.ON_FETCH_SETTINGSVALUES, response})
            }
        });
    };
}

export function updateSettingsValues(data) {
    return (dispatch) => {
        dispatch({
            type: API,
            payload: {
                method: "Post",
                url: "settings/settingsValues",
                data,
                onSuccess: (response) => ({type: types.ON_FETCH_SETTINGSVALUES, response}),
                successText: "Updated"
            }
        });
    };
}
const initialState = {
    settingsValues: [],
    counterRaports: {
        thirdCounter: 0,
        secondCounter: 0,
        firstCounter: 0,
    },
};

export function settingsValues(state = initialState, action) {
    switch(action.type) {
        case types.ON_FETCH_SETTINGSVALUES:
            let actualCounter = state.counterRaports;
            let newCounter = `${Number(action.response[0].settingValue)}`;
            newCounter = newCounter.length ===3 ? newCounter : newCounter.length === 2 ? `0${newCounter}` : newCounter.length === 1 ? `00${newCounter}` : '000';
            actualCounter.thirdCounter = Number(newCounter[0]);
            actualCounter.secondCounter = Number(newCounter[1]);
            actualCounter.firstCounter = Number(newCounter[2]);
            return {
                ...state,
                settingsValues: action.response,
            };
        default:
            return state;
    }
}