import React from 'react';
import NumberCounter from './NumberCounter';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info';

const countInputs = ({ countFirst, countSecond, countThird, valueName, handleCounterOnChange, handleOnKeyDown, handleCounterOnBlur, handleOnClickArrow, text, textTooltip }) => {

    const inputRefFirst = React.useRef();
    const inputRefSecond = React.useRef();
    const inputRefThird = React.useRef();


    const handleKeyPress = (e, nextRef, prevRef) => {
        if (e.key) {
            nextRef.current.focus();
        }
    };

    return (
        <>
            <div className="inputsNumber">
                <NumberCounter
                    valueName={valueName}
                    value={countFirst.value}
                    name={countFirst.name}
                    handleOnKeyDown={handleOnKeyDown}
                    colorValue={countFirst.colorValue}
                    handleCounterOnBlur={handleCounterOnBlur}
                    handleKeyPress={handleKeyPress}
                    ref={inputRefFirst}
                    nextRef={inputRefSecond}
                    prevRef={inputRefFirst}
                    handleCounterOnChange={handleCounterOnChange}
                />
                <NumberCounter
                    valueName={valueName}
                    value={countSecond.value}
                    name={countSecond.name}
                    handleOnKeyDown={handleOnKeyDown}
                    colorValue={countSecond.colorValue}
                    handleCounterOnBlur={handleCounterOnBlur}
                    handleKeyPress={handleKeyPress}
                    ref={inputRefSecond}
                    nextRef={inputRefThird}
                    prevRef={inputRefFirst}
                    handleCounterOnChange={handleCounterOnChange}
                />
                <NumberCounter
                    valueName={valueName}
                    value={countThird.value}
                    name={countThird.name}
                    handleOnKeyDown={handleOnKeyDown}
                    colorValue={countThird.colorValue}
                    handleCounterOnBlur={handleCounterOnBlur}
                    handleKeyPress={handleKeyPress}
                    ref={inputRefThird}
                    nextRef={inputRefThird}
                    prevRef={inputRefSecond}
                    handleCounterOnChange={handleCounterOnChange}
                />
                <div className="counterArrowLeft">
                    <ChevronLeftIcon onClick={() => handleOnClickArrow('minus', valueName)} />
                </div>
                <div className="counterArrowRight">
                    <ChevronRightIcon onClick={() => handleOnClickArrow('plus', valueName)} />
                </div>
            </div>
            <div className="inputsText">
                {text}
            </div>
            <div className="infoIco">
                <div className="tooltip"><InfoIcon />
                    <span className="tooltiptext">{textTooltip}</span>
                </div>
            </div>
        </>
    );
};

export default countInputs;