export const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
export const days = ['Sun', 'Mon','Tue', 'Wen', 'Thr', 'Fri', 'Sat'];

export const EN = {
    common:{
        searchBar: {
            hint: "Search"
        },
        cancel: "Cancel",
        send: "Send",
        add: "Add",
        save: "Save",
        submit: "Submit",
        delete: "Delete",
        content: "Content",
        reject: "Reject",
        accept: "Accept",
        version: "Version",
        employee: "Employee",
        rejectedBy: "Rejected by",
        comment: "Comment",
        all: "All",
        summary: "Summary",
        close: "Close",
    },
    tooltip:{
        sendMailToAllEmployees: "Send mail to all employees",
        sendMailToEmployee:  "Send mail to employee"
    },
    general:{
        serverNotFound:"Server not found",
        forbidden:'You don\'t have authorization to perform this action',
        validationError: 'Submitted request has invalid values'
    },
    flashMessages:{
        error: "Error",
        loginError: "Invalid login or password",
        unathorizedError: "You need to login first",
        connectionLost: "Connection lost. Trying to reconnect",
        connectionEstablished: "Connection established"
    },
    navigationBar:{
        welcome:'Welcome',
        emailBtn:'Emails',
        menuBtn:'Side Menu',
        logOutBtn:'Log Out',
        helpBtn: 'Support',
        changeLogBtn: 'Change Log',
        FavPageTip: "Choose your favorite application that will launch after logging into the platform",
        supportDialog:{
            title: 'Support',
            email: 'E-mail',
            contact: 'Contact',
            video: 'Video',
            timesheetNexio: 'Nexio Timesheet',
            holidays: 'Absences'
        }
    },
    sideMenu:{
        homeBtn:"Home",
        projectsBtn:"Projects",
        employeesBtn: 'Employees',
        pricingBtn: 'Pricing',
        scrumBtn: 'Scrum Management',
        settingsBtn:"Settings",
        adminBtn: 'Administration',
        hr: {
            mainBtn: 'HR',
            hrHolidays: 'Holidays',
            reports: 'Reports',
            workTime: {
                main: "Work time",
                irregular: "Irregular work time"
            }
        },
        notifications: "Notifications",
        employeesTasksBtn: "Employees tasks",
        projectManagmentBtn: "Project management",
        teamManagementButton: "Team management",
        holiday: {
            mainBtn: "Absences",
            myBtn: "My absences",
            otherBtn: "Team Panel"
        },
        changePasswordBtn: "Change password",
        changeLogs: "Change Logs",
        teamTimesheetBtn: "Team Timesheet",
        mailWhitelist: "Mail Whitelist"
    },
    teamTimesheet: {
        pendingApplication: {
            title: "Requests list",
            employeeColumn: "Employee",
            versionColumn: "Version",
            postDateColumn: "Post date",
            statusColumn: "Status",
            downloadColumn: "Download",
            actionColumn: "Action",
            verify: "Verify"
        },
        settledEmployees: {
            title: "Settled employees",
            employeeColumn: "Employee",
            versionColumn: "Version",
            acceptedBy: "Accecpted by",
            postDateColumn: "Post date",
            acceptedDateColumn: "Accepted date",
            downloadColumn: "Download",
            actionColumn: "Action",
            view: "View"
        },
        unsettledEmployees: {
            title: "Unsettled employees",
            employeeColumn: "Employee",
            actionColumn: "Action",
        },
        buttons: {
            pendingApplication: "Pending Applications",
            settledEmployees: "Settled Employees",
            unsettledEmployees: "Unsettled Employees",
            history: "History",
            reports: "Reports",
        },
        timesheetProposalsTable: {
            title: "Request list",
            version: "Version",
            postDate: "Post date",
            acceptedBy: "Accepted by",
            status: "Status",
            dateOfVerification: "Date of verification",
            comment: "Comment"
        }
    },
    loginPage:{
        preloader: "Loading. Please wait...",
        username:"Username",
        usernameHint:"Type your username",
        password:"Password",
        passwordHint:"Type your password",
        loginButton: 'LOGIN',
        notificationsLoader: "Getting notifications...",
        forgotPasswordLabel: "Forgot password?",
        resetPassLoader: "Reseting password...",
        resetSuccess: "Password reseted successfully. Please check your e-mail.",
        uniqueLinkPassLoader: "Generating link...",
        uniqueLinkSuccess: "Link generated successfully. Please check your e-mail.",
        rememberMe: 'Rembember me',
        email: 'Email',
        sendEmail: 'Send Email'
    },
    timesheetPage: {
        remoteWork: "Remote work",
        preloader:"Loading Projects Data for ",
        issuePanel:{
            idColumn: "Id",
            issueColumn: "Issue"
        },
        projectsPanel:{
            preloader:"Loading Projects Data for user",
            preloaderHr:"Loading HR Data for "
        },
        calendarPanel:{
            preloader:"Loading Issues Data for ",
            months: months,
            days: days,
            warning1: "No,no,no...You have clicked another issue day. Why?",
            dailyHoursLabel: "Daily Hours Total",
            weeklyHoursLabel: "Weekly Hours Total",
            monthlyHoursLabel: "Monthly Hours Total",
            comments: "Comments",
            submitTimesheetFor: "Submit timesheet for ",
            doYouWantToSubmitTimesheetFor : "Do you want to submit Timesheet for ",
            toApproval : " to approval?",
            youWillSendAnIncompleteMonth: "You will send an incomplete month.",
            firstSubmitTimesheetFor: "First, submit the timesheet for ",
            notSubmitedTimesheet: {
                content: "You have unsubmitted timesheet for previous month.",
                buttonText: "OK"
            }
        },
        manageDaysPanel:{
            preloader:"Saving. Please wait... ",
            preloaderHr:"Updating HR Summary Panel. Please wait... ",
            actionType:{
                name: "Select Action type",
                addHours: "Add Work Hours",
                daysOff: "Add Days Off",
                sickLeave: "Add Sick Leave",
            },
            hours: "Hours",
            minutes: "Minutes",
            startDate: "Start Date",
            endDate: "End Date",
            includeWeekends: "Include Weekends",
            includeWeekendsAndHolidays: "Include Weekends and Holidays",
            comments: "Comments",
            commentsHint: "Type your comment",
            months: months,
            days: days
        },
        summaryHrPanel:{
            summaryHeader: "HR Summary",
            preloader:"Updating HR Summary Panel. Please wait...",
            months: months,
            days: days,
            holidaysTotal: "Holidays total:",
            holidaysUsed: "Holidays used:",
            holidaysPlanned: "Holidays planned:",
            holidaysLeft: "Holidays left:",
            holidaysCurrent: "Current request:",
            holidaysRemaining: "Remaining:",
            onDemandTotal: "Days on demand total:",
            onDemandUsed: "Days on demand used:",
            onDemandLeft: "Days on demand left:",
            sickLeaveUsed: "Sick leave:",
            otherUsed: "Other holidays:",
            unpaidUsed: "Unpaid holidays:",
            emptyHolidaySettings: "No data has been entered for this year"
        },
        legendPanel:{
            legendHeader: "Legend",
            textTop: "Note has comment",
            textBottom: "Worked hours"
        }
    },
    employeesPage:{
        tableHeader: 'Employees',
        search: 'Search',
        username: 'Login',
        firstname: 'First name',
        lastname: 'Last name',
        defaultHolidays: 'Holidays for year',
        prevYearHolidays: 'Holidays from year',
        availableHolidays: 'Available Holidays',
        holidaysTotal: "Holidays total",
        holidaysUsed: "Holidays used",
        holidaysPlanned: "Holidays planned",
        holidaysLeft: "Holidays left",
        contract: 'Contract type',
        employment: 'Employment',
        b2b: 'B2B',
        civil: 'Civil',
        id: 'Id',
        password: 'Password',
        email: 'E-mail',
        company: 'Company',
        period: 'Period',
        supervisor: 'Supervisor',
        status: 'Status',
        workgroup: 'Workgroups',
        updateDialog:{
            username: 'Username',
            legacyPrevYearHolidays: 'Before Previous Year Holidays',
            prevYearHolidays: 'After Previous Year Holidays',
            legacyStartingHolidays: 'Before Default Holidays',
            startingHolidays: 'After Default Holidays'
        },
        filters:{
            employee: 'Employee',
            supervisor: 'Supervisor',
            contractType: 'Contract type',
            status: 'Status'
        },
        firstEmployment: "First employment",
        AnnualHoliday: "Annual Holiday Pool",
        personalInformation: "Personal information",
        profile: "Profile",
        history: "History",
        holidays: "Holidays",
        jobDetails: "Job details",
        joiningDate: "Joining date",
        userActive: "Active",
        userInactive: "Inactive",
        buttons: {
            cancel: "CANCEL",
            save: "SUBMIT",
            edit: "Edit profile",
            addAvatar: "Add avatar",
            removeAvatar: "Remove"
        },
        changeSupervisorDialog: {
            dialogTitle: "Select supervisor",
            warningMessage: "NOTE: You will change supervisor for all selected users",
        }
    },
    pricingPage:{
        tableHeader: "Pricing",
        doneCol: "Solved",
        jiraCol: "Inner Task Number",
        childCol: "Child",
        redmineTaskIdCol: 'Redmine',
        nameCol: "Name",
        analystCol: "Analyst",
        developerCol: "Developer",
        estimationsDevCol: "Estimation",
        realizationsDevCol: "Relization",
        estimationsDeveloperCol: "Developer for estimation",
        realizationsDeveloperCol: "Developer for realization",
        analysisCol: "Analysis",
        developmentCol: "Development",
        estimation: "Estimation",
        developerMd: "MD",
        testingCol: "Testing",
        analystsEstimationCol: "Analysts estimation",
        developersEstimationCol: "Developer estimation",
        developerSection: "Developer section",
        developersMdCol: "Developers MD",
        estimationForClientCol: "Estimation for client",
        total: "Total",
        commentsCol: "Comments",
        taskDialog:{
            cancelBtn: 'Cancel',
            submitBtn: 'Submit'
        },
        search: "Search",
        successMessage: 'Task added/edited successfully.',
        successSolvedMessage: 'Tasks status changed successfully.',
        successDeleteMessage: 'Task deleted successfully.',
        requiredFieldMessage:  "This field is required",
        warningTaskExists: "Task already exists.",
    },
    scrumManagementPage:{
        usersTableColumnName:{
            availability: "Availability",
            firstname: "First Name",
            lastname: "Last Name",
            scrumStatus: "Scrum Status",
        },
        scrumTableColumnName:{
            taskRedmineId: "Redmine",
            taskName: "Issue",
            realProgress: "Progress",
            budgetProgress: "Progress",
            deadline: "Deadline",
        },
        scrumPanel:{
            developer: 'Developer:',
            scrumNotes: 'Scrum Notes',
            yesterdayTasks:'Yesterday tasks:',
            todayTasks:'Today tasks:',
            risks:'Risks:',
            availability: "Availability:",
            availabilityAvailable:'Available',
            availabilitySoon:'Soon',
            availabilityBusy:'Busy'
        }
    },
    workTimePage: {
        irregular: {
            filters: {
                AllFilters: "All filters",
                UnreportedDays: "Unreported days",
                UnreportedMonth: "Unreported month",
                LessThan8HourPerDay: "Less than 8 hour per day",
                OverThan8hourPerDay: "Over than 8 hour per day",
                ContractType: "Contract type",
                LastName: 'Last name'
            },
            noReports: "Nothing was reported"
        }
    },
    notifications:{
        title: "Notifications",
        subject: "Subject",
        body: "Body",
        date: "Date",
        group: "Group"
    },
    holidaysPage:{
        myTeamPageTitle: 'My Team Absences Panel',
        myWorkersPageTitle: 'My Workers Absences Panel',
        applicant: "Applicant",
        accepted: "Accepted By",
        days: 'Days',
        dateFrom: "From",
        dateTo: "To",
        timeFrom: "Time from",
        timeTo: "Time to",
        status: "Status",
        comment: "Comment",
        request: "Request",
        replacement: "Replacement",
        selectReplacement: "Select replacement",
        selectedReplacement: "Replacement",
        replacementGroups: {
            lastSelected: "Last selected",
            others: "Others",
        },
        attachmentIsRequired: "At least one attachment is required",
        omittedFile: "At least one file was omitted due to its size.",
        maxFileSize: "* Maximum file size is 10 MB. Bigger files will be omitted.",
        type: "Type",
        legend: {
            newRemote: "New remote work request",
            acceptedRemote: "Accepted remote work",
            acceptedUnpaidHoliday: "Accepted unpaid holiday",
            newUnpaidHoliday: "New unpaid holiday",
            newHoliday: "New holiday request",
            acceptedHoliday: "Accepted holiday",
            newSickLeave: "New sick leave request",
            acceptedSickLeave: "Accepted sick leave",
            newOtherHoliday: "New other holiday request",
            acceptedOtherHoliday: "Accepted other holiday"
        },
        supervisor: "Supervisor",
        myteam: "My Team",
        myworkers: "My Workers",
        holidaysSummaryHeader: "holidays summary for",
        commentWarning: "Comment is required when rejecting the request.",
        changeDate: "Change date",
        fieldName: "Field name",
        newValue: "New value",
        previousValue: "Previous value",
        historyAction: "History action",
        message: "Message",
        changedByName: "Changed by",
        history: 'History',
        showInitial: 'Show initial',
        noEmployeesWarningDialog: {
            content: "You don't have any employees yet",
            buttonText: "Ok"
        },
        workHoursReportedWarning: "You have work hours reported during given period",
        holidaysAlreadyReportedWarning: "You have already reported holidays during given period",
        doesEmployedWarning: "You were not employed at the time",
        system: "System"
    },
    changePassword:{
        oldPassword: 'Old password',
        pastHolidayWarning: "Warning! The request was made for the past.",
        newPassword: 'New password',
        confirmPassword: 'Confirm new password',
        submitBtn: 'Submit',
        loader: "Changing password...",
        success: "Password changed successfully"
    },
    changeLogs:{
        title: "Change Logs",
        subject: "Title",
        description: "Description",
        date: "Date",
        documentFile: "Document File",
        file: "File",
        warningFileSizeDialog: {
            content: 'Selected file exceeds maximum size (10MB)',
            closeButton: 'Close'
        }
    },
    teamManagement:{
        unsavedChangesInfo: "There are unsaved changes. Changes to user roles will apply after their next login. Users without any roles will be removed from team.",
        addRole: "Add role",
        addUser: "Add user",
        addTeam: "Add team",
        newTeamName: "New team name",
        deleteTeam: "Delete team",
        removeAllRoles: "Remove all roles",
        reset: "Undo changes"
    },
    hrUserHolidays:{
        employee: 'Employee',
        contractType: 'Contract type',
        holidayType: "Holiday type",
        dateFrom: "From",
        dateTo: "To",
        numberOfDays: "Days",
        request: "Request",
        acceptedBy: "Accepted By",
        status: "Status",
        action: "Action",
        solvedOn:  "Solved on",
        reject: 'Reject',
        approve: 'Approve',
        system: 'System',
        rejectConfirmText: "Do you want to reject the request?",
        approveConfirmText: "Do you want to approve the request?"
    },
    supervisiorSettings:{
        inputCounter: "Number of working days for checking",
        inputRaports: "Number of days for closed task visibility",
        tooltipCounter: 'The setting used by "checking unreported working hours" email mechanizm. The number of days back taken into account when checking unreported employee hours.',
        tooltipRaports: "Number of days for which a closed task should be visible in the ‘Task Report’",
        tooltipSwitch: "If you check this option, your employees will be required to submit application for holidays. If this option is turned off, then employees will be able to report days off without submitting an application and supervisor’s acceptance.",
        switchManualTimesheetApproval: "If you check this option, your employees will be required to submit timesheet for every month and you will accept or reject this timesheet. If this option is turned off, then timesheets of your employees will be accepted automatically.",
        replecingName: 'Replacing person*',
        testMessage: 'Test Message',
        textSlack: 'Slack URL for daily holiday status message',
        createSlackUrl: 'Create Slack URL',
        textMSTeams: 'MSTeams URL for daily holiday status message',
        createMSTeamsUrl: 'Create Teams URL',
        enableManualTimesheetApproval: 'Enable manual timesheet approval',
        manualAcceptHoliday: 'Enable Holidays Requests',
        replacement: 'Replacement'
    },
    whitelist: {
        headerTitle: "Whitelist e-mails",
        deletingMessage: "Removing mails from whitelist",
        deletedMessage: "Mails removed",
        savingMessage: "Saving mail in whitelist",
        savedMessage: "Mail saved",
        loadingWhitelist: "Loading whitelist",
        mailHint: "Type new e-mail",
        dialogTitle: "Submit e-mail",
        invalidEmail: "E-mail is not valid"
    }
};