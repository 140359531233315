import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Button from './TeamButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { addTeam, deleteSelectedTeam } from '../../store/Teams';
import {EN} from '../common/translations';

const AddTeamButton = ({ addTeam }) => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const handleClick = useCallback(() => setOpen(true), [setOpen]);
    const handleDialogAdd = useCallback(() => { setOpen(false); addTeam(name); }, [setOpen, name]);
    const handleDialogClose = useCallback(() => { setOpen(false); setName(""); }, [setOpen, setName]);
    const handleNameChange = useCallback((e) => setName(e.target.value), [setName]);
    return (<>
        <Button
            variant="outlined"
            color="primary"
            className="team-button"
            onClick={handleClick}
        >
            {EN.teamManagement.addTeam}
        </Button>
        <Dialog open={open}>
            <DialogTitle>{EN.teamManagement.addTeam}</DialogTitle>
            <DialogContent> 
                <TextField label={EN.teamManagement.newTeamName} onChange={handleNameChange} value={name} /> 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>{EN.common.cancel}</Button>
                <Button color="primary" onClick={handleDialogAdd}>{EN.common.add}</Button>
            </DialogActions>
        </Dialog>
    </>);
};

const mapDispatchToProps = {
    addTeam
};

const ConnectedAddTeamButton = connect(undefined, mapDispatchToProps)(AddTeamButton);

const DeleteTeamButton = ({ deleteSelectedTeam, users }) => (
    <Button
        variant="contained"
        color="primary"
        onClick={deleteSelectedTeam}
        disabled={!users || users.length > 0}
    >
    {EN.teamManagement.deleteTeam}
    </Button>
);

const mapDeleteStateToProps = (state) => ({
    users: state.teams.teamUsers[state.teams.selectedTeam],
});

const ConnectedDeleteTeamButton = connect(mapDeleteStateToProps, { deleteSelectedTeam })(DeleteTeamButton);

const AdminButtons = () => (<>
    <ConnectedAddTeamButton/>
    <ConnectedDeleteTeamButton/>
</>);

export default AdminButtons;