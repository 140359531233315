import { validateFunctions } from '../../common/helpers/validateFunctions';
import {Constants} from '../../common/constants';
import { areDatesFromTheSameYear, countDayOff } from '../../common/helpers/date';
import { store } from '../../../index';

export default function taskDialogValidation(data, isSupervisor, publicHolidays) {

    const DEFAULT_HOLIDAY_LIMIT_FOR_NEXT_YEAR = 20;
    const state = store.getState().holidays.selectedUser;
    const availableHolidays = state?.prevYearHolidays + state?.startingHolidays - state?.usedHolidays - state?.plannedHolidays;

    let errorsTask = {};

    if (!validateFunctions.isStringNotEmpty(data.type)) {
        errorsTask.type = 'This field is required';
    }

    if (isSupervisor && !validateFunctions.isNumberOverZero(data.userId)) {
        errorsTask.userId = 'This field is required';
    }

    if (!validateFunctions.isStringNotEmpty(data.dateFrom)) {
        errorsTask.dateFrom = 'This field is required';
    }
    else if (!validateFunctions.isDateStringValid(data.dateFrom)) {
        errorsTask.dateFrom = 'Date is invalid';
    }
    else if (!validateFunctions.validateDatesRange(new Date(data.dateFrom), new Date(data.dateTo))) {
        errorsTask.dateFrom = 'Date from should not be larger than Date to';
    }
   
    if (!validateFunctions.isStringNotEmpty(data.dateTo)) {
        errorsTask.dateTo = 'This field is required';
    }
    else if (!validateFunctions.isDateStringValid(data.dateTo)) {
        errorsTask.dateTo = 'Date is invalid';
    }
    else if (!validateFunctions.validateDatesRange(new Date(data.dateFrom), new Date(data.dateTo))) {
        errorsTask.dateTo = 'Date to should not be smaller than Date from';
    }
    else if (areDatesFromTheSameYear(new Date(data.dateFrom), new Date(data.dateTo)) 
    && data.type === Constants.HolidayTypes.HOLIDAY) {
        if (countDayOff(new Date(data.dateFrom), new Date(data.dateTo), publicHolidays) > availableHolidays){
            errorsTask.dateTo = 'Holidays limit exceeded';
        }
    }
    else if(!areDatesFromTheSameYear(new Date(data.dateFrom), new Date(data.dateTo)) 
    && data.type === Constants.HolidayTypes.HOLIDAY) {
        if (countDayOff(new Date(data.dateFrom), new Date(data.dateTo), publicHolidays) > availableHolidays + DEFAULT_HOLIDAY_LIMIT_FOR_NEXT_YEAR){
            errorsTask.dateTo = 'Holidays limit exceeded';
        }
    }

    if (data.type === Constants.HolidayTypes.REMOTE) {
        if (!validateFunctions.isStringNotEmpty(data.timeFrom)) {
            errorsTask.timeFrom = 'This field is required';
        }
        else if (!validateFunctions.isTimeStringValid(data.timeFrom)) {
            errorsTask.timeFrom = 'Time is invalid';
        }
        else if (!validateFunctions.validateTimeRange(data.timeFrom, data.timeTo)) {
            errorsTask.timeFrom = 'Time from should not be larger than Time to';
        }
    
        if (!validateFunctions.isStringNotEmpty(data.timeTo)) {
            errorsTask.timeTo = 'This field is required';
        }
        else if (!validateFunctions.isTimeStringValid(data.timeTo)) {
            errorsTask.timeTo = 'Time is invalid';
        }
        else if (!validateFunctions.validateTimeRange(data.timeFrom, data.timeTo)) {
            errorsTask.timeTo = 'Time to should not be smaller than Time from';
        }
    }    

    if (!isSupervisor && !validateFunctions.isStringNotEmpty(data.request)) {
        errorsTask.request = 'This field is required';
    }
    
    return errorsTask;
}