import React from 'react';
import PropTypes from 'prop-types';
import StopHaker from '../../assets/notPass.png';

const propTypes = {
    message:PropTypes.string
};

class UnAuthorized extends React.Component {

    render() {
        let {message} = this.props;
        return (
            <div className="jumbotron" style={{textAlign:'center'}}>
                <img src={StopHaker}/>
            </div>
        );
    }
}

UnAuthorized.propTypes = propTypes;

export default UnAuthorized;
