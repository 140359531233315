import React from 'react';
import PropTypes from 'prop-types';

import memoize from "memoize-one";
import moment from 'moment';
import _ from 'lodash';

import { max, extent } from 'd3-array';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { curveMonotoneX as curveBasis } from '@vx/curve';
import { localPoint } from '@vx/event';
import { Group } from '@vx/group';
import { scaleTime, scaleLinear, scaleOrdinal } from '@vx/scale';
import { LinePath, Circle, Line} from '@vx/shape';
import { TooltipWithBounds } from '@vx/tooltip';
import { LegendOrdinal, LegendItem, LegendLabel } from '@vx/legend';

import { colors } from '../../common/styles.js';
import { dateFormat } from '../../common/helpers/date';
import { closestElementIndex } from '../../common/helpers/utils';

const xDate = (o) => new Date(o.date);
const yReal = (o) => o.realProgress;
const yBudget = (o) => o.budgetProgress;
const realColor = colors.nexioOrange;
const budgetColor = "white";
const axisColor = colors.nexioOrange;

const formatDate = (d) => {
    return moment(d).format(dateFormat);
};

const day = 24*60*60*1000;

const legendScale = scaleOrdinal({
    domain: ['Budget', 'Real'],
    range: [budgetColor, realColor]
  });

const GraphLegend = (props) => (
    <LegendOrdinal scale={legendScale}>
    {labels => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {labels.map((label, i) => {
            const size = 15;
            return (
              <LegendItem
                key={i}
                margin={'0 5px'}
              >
                <svg width={size} height={size}>
                  <rect fill={label.value} width={size} height={size} />
                </svg>
                <LegendLabel align={'left'} margin={'0 0 0 4px'}>
                  {label.text}
                </LegendLabel>
              </LegendItem>
            );
          })}
        </div>
      );
    }}
  </LegendOrdinal>
);

class ProgressGraph extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.getXGetter = memoize(this.getXGetter);
        this.getRealGetter = memoize(this.getRealGetter);
        this.getBudgetGetter = memoize(this.getBudgetGetter);
        this.getXExtent = memoize((data) => extent(data, xDate));
        this.getYExtent = memoize((data) => [0, Math.max(max(data, yBudget), max(data, yReal), 100)]);
        this.transformData = memoize(this.transformData);
        this.state = {
            el: null,
            tooltipX: 0,
            tooltipY: 0
        };
        this.createSvgRef = (ref) => this.svgRef = ref;
    }

    getSize() {
        return {
            width: this.context.muiTheme.baseTheme.spacing.desktopKeylineIncrement * 11,
            height: this.context.muiTheme.baseTheme.spacing.desktopKeylineIncrement * 8,
            margin: {
                top: this.context.muiTheme.baseTheme.spacing.desktopKeylineIncrement / 2,
                bottom: this.context.muiTheme.baseTheme.spacing.desktopKeylineIncrement / 2,
                left: this.context.muiTheme.baseTheme.spacing.desktopKeylineIncrement / 3 * 2,
                right: this.context.muiTheme.baseTheme.spacing.desktopKeylineIncrement / 2,
            }
        };
    }


    transformData(data) {
        const [min, max] = extent(data, xDate);
        const ret = [];
        if (!max) return data;
        let prev = data[0];
        for (const d = new Date(max.getTime()); d >= min; d.setDate(d.getDate() - 1)) {
            let el = data.find(x => xDate(x).getTime() === d.getTime());
            if (!el) {
                el = { ...prev, date: new Date(d.getTime()) };
            }
            ret.push(el);
            prev = el;
        }
        return ret.reverse();
    }

    render() {
        const data = this.transformData(this.props.data);
        const { width, height, margin } = this.getSize();

        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;
        const xScale = scaleTime({
            range: [0, xMax],
            domain: this.getXExtent(data)
        });
        const yScale = scaleLinear({
            range: [yMax, 0],
            domain: this.getYExtent(data)
        });

        const x = (d) => xScale(xDate(d));
        const real = (d) => yScale(yReal(d));
        const budget = (d) => yScale(yBudget(d));

        const handleMouseMove = (event) => {
            const coords = localPoint(this.svgRef, event);
            const idx = closestElementIndex(this.props.data, coords.x - margin.left, x);    
            const el = this.props.data[idx];
            //Point in svg coordinates, scale of svg has to be 1 and it has to 
            //be top left element in surrounding div. 
            const point = {x: x(el) + margin.left, y: real(el) + margin.top};
            if (el) {
                this.setState({el, tooltipX: point.x, tooltipY: point.y});
            }
        };

        const onMouseOut = (e)=>{
            // Checks if mouse leaves svg into an element in this svg or vice versa
            if (e.target.ownerSVGElement == this.svgRef || e.relatedTarget && e.relatedTarget.ownerSVGElement == this.svgRef) {
                return;
            }
            this.setState({el: null});
        };

        const fontFamily = this.context.muiTheme.baseTheme.fontFamily;
        const xTickLabel = () => ({ dy: '0.25em', fill: axisColor, fontFamily: fontFamily, fontSize: 10, textAnchor: 'middle' });
        const tooltipStyle = { position: "absolute", backgroundColor: "#303030", color: colors.nexioOrange };

        return (<div style={{position:"relative"}}>
            <svg width={width} height={height} onMouseOut={onMouseOut} onMouseMove={handleMouseMove} ref={this.createSvgRef}>
                <Group top={margin.top} left={margin.left} >
                    {<Line 
                            from={{x: 0, y: yScale(100)}} 
                            to={{x: xMax, y: yScale(100)}} 
                            stroke={realColor}
                            strokeWidth={2}
                            strokeDasharray="4"
                    />}                   
                    <LinePath
                        data={data}
                        x={x}
                        y={budget}
                        stroke={budgetColor}
                        strokeWidth={1}
                        curve={curveBasis}
                    />
                    <LinePath
                        data={data}
                        x={x}
                        y={real}
                        stroke={realColor}
                        strokeWidth={2}
                        curve={curveBasis}
                    />
                    <AxisBottom 
                        top={yMax}
                        numTicks={data.length > 5 ? 5 : Math.max(data.length - 1, 1)}
                        stroke={axisColor}
                        tickStroke={axisColor}
                        scale={xScale}
                        tickLabelProps={xTickLabel}
                        tickFormat={formatDate}
                    />
                    {this.props.data.map((o, i) => (
                        <Circle key={i} cx={x(o)} cy={budget(o)} fill={budgetColor} r={2}/>
                    ))}
                    {this.props.data.map((o, i) => (
                        <Circle key={i} cx={x(o)} cy={real(o)} fill={realColor} r={2}/>
                    ))}
 
                </Group>
                <Group top={margin.top} left={margin.left} >
                    {this.state.el && <Circle cx={x(this.state.el)} cy={budget(this.state.el)} fill={budgetColor} r={4}/>}
                    {this.state.el && <Circle cx={x(this.state.el)} cy={real(this.state.el)} fill={realColor} r={4}/>}

                </Group>
            </svg>
            <GraphLegend />
            {this.state.el && (<TooltipWithBounds
                // set this to unique so it correctly updates with parent bounds
                key={_.uniqueId()}
                top={this.state.tooltipY}
                left={this.state.tooltipX}
                style={tooltipStyle}
            >
                <strong>{formatDate(this.state.el.date) + " - "}
                {this.state.el.realProgress}%, </strong> 
                <span style={{color: budgetColor}}>{this.state.el.budgetProgress}%</span>
            </TooltipWithBounds>)}
        </div>);
    }
}

ProgressGraph.contextTypes = {
    muiTheme: PropTypes.object.isRequired,
};

export default ProgressGraph;