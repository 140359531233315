import moment from 'moment';

export const dateFormat='DD/MM/YYYY';
export const isoDateFormat='YYYY-MM-DD';

export function dateToIso(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}

export function dateToApi(date) {
    return dateToIso(date).substr(0, 10);
}

export function formatValidDate(date){
    const formattedDate = moment(date,dateFormat).format(dateFormat);
    return moment(formattedDate,dateFormat,true).isValid() ? formattedDate : moment(date,isoDateFormat).format(dateFormat);
}

export const isDayOff = (date, publicHolidays) => {
    const dayOfWeek = new Date(date).getDay();
    const isDayOff = dayOfWeek === 6 || dayOfWeek === 0 || (publicHolidays.has(dateToIso(date).substr(0, 10)));
    return isDayOff;
};

export const countDayOff = (dateFrom, dateTo, publicHolidays) => {
    if (!publicHolidays) return 0;
    dateFrom = new Date(dateFrom);
    dateTo = new Date(dateTo);
    const dates = getDatesBetween(dateFrom, dateTo);
    return dates.filter(x=> !isDayOff(x, publicHolidays)).length;
};

export const getDefaultDate = () => {
    const date = moment().subtract(1, 'months');
    return {
        month: date.month(),
        year: date.year()
    };
};

export const areDatesFromTheSameYear = (dateFrom, dateTo) => {
    dateFrom = new Date(dateFrom);
    dateTo = new Date(dateTo);
    return dateFrom.getFullYear() === dateTo.getFullYear();
};

const getDatesBetween = (startDate, endDate) => {
    endDate = new Date(endDate.getTime() + 2*60*60*1000); //adding hour to avoid time zone error
    let dates = [],
        currentDate = startDate,
        addDays = function(days) {
          let date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  };