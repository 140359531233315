import { type } from "os";
import moment from 'moment';
import { isoDateFormat } from './date';

export const validateFunctions = {
    isNumberOverZero: (value) => { 
        if (!value || typeof value != 'number' || value < 1)
            return false;
        else 
            return true;
    },
    isStringNotEmpty: (value) => { 
        if (!value || typeof value != 'string' || value.length < 1 )
            return false;
        else 
            return true;
    },
    validateEmail: (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    isDateNotEmpty: (value) => {
        if (!value || typeof value != 'object' || value == null )
            return false;
        else
            return true;
    },
    isDateStringValid: (value) => {
        return !value || /^\d{4}-\d{2}-\d{2}/.test(value) && moment(value, isoDateFormat).isValid();
    },
    isTimeStringValid: (value) => {
        return !value || /^\d{2}:\d{2}/.test(value) && moment(value, 'HH:mm').isValid();
    },
    validateDatesRange: (dateFrom, dateTo) => {
        dateFrom = new Date(dateFrom);
        dateTo = new Date(dateTo);
        if (dateTo < dateFrom) {
            return false;
        }
        return true;
    },
    validateTimeRange: (timeFrom, timeTo) => {
        if (timeFrom == null || timeTo == null) {
            return true;
        }
        timeFrom = timeFrom.split(':');
        timeTo = timeTo.split(':');
        let dateFrom = new Date(2000, 1, 1, timeFrom[0], timeFrom[1], 0);
        let dateTo = new Date(2000, 1, 1, timeTo[0], timeTo[1], 0);
        if (dateTo < dateFrom) {
            return false;
        }
        return true;
    }
};
