import React from 'react';
// components
import CalendarControls from '../../../timeSheet/calendarPanel/CalendarControls';
import ViewSelect, { routes } from './viewSelect/viewSelect';
import {EN} from '../../../common/translations'; 

const header = (props) => {

    return (
        <div className="header">
            <ViewSelect selected={EN.sideMenu.hr.workTime.irregular} values={routes} />
            <div className="calendar-panel report-calendar-panel">
                <div className="calendar">
                    <div className="calendar-controls">
                        <CalendarControls month={props.month} year={props.year} onChange={props.onCalendarChanged} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default header;