import { types as holidayPanelTypes, getHolidaysByWorkerIds } from './HolidayPanel';
import { fetchHrUserHolidays } from './HrUserHolidays';
import _ from 'lodash';
import { API } from './types';
import { addObjectsWithCheck } from '../components/common/helpers/storeUtils';

const PAGE_SIZE = 10;

export const types = {
    ON_FETCH_TABLE_DATA: "HOLIDAYS_ON_FETCH_TABLE_DATA",
    ON_FETCH_USER_ABSANCES_PERIOD: "ON_FETCH_USER_ABSANCES_PERIOD",
    ON_FETCH_TABLE_HISTORY_DATA: "HOLIDAYS_ON_FETCH_TABLE_HISTORY_DATA",
    OPEN_DIALOG: "HOLIDAYS_OPEN_DIALOG",
    OPEN_HISTORY_DIALOG: "HOLIDAYS_OPEN_HISTORY_DIALOG",
    CLOSE_HISTORY_DIALOG: "HOLIDAYS_CLOSE_HISTORY_DIALOG",
    OPEN_SUMMARY_DIALOG: "HOLIDAYS_OPEN_SUMMARY_DIALOG",
    CLOSE_SUMMARY_DIALOG: "HOLIDAYS_CLOSE_SUMMARY_DIALOG",
    SET_HISTORY_SHOW_INITIAL: "HOLIDAYS_SET_HISTORY_SHOW_INITIAL",
    CLOSE_DIALOG: "HOLIDAYS_CLOSE_DIALOG",
    SAVE_HOLIDAY: "HOLIDAYS_SAVE_HOLIDAY",
    DELETE_HOLIDAY: "HOLIDAYS_DELETE_HOLIDAY",
    ON_FETCH_CALENDAR_DATA: "HOLIDAYS_ON_FETCH_CALENDAR_DATA",
    GET_WTHOLIDAYS_FOR_MONTH: "WTHOLIDAYS_FOR_MONTH",
    ON_FETCH_WORKERS: "HOLIDAYS_FETCH_WORKERS",
    HOLIDAY_PREV_SUMMARY: "HOLIDAY_PREV_SUMMARY",
    HOLIDAY_SUMMARY: "HOLIDAY_SUMMARY",
    HOLIDAY_PANEL_CHANGE_OPEN_HOLIDAY: "HOLIDAY_PANEL_CHANGE_OPEN_HOLIDAY",
    HOLIDAY_ATTACHMENT_ADD: "HOLIDAY_ATTACHMENT_ADD",
    HOLIDAY_ATTACHMENT_DELETE: "HOLIDAY_ATTACHMENT_DELETE",
    HOLIDAY_ATTACHMENT_REMOVE_UNSAVED: "HOLIDAY_ATTACHMENT_REMOVE_UNSAVED",
    HOLIDAY_ATTACHMENT_UPLOAD_PROGRESS: "HOLIDAY_ATTACHMENT_UPLOAD_PROGRESS",
    HOLIDAY_ATTACHMENT_UPLOAD_FINISHED: "HOLIDAY_ATTACHMENT_UPLOAD_FINISHED",
    HOLIDAY_SELECTED_REPLACEMENT_PERSON_ID: "HOLIDAY_LAST_REPLACEMENT_PERSON_ID",
    ADD_SUPERVISIOR_FROM_SETTINGS: "ADD_SUPERVISIOR_FROM_SETTINGS",
    GET_ACTIVE_APPLIES_COUNTS: "GET_ACTIVE_APPLIES_COUNTS",
    UPDATE_HISTORY_OF_SEARCHING_REPLACEMENTS: "UPDATE_HISTORY_OF_SEARCHING_REPLACEMENTS",
    ADD_HOLIDAY_ERROR: "ADD_HOLIDAY_ERROR",
    CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE"
};

/**
 * @param {object} attachment attachment object, created by file input { guid: string, name: string, isNew: bool, uploadDone: bool }
 * @param {File} file file to upload on the server
 */
export const onAttachmentAdd = (attachment, file) => dispatch => {
    dispatch({ type: types.HOLIDAY_ATTACHMENT_ADD, attachment });
    const form = new FormData();
    form.append("file", file);
    dispatch({
        type: API,
        payload: {
            url: 'holiday/AddAnAttachment',
            method: 'put',
            data: form,
            onProgress: progress => {
                const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
                dispatch({ type: types.HOLIDAY_ATTACHMENT_UPLOAD_PROGRESS, guid: attachment.guid, progress: percentCompleted });
            },
            onSuccess: response => {
                dispatch(onAttachmentUploadFinished(attachment.guid, response));
            }
        }
    });
};

export const clearErrorMessage = () => dispatch => dispatch({ type: types.CLEAR_ERROR_MESSAGE });

export const onAttachmentDelete = (attachmentId) => ({ type: types.HOLIDAY_ATTACHMENT_DELETE, attachmentId });
const onAttachmentUploadFinished = (attachmentId, remoteGuid) => ({ type: types.HOLIDAY_ATTACHMENT_UPLOAD_FINISHED, attachmentId, remoteGuid });

const onFetchTableData = (response, page) => ({ type: types.ON_FETCH_TABLE_DATA, response, page });
const onFetchUserAbsencesPeriod = (response) => ({ type: types.ON_FETCH_USER_ABSANCES_PERIOD, response });


const handleAddHolidayError = (error) => ({type:types.ADD_HOLIDAY_ERROR, payload: error}) 

export const fetchTableData = (page, userId) => {
    return (dispatch, getState) => {
        dispatch({
            type: API, payload: {
                url: 'holiday/getUser',
                method: "GET",
                text: "Loading holidays table",
                onSuccess: (response) => onFetchTableData(response, page),
                params: {
                    start: (page - 1) * PAGE_SIZE,
                    count: PAGE_SIZE,
                    userId: userId
                }
            }
        });
    };
};

const onFetchTableHistoryData = (response, page) => ({ type: types.ON_FETCH_TABLE_HISTORY_DATA, response, page });

export const fetchUserAbsencesPeriod = (year, month) => dispatch =>
    dispatch({
        type: API,
        payload: {
            url: "holiday/getUserPeriod",
            method: "GET",
            text: "Loading user absences",
            onSuccess: (response) => onFetchUserAbsencesPeriod(response),
            params: {
                year,
                month
            }
        }
    });


export const fetchTableHistoryData = (historyPage, holidayRequestId, showInitial = false) => {
    return (dispatch, getState) => {
        dispatch({
            type: API, payload: {
                url: 'holiday/getHolidayRequestHistory',
                method: "GET",
                text: "Loading history table",
                onSuccess: (response) => onFetchTableHistoryData(response, historyPage),
                params: {
                    start: (historyPage - 1) * PAGE_SIZE,
                    count: PAGE_SIZE,
                    holidayRequestId: holidayRequestId,
                    showInitial: showInitial
                }
            }
        });
    };
};


export const onAddAppliesCounts = (response) => ({ type: types.GET_ACTIVE_APPLIES_COUNTS, response });

export const getFetchActiveAppliesCounts = (userId) => {
    return (dispatch) => {
        dispatch({
            type: API, payload: {
                url: 'holiday/getActiveAppliesCountsForUser',
                method: "GET",
                text: "Loading holidays table",
                onSuccess: (response) => onAddAppliesCounts(response),
                params: {
                    userId: userId
                }
            }
        });
    };
};


export const openAddDialog = (forOthersHoliday = false, newHoliday, requestForAnyWorker = false) => ({
    type: types.OPEN_DIALOG,
    id: 0,
    forOthersHoliday,
    requestForAnyWorker,
    newHoliday: {
        ...newHoliday,
        madeBySupervisor: forOthersHoliday && !requestForAnyWorker,
        madeByHR: requestForAnyWorker
    }
});

export const openEditDialog = (id, editable = true) => ({ type: types.OPEN_DIALOG, id, editable: editable });
export const closeDialog = () => (dispatch, getState) => {
    dispatch({ type: types.HOLIDAY_ATTACHMENT_REMOVE_UNSAVED });
    dispatch({ type: types.CLOSE_DIALOG });
};

export const openHistoryDialog = (id) => ({ type: types.OPEN_HISTORY_DIALOG, id });
export const closeHistoryDialog = () => ({ type: types.CLOSE_HISTORY_DIALOG });
export const openSummaryDialog = (id, year = new Date().getFullYear()) => ({ type: types.OPEN_SUMMARY_DIALOG, id, year });
export const openSummaryDialogTeam = (id,year = new Date().getFullYear(),name,surname) => ({type: types.OPEN_SUMMARY_DIALOG,id,year,name,surname,});
export const closeSummaryDialog = () => ({ type: types.CLOSE_SUMMARY_DIALOG });
export const setHistoryShowInitial = (dialogHistoryShowInitial) => ({
    type: types.SET_HISTORY_SHOW_INITIAL,
    dialogHistoryShowInitial
});

export const saveHoliday = (holiday, { setSubmitting, setErrors }) => (dispatch, getState) => {
    dispatch({
        type: API,
        payload: {
            url: 'holiday/update',
            method: "POST",
            text: "Saving leave request",
            successText: "Leave request saved",
            onSuccess: (response) => onSaveHoliday(response),
            onFinish: () => setSubmitting(false),
            onValidationError: (error) => setErrors(error.response.data),
            data: getHolidayFullObject(getState(), holiday)
        }
    });
};


export const addHoliday = (holiday, { setSubmitting, setErrors }, callback) => (dispatch, getState) => {
    dispatch({
        type: API,
        payload: {
            url: 'holiday/save',
            method: "PUT",
            text: "Adding leave request",
            successText: "Leave request added",
            onSuccess: (response) => {
                dispatch(onSaveHoliday(response, holiday.madeByHR));
                if ("function" === typeof callback) {
                    callback();
                }
            },
            onFinish: () => setSubmitting(false),
            onError : handleAddHolidayError,
            onValidationError: (error) => setErrors(error.response.data),
            data: getHolidayFullObject(getState(), holiday)
        }
    });
};

export const getUserHolidaysSummary = (userId, holidayId, year, prevYear = false) => (dispatch) => {
    dispatch({
        type: API,
        payload: {
            url: 'holiday/getUserHolidaysSummary',
            method: "GET",
            params: {
                userId: userId,
                holidayId: holidayId,
                year: year
            },
            onSuccess: (response)=>{
                if (prevYear)
                    dispatch(onGetUserHolidaysPrevSummary(response));
                else
                    dispatch(onGetUserHolidaysSummary(response));
            } 
        }
    });
};

export const getSelectedReplacementPersonId = (userId) => (dispatch) => {
    dispatch({
        type: API,
        payload: {
            url: 'holiday/getSelectedReplacementPersonId',
            method: "GET",
            params: {
                userId: userId
            },
            onSuccess: (response) => dispatch(onGetSelectedReplacementPersonId(response)),
        }
    });
};


const onSaveHoliday = (response, madeByHR) => {
    return (dispatch, getState) => {
        dispatch({ type: types.SAVE_HOLIDAY, response });
        if (madeByHR) {
            dispatch(fetchHrUserHolidays());
        }
        else {
            const { page } = getState().holidays;
            dispatch(fetchTableData(page));
        }
    };
};

const onGetUserHolidaysSummary = (response) => ({ type: types.HOLIDAY_SUMMARY, response });
const onGetSelectedReplacementPersonId = (response) => ({ type: types.HOLIDAY_SELECTED_REPLACEMENT_PERSON_ID, response });
const onGetUserHolidaysPrevSummary = (response) => ({type: types.HOLIDAY_PREV_SUMMARY, response,});

export const deleteHoliday = (holiday) => ({
    type: API,
    payload: {
        url: 'holiday/delete',
        method: "DELETE",
        text: "Deleting leave request",
        successText: "Leave request deleted",
        onSuccess: () => onDeleteHolidays(holiday.id),
        params: { holidayId: holiday.id }
    }
});

export const fetchCalendarData = (month, year) => ({
    type: API,
    payload: {
        url: 'holiday/getHolidaysForAroundMyTeam',
        method: "GET",
        text: "Loading calendar data",
        onSuccess: (response) => ({ type: types.ON_FETCH_CALENDAR_DATA, response, month, year }),
        params: { month, year }
    }
});

export const fetchWorkers = (userId) => ({
    type: API,
    payload: {
        url: 'holiday/getWorkersAroundMyTeam',
        method: "GET",
        text: "Loading workers",
        onSuccess: (response) => ({ type: types.ON_FETCH_WORKERS, response }),
        params: {
            userId: userId
        }
    }
});

export const fetchAllWorkers = () => ({
    type: API,
    payload: {
        url: 'holiday/getAllActiveEmployees',
        method: "GET",
        text: "Loading workers",
        onSuccess: (response) => ({ type: types.ON_FETCH_WORKERS, response })
    }
});

export const onDeleteHolidays = (holidayId) => ({ type: types.DELETE_HOLIDAY, id: holidayId });

export const addSupervisiorFromSettings = (id) => ({ type: types.ADD_SUPERVISIOR_FROM_SETTINGS, id });


export function updateCalendar(calendarIds, newHoliday, year, month) {
    const holidayIds = calendarIds[newHoliday.userId] || [];
    const startMonth = new Date(year, month, 1);
    const endMonth = new Date(year, month + 1, 0);
    const inRange = new Date(newHoliday.dateTo) >= startMonth && endMonth >= new Date(newHoliday.dateFrom);
    const tableIndex = holidayIds.indexOf(newHoliday.id);
    if ((tableIndex === -1) === !inRange) {
        return calendarIds;
    } else if (tableIndex !== -1) {
        return { ...calendarIds, [newHoliday.userId]: holidayIds.filter((x) => newHoliday.id !== x) };
    } else {
        return { ...calendarIds, [newHoliday.userId]: holidayIds.concat(newHoliday.id) };
    }
}

export const setHhistoryOfSearchingReplacements = searchingId => ({
    type: types.UPDATE_HISTORY_OF_SEARCHING_REPLACEMENTS,
    searchingId
});

const emptyHoliday = {
    madeBySupervisor: false,
    comment: null,
    dateFrom: null,
    dateTo: null,
    timeFrom: null,
    timeTo: null,
    id: 0,
    request: null,
    status: 'New',
    type: null,
    userId: null,
    attachments: []
};

function updateEmptyHoliday(holidays, newHoliday) {
    newHoliday = { ...emptyHoliday, ...newHoliday };
    if (newHoliday) {
        newHoliday.id = 0;
        if (holidays[0] && _.isEqual(newHoliday, holidays[0])) {
            return holidays;
        }
        return { ...holidays, "0": newHoliday };
    } else {
        if (holidays[0]) {
            const { "0": _ignore, ...rest } = holidays;
            return rest;
        }
        return holidays;
    }
}

const initialState = {
    data: {},
    page: 1,
    pageCount: 1,
    tableIds: [],
    dialogOpen: false,
    editableDialog: true,
    dialogId: 0,
    dialogHistoryOpen: false,
    dialogHistoryId: 0,
    dialogSummaryOpen: false,
    forOthersHoliday: false,
    requestForAnyWorker: false,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    calendarIds: {},
    calendarLoaded: false,
    workers: {},
    workersLoaded: false,
    filteredWorkers: [],
    filteredWorkersLoaded: false,
    selectedReplacementPersonId: 0,
    allCounts: [],
    notificationCount: {},
    historyData: [],
    historyPage: 1,
    historyPageCount: 1,
    holidayRequestId: 0,
    dialogHistoryShowInitial: false,
    historyOfSearchingReplacements: [],
    workerIds: [],
    errorMessage: '',
    selectedUserName: '',
};

export function holidays(state = initialState, action) {
    switch (action.type) {
        case holidayPanelTypes.ON_FETCH_TABLE_DATA:
            return {
                ...state,
                data: addObjectsWithCheck(state.data, action.response.tableData),
            };

        case holidayPanelTypes.ON_FETCH_CALENDAR_DATA:
            return {
                ...state,
                data: addObjectsWithCheck(state.data, action.response),
            };

        case holidayPanelTypes.APPROVE_HOLIDAY:
        case holidayPanelTypes.REJECT_HOLIDAY:
            return {
                ...state,
                calendarIds: updateCalendar(state.calendarIds, action.response, state.year, state.month),
                data: addObjectsWithCheck(state.data, [action.response]),
            };

        case holidayPanelTypes.ON_FETCH_WORKERS:
            return {
                ...state,
                workers: addObjectsWithCheck(state.workers, action.response)
            };

        case types.ON_FETCH_CALENDAR_DATA:
            return {
                ...state,
                year: action.year,
                month: action.month,
                calendarLoaded: true,
                calendarIds: getHolidaysByWorkerIds(action.response, state.calendarIds),
                data: addObjectsWithCheck(state.data, action.response),
            };

        case types.ON_FETCH_WORKERS:
            return {
                ...state,
                workersLoaded: true,
                workers: addObjectsWithCheck(state.workers, action.response),
                workerIds: action.response.map(x => x.id)
            };
        case types.ON_FETCH_TABLE_DATA:
            return {
                ...state,
                data: addObjectsWithCheck(state.data, action.response.tableData),
                tableIds: action.response.tableData.map(x => x.id),
                page: action.page,
                pageCount: Math.ceil((action.response.count) / PAGE_SIZE)
            };

        case types.ON_FETCH_USER_ABSANCES_PERIOD:
            return {
                ...state,
                data: addObjectsWithCheck(state.data, action.response.tableData),
                tableIds: action.response.tableData.map(x => x.id)
            };

        case types.ON_FETCH_TABLE_HISTORY_DATA:
            return {
                ...state,
                historyData: action.response.tableData,
                historyPage: action.page,
                historyPageCount: Math.ceil((action.response.count) / PAGE_SIZE),
            };

        case types.GET_ACTIVE_APPLIES_COUNTS:
            let newCounts = [];
            const newAllCounts = action.response[0].replacements.map(item => {
                newCounts = [
                    ...newCounts,
                    {
                        id: item.replacementId,
                        count: item.count,
                    }
                ];
            });
            return {
                ...state,
                notificationCount: {
                    requestsToAccept: action.response[0].totalCount
                },
                allCounts: newCounts
            };

        case types.OPEN_DIALOG:
            return {
                ...state,
                dialogOpen: true,
                editableDialog: action.editable,
                dialogId: action.id,
                forOthersHoliday: action.forOthersHoliday,
                requestForAnyWorker: action.requestForAnyWorker,
                selectedReplacementPersonId: 0,
                data: updateEmptyHoliday(state.data, action.newHoliday)
            };

        case types.CLOSE_DIALOG:
            return {
                ...state,
                dialogOpen: false,
                selectedUser: null,
                currentHolidays: 0,
                errorMessage: ""
            };

        case types.OPEN_HISTORY_DIALOG:
            return {
                ...state,
                dialogHistoryOpen: true,
                dialogHistoryId: action.id,
            };

        case types.OPEN_SUMMARY_DIALOG:
            return {
              ...state,
              dialogSummaryOpen: true,
              selectedUserName: action.name + " " + action.surname,
              selectedUser: null,
            };

        case types.CLOSE_SUMMARY_DIALOG:
            return {
              ...state,
              dialogSummaryOpen: false,
              selectedUserName: null,
            };

        case types.CLOSE_HISTORY_DIALOG:
            return {
                ...state,
                dialogHistoryOpen: false,
            };
                
        case types.SET_HISTORY_SHOW_INITIAL:
            return {
                ...state,
                dialogHistoryShowInitial: action.dialogHistoryShowInitial,
            };

        case types.SAVE_HOLIDAY:
            return {
                ...state,
                calendarIds: updateCalendar(state.calendarIds, action.response, state.year, state.month),
                data: addObjectsWithCheck(state.data, [action.response]),
                dialogOpen: false
            };

        case types.DELETE_HOLIDAY:
            // toString needed due to babel error fixed in 7.1.4
            // https://github.com/babel/babel/issues/8785
            const { [action.id.toString()]: _, ...newData } = state.data;
            return {
                ...state,
                dialogOpen: false,
                data: newData
            };

        case types.HOLIDAY_SUMMARY:
            return {
                ...state,
                selectedUser: action.response,
            };

        case types.HOLIDAY_PREV_SUMMARY:
            return {
              ...state,
              selectedSummaryUser: action.response,
            };

        case types.HOLIDAY_PANEL_CHANGE_OPEN_HOLIDAY:
            return {
                ...state,
                forOthersHoliday: true,
            };

        case types.HOLIDAY_ATTACHMENT_REMOVE_UNSAVED:
            const withoutUnsaved = [...(state.data[state.dialogId].attachments || [])].filter(attachment => !attachment.isNew);
            return updateAttachmentsArray(state, withoutUnsaved);

        case types.HOLIDAY_ATTACHMENT_ADD:
            const newAttachments = [...(state.data[state.dialogId].attachments || []), action.attachment];
            return updateAttachmentsArray(state, newAttachments);

        case types.HOLIDAY_ATTACHMENT_DELETE:
            const removed = action.attachmentId;
            const attachments = [...(state.data[state.dialogId].attachments || [])]
                .filter(el => el.tmpGuid ? (el.tmpGuid !== removed && el.guid !== removed) : el.guid !== removed);
            return updateAttachmentsArray(state, attachments);

        case types.HOLIDAY_ATTACHMENT_UPLOAD_PROGRESS:
            const attachmentsProgress = [...(state.data[state.dialogId].attachments || [])].map(attachment => {
                if (attachment.guid === action.guid)
                    attachment.progress = action.progress;
                return attachment;
            });
            return updateAttachmentsArray(state, attachmentsProgress);

        case types.HOLIDAY_ATTACHMENT_UPLOAD_FINISHED:
            const attachmentUploaded = [...(state.data[state.dialogId].attachments || [])].map(el => {
                if (el.guid === action.attachmentId) {
                    el.uploadDone = true;
                    /*
                    tmpGuid is guid assigned to an attachment before its upload has been ended
                    it can be helpful if the user tries to delete the attachment ideally when 
                    the sending is completed and the guid is overwritten with the one received from the server
                    */
                    el.tmpGuid = el.guid;
                    el.guid = action.remoteGuid;
                }
                return el;
            });
            return updateAttachmentsArray(state, attachmentUploaded);


        case types.ADD_SUPERVISIOR_FROM_SETTINGS:

            return {
                ...state,
                selectedReplacementPersonId: action.id,
            };

        case types.HOLIDAY_SELECTED_REPLACEMENT_PERSON_ID:

            return {
                ...state,
                selectedReplacementPersonId: action.response,
            };

        case types.UPDATE_HISTORY_OF_SEARCHING_REPLACEMENTS:
            return {
                ...state,
                historyOfSearchingReplacements: [
                    action.searchingId,
                    ...state.historyOfSearchingReplacements.filter(x => x !== action.searchingId)
                ]
            };

        case types.ADD_HOLIDAY_ERROR:
            return {
                ...state,
                errorMessage:action.payload.response.data.description
            }

        case types.CLEAR_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: ''
            }    

        default:
            return state;
    }
}

function updateAttachmentsArray(state, newAttachmentsArray) {
    return {
        ...state,
        data: {
            ...state.data,
            [state.dialogId]: {
                ...state.data[state.dialogId],
                attachments: newAttachmentsArray
            }
        }
    };
}

function getAttachmentsArray(state, holidayId) {
    return state.holidays.data[holidayId].attachments;
}

function getHolidayFullObject(state, holiday) {
    const currentAttachments = getAttachmentsArray(state, holiday.id);
    const objToSend = { ...holiday };
    objToSend.attachments = currentAttachments;
    return objToSend;
}