import { API } from './types';

const Actions = {
    ON_FETCH_USER_DATA: "USER_TASKS_ON_FETCH_USER_DATA"
};

const PAGE_SIZE = 200;

export const fetchUserTasks = (userId, page) => (dispatch, getState) => {
    const state = getState().userTasks[userId];
    if (state && page === state.page)
        return;
    dispatch({
        type: API,
        payload: {
            url: 'tasks/getUserTasks',
            method: "GET",
            text: "Loading user tasks", 
            onSuccess: (response) => onFetchUserData(response, page, userId),
            params: {
                start: (page - 1) * PAGE_SIZE,
                count: PAGE_SIZE,
                userId: userId,
            }
        }
    });
};

const onFetchUserData = (response, page, userId) => ({
    type: Actions.ON_FETCH_USER_DATA,
    response,
    page,
    userId
});

export function userTasks(state = {}, action) {
    switch(action.type) {
        case Actions.ON_FETCH_USER_DATA:
            return {
                ...state,
                [action.userId]: {
                    tasks: action.response.data,
                    page: action.page,
                    count: action.response.count 
                }
            };
        default:
            return state;
    }
}