import React from 'react';
import * as ReactRedux from 'react-redux';
import PropTypes from 'prop-types';
import { dateToIso } from '../../../common/helpers/date';
import { days as dayNames } from '../../../common/translations';
import { HolidayPanelStyles } from '../../HolidayStyles';
import { EN } from '../../../common/translations';
import _ from 'lodash';
import { NoEncryptionRounded } from '@material-ui/icons';

const propTypes = {
    selectedMonth: PropTypes.number,
    selectedYear: PropTypes.number,
    holidaySummary: PropTypes.bool,
};

const CalendarHeader = (props) => {

    const {selectedMonth, selectedYear, publicHolidays,holidaySummary} = props;
    let days = [];
    let numberOfDays = (new Date(selectedYear, selectedMonth + 1, 0)).getDate();

    for (let i = 1; i <= numberOfDays; i++) {
        let date = new Date(selectedYear, selectedMonth, i);
        let dayOfWeek = date.getDay();
        days.push({
            dayNumber: i,
            isWeekend: dayOfWeek === 0 || dayOfWeek === 6 || (publicHolidays.has(dateToIso(new Date(selectedYear, selectedMonth, i)).substr(0, 10))) ? " holidays" : "",
            dayName: dayNames[dayOfWeek]
        });

    }

    return (
        <div className="calendar-item">
            <div  style={HolidayPanelStyles.headerEmptyCol} />
            {days.map(day => {
                    return (
                        <div 
                            style={day.isWeekend ? HolidayPanelStyles.headerWeekend : HolidayPanelStyles.headerDay}
                            key={_.uniqueId('day_')}>
                                <div>{day.dayNumber}</div>
                                <div>{day.dayName}</div>
                        </div>
                    );
                })}
               {holidaySummary && <div style={HolidayPanelStyles.headerSummaryCol}/> }
            <div style={HolidayPanelStyles.clearBoth} />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        publicHolidays: state.publicHolidays.wtPublicHolidays
    };
  }
  

CalendarHeader.propTypes = propTypes;

export default ReactRedux.connect(
    mapStateToProps
  )(CalendarHeader);