import React from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import * as ReactRedux from 'react-redux';
//Components
import AuthWrapper from './components/authWrapper/AuthWrapper';
import App from './components/App';
import Login from './components/login/Login';
import UnAuthorized from './components/unAuthorized/UnAuthorized';
import Employees from './components/Employees/Employees';
import MailWhitelist from './components/mailWhitelist/MailWhitelist';
import Pricing from './components/pricing/Pricing';
import Scrum from './components/scrumManagement/ScrumManagement';
import Notifications from './components/notifications/Notifications';
import ChangeLogs from './components/changeLogs/ChangeLog';
import Holidays from './components/holidays/Holidays';
import PanelContainer from './components/holidays/Panel/Container/PanelContainer';
import Settings from './components/settings/Settings';
import ChangePassword from './components/changePassword/ChangePassword';
import ResetPassword from './components/changePassword/ResetPassword';
import SupervisedEmployees from './components/userTasks/SupervisedEmployees';
import TeamManagment from './components/teams/TeamManagment';
import Report from './components/hr/reports/Report';
import IrregularWorkTime from './components/hr/IrregularWorkTime/irregularWorkTime';
import HrUserHolidaysTable from './components/hr/hrUserHolidays/HrUserHolidaysTable';
import TeamTimesheet from './components/teamTimesheet/teamTimesheet';
//others
import SignedIn from './authentication/SignedIn.js';
import LoginWithToken from './components/login/LoginWithToken';

import asyncComponent from "./asyncComponent/asyncComponent";

const Timesheet = asyncComponent(() => {
    return import("./components/timeSheet/TimeSheet");
});

//constants
import { Constants } from './components/common/constants';
import { hot } from 'react-hot-loader/root';

class Routes extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <App>
                    <Switch>
                        <Route exact path="/" component={AuthWrapper(Timesheet)} />
                        <Route path="/signed-in" component={SignedIn} />
                        <Route path="/login" component={Login} />
                        <Route path="/reset" component={ResetPassword} />
                        <Route path="/timesheet" component={AuthWrapper(Timesheet)} />
                        <Route path="/timesheet/:id" component={AuthWrapper(Timesheet)} />
                        <Route path="/employees" component={AuthWrapper(Employees, [Constants.Roles.admin])} />
                        {process.env.NODE_ENV !== 'production' &&
                            <Route path="/mailWhitelist" component={AuthWrapper(MailWhitelist, [Constants.Roles.admin])} />
                        }
                        <Route path="/unauthorized" component={AuthWrapper(UnAuthorized)} />
                        <Route path="/pricing" component={AuthWrapper(Pricing, undefined, [Constants.TeamRoles.evaluator])} />
                        <Route path="/scrum" component={AuthWrapper(Scrum, undefined, [Constants.TeamRoles.scrumMaster])} />
                        <Route path="/notifications" component={AuthWrapper(Notifications, [Constants.Roles.admin])} />
                        <Route path="/holidays" component={AuthWrapper(Holidays, [Constants.Roles.holiday])} />
                        <Route path="/holidaypanel" component={AuthWrapper(PanelContainer, [Constants.Roles.supervisor])} />
                        <Route path="/changeLogs" component={AuthWrapper(ChangeLogs, [Constants.Roles.admin])} />
                        <Route path="/settings" component={AuthWrapper(Settings, [Constants.Roles.supervisor])} />
                        <Route path="/supervisedEmployees" component={AuthWrapper(SupervisedEmployees, [Constants.Roles.supervisor, Constants.Roles.admin])} />
                        <Route path="/teamManagement" component={AuthWrapper(TeamManagment, [Constants.Roles.admin], [Constants.TeamRoles.teamLeader])} />
                        <Route path="/changePassword" component={AuthWrapper(ChangePassword)} />
                        <Route path="/workTimeReport" component={AuthWrapper(Report)} />
                        <Route path="/workTimeIrregular" component={AuthWrapper(IrregularWorkTime)} />
                        <Route path="/hrHolidays" component={AuthWrapper(HrUserHolidaysTable)} />
                        <Route path="/hrHolidaysHistory" component={AuthWrapper(HrUserHolidaysTable)} />
                        <Route path="/teamTimeSheet" component={AuthWrapper(TeamTimesheet, [Constants.Roles.supervisor,Constants.Roles.hr])} />
                        <Route path="/setToken/:accesToken/:refreshToken" component={LoginWithToken} />
                        <Route path="*"><Redirect to="/timesheet"/></Route>
                    </Switch>
                </App>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    return { isAuthenticated: state.login.isAuthenticated };
}

export default hot(ReactRedux.connect(mapStateToProps)(Routes));
