import React from 'react';
//
import BodyMonth from '../../../timeSheet/calendarPanel/calendarBody/BodyMonth';
import { colors } from '../../../common/styles';
import _ from 'lodash';

class Months extends React.Component {

    shouldComponentUpdate = (nextProps) => {
        const { addToFooterProps, showComment, hideComment, ...comparableProps } = this.props;
        const { addToFooterProps: a, showComment: b, hideComment: c, ...comparableNextProps } = nextProps;
        return !_.isEqual(comparableProps, comparableNextProps);
    }

    months = () => {
        const timeSheets = [];

        for (let Issue in this.props.reportData) {
            const issueRef = this.props.reportData[Issue];

            const monthProps = {
                selectedMonth: this.props.selectedMonth,
                selectedYear: this.props.selectedYear,
                selectedProject: {
                    color: colors.nexioOrange
                },
                selectedDay: {},
                selectedDays: [],
                issue: issueRef,
                hasHolidayRequestEnabled: true,
                selectionEnabled: false,
                selectMode: "none",
                selectDay: () => null,
                selectRange: () => null,
                showComments: this.props.showComment,
                hideComments: this.props.hideComment,
                onDaySelectStart: () => null,
                onDaySelectEnd: () => null
            };

            timeSheets.push(<BodyMonth key={`issue_month_${issueRef.id}`} {...monthProps}/>);
        }
        
        return timeSheets;
    };

    render() {
        return (
            <div className="calendar-body">
                {this.months()}
            </div>
        );
    }
}

export default Months;