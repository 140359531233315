import React from 'react';
//
import _ from 'lodash';
import moment from 'moment';
//
import CalendarHeader from '../../../timeSheet/calendarPanel/calendarHeader/CalendarHeader';
import CalendarFooter from '../../../timeSheet/calendarPanel/calendarFooter/CalendarFooter';
import { formatValidDate } from '../../../common/helpers/date';
import { Constants } from '../../../common/constants';
import Months from './Months';
//
import './calendarreport.scss';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { onShowComment, onHideComment } from '../../../../store/Comments';


class calendarReport extends React.Component {

    shouldComponentUpdate = (nextProps) => {
        const { showComment, hideComment, ...comparableProps } = this.props;
        const { showComment: b, hideComment: c, ...comparableNextProps } = nextProps;
        return !_.isEqual(comparableProps, comparableNextProps);
    }

    componentDidUpdate = (prevProps) => {
        if (!_.isEqual(prevProps.selectedMonth, this.props.selectedMonth) ||
            !_.isEqual(prevProps.selectedYear, this.props.selectedYear) ||
            !_.isEqual(prevProps.reportData, this.props.reportData)
        )
            this.footerProps = { ...this.initialFooterProps };
    }

    mapRemoteDays = () => {
        const holidays = this.props.userHolidays;
        if (!holidays.length)
            return [];

        const remoteRequests = holidays.filter(request => request.type === Constants.HolidayTypes.REMOTE);
        if (!remoteRequests.length)
            return [];

        const remoteDays = remoteRequests.map(request => moment(request.dateFrom).date());
        return remoteDays || [];
    }

    cutRemoteDays = () => {
        const holidays = this.props.userHolidays;
        if (!holidays.length)
            return [];

        return holidays.filter(holiday => holiday.type !== Constants.HolidayTypes.REMOTE) || [];
    }

    groupHolidaysByIssueId = (issueId) => {
        const holidays = this.cutRemoteDays();
        
        switch (issueId){
            case Constants.Issues.holidayIssueId:
                return holidays.filter(holiday => holiday.type === Constants.HolidayTypes.HOLIDAY);
            case Constants.Issues.sickIssueId:
                return holidays.filter(holiday => holiday.type === Constants.HolidayTypes.SICK_LEAVE);
            case Constants.Issues.otherHolidayIssueId:
                return holidays.filter(holiday => holiday.type === Constants.HolidayTypes.OTHERS);
            case Constants.Issues.unpaidHolidayIssueId:
                return holidays.filter(holiday => holiday.type === Constants.HolidayTypes.UNPAID_HOLIDAY);
            default:
                return holidays;
        }
    }

    showComment = (comments) => {
        this.props.onShowComment(comments);
    }

    hideComment = () => {
        this.props.onHideComment();
    }

    render() {

        const mappedReportData = [];
        let prevIssueId = [];

        Object.keys(this.props.reportData).forEach((proj) => {
            const lookup = this.props.reportData[proj].reduce((a, e) => {
                a[e.issueId] = ++a[e.issueId] || 0;
                return a;
            }, {});

            const duplicateIssues = this.props.reportData[proj].filter(e => lookup[e.issueId]);

            function duplicateIssue(issueId) {
                return duplicateIssues.some(function (i) {
                    return i.issueId === issueId;
                });
            }

            Object.keys(this.props.reportData[proj]).forEach((issue) => {
                this.props.reportData[proj][issue].date = formatValidDate(this.props.reportData[proj][issue].spentOn);
                const issueId = this.props.reportData[proj][issue].issueId;

                if (!duplicateIssue(this.props.reportData[proj][issue].issueId)) {
                    mappedReportData.push({
                        id: this.props.reportData[proj][issue].issueId,
                        projectId: this.props.reportData[proj][issue].projectId,
                        name: this.props.reportData[proj][issue].issueName,
                        userHolidays: this.groupHolidaysByIssueId(this.props.reportData[proj][issue].issueId),
                        timesheetRows: [this.props.reportData[proj][issue]]
                    });
                    prevIssueId.push(this.props.reportData[proj][issue].issueId);
                }
                else {
                    if (!prevIssueId.includes(this.props.reportData[proj][issue].issueId)) {
                        mappedReportData.push({
                            id: this.props.reportData[proj][issue].issueId,
                            projectId: this.props.reportData[proj][issue].projectId,
                            name: this.props.reportData[proj][issue].issueName,
                            userHolidays: this.groupHolidaysByIssueId(this.props.reportData[proj][issue].issueId),
                            timesheetRows: duplicateIssues.filter(function (e) {
                                return e.issueId === issueId;
                            })
                        });
                        prevIssueId.push(this.props.reportData[proj][issue].issueId);
                    }
                }
            });
        });

        const footerProps = {
            selectedMonth: this.props.selectedMonth,
            selectedYear: this.props.selectedYear,
            issues: _.values(mappedReportData),
            selectedProject: {},
            onDayClicked: () => null,
            isTeamTimesheet: true
        };

        const headerProps = {
            selectedMonth: this.props.selectedMonth,
            selectedYear: this.props.selectedYear,
            hrIssues: [],
            issuesNum: Object.keys(this.props.reportData).length,
            remoteDays: this.mapRemoteDays()
        };

        const monthsProps = {
            selectedMonth: this.props.selectedMonth,
            selectedYear: this.props.selectedYear,
            reportData: mappedReportData,
            showComment: this.showComment,
            hideComment: this.hideComment
        };

        return (
            <div className="report-presentation">
                <div className="calendar-panel">
                    <div className="calendar">
                        <div className="calendar-box">
                            <CalendarHeader {...headerProps} />
                            <Months {...monthsProps} />
                            <CalendarFooter {...footerProps} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onShowComment,
        onHideComment
    }, dispatch);
}

export default ReactRedux.connect(null, mapDispatchToProps)(calendarReport);