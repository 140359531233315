export const Constants ={
    Roles:{
        admin: "Admin",
        evaluator: "Evaluator",
        scrumMaster: "ScrumMaster",
        holiday: "Holiday",
        supervisor: "Supervisor",
        teamLeader: "TeamLeader",
        hr: 'HR'
    },
    HolidayStatuses:{
        REJECTED: 'Rejected',
        ACCEPTED: 'Accepted',
        NEW: 'New'
    },
    HolidayTypes:{
        HOLIDAY: 'Holiday',
        UNPAID_HOLIDAY: 'Unpaid holiday',
        SICK_LEAVE: 'Sick leave',
        OTHERS: 'Others absences',
        REMOTE: 'Remote work'
    },
    HolidayReplacement:{
        NONE_ID: 0
    },
    ValueTypes: {
        STRING: '1',
        INT: '2',
        DECIMAL: '3',
        DATETIME: '4'
    },
    TeamRoles: {
        analyst : 1,
        developer : 2,
        evaluator : 3,
        scrumMaster : 4,
        tester : 5,
        teamLeader : 6
    },
    TeamRolesTextMapping: {
        "1": "Analyst",
        "2": "Developer",
        "3": "Evaluator",
        "4": "Scrum master",
        "5": "Tester",
        "6": "Team leader",
        "7": "PM"
    },
    Issues: {
        holidayProjectId: 89,
        holidayIssueId: 2912,
        unpaidHolidayIssueId: -3459,
        sickIssueId: 2913,
        otherHolidayIssueId: 3459,
        allProjectsId: 0
    },
    HolidayHRStatuses:{
        REJECTED: 'Rejected',
        ACCEPTED: 'Accepted',
        NEW: 'New'
    },
    HolidayHRTypes:{
        HOLIDAY: 'Holiday',
        UNPAIDHOLIDAY: 'Unpaid holiday',
        SICK_LEAVE: 'Sick leave',
        OTHERS: 'Others absences'
    },
    UnpaidHolidayProject:{
        NAME: 'HR - urlopy, chorobowe'
    },
    HolidayHRContractTypes:{
        B2B: 'B2B',
        CIVIL: 'Civil',
        EMPLOYMENT: 'Employment'
    },
    IrregularWorkTime: {
        AllFilters: "AllFilters",
        LastName: "LastName",
        ContractName: "ContractName"
    },
    ContractTypes: {
        Employment: "Employment",
        Civil: "Civil",
        B2B: "B2B"
    },
    UserStatusMapping: {
        1: "Active",
        3: "Inactive"
    },
    UserStatuses: {
        ACTIVE: 1,
        INACTIVE: 3,
    },
    HttpStatusCodes: {
        OK: 200,
        Created: 201,
        Accepted: 202,
        NoContent: 204,
        MovedPermanently: 301,
        Found: 302,
        SeeOther: 303,
        NotModified: 304,
        TemporaryRedirect: 307,
        BadRequest: 400,
        Unauthorized: 401,
        Forbidden: 403,
        NotFound: 404,
        MethodNotAllowed: 405,
        NotAcceptable: 406,
        PreconditionFailed: 412,
        UnsupportedMediaType: 415,
        InternalServerError: 500,
        NotImplemented: 501
    },
    TeamTimesheet: {
        statusAccepted: "Accepted",
        statusNew: "New"
    },
    Pool: {
       firstEmployment: "First Employment",
       regularAmount: "20",
       longAmount: "26"
    },
    ScrumAvailability: {
        Available: 0,
        Soon: 1,
        Busy: 2,
    }
};