import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../../store/teamTimeSheet/actions';
import { getPendingApplication } from '../../../../store/teamTimeSheet/operations';
import Pagination from '../../../common/partials/pagination/pagination';
import CalendarControls from '../../../timeSheet/calendarPanel/CalendarControls';
import ExportToExcel from '../../../timeSheet/calendarPanel/ExportToExcel';
import { createParametrizedUrl } from '../../../common/helpers/urls';
/*Material*/
import {Button, Switch, FormControlLabel } from '@material-ui/core';
import { EN } from '../../../common/translations';
import { TableStyles, HeaderStyles, SwitchStyles } from '../../style';
import DataTable from '../../../common/DataTable';
import TableDialog from '../dialog/tableDialog'
import RejectDialog from '../dialog/RejectDialog';
import { getWorktimeReportDetails } from '../../../../store/HR';
import {apiExcelPerApplication} from  '../../../common/cfg';

class PendingApplication extends Component {
    constructor(props) {
        super(props);
        this.columns = this.getColumns();
    }

    componentDidMount() {
        this.props.getPendingApplication(1, this.props.month, this.props.year);
    }

    getExcelPerApplicationUrl = (selectedApplicationId) => {
        return {
                url: createParametrizedUrl(apiExcelPerApplication, {
                selectedApplicationId: selectedApplicationId
            })
        };
    };
    
    openTableDialogF(version, employeeId, employeeName, id) {
        this.props.getWorktimeReportDetails(employeeId, this.props.month, this.props.year);
        this.props.openTableDialog(version, employeeId, employeeName, id);
    }

    getColumns() {
        return [
            {
                header: EN.teamTimesheet.pendingApplication.employeeColumn,
                valueProperty: "employeeName"
            },
            {
                header: EN.teamTimesheet.pendingApplication.versionColumn,
                valueProperty: 'version'
            },
            {
                header: EN.teamTimesheet.pendingApplication.postDateColumn,
                getValue: (row) => row.postDate.substring(0, 10)
            },
            {
                header: EN.teamTimesheet.pendingApplication.statusColumn,
                valueProperty: "status"
            },
            {
                header: EN.teamTimesheet.pendingApplication.downloadColumn,
                getValue: row => (
                    <ExportToExcel
                        getUrl={() => this.getExcelPerApplicationUrl(row.id)}/>)
            },
            {
                header: EN.teamTimesheet.pendingApplication.actionColumn,
                getValue: row => (
                        <Button            
                            variant="contained" 
                            color="primary"
                            onClick={() => this.openTableDialogF(row.version,row.employeeId, row.employeeName, row.id)}>{EN.teamTimesheet.pendingApplication.verify}
                        </Button>)
            },
        ];
    }

    render() {
        const calendarControlsProps = {
            month: this.props.month,
            year: this.props.year,
            page: this.props.tablePage,
            onChange: (month, year) => this.props.getPendingApplication(this.props.tablePage, month, year),
        };
        return (
            <>
                <h3 style={HeaderStyles}>{EN.teamTimesheet.pendingApplication.title}</h3>
                <div className="calendar-panel">
                    <div className="calendar">
                        <div className="calendar-controls">
                            <CalendarControls {...calendarControlsProps}/>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={this.columns}
                    cellStyle={TableStyles.headerColStyle}
                    headerStyle={TableStyles.headerColStyle}
                    tableStyle={TableStyles.table}
                    data={this.props.tableData}
                />
                <Pagination
                    onChange={(page) => this.props.getPendingApplication(page, this.props.month, this.props.year)}
                    value={this.props.tablePage}
                    count={this.props.tableCount}
                />
                {this.props.isOpen? <TableDialog isAcceptable={true}/> : null}
                <RejectDialog/>
            </>
        );
    }
}

const mapStateToProps = state => ({
    isOpen: state.teamTimesheet.tableDialog.open,
    tableData: state.teamTimesheet.pendingApplication.dataTable,
    tablePage: state.teamTimesheet.pendingApplication.page,
    tableCount: state.teamTimesheet.pendingApplication.count,
    year: state.teamTimesheet.teamTimesheetDate.year,
    month: state.teamTimesheet.teamTimesheetDate.month,
});

const mapDispatchToProps = dispatch => ({
    getWorktimeReportDetails: (userId, month, year) => dispatch(getWorktimeReportDetails(userId, month + 1, year)),
    getPendingApplication: (page, month, year) => dispatch(getPendingApplication(page, month, year)),
    openTableDialog: (version, employeeId, employeeName, selectedApplicationId) => dispatch(actions.openTableDialog(version, employeeId, employeeName, selectedApplicationId)) 
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingApplication);