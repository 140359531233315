/*React Libs*/
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
/*Store*/
import {onLoadingStart, onLoadingEnd, onLoadingError} from '../../store/Preloader';
import {onAddFlashMessage, onClearFlashMessages} from '../../store/FlashMessage';
import {onSetNotifications} from '../../store/Notifications';
/*Material*/
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';
import axios from 'axios';
//Common
import {colors} from '../common/styles.js';
import {EN} from '../common/translations';
import {httpReqErrorHandler} from '../common/helpers/errorHandler';
//Custom
import AddEditModal from './AddEditModal/AddEditModal';
import {host} from '../common/cfg';
import notificationsStyles from './notificationsStyles';
//Styles

class Notifications extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            fixedHeader: false,
            stripedRows: false,
            showRowHover: true,
            selectable: false,
            multiSelectable: false,
            enableSelectAll: false,
            deselectOnClickaway: true,
            showCheckboxes: false,
            height: '100%',
            openModal: false,
            openUpdateModal: false,
            openChangesModal: false,
            editData: {},
            sorting: 'nameDesc',
            tableData: [],
            snackMessage: '',
            snackOpen: false,
            b2bCheck: false,
            civilCheck: false,
            employmentCheck: false,
            updateChanges:[]
    
        };
        this.dataCopy = [];
        this.addBtnStyle = {
            color: colors.nexioOrange
        };

        this.fieldsToIgnore = ['id', 'addedDate'];
    
        this.getNotifications = this.getNotifications.bind(this);
        this.saveNotification = this.saveNotification.bind(this);
        this.deleteNotification = this.deleteNotification.bind(this);

        this.handleClose = () => {
            this.setState({openModal: false});
        };

        this.handleSubmit = (notification, groupId) => {
            this
                .props
                .onLoadingStart('Saving notification');
            this.saveNotification(notification, groupId);
            this.setState({openModal: false});
        };

        this.handleDelete = (notification) => {
            this
                .props
                .onLoadingStart('Deleting notification');
            this.deleteNotification(notification);
            this.setState({openModal: false});
        };
    
        this.handleUpdateClose = () => {
            this.setState({openUpdateModal: false});
        };
    
        this.onAddClickHandler = () => {
            let editData = {
                subject: "",
                body: ""
            };
            this.setState({openModal: true, editData: editData});
        };
    
        this.onCellClickHandler = (row) => {
            let dataToEdit = this.state.tableData[row];
            let editData = {
                ...dataToEdit
            };
            this.setState({openModal: true, editData: editData, snackOpen: false});
        };
    
        this.onTextFieldChange = (field, value) => {
            let editDataCopy = {
                ...this.state.editData
            };
            editDataCopy[field] = value;
            this.setState({editData: editDataCopy});
        };
    }
    
    componentDidMount() {
        this.getNotifications();
    }

    getNotifications(){
        this
                .props
                .onLoadingStart('Loading notifications');
        let headers = {
            'headers': {
                'Content-Type': 'application/json;text/plain;text/json',
                'Accept': 'application/json'
            }
        };

        axios
            .get(`${host}/api/notification/getAll`, headers)
            .then(response => {
                this.setState({tableData: response.data});
                this.dataCopy = this.state.tableData;
                this
                    .props
                    .onLoadingEnd();
            }, 
            (err)=> {
                this
                    .props
                    .onAddFlashMessage(httpReqErrorHandler(err));
                setTimeout(() => {
                    this
                        .props
                        .onClearFlashMessages();
                }, 5000);
            });
    }

    saveNotification(notification, groupId){
        let headers = {
            'headers': {
                'Content-Type': 'application/json;text/plain;text/json'
            }
        };

        axios
            .put(`${host}/api/notification/save?groupId=${groupId}`, notification, headers)
            .then(response => {
                this
                    .props
                    .onLoadingEnd();
                localStorage.setItem('notifications', JSON.stringify(response.data));
                this.props.onSetNotifications(response.data);
                this.getNotifications();
            }, 
            (err)=> {
                this
                    .props
                    .onLoadingEnd();
                this
                    .props
                    .onAddFlashMessage(httpReqErrorHandler(err));
                setTimeout(() => {
                    this
                        .props
                        .onClearFlashMessages();
                }, 5000);
            });
    }

    deleteNotification(notificationId){
        let headers = {
            'headers': {
                'Content-Type': 'application/json;text/plain;text/json'
            }
        };

        axios
            .post(`${host}/api/notification/deleteAll?notificationId=${notificationId}`, headers)
            .then(response => {
                this
                    .props
                    .onLoadingEnd();
                localStorage.setItem('notifications', JSON.stringify(response.data));
                this.props.onSetNotifications(response.data);
                this.getNotifications();
            }, 
            (err)=> {
                this
                    .props
                    .onLoadingEnd();
                this
                    .props
                    .onAddFlashMessage(httpReqErrorHandler(err));
                setTimeout(() => {
                    this
                        .props
                        .onClearFlashMessages();
                }, 5000);
            });
    }

    render() {
        let tableBody = (
            this
                .state
                .tableData
                .map((row, index) => (
                    <TableRow key={index} className="hoverRowClass">
                        <TableRowColumn style={notificationsStyles.employeesColStyle}>{moment.utc(row.addedDate).local().format("YYYY-MM-DD")}</TableRowColumn>
                        <TableRowColumn style={notificationsStyles.employeesColStyle}>{row.subject.length > 20 ? row.subject.substring(0,20) + "..." : row.subject}</TableRowColumn>
                        <TableRowColumn style={notificationsStyles.employeesColStyle}>{row.body.length > 20 ? row.body.substring(0,50) + "..." : row.body}</TableRowColumn>
                    </TableRow>
                ))
        );

        if(this.state.tableData.length === 0){
            tableBody = <TableRow><TableRowColumn style={notificationsStyles.employeesColStyle} colSpan={3}>0 notifications</TableRowColumn></TableRow>;
        }

        return (
            <div style={notificationsStyles.tableDivStyle}>
                <AddEditModal
                    isOpened={this.state.openModal}
                    onCancelClick={this.handleClose}
                    onSubmitClick={this.handleSubmit}
                    onDeleteClick={this.handleDelete}
                    notification={this.state.editData}
                    fieldsToIgnore={this.fieldsToIgnore}
                    />
                <div style={{
                    boxSizing:'border-box',
                    width:'100%', 
                    position:'relative',
                    display:'block',
                    height: 54,
                    padding: 4,
                    }}>
                    <span style={{float:'right'}}>
                        <RaisedButton
                            label="Add"
                            backgroundColor={colors.nexioOrange}
                            onClick={() => this.onAddClickHandler()}/>
                        <div style={{width:15, display:"inline-block"}} />
                    </span>
                </div>
                <Table
                    style={{clear: 'both', position:'relative', paddingTop:4, tableLayout: 'auto'}}
                    className={'clearFix'}
                    height={this.state.height}
                    fixedHeader={this.state.fixedHeader}
                    fixedFooter={this.state.fixedFooter}
                    selectable={this.state.selectable}
                    multiSelectable={this.state.multiSelectable}
                    onCellClick={(row) => this.onCellClickHandler(row)}>
                    <TableHeader
                        displaySelectAll={this.state.showCheckboxes}
                        adjustForCheckbox={this.state.showCheckboxes}
                        enableSelectAll={this.state.enableSelectAll}>
                        <TableRow>
                            <TableHeaderColumn style={notificationsStyles.headerColStyle}>
                                {EN.notifications.date}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={notificationsStyles.headerColStyle}>{EN.notifications.subject}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={notificationsStyles.headerColStyle}>{EN.notifications.body}
                            </TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody
                        displayRowCheckbox={this.state.showCheckboxes}
                        deselectOnClickaway={this.state.deselectOnClickaway}
                        showRowHover={this.state.showRowHover}
                        stripedRows={this.state.stripedRows}
                    >
                        {tableBody}
                    </TableBody>
                </Table>
                <Snackbar
                    open={this.state.snackOpen}
                    message={this.state.snackMessage}
                    autoHideDuration={4000}
                    onRequestClose={this.handleRequestClose}
                    bodyStyle={notificationsStyles.snackStyle}
                    contentStyle={notificationsStyles.snackStyle}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        login: state.login
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onLoadingStart,
        onLoadingEnd,
        onLoadingError,
        onAddFlashMessage,
        onClearFlashMessages,
        onSetNotifications
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Notifications);