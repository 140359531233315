const changeLogsDialogStyles = {
    
    logsPanel :{
        boxSizing: 'border-box',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    logsListBox :{
            overflow: 'auto',
            position: 'relative'
    },
    logsList :{
        height: 'auto',
        display: 'list-item',
    },
    logsListItem :{
        fontSize: '0.8em',
        listStyleType: 'circle',
        paddingTop: '4px',
        paddingBottom: '4px',
        textAlign: 'left',
        color: '#c5c5c5',
        width:'100%',
        textDecoration:'none',
        display: 'list-item',
    },
    logsTitleItem :{
        fontSize: '2em !important',
        listStyleType: 'none',
        paddingTop: '4px',
        paddingBottom: '4px',
        textAlign: 'left',
        borderBottom: '2px solid #222222'
    },
    logsContent :{
        display: 'inline-block',
        position: 'relative'
    },
    logsItem: {
        marginBottom: '10px'
    },
    logFile: {
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '13px'
    }
};

export default changeLogsDialogStyles;