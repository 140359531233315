/**IMPORTANT!!!
 * 
 * To disable red outline of search panel change variable:
 * $visibleBorders: false !default;
 * 
 * in styles\varaibles.scss
 */

//React Libs
import React from 'react';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
//Translations
import {
    EN
} from '../common/translations';
//Components
import SearchBar from '../common/partials/SearchBar';
import EmployeesListPanel from './employeesListPanel/EmployeesListPanel';
import ScrumPanel from './scrumPanel/ScrumPanel';
//Store
import {
    onLoadingStart,
    onLoadingEnd,
    onLoadingError
} from '../../store/Preloader';
import {
    onAddFlashMessage,
    onClearFlashMessages
} from '../../store/FlashMessage';
import {
    getEmployeesListForScrum,
    sendMail,
    getScrumReports,
    saveScrum
} from '../../store/ScrumStore';
//Icons
import SendMailIcon from '@material-ui/icons/MailOutline';
import SortIcon from '@material-ui/icons/Sort';
//Material UI
import IconButton from 'material-ui/IconButton';
import _ from 'lodash';
import {
    colors
} from '../common/styles';
//Common
import {httpReqErrorHandler} from '../common/helpers/errorHandler';
import moment from 'moment';
import { Constants } from "../common/constants";

const propTypes = {};
const defaultProps = {};

class ScrumManagement extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                employeesList: [],
                scrumDate: {},
                previousTeamId: undefined
            };
            this.dateCopy = [];
            this.searchValue = '';

            this.filterUsers = (event, value) => {
                let tableDataCopy = this.dateCopy;
                this.searchValue = value;
                if (value != '')
                    tableDataCopy = _.filter(tableDataCopy, x => (x.firstname.toUpperCase().lastIndexOf(value.toUpperCase()) != -1 || x.lastname.toUpperCase().lastIndexOf(value.toUpperCase()) != -1));
                this.setState({
                    employeesList: tableDataCopy
                });
            };

            this.sendMail = () => {
                this.props.onLoadingStart('Sending mail');
                this.props.sendMail(this.props.teamId).then(response => {
                        this.props.onLoadingEnd();
                    }).catch(this.handleError);
            };

            this.onCellClickHandler = (row, cell) => {
                let user = this.state.employeesList[row];
                this.props.onLoadingStart('Loading scrum report');
                this.props.getScrumReports(user.id)
                    .then(response => {
                        this.setState({
                            scrumDate: response.data
                        });
                        this.props.onLoadingEnd();
                    }).catch(this.handleError);
            };

            this.onProgresChange = (field, newValue, idTask) => {
                let editDataCopy = this.state.scrumDate;
                editDataCopy['taskProgress'].map(function (value, index) {
                    if (value.taskRedmineId === idTask)
                        editDataCopy['taskProgress'][index][field] = newValue;
                });
                this.setState({
                    scrumDate: editDataCopy
                });
            };

            this.onFieldChange = (field, newValue) => {
                let editDataCopy = this.state.scrumDate;
                editDataCopy[field] = newValue;
                this.setState({
                    scrumDate: editDataCopy
                });
            };

            this.onSaveScrum = () => {
                this.props.onLoadingStart('Saving scrum');
                this.props.saveScrum(this.state.scrumDate).then(response => {
                    this.props.onLoadingEnd();
                    this.props.onLoadingStart('Loading employees list');
                    this.props.getEmployeesListForScrum(this.props.teamId).then(response => {
                        this.dateCopy = response.data;
                        this.filterUsers(null, this.searchValue);
                        this.props.onLoadingEnd();
                    });
                }).catch(this.handleError);
            };

            this.getScrumForDate = (date) => {
                if(!moment(date.target.value).isValid()) {
                    return;
                }
                const objectDate = new Date(date.target.value);
                this.props.onLoadingStart('Loading scrum report');
                this.props.getScrumReports(this.state.scrumDate.userId, objectDate.toJSON()).then(response => {
                    this.setState({
                        scrumDate: response.data
                    });
                    this.props.onLoadingEnd();
                }).catch(this.handleError);
            };

            this.handleError = (error) => {
                this.props.onLoadingEnd();
                this.props.onAddFlashMessage(httpReqErrorHandler(error));
                setTimeout(() => {this.props.onClearFlashMessages();}, 5000);
            };
        }


        componentDidMount() {
            this.getEmployeeList();
        }

        componentDidUpdate() {
            if (this.state.previousTeamId !== this.props.teamId) {
                this.getEmployeeList();
            }
        }
        
        getEmployeeList() {
            const teamId = this.props.teamId;
            this.setState({
                previousTeamId: teamId,
            });
            if (!teamId)
                return;
            this.props.onLoadingStart('Loading employees list');
            this.props.getEmployeesListForScrum(teamId).then(response => {
                
                this.dateCopy = response.data;
                if (teamId === this.props.teamId) {
                    this.setState({
                        employeesList: response.data
                    });
                }
                this.props.onLoadingEnd();
            }).catch(this.handleError);
        }

        render() {

            return ( < div className = "scrum-management page" >
        <div className="ts container">
            <div className="ts row">
                <div className="ts col-left col col-md-5">
                    <div style={{display:'inline-block', paddingLeft:'15px', paddingTop:'5px'}}>
                        <SearchBar position="initial" onSearch={this.filterUsers}/>
                    </div>
                    <div style={{display:'inline-block', float:'right', paddingTop:'15px'}}>
                        <IconButton 
                            onClick={this.sendMail}
                            iconStyle={{width: 30, height: 30}}
                            style={{
                        backgroundColor: colors.nexioOrange,
                        width: 70,
                        height: 30,
                        padding: '0px 0px 0px 0px'
                              }}>
                            <SendMailIcon/>
                            <SortIcon/>
                        </IconButton>
                    </div>
                    <div>
                        <EmployeesListPanel 
                        onCellClick={(row, cell) => this.onCellClickHandler(row, cell)}
                        employeesList={this.state.employeesList}/>
                    </div>
                </div>
                <div className="ts col-right col col-md-7">
                        {this.state.scrumDate.reportId != null ? 
                            <ScrumPanel 
                                onCalendarChange={this.getScrumForDate} 
                                scrumDate={this.state.scrumDate} 
                                onFieldChange={this.onFieldChange} 
                                onProgressChange={this.onProgresChange} 
                                onSaveClick={this.onSaveScrum}/> 
                        :''}
                </div>
            </div>
        </div>
    </div>);
            }
        }

        ScrumManagement.propTypes = propTypes;
        ScrumManagement.defaultProps = defaultProps;

        /**Redux Part Start */
        function mapStateToProps(state) {
            return {
                teamId: state.teams.selectedTeam
            };
        }

        function mapDispatchToProps(dispatch) {
            return Redux.bindActionCreators({
                getEmployeesListForScrum,
                sendMail,
                getScrumReports,
                saveScrum,
                onLoadingStart,
                onLoadingEnd,
                onLoadingError,
                onAddFlashMessage,
                onClearFlashMessages,
            }, dispatch);
        }
        /**Redux Part End */

        export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ScrumManagement);
