import { connect } from 'react-redux';
import { getTreeList } from '../common/TreeList';
import { Constants } from '../common/constants';
import { loadSupervisedEmployees } from '../../store/SupervisedEmployees';

const mapDispatchToProps = {
    onExpand: loadSupervisedEmployees
};


const emptyArray = [];

function mapStateToProps({ supervisedEmployees, login }, { id }) {

    const noData= id=> !!supervisedEmployees.employees[id] && supervisedEmployees.employees[id].noData;
    const giveData = id => {
        if(!supervisedEmployees.employees[id]) return emptyArray;
        return supervisedEmployees.employees[id].myEmployees;
    }

    if (!id) {
        id = login.user.roles.includes(Constants.Roles.admin) ? 0 : login.user.id;
    }
    if(Array.isArray(supervisedEmployees.employees[0]) && !login.user.roles.includes(Constants.Roles.admin)) 
    {
        supervisedEmployees.employees[0] = supervisedEmployees.employees[0].filter(function (item) { return item.id == login.user.id; });
        return {
            id,
            data: giveData(id),
            noData: noData(id)
        }
    }
    return {
        id,
        data: giveData(id),
        noData: noData(id)
    }
};
const wrapper = connect(mapStateToProps, mapDispatchToProps);

const TreeList = getTreeList({
    wrapper, 
    getId:(d) => d.id, 
    calculateCanExpand: (d) => d.isSupervisor
});

export default TreeList;