
import shortId from 'shortid';
import lodash from 'lodash';
/** ACTION START */
const types = {
    ADD_FLASH_MESSAGE: 'ADD_FLASH_MESSAGE',
    DELETE_FLASH_MESSAGE: 'DELETE_FLASH_MESSAGE',
    CLEAR_FLASH_MESSAGES: 'CLEAR_FLASH_MESSAGES'
};

export const onAddFlashMessage = (message) => ({type: types.ADD_FLASH_MESSAGE, data: message});
export const onDeleteFlashMessage = (id) => ({type: types.DELETE_FLASH_MESSAGE, data: id});
export const onClearFlashMessages = () => ({type: types.CLEAR_FLASH_MESSAGES});
/** ACTION END */

/** REDUCER START */
export const flashMessages =(prevState = [], action = {}) => {

    switch (action.type) {
        case types.ADD_FLASH_MESSAGE:
            prevState = prevState.length === 3 ? prevState.slice(1,3) : prevState;
            return [
                ...prevState,
                {
                    id: action.data.id || shortId.generate(),
                    type: action.data.type,
                    text: action.data.text
                }
            ];

        case types.DELETE_FLASH_MESSAGE:
            return prevState.filter( message => message.id !== action.data);

        case types.CLEAR_FLASH_MESSAGES:
            return [];

        default:
            return prevState;
    }
};
/** REDUCER END */
