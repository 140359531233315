import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import {EN} from '../../common/translations';
import {onLoadingStart, onLoadingEnd} from '../../../store/Preloader';
import changeLogDialogStyles from './ChangeLogsDialogStyles';
import OrangeText from '../../common/partials/orangeText';
import {colors} from '../../common/styles.js';
import { downloadFileById } from '../../../store/ChangeLog';


const propTypes = {
    onLoadingStart: PropTypes.func,
    onLoadingEnd: PropTypes.func,
    onCloseClick: PropTypes.func,
    changeLogs: PropTypes.array,
    isChangeLogsOpen: PropTypes.bool
};

const defaultProps = {
    textFieldStyle : {
        underlineFocusStyle: {borderColor: '#fff', paddingBottom:'2px'},
        textareaStyle:{fontSize:'14px'}
    }
};

const downloadFile = (e, id) => {
    e.stopPropagation();
    downloadFileById(id);
};

export class ChangeLogsDialog extends Component {

    render() {
        const { changeLogs, onCloseClick, isChangeLogsOpen, textFieldStyle } = this.props;
        const {title} = EN.changeLogs;
        const bottomActions = <FlatButton key="close" label="Close" primary onClick = {onCloseClick}/>;
        
        const table = changeLogs.length === 0
            ? <ul style={changeLogDialogStyles.logsList}><li>0 change logs</li></ul>
            : changeLogs.map(({id, userId, addedDate: addDate, title, description: desc, filename}) => (
                <div key={id} style={changeLogDialogStyles.logsItem}>
                        <OrangeText style={changeLogDialogStyles.logsTitleItem}>
                            {
                                title && (title.length > 50 ? title.substring(0,50) + "..." : title)  
                            }
                        </OrangeText>
                            {
                                desc && 
                                    (<ul style={changeLogDialogStyles.logsList}>
                                        {
                                            (desc.length > 500
                                                ? desc.substring(0,500) + "..."
                                                : desc).split('\n').map((item, index) => {
                                                    return (
                                                        <li key={index} style={changeLogDialogStyles.logsListItem}>
                                                            {item}
                                                        </li>
                                                    );
                                            })
                                        }
                                    </ul>)
                            }
                            {
                                filename && <div style={changeLogDialogStyles.logFile} onClick={e => downloadFile(e, id)}>{filename}</div>
                            }
                </div>
            ));

        return (
            <Dialog
                title={title}
                actions={bottomActions}
                modal={false}
                open={isChangeLogsOpen}
                bodyStyle={{ paddingBottom: '0px', maxWidth:'950px' }}
                autoScrollBodyContent>
                <div className="changeLogTable" style={changeLogDialogStyles.logsPanel}>
                    <div style={changeLogDialogStyles.logsListBox}>
                        {table}
                    </div>
                </div>
            </Dialog>
        );
    }
} 

ChangeLogsDialog.propTypes = propTypes;
ChangeLogsDialog.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onLoadingStart,
        onLoadingEnd
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ChangeLogsDialog);