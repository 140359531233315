
import React from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '../../../common/Breadcrumbs';
import { goBackToSupervisor } from '../../../../store/HolidayPanel';
import memoize from "memoize-one";


function SupervisorBreadcrumbs({ supervisors, goBackToSupervisor }) {
    return (
        <Breadcrumbs onClick={goBackToSupervisor} className="supervisor-breadcrumb">
            {supervisors.map(s => (s.firstname && s.lastname) ? `${s.firstname} ${s.lastname}` : s.name)}
        </Breadcrumbs>);
}

const getSupervisors = memoize((user, workers, supervisorIds, supervisiorsHolidays) =>
    supervisorIds.map(id => user.id == id ? user : workers[id] || supervisiorsHolidays.find(x => x.id == id)));

function mapStateToProps(state) {
    return {
        supervisors: getSupervisors(state.login.user, state.holidays.workers, state.holidayPanel.supervisorIds, state.holidayPanel.supervisiorsHolidays)
    };
}

const mapDispatchToProps = {
    goBackToSupervisor
};

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorBreadcrumbs);
