import authenticationService from '../services/authentication-service';

const SIGN_OUT_START = 'SIGN_OUT_START';

const initialState = {
  isLoading: false,
  data: null,
  error: null,
  promise: null
};

export function reducerSignOut(state =initialState, action){
  const { type } = action;
    switch(type){
      case SIGN_OUT_START: 
        return{
            ...state,
          promise: action.promise
        };
      default: 
        return state; 
  }
}

export default reducerSignOut;

export const signOut = () => ({
  type: SIGN_OUT_START,
  promise: authenticationService.signOut(),
});
