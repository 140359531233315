import React from 'react';
import SupervisorSettings from './SupervisorSettings';
import * as ReactRedux from 'react-redux';
import { Constants } from '../common/constants';
import HideableSection from '../common/partials/HideableSection';
import FlatButton from 'material-ui/FlatButton';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supervisorOpen: true,
            appSettingsOpen: true,
            submitForm: false,
            isFormValid: true
        };
    }

    componentDidUpdate() {
        refreshSubmitFormFlag(this);
    }

    submitForm = () => {
        this.setState({
            submitForm: true
        });
    }

    render() {
        return (
            <div>
                <SupervisorSettings submitForm={this.state.submitForm} onValidate={val => this.setState({isFormValid: val})}/>
                {(this.state.appSettingsOpen || this.state.supervisorOpen) && (
                    <div className="container">
                        <div className="row">
                            <FlatButton disabled={!this.state.isFormValid} className="col-sm-6" type="button" onClick={this.submitForm} key={1} label="Save" primary />
                        </div>
                    </div>)}
            </div>
        );
    }
}
function refreshSubmitFormFlag(settings) {
    if (settings.state.submitForm == true)
        settings.setState({
            submitForm: false
        });
}

function mapStateToProps(state) {
    return { login: state.login };
}

export default ReactRedux.connect(mapStateToProps)(Settings);