import { EN } from '../../components/common/translations';
import types from './types';
import moment from 'moment';

const { pendingApplication, settledEmployees, unsettledEmployees, history, reports } = EN.teamTimesheet.buttons;

const INITIAL_STATE = {
    buttons: updateButtonsState(settledEmployees),
    teamTimesheetDate: {
        year: moment().month() === 0 && moment().date() <= 10 ? new Date().getFullYear() - 1 : new Date().getFullYear() ,
        month: moment().month() === 0 && moment().date() <= 10 ? 11 : moment().date() <= 10 ? new Date().getMonth() - 1 : new Date().getMonth(),
    },
    pendingApplication: {
        dataTable:[],
        page: 1,
        count: 1,
    },
    tableDialog: {
        version: "",
        employeeId: 0,
        employeeName: "",
        selectedApplicationId: 0,
        open: false,
    },
    rejectDialog: {
        open: false,
    },
    settledEmployees: {
        dataTable:[],
        page: 1,
        count: 1,
    },
    unsettledEmployees: {
        dataTable:[],
        page: 1,
        count: 1,
    },
    unsettledEmployeesMailDialog: {
        open: false,
        selectedApplicationId: 0,
        selectedEmployee: "",
        sendToAll: false,
    },
    teamTimesheetCount: 0,
    unusedTimesheetProposals: [],
    timesheetProposals: [],
    isOnlyMyTeamEnabled: true,
};

const teamTimesheet = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.PENDING_APPLICATION_CLICK:
            return {
                ...state,
                buttons: updateButtonsState(pendingApplication),
            };
        case types.SETTLED_EMPLOYEES_CLICK:
            return {
                ...state,
                buttons: updateButtonsState(settledEmployees),
                };
        case types.UNSETTLED_EMPLOYEES_CLICK:
            return {
                ...state,
                buttons: updateButtonsState(unsettledEmployees),
                };
        case types.GET_PENDING_APPLICATION_TABLE:
            return {
                ...state,
                pendingApplication: {
                    dataTable: action.response.data,
                    page: action.page,
                    count: Math.ceil((action.response.count) / types.PAGE_SIZE),
                },
                teamTimesheetDate: {
                    year: action.year,
                    month: action.month,
                }
            };
        case types.GET_SETTLED_EMPLOYEES_TABLE:
            return {
                ...state,
                settledEmployees: {
                    dataTable: action.response.data,
                    page: action.page,
                    count: Math.ceil((action.response.count) / types.PAGE_SIZE),
                },
                teamTimesheetDate: {
                    year: action.year,
                    month: action.month,
                }
            };
        case types.GET_UNSETTLED_EMPLOYEES_TABLE:
            return {
                ...state,
                unsettledEmployees: {
                    dataTable: action.response.data,
                    page: action.page,
                    count: Math.ceil((action.response.count) / types.PAGE_SIZE),
                },
                teamTimesheetDate: {
                    year: action.year,
                    month: action.month,
                }
            };
        case types.TEAM_TIMESHEET_COUNT_CHANGE:
            return {
                ...state,
                teamTimesheetCount: action.response[0].countElement,
            };
        case types.CHANGE_MAIL_DIALOG:
            return {
                ...state,
                unsettledEmployeesMailDialog: {
                    open: action.open,
                    selectedApplicationId: action.selectedApplicationId,
                    sendToAll: action.sendToAll,
                    selectedEmployee: action.selectedEmployee,
                }
            };
        case types.ON_SEND_MAIL_TEAM_TIMESHEET:
            return {
                ...state,
                unsettledEmployeesMailDialog: {
                    open: false,
                    selectedApplicationId: 0,
                    sendToAll: false,
                    selectedEmployee: ""
                }
            };
        case types.CHANGE_TABLE_DIALOG_TEAM_TIMESHEET:
            return {
                ...state,
                tableDialog: {
                    ...state.tableDialog,
                    selectedApplicationId: action.selectedApplicationId,
                    version: action.version,
                    employeeId: action.employeeId,
                    employeeName: action.employeeName,
                    open: action.open,
                    data: action.data
                }
            };
        case types.OPEN_REJECT_DIALOG_TEAM_TIMESHEET:
            return {
                ...state,
                tableDialog: {
                    ...state.tableDialog,
                    open: false,
                },
                rejectDialog: {
                    open: true,
                }
            };
        case types.CLOSE_REJECT_DIALOG_TEAM_TIMESHEET:
            return {
                ...state,
                tableDialog: {
                    ...state.tableDialog,
                    open: true,
                },
                rejectDialog: {
                    open: false,
                }
            };
        case types.ACCEPT_TEAM_TIMESHEET:
            return {
                ...state,
                teamTimesheetCount: state.teamTimesheetCount - 1,
                tableDialog: {
                    ...state.tableDialog,
                    selectedApplicationId: 0,
                    version: "",
                    employeeId: 0,
                    employeeName: "",
                    open: false,
                    data: {}
                },
                rejectDialog: {
                    open: false,
                },
                pendingApplication: {
                    ...state.pendingApplication,
                    dataTable: state.pendingApplication.dataTable.filter(x => x.id != action.response),
                }
            };
            case types.REJECT_TEAM_TIMESHEET:
                return {
                    ...state,
                    teamTimesheetCount: state.teamTimesheetCount - 1,
                    tableDialog: {
                        ...state.tableDialog,
                        selectedApplicationId: 0,
                        version: "",
                        employeeId: 0,
                        employeeName: "",
                        open: false,
                        data: {}
                    },
                    rejectDialog: {
                        open: false,
                    },
                    settledEmployees:{
                        ...state.settledEmployees,
                        dataTable: state.settledEmployees.dataTable.filter(x => x.id != action.response.applicationId),
                    }
                    ,
                    pendingApplication: {
                        ...state.pendingApplication,
                        dataTable: state.pendingApplication.dataTable.filter(x => x.id != action.response.applicationId),
                    }
                };
            case types.GET_UNUSED_TIMESHEET_PROPOSAL:
                return {
                    ...state,
                    unusedTimesheetProposals: action.response
                };
            case types.GET_TIMESHEET_PROPOSALS:
                return {
                    ...state,
                    timesheetProposals: action.response,
                };
            case types.TOGGLE_ONLY_MY_TEAM_SWITCH:
                return {
                    ...state,
                    isOnlyMyTeamEnabled: !state.isOnlyMyTeamEnabled,
                };
        default:
            return state;
    }
};

function updateButtonsState (nameOfSelectedButton) {
    return {
        pendingApplication: {
            name: pendingApplication,
            isSelected: nameOfSelectedButton === pendingApplication
        },
        settledEmployees: {
            name: settledEmployees,
            isSelected: nameOfSelectedButton === settledEmployees
        },
        unsettledEmployees: {
            name: unsettledEmployees,
            isSelected: nameOfSelectedButton === unsettledEmployees
        },
        history: {
            name: history,
            isSelected: nameOfSelectedButton === history
        },
        reports: {
            name: reports,
            isSelected: nameOfSelectedButton === reports
        },
    };
}

export default teamTimesheet;