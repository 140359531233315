const settingsStyles = {
    defaultUnderlineStyle: {
        borderBottom: "1px solid rgba(255, 255, 255, 0.3)"
    },

     modifiedUnderlineStyle: {
        borderBottom: "2px solid green"
    }
};

export default settingsStyles;