import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

class HideableSection extends React.Component {
    render() {
        return (<div className={this.props.className}>
            <Button onClick={this.props.onToggle} fullWidth> 
                {!this.props.in ? <ExpandMore /> : <ExpandLess />} 
                {this.props.text} 
            </Button>
            <Collapse in={this.props.in} mountOnEnter={this.props.mountOnEnter}>
                <div>{this.props.children}</div>
            </Collapse>
            </div>);
    }
}

export class UncontrolledHideableSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            in: props.opened
        };
        this.handleToggle = () => {
            this.setState({in: !this.state.in});
        };
    }
    render() {
        const { opened, ...rest } = this.props;
        return <HideableSection onToggle={this.handleToggle} in={this.state.in} {...rest} />;
    }
}

export default HideableSection;