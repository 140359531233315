import React from 'react';
import PropTypes from 'prop-types';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import {preloaderStyle} from '../common/styles';

const propTypes = {
    data: PropTypes.object,
};

const defaultProps = {
};

const Preloader = ({data}) => {
    return (
        <div className="preloader">
            <div className="preloader-coverage"/>
            <div className="preloader-box">
                <div className="preloader-animation">
                    <RefreshIndicator
                        size={50}
                        left={0}
                        top={0}
                        status="loading"
                        loadingColor={preloaderStyle.nexio.color}
                        style={preloaderStyle.nexio}/>
                </div>
                <div className="preloader-text">
                    {data.text}
                </div>
            </div>
        </div>
    );
};

Preloader.propTypes = propTypes;
Preloader.defaultProps = defaultProps;

export default Preloader;