import {host} from '../components/common/cfg';
import axios from 'axios';

export const onEditOrAddTask = (task) => {
    return (dispatch) => {

        let headers = {
            'Content-Type': 'application/json'
        };

        let data = task;

        return axios
            .put(`${host}/api/tasks/edit`, data, {headers} )
            .then(response => {
                return response;
            });
    };
};

export const getTeamTasks = (onlyNotSolved, teamId) => {
    return (dispatch) => {

        let headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .get(`${host}/api/tasks/getTeamTasks`, { headers, params: { onlyNotSolved, teamId } } )
            .then(response => {
                return response;
            });
    };
};

export const getAllUsers = (teamId) => {
    return (dispatch) => {

        let headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .get(`${host}/api/tasks/usersForAutocomplete?teamId=${teamId}`, {headers} )
            .then(response => {
                return response;
            });
    };
};

export const getAllDevelopers = () => {
    return (dispatch) => {

        let headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .get(`${host}/api/User/developers`, {headers} )
            .then(response => {
                return response;
            });
    };
};

export const getDevelopersByTeam = (id) => {
    return (dispatch) => {

        let headers = {
            'Content-Type': 'application/json'
        };
        
        return axios
            .get(`${host}/api/User/developersByTeam?teamId=${id}`, {headers} )
            .then(response => {
                return response;
            });
    };
};

export const onDeleteTask = (id) => {
    return (dispatch) => {

        let headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .delete(`${host}/api/tasks/delete`, {params: {id:id}}, {headers} )
            .then(response => {
                return response;
            });
    };
};
