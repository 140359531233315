import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { EN } from '../../../common/translations';
import Select from '../../../common/controls/Select';
import filterTypes from './filterTypes';
import _ from 'lodash';
//styles
import './filters.scss';

const filters = (props) => {

    const FilterColumn = ({ children, className }) => {
        let divClassName = 'col';
        if (className)
            divClassName += ` ${className}`;
        return (
            <div className={divClassName}>
                {children}
            </div>
        );
    };

    const createFiltersList = () => {
        if (!props.filters || !props.filters.length)
            return;

        return props.filters.map((filter, index) => {
            const label = EN.workTimePage.irregular.filters[filter.name];
            const changedHandler = value => props.onFilterChanged(filter.name, value);

            if (filter.type === filterTypes.Checkbox) {
                return (
                    <FilterColumn key={`filter_${index}`}>
                        <Checkbox
                            label={label}
                            checked={filter.active}
                            onCheck={changedHandler}
                        />
                    </FilterColumn>
                );
            }

            if (filter.type === filterTypes.Select) {
                return (
                    <FilterColumn key={`filter_${index}`} className="filter-input">
                        <FormControl fullWidth>
                            <InputLabel id={filter.name}>{filter.label}</InputLabel>
                            <Select
                                fullWidth
                                labelid={filter.name}
                                value={filter.value}
                                onChange={event => changedHandler(event.target.value)}
                            >
                                {
                                    filter.items
                                        .map((option, optIdx) => (
                                            <MenuItem
                                                key={`filter_${index}_option_${optIdx}`}
                                                value={option.value}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))
                                }
                            </Select>
                        </FormControl>
                    </FilterColumn>
                );
            }

            if(filter.type === filterTypes.TextField) {
                const inputChanged = _.debounce(val => changedHandler(val), 400);

                return (
                    <FilterColumn key={`filter_${index}`} className="filter-input">
                        <FormControl fullWidth>
                            <TextField
                                id={filter.name}
                                label={filter.label}
                                defaultValue={filter.value}
                                onChange={event => inputChanged(event.target.value)}
                                autoComplete="off"
                                autoFocus={props.lastFocusedTextFieldName === filter.name}
                            />
                            {
                                filter.value ?
                                    <IconButton style={{position: 'absolute', right: 0}} onClick={() => changedHandler('')}>
                                        <ClearIcon />
                                    </IconButton> :
                                    null
                            }
                        </FormControl>
                    </FilterColumn>
                );
            }
        });
    };

    return (
        <div className="filters">
            {createFiltersList()}
        </div>
    );
};

filters.propTypes = {
    onFilterChanged: PropTypes.func.isRequired,
    filters: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        active: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        items: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        }))
    }).isRequired).isRequired
};

export default filters;