import React from 'react';
import PropTypes from 'prop-types';
//MDL
import EmployeesTable from './EmployeesTable';
import { EN } from '../../common/translations';

class EmployeesListPanel extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        let headers = EN.scrumManagementPage.usersTableColumnName;
        return(
                <EmployeesTable 
                onCellClick={(row, cell) => this.props.onCellClick(row, cell)}
                headers={headers} rows={this.props.employeesList}/>
            );
        }
}

export default EmployeesListPanel;