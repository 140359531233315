import React from 'react';
import * as ReactRedux from 'react-redux';
import * as Redux from 'redux';
import { fetchHrUserHolidays, hrSolveUserHoliday, showHistory, hrRejectUserHoliday, hrApproveUserHoliday } from '../../../store/HrUserHolidays';
import memoize from "memoize-one";
import Pagination from '../../common/partials/pagination/pagination';
import { EN } from '../../common/translations';
import DataTable from '../../common/DataTable';
import { HolidayPanelStyles, HolidayStyles } from '../../holidays/HolidayStyles';
import HrUserHolidaysFilters from './HrUserHolidaysFilters';
import Button from '@material-ui/core/Button';
import { countDayOff } from '../../common/helpers/date';
import { colors } from "../../common/styles";
import { Constants } from '../../common/constants';
import AttachmentIcon from '../../common/holidays/AttachmentIcon';
import HolidayPreviewDialog from '../../common/holidays/HolidayPreviewDialog';
import { FormButton } from '../../common/controls/formik/FormControls';
import HolidayEditDialog from '../../holidays/Dialogs/HolidayEditDialog';
import {fetchAllWorkers} from '../../../store/Holidays';
import { ConfirmContext } from '../../confirm/ConfirmDialog';
import RejectHolidayTableButton from '../../common/holidays/RejectHolidayTableButton';
import { fetchAllSupervisors } from '../../../store/Settings';

class HrUserHolidaysTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            holidayObject: null,
            rowId: -1,
            status: null,
            hrSolvedDate: null
        };

        this.DEFAULT_DATE_STRING = "0001-01-01T00:00:00";

        this.updatePaging = this.updatePaging.bind(this);
        this.columns = [
            {
                header: EN.hrUserHolidays.employee,
                getValue: row => <AttachmentIcon holidayRequest={row.userHolidays} text={x => `${x.userFirstName} ${x.userLastName}`} />
            },
            {
                header: EN.hrUserHolidays.contractType,
                getValue: (row) => row.userContractType
            },
            {
                header: EN.hrUserHolidays.holidayType,
                valueProperty: 'type'
            },
            {
                header: EN.hrUserHolidays.dateFrom,
                getValue: (row) => row.dateFrom.substring(0, 10)
            },
            {
                header: EN.hrUserHolidays.dateTo,
                getValue: (row) => row.dateTo.substring(0, 10)
            },
            {
                header: EN.hrUserHolidays.numberOfDays,
                valueProperty: 'daysCount'
            },
            {
                header: EN.hrUserHolidays.request,
                valueProperty: 'request'
            },
            {
                header: EN.hrUserHolidays.acceptedBy,
                getValue: (row) => this.getAcceptedBy(row.userAcceptedFirstName, row.userAcceptedLastName)
            },
            {
                header: EN.hrUserHolidays.status,
                valueProperty: 'status'
            },
            this.props.showHistoryP ?
                {
                    header: EN.hrUserHolidays.solvedOn,
                    getValue: (row) => row.hrSolvedDate.substring(0, 10)

                } : 
                {
                    header: EN.hrUserHolidays.action,
                    getValue: (row) => (
                        <Button variant="contained" color="primary" disabled={row.status == Constants.HolidayStatuses.NEW ? true : false}
                            onClick={(e) => (e.stopPropagation(), this.props.hrSolveUserHoliday(row.id))}>
                            Solve
                        </Button>
                    )
                },
            {
                header: EN.hrUserHolidays.action,
                getValue: row => (
                    <RejectHolidayTableButton 
                        disabled={row.status == Constants.HolidayStatuses.NEW || row.status == Constants.HolidayStatuses.ACCEPTED ? false : true}
                        onClick={(e) => (e.stopPropagation(), this.handleReject(row.userHolidaysId))}>
                        {EN.hrUserHolidays.reject}
                    </RejectHolidayTableButton>
                )
            },
            !this.props.showHistoryP ? 
                {
                    header: EN.hrUserHolidays.action,
                    getValue: (row) => (
                        <Button variant="contained" color="primary" disabled={row.status != Constants.HolidayStatuses.NEW}
                            onClick={(e) => (e.stopPropagation(), this.handleApprove(row.userHolidaysId))}>
                            {EN.hrUserHolidays.approve}
                        </Button>
                    )
                } : {
                    header: '',
                    getValue: ''
                }
        ];
    }

    
    getAcceptedBy(firstName,lastName){
        let acceptedBy = firstName + " " + lastName;
        if(acceptedBy.trim() === ""){
            return EN.hrUserHolidays.system;
        }
        
        return acceptedBy;
    }

    componentDidMount() {
        if (this.props.match.path == "/hrHolidays") {
            this.props.showHistory(false);
        }
        else {
            this.props.showHistory(true);
        }
        this.props.fetchHrUserHolidays();
        this.props.fetchAllWorkers();
        this.props.fetchAllSupervisors();
    }

    handleReject(userHolidaysId){
        const confirm = this.context({content: EN.hrUserHolidays.rejectConfirmText, yesText: "Yes", noText: "No"});
        confirm(() => {
            this.props.hrRejectUserHoliday(userHolidaysId);
            this.setPreviewModalStatus(null);
        });
    }

    handleApprove(userHolidaysId, replacementPersonId = 0){
        const confirm = this.context({content: EN.hrUserHolidays.approveConfirmText, yesText: "Yes", noText: "No"});
        confirm(() => {
            this.props.hrApproveUserHoliday(userHolidaysId, replacementPersonId);
            this.setPreviewModalStatus(null);
        });
    }

    /**
     * @param {object} holidayObject object of holiday request returned form clicked row
     */
    setPreviewModalStatus = (holidayObject, rowId, status, hrSolvedDate) => {
        this.setState(prev => ({
            ...prev,
            holidayObject,
            rowId,
            status,
            hrSolvedDate: hrSolvedDate || this.DEFAULT_DATE_STRING
        }));
    }

    updatePaging(page) {
        this.props.fetchHrUserHolidays(page);
    }

    render() {
        const hideDialogHandler = () => this.setPreviewModalStatus(null);
        const solveButton = ((state) => {
            if(state.hrSolvedDate === this.DEFAULT_DATE_STRING) {
                const solve = () => {
                    this.props.hrSolveUserHoliday(state.rowId);
                    hideDialogHandler();
                };
                return (
                    <FormButton key={3} 
                        onClick={solve} 
                        color="primary" 
                        disabled={state.status === Constants.HolidayStatuses.NEW}
                        >
                            Solve
                        </FormButton>
                );
            }
            return null;
        })(this.state);

        const rejectButton = ((state) => {
            const reject = () => {

                this.handleReject(state.holidayObject.id);
            };

            if(state.status == Constants.HolidayStatuses.NEW || state.status == Constants.HolidayStatuses.ACCEPTED){
                return <FormButton key={4} onClick={reject} color={"primary"} style={HolidayPanelStyles.buttonDeleteStyle}>{EN.hrUserHolidays.reject}</FormButton>;
            }
            return null;

        })(this.state);

        const approveButton = ((state) => {
            const approve = () => {
                this.handleApprove(state.holidayObject.id, state.holidayObject.replacementPersonId);
            };

            if(state.status == Constants.HolidayStatuses.NEW){
                return <FormButton key={5} onClick={approve} color={"primary"}>{EN.hrUserHolidays.approve}</FormButton>;
            }
            return null;

        })(this.state);

        const previewDialogActionElements = [
            <FormButton key={2} onClick={hideDialogHandler}>{EN.common.cancel}</FormButton>,
            solveButton,
            rejectButton,
            approveButton
        ];

        return (
            <>
                <HolidayEditDialog/>
                <HolidayPreviewDialog 
                    disableComment
                    holiday={this.state.holidayObject || {}}
                    actions={previewDialogActionElements}
                    hideDialog={hideDialogHandler}
                    allSupervisor={this.props.allSupervisor}
                />
                <div>
                    {this.props.showHistoryP ?
                        <div style={{ textAlign: "center" }}>
                            <h4 style={{ color: colors.nexioOrange }}>History</h4>
                        </div> : null}
                    <div>
                        <HrUserHolidaysFilters />
                    </div>
                    <DataTable
                        columns={this.columns}
                        cellStyle={HolidayStyles.headerColStyle}
                        headerStyle={HolidayStyles.headerColStyle}
                        tableStyle={HolidayPanelStyles.table}
                        data={this.props.tableData}
                        onCellClick={row => this.setPreviewModalStatus(row.userHolidays, row.id, row.status, row.hrSolvedDate)}
                    />

                    <Pagination
                        onChange={this.updatePaging}
                        value={this.props.tablePage}
                        count={this.props.tableCount}
                    />
                </div>
            </>
        );
    }
}

HrUserHolidaysTable.contextType = ConfirmContext;



const getTableHrUserHolidays = memoize((hrUserHolidays, publicHolidays) => {
    return hrUserHolidays
        .filter(x => x !== undefined || x !== null)
        .map(x => ({ ...x, daysCount: countDayOff(x.dateFrom, x.dateTo, publicHolidays) }));
});

function mapStateToProps(state) {
    return {
        tablePage: state.hrUserHolidays.page,
        tableCount: state.hrUserHolidays.pageCount,
        workers: state.hrUserHolidays.workers,
        tableData: getTableHrUserHolidays(state.hrUserHolidays.tableData, state.publicHolidays.wtPublicHolidays),
        showHistoryP: state.hrUserHolidays.tableOptions.showHistory,
        allSupervisor: state.settings.allSupervisor
    };
}

const mapDispatchToProps = {
    fetchHrUserHolidays,
    hrSolveUserHoliday,
    showHistory,
    fetchAllWorkers,
    hrApproveUserHoliday,
    hrRejectUserHoliday,
    fetchAllSupervisors
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HrUserHolidaysTable);
