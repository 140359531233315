/*React Libs*/
import React from 'react';

import HolidayEditDialog from './Dialogs/HolidayEditDialog';
import HolidaysHistoryDialog from './Dialogs/HolidaysHistoryDialog';
import HolidaysTable from './HolidaysTable';
import HolidayMyTeam from './HolidayMyTeam';
import HideableSection from '../common/partials/HideableSection';
import {HolidayStyles as styles} from './HolidayStyles';
import { EN } from '../common/translations';

class Holidays extends React.Component {
    state = {
        calendarOpen: true,
        listOpen: true
    }

    onListToggle = () => {
        this.setState({listOpen: !this.state.listOpen});
    }

    onCalendarToggle = () => {
        this.setState({calendarOpen: !this.state.calendarOpen});
    }

    render() {
        return(
            <div>
                <div style={styles.title}>{EN.holidaysPage.myTeamPageTitle}</div>
                <HolidayEditDialog />
                <HolidaysHistoryDialog/>
                <div className="calendarlegend-wrapper">
                    <HideableSection text="Leave calendar" onToggle={this.onCalendarToggle} in={this.state.calendarOpen}>
                        <HolidayMyTeam />
                    </HideableSection>
                </div>
                <HideableSection text="Leave requests list" onToggle={this.onListToggle} in={this.state.listOpen}>
                    <HolidaysTable />
                </HideableSection>
            </div>
        );
    }
}

export default Holidays;
