import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import FormDialog from '../common/controls/formik/FormDialog';
import { FormTextField, FormButton } from '../common/controls/formik/FormControls';
import { setMailDialogOpen, sendTeamMail } from '../../store/Teams';

const getInitialValues = memoize((id) => ({
    subject: "",
    body: "",
    teamId: id
}));

const SendMailDialog = ({ open, id, onClose, sendTeamMail }) => {
    const actions = useMemo(() => [
        <FormButton key={1} onClick={onClose}>Cancel</FormButton>,
        <FormButton key={0} type="submit" color="primary" variant="contained">Send</FormButton>
    ], [onClose]);

    return (
        <FormDialog 
            open={open} 
            initialValues={getInitialValues(id)} 
            actions={actions} 
            onClose={onClose} 
            onSubmit={sendTeamMail}>
            <FormTextField label={"Subject"} name="subject" autoFocus required/>
            <FormTextField label={"Content"} name="body" multiline required rows={1} rowsMax={100}/>

        </FormDialog>
    );
};

const mapStateToProps = (state) => ({
    open: state.teams.mailDialogOpen,
    id: state.teams.selectedTeam
});

const mapDispatchToProps = ({
    onClose: () => setMailDialogOpen(false),
    sendTeamMail
});

export default connect(mapStateToProps, mapDispatchToProps)(SendMailDialog);