import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

import { EN } from "../common/translations";
import { colors, inputFieldsStyles } from "../common/styles";
import {httpReqErrorHandler} from '../common/helpers/errorHandler';

import { onChangePassword, onResetPassword } from "../../store/Login";
import {onLoadingStart, onLoadingEnd} from '../../store/Preloader';
import {onAddFlashMessage, onClearFlashMessages} from '../../store/FlashMessage';

import { formStyle } from "./styles";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      oldError: null,
      newError: null,
      confError: null
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(ev, field) {
    this.setState({ [field]: ev.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({oldError: null, newError: null, confError: null});
    let isValid = true;
    if(this.state.oldPassword === "" && !this.props.isReset){
        this.setState({oldError: "Required"});
        isValid = false;
    }
    if(this.state.newPassword !== this.state.confirmPassword){
        this.setState({newError: "Passwords do not match", confError: "Passwords do not match"});
        isValid = false;
    }
    if(this.state.newPassword.length < 8){
        this.setState({newError: "Password minimum length: 8"});
        isValid = false;
    }
    if(!isValid) return;

    this.props.onLoadingStart(EN.changePassword.loader);
    
    const promise = this.props.isReset ?
      this.props.onResetPassword({
        hash: this.props.hash,
        newPassword: this.state.newPassword,
      }) :
      this.props.onChangePassword({
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword
      });
    promise.then(() => {
        this.props.onLoadingEnd();
        this.props.onAddFlashMessage({type: 'success', text: EN.changePassword.success});
        if (this.props.isReset) {
          this.props.history.push('login');
        }
    }, 
    reject => {
        this.props.onLoadingEnd();
        this.props.onAddFlashMessage(httpReqErrorHandler(reject));       
    });

    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit} style={formStyle}>
        {!this.props.isReset ? 
        <TextField
          className="login-input"
          type="password"
          name="oldPass"
          hintText={EN.changePassword.oldPassword}
          floatingLabelText={EN.changePassword.oldPassword}
          errorText={this.state.oldError}
          value={this.state.oldPassword}
          onChange={event => this.onChange(event, "oldPassword")}
          floatingLabelStyle={inputFieldsStyles.floatingLabelFocusStyle}
          underlineFocusStyle={inputFieldsStyles.underlineStyle}
        /> : null}
        <TextField
          type="password"
          name="newPass"
          hintText={EN.changePassword.newPassword}
          floatingLabelText={EN.changePassword.newPassword}
          errorText={this.state.newError}
          value={this.state.newPassword}
          onChange={event => this.onChange(event, "newPassword")}
          floatingLabelStyle={inputFieldsStyles.floatingLabelFocusStyle}
          underlineFocusStyle={inputFieldsStyles.underlineStyle}
        />
        <TextField
          type="password"
          name="confirmPass"
          hintText={EN.changePassword.confirmPassword}
          floatingLabelText={EN.changePassword.confirmPassword}
          errorText={this.state.confError}
          value={this.state.confirmPassword}
          onChange={event => this.onChange(event, "confirmPassword")}
          floatingLabelStyle={inputFieldsStyles.floatingLabelFocusStyle}
          underlineFocusStyle={inputFieldsStyles.underlineStyle}
        />
        <RaisedButton
          className="tms-btn"
          label={EN.changePassword.submitBtn}
          type="submit"
          backgroundColor={colors.nexioOrange}
          style={{marginTop: "20px"}}
        />
      </form>
    );
  }
}

const mapDispatchToProps = {
  onChangePassword,
  onAddFlashMessage,
  onClearFlashMessages,
  onLoadingEnd,
  onLoadingStart,
  onResetPassword
};

export default withRouter(connect(null, mapDispatchToProps)(ChangePassword));
