import React, { useState, useMemo, useRef, useCallback, memo } from 'react';
import moment from 'moment';
import { TimePicker } from 'material-ui-pickers';
import TextField from '@material-ui/core/TextField';
import NexioTextField from './TextField';

const mask = 'hh:mm'.split("").map(x => /[hHmM]/.test(x) ? /\d/ : x);

const NexioTimePicker = (props) => {
    let { required, onChange, onBlur, value, inputRef, readOnly, ...rest } = props;
    const inputRefInner = useRef(null);
    inputRef = inputRef || inputRefInner;
    const tfProps = useRef({ value, onChange, inputRef, onBlur });
    tfProps.current = { value, onChange, inputRef, onBlur };

    //Initialized in this way, because recreating component loses focus.
    const TextFieldComponent = useMemo(() => function CalendarTextField(props) {
        return (
            <TextField
                fullWidth
                {...{ ...props, ...tfProps.current }}
            />);
    }, []);

    const handleDatePickerSelect = useCallback((date) => {
        if (!inputRef.current)
            return;
        inputRef.current.value = date ? moment(date).format('HH:mm') : '';
        onChange({ target: inputRef.current });
    }, [inputRef, onChange]);


    const datePickerDate = useMemo(() => {
        const date = moment(value, 'HH:mm');
        if (value && !value.includes('_') && date.isValid()) {
            return date.toDate();
        } else {
            return moment().toDate().setMinutes(0);
        }
    }, [value]);

    if (readOnly) return <NexioTextField {...props} />;
    return (
        <TimePicker
            keyboard
            disableOpenOnEnter
            format="HH:mm"
            clearable={!required}
            required={required}
            value={datePickerDate}
            onChange={handleDatePickerSelect}
            autoOk
            ampm={false}
            TextFieldComponent={TextFieldComponent}
            placeholder="HH:mm"
            mask={value => value ? mask : []}
            {...rest}
        />
    );

};

export default memo(NexioTimePicker);