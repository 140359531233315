import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { Link } from 'react-router-dom';
import { getAccessToken, getRefreshToken, onLogOut } from '../../store/Login';
import { onClearFlashMessages } from '../../store/FlashMessage';
import { onSideMenuBtnClick } from '../../store/SideMenu';
import { onHelpBtnClick, onCloseBtnClick, userProfileActiveClick } from '../../store/NavigationBar';
import { onCloseNotificationClick, onNotificationCountChange, openNotificationsHandler, onHideNotification } from '../../store/Notifications';
import { getFetchActiveAppliesCounts } from '../../store/Holidays';
import { getTimesheetToAcceptCount } from '../../store/teamTimeSheet/operations';

import { closeChangeLog, onChangeLogsGet } from '../../store/ChangeLog';
import { fetchAvatar } from '../../store/Employees';
import { fetchIntranetNotifications, clearIntranetNotifications } from '../../store/IntranetNotifications';
import { onNotificationsRequest } from '../../store/Notifications';

//MDL
import AppBar from 'material-ui/AppBar';
import IconButton from 'material-ui/IconButton';
import Badge from 'material-ui/Badge';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/HelpOutline';
import ChangeLogIcon from '@material-ui/icons/Book';
import NotificationsIcon from '@material-ui/icons/Notifications';
import UserProfileIcon from '@material-ui/icons/AccountCircle';
import WebIcon from '@material-ui/icons/Web';
//Common
import { navigationBarStyle, colors as customColors } from '../common/styles';
import { EN } from '../common/translations';
import * as cfg from '../common/cfg';
//GFX
import logoNexio from '../../assets/logo_nexio.png';
import logoMicrosec from '../../assets/Microsec-Logo.png';
//Custom
import SupportDialog from './supportDialog/SupportDialog';
import NotificationsDialog from './notificationsPanel/NotificationsDialog';
import NotificationWindow from './notificationsPanel/NotificationWindow';
import TeamSelect from './TeamSelect';
import { ChangeLogsDialog } from './changeLogsPanel/ChangeLogsDialog';
import _ from 'lodash';
import UserProfile from '../userProfile/userProfile';

const propTypes = {
    sideMenu: PropTypes.object,
    login: PropTypes.object,
    onLogOut: PropTypes.func,
    onClearFlashMessages: PropTypes.func,
    onSideMenuBtnClick: PropTypes.func,
    isSupportOpen: PropTypes.bool,
    onHelpBtnClick: PropTypes.func,
    onCloseBtnClick: PropTypes.func
};

const contextTypes = {
    router: PropTypes.object.isRequired
};

const WithBadgeNumber = (props) => (props.number > 0 ?
    <Badge badgeContent={props.number} badgeStyle={{ backgroundColor: customColors.nexioOrange }} style={{ padding: "5px", cursor: 'pointer' }}>
        {props.children}
    </Badge> :
    props.children
);


class NavigationBar extends React.Component {

    state = {
        isNotificationCountFetched: false,
        currentAvatar: null,
    }

    componentDidMount() {
        this.configure();
        this.props.fetchAvatar(this.props.userId || 0, false);
        this.props.fetchIntranetNotifications();
        this.props.onNotificationsRequest();
    }

    componentDidUpdate() {
        this.configure();
    }

    configure = () => {
        if (!this.state.isNotificationCountFetched && this.props.login.isAuthenticated) {
            this.setState({
                isNotificationCountFetched: true,
            });
            if (this.props.userId > 0) {
                this.props.getFetchActiveAppliesCounts(this.props.userId);
                this.props.getTimesheetToAcceptCount();
            }
        }
    }

    onLogoutFn = (evt) => {
        evt.preventDefault();
        this
            .props
            .onLogOut();
        this
            .props
            .onClearFlashMessages();

        if (this.props.sideMenu.isOpen)
            this.props.onSideMenuBtnClick();

        this.context
            .router
            .history
            .push('/login');
    }

    onLeftIconButtonClickFn(evt) {
        this
            .props
            .onSideMenuBtnClick();
    }

    getNotificationCount = () => {
        const notif = this.props.notificationCount;
        let count = 0;
        for (let prop in notif) {
            count += notif[prop];
        }
        return count;
    }

    handleClickUserProfile = () => {
        this.props.userProfileActiveClick();
    }

    hideNotifications = () => {
        this.props.clearIntranetNotifications();
    }

    render() {
        const { login,
            unreadNotifications,
            notifications,
            openNotificationsHandler,
            onHelpBtnClick,
            isSupportOpen,
            onCloseBtnClick,
            areNotificationsOpened,
            onCloseNotificationClick,
            onHideNotification,
            closeChangeLog,
            isChangeLogsOpen,
            changeLogs,
            onChangeLogsGet } = this.props;
        let user = login.user;

        const logo = (
            <Fragment>
                <Link to="/timesheet" style={{ textDecoration: 'none '}}>
                    <img
                        src={cfg.company === "Nexio" ? logoNexio : logoMicrosec}
                        style={{ height: "40px" }} />
                    {(cfg.isDemo) ? <span style={navigationBarStyle.demoSpan}>Demo</span> : ""}
                </Link>
                <TeamSelect />
            </Fragment>
        );
        const iconElementLeft = (
            <div>
                <WithBadgeNumber number={this.props.notificationCount.requestsToAccept + this.props.teamTimesheetCount}>
                    <IconButton tooltip={EN.navigationBar.menuBtn}>
                        <MenuIcon />
                    </IconButton>
                </WithBadgeNumber>
            </div>
        );

        const userAvatar = this.props.currentAvatar ? <img src={this.props.currentAvatar} className="nav-userAvatar" /> : <UserProfileIcon />;
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();
        const iconElementRight = (
            <div>
                <div className="nav-userProfile" onClick={this.handleClickUserProfile}>
                    <>
                        {userAvatar}
                        <div className="nav-username">{user?.firstname + " " + user?.lastname}</div>
                    </>
                </div>
                <div style={{ display: "inline-block" }}>
                    <WithBadgeNumber number={unreadNotifications.length}>
                        <IconButton tooltip="Notifications" onClick={openNotificationsHandler}>
                            <NotificationsIcon />
                        </IconButton>
                    </WithBadgeNumber>
                </div>
                {this.props.intranetNotifications.intranetTablesExists &&
                    <a target="__blank" href={cfg.intranetClient + '/setToken/' + accessToken + '/' + refreshToken} className="linkIntranet" onClick={this.hideNotifications}>
                        <WithBadgeNumber number={this.props.intranetNotifications.intranetNotificationsCount}>
                            <IconButton tooltip="Intranet">
                                <WebIcon />
                            </IconButton>
                        </WithBadgeNumber>
                    </a>}
                <IconButton tooltip={EN.navigationBar.logOutBtn} onClick={this.onLogoutFn}>
                    <PowerIcon style={navigationBarStyle.textColor} />
                </IconButton>
                <IconButton
                    tooltip={EN.navigationBar.helpBtn}
                    style={navigationBarStyle.helpIcon}
                    tooltipPosition="bottom-left"
                    onClick={onHelpBtnClick}>
                    <HelpIcon />
                </IconButton>
                <IconButton
                    tooltip={EN.navigationBar.changeLogBtn}
                    style={navigationBarStyle.textColor}
                    tooltipPosition="bottom-left"
                    onClick={onChangeLogsGet}>
                    <ChangeLogIcon />
                </IconButton>
            </div>
        );

        return (
            <div>
                <AppBar
                    id="navigation-bar"
                    title={logo}
                    style={navigationBarStyle.titleBar}
                    titleStyle={navigationBarStyle.textColor}
                    onLeftIconButtonClick={(evt) => this.onLeftIconButtonClickFn(evt)}
                    iconElementLeft={iconElementLeft}
                    iconElementRight={iconElementRight} />
                <SupportDialog isSupportOpen={isSupportOpen} onCloseClick={onCloseBtnClick} />
                <NotificationsDialog isDialogOpen={areNotificationsOpened} onCloseClick={onCloseNotificationClick} notifications={notifications} />
                <NotificationWindow onCloseClick={onHideNotification} notifications={notifications} />
                <ChangeLogsDialog isChangeLogsOpen={isChangeLogsOpen} onCloseClick={closeChangeLog} changeLogs={changeLogs} />
                <UserProfile />
            </div>
        );
    }
}

NavigationBar.propTypes = propTypes;
NavigationBar.contextTypes = contextTypes;


const mapStateToProps = (state) => (
    {
        login: state.login,
        sideMenu: state.sideMenu,
        isSupportOpen: state.navigationBar.isSupportOpen,
        areNotificationsOpened: state.notificationStore.areNotificationsOpened,
        notifications: state.notificationStore.notifications,
        unreadNotifications: state.notificationStore.unreadNotifications,
        showNotification: state.notificationStore.showNotification,
        notificationCount: state.holidays.notificationCount,
        isChangeLogsOpen: state.changeLogStore.isChangeLogsOpen,
        changeLogs: state.changeLogStore.changeLogs,
        userId: state.login.user?.id,
        allCounts: state.holidays.allCounts,
        currentAvatar: state.employees.currentAvatar,
        intranetNotifications: state.intranetNotificationStore,
        teamTimesheetCount: state.teamTimesheet.teamTimesheetCount
    });

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onLogOut,
        onClearFlashMessages,
        onSideMenuBtnClick,
        onHelpBtnClick,
        onCloseBtnClick,
        userProfileActiveClick,
        onCloseNotificationClick,
        openNotificationsHandler,
        onHideNotification,
        onNotificationCountChange,
        closeChangeLog,
        onChangeLogsGet,
        fetchAvatar,
        getFetchActiveAppliesCounts,
        getTimesheetToAcceptCount,
        fetchIntranetNotifications,
        clearIntranetNotifications,
        onNotificationsRequest
    }, dispatch);
}
export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(NavigationBar);