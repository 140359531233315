import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import UserTask from './UserTask';
import {fetchUserTasks} from '../../store/UserTasks';


class UserTasksList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null
        };
        this.renderData = this.renderData.bind(this);
    }
    componentDidMount() {
        this.props.fetchUserTasks(this.props.userId, 1);
    }

    renderData(row) {
        return (<UserTask 
            key={row.id} 
            data={row} 
            onClick={()=> this.setState({selectedId: this.state.selectedId === row.id ? null : row.id})}
            in={this.state.selectedId === row.id}/>);
    }

    render() {
        return (
            <Table>
                <TableBody>
                    {this.props.data.tasks.map(this.renderData)}
                </TableBody>
            </Table>);
    }

}

const emptyState = {tasks: [], page: 1, count: 0};

const mapStateToProps = (state, ownProps) => ({
    data: state.userTasks[ownProps.userId] || emptyState,
    ...ownProps
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserTasks,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(UserTasksList);