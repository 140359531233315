import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import memoize from "memoize-one";
import { withStyles } from '@material-ui/core/styles';

const directions = {
  forward: "f",
  backward: "b"
};

const PaginationButton = withStyles({
  contained: {
    backgroundColor: '#303030',
    color: '#fff',
    '&:disabled': {
      backgroundColor: '#282828'
    }
  },
})((props) => <Button variant="contained" {...props} />);

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false
    };
    this.options = memoize(count => Array(count)
      .fill()
      .map((_, idx) => 1 + idx));
    this.onClickHandler = this.onClickHandler.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onClickOutside);
  }

  onClickHandler() {
    this.setState(prevState => {
      return { showOptions: !prevState.showOptions };
    });
  }

  onSelectHandler(value) {
    this.props.onChange(value);
    this.setState({ showOptions: false });
  }

  onPageHandler(direction) {
    const options = this.options(this.props.count);
    if (
      direction === directions.forward &&
      options.find(o => o === this.props.value + 1)
    )
      this.props.onChange(this.props.value + 1);

    if (
      direction === directions.backward &&
      options.find(o => o === this.props.value - 1)
    )
      this.props.onChange(this.props.value - 1);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onClickOutside(ev) {
    if (this.wrapperRef && !this.wrapperRef.contains(ev.target))
      this.setState({ showOptions: false });
  }

  render() {
    const options = this.options(this.props.count);
    return (
      <div
        className="custom-select"
        style={{ float: "right", display: "flex" }}
      >
        <PaginationButton
          style={{ minWidth: "40px", boxShadow: "none" }}
          onClick={this.onPageHandler.bind(this, directions.backward)}
          disabled={this.props.value === 1}
        >
          <span>&lt;&lt;</span>
        </PaginationButton>
        <div className="select" ref={this.setWrapperRef}>
          <span onClick={this.onClickHandler}>{this.props.value}/{options.length || 1}</span>
          <div className={this.state.showOptions ? "options" : "options hidden"}>
            <div>
              {options.map(o => (
                <span key={o} onClick={this.onSelectHandler.bind(this, o)}>
                  {o}
                </span>
              ))}
            </div>
          </div>
        </div>
        <PaginationButton
          style={{ minWidth: "40px", boxShadow: "none" }}
          onClick={this.onPageHandler.bind(this, directions.forward)}
          disabled={this.props.value === this.props.count}
        >
          <span>&gt;&gt;</span>
        </PaginationButton>
      </div>
    );
  }
}

export default Pagination;
