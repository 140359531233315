import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Components
import HeaderWeek from './HeaderWeek';
//3rd lib
import moment from 'moment';
import _ from 'lodash';

//Heleprs START
const getIssues = (issues, issueId) => {
    return issues.find( issue => issue.id === issueId);
};
//Heleprs END

const propTypes = {
    selectedMonth: PropTypes.number,
    selectedYear: PropTypes.number,
    issuesNum: PropTypes.number,
    hrIssues: PropTypes.array
};

const defaultProps = {
    selectedMonth: moment().month(),
    selectedYear: moment().year(),
};

const CalendarHeader = (props) => {

    const {selectedMonth, selectedYear, issuesNum, hrIssues, remoteDays} = props;

    let weeks = [];
    let done = false;
    let date = moment({month: selectedMonth, year: selectedYear}).startOf("month").add("w" - 1).day("Sunday");
    let count = 0;
    let monthIndex = date.month();
    
    let holidaysRows = Object(hrIssues[0]).hasOwnProperty('timesheetRows') ? hrIssues[0].timesheetRows.filter( row => row.hours > 0 ) : [];
    let sickLeaveRows = Object(hrIssues[1]).hasOwnProperty('timesheetRows') ? hrIssues[1].timesheetRows.filter( row => row.hours > 0 ) : [];

    while (!done) {
        weeks.push(
            <HeaderWeek 
                key={_.uniqueId('week_')}
                date={date.clone()}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                issuesNum={issuesNum}
                holidaysRows={holidaysRows}
                sickLeaveRows={sickLeaveRows}
                remoteDays={remoteDays}
                />
        );

        date.add(1, "w");

        done = count++ > 2 && monthIndex !== date.month();
        monthIndex = date.month();
    }


    return (
        <div className="calendar-header">{weeks}</div>);
};

CalendarHeader.propTypes = propTypes;
CalendarHeader.defaultProps = defaultProps;

export default CalendarHeader;