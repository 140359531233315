/*React Libs*/
import React from 'react';
import PropTypes from 'prop-types';
//Redux
import * as ReactRedux from 'react-redux';
//MDL
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import AutoComplete from 'material-ui/AutoComplete';
import Checkbox from 'material-ui/Checkbox';
//styles
import {colors} from '../../common/styles';
import taskDialogStyles from './taskDialogStyles';
import ProgressGraph from './ProgressGraph';
//translations
import {EN} from '../../common/translations';
//others
import _ from 'lodash';

const propTypes = {
    data:PropTypes.object,
    onDeleteClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    textFieldChange: PropTypes.func.isRequired,
    getErrorText: PropTypes.func.isRequired,
    developerList:PropTypes.array,
    analystList:PropTypes.array,
    availalbeTeams: PropTypes.array
};

const defaultProps = {
};

class TaskDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            task: props.data,
            originalTask: props.data
        };
    }

    static getDerivedStateFromProps(props, state){
        if (props.data != state.originalTask) {
            return {
                task: props.data,
                originalTask: props.data
            };
        }
        return null;
    }

    onTextFieldChange(field, value) {
        let taskCopy = _.clone(this.state.task);
        taskCopy[field] = value;
        this.setState({task: taskCopy});
    }

    onDeveloperChange(obj) {
        let taskCopy = _.clone(this.state.task);
        taskCopy['developRealizator'] = obj.name;
        taskCopy['developRealizatorId'] = obj.id;
        this.setState({task: taskCopy});
    }

    onTeamChange(obj) {
        let taskCopy = _.clone(this.state.task);
        taskCopy['teamId'] = obj.id;
        this.setState({task: taskCopy});
    }

    render() {
        const actions = [
            <FlatButton key="Delete" label = "Delete" primary onClick={this.props.onDeleteClick} style={this.state.task.id > 0 ? taskDialogStyles.buttonDeleteStyle : {display:'none'}} />,
            <FlatButton key="Cancel" label = "Cancel" primary onClick={(e) => {this.props.onCancelClick(e); this.setState({task: this.state.originalTask});}} style={taskDialogStyles.buttonStyle} />,
            <FlatButton key="Submit" label = "Submit" primary onClick={() => this.props.onSubmitClick(this.state.task)} style={taskDialogStyles.buttonStyle} />
        ];
        let availableTeams = this.state.task.teams;
        return (
            <Dialog
                modal={false}
                actions={actions}
                open={this.props.isOpened}
                autoScrollBodyContent
                onRequestClose={this.handleClose}>
                <form>
                    <div className="form-group">
                        <div className="form-group-item">
                            <div style={taskDialogStyles.textAreaStyle.pricingInsertRow}>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldJiraNum}
                                        errorText={this.state.task.id < 1 && this.state.task.jiraTaskId ? this.props.getErrorText('jiraTaskId', this.state.task.jiraTaskId) : ''}
                                        errorStyle={!this.state.task.jiraTaskId? {fontSize:'12px',color:'rgb(244, 67, 54)'} : {fontSize:'12px',color:colors.nexioOrange}}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.jiraTaskId}
                                        floatingLabelText = {EN.pricingPage.jiraCol}
                                        onChange={(event, value) => this.onTextFieldChange('jiraTaskId', value)}
                                        id="jiraTaskId "/>
                                <Checkbox
                                        label="Child"
                                        checked={this.state.task.child}
                                        style={{marginTop:'30px', width: "auto"}}
                                        labelStyle={{fontSize:'12px'}}
                                        onCheck={(event, isInputChecked) => this.onTextFieldChange('child', isInputChecked)}/>
                                {availableTeams && availableTeams.length> 0 &&
                                <div className="col-md-3" style={taskDialogStyles.textAreaStyle.teamDropDownBoxDivStyle}>
                                <DropDownMenu
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                                        value = {availableTeams.find(x => x[1].id == this.state.task.teamId)[1].name}
                                        selectedMenuItemStyle={{color: colors.nexioOrange}}
                                        labelStyle = {{color: colors.nexioOrange, opacity: 1, top: 0}}
                                        onChange={(event, index) => this.onTeamChange(availableTeams[index][1])}
                                        style={taskDialogStyles.textAreaStyle.developRealizatorListStyle}>
                                        {availableTeams.map(
                                                (item, index) => <MenuItem key={index} value={item[1].name} primaryText={item[1].name} />
                                        )}
                                </DropDownMenu>
                                </div>
                                }
                            </div>
                            <div style={taskDialogStyles.textAreaStyle.pricingInsertRow}>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldRedmineTask}
                                        errorText={this.state.task.redmineTaskId ? (this.state.task.id < 1 ? this.props.getErrorText('redmineTaskId', this.state.task.redmineTaskId): '')  : (this.state.task.child ? EN.pricingPage.requiredFieldMessage : '')}
                                        errorStyle={!this.state.task.redmineTaskId? {fontSize:'12px',color:'rgb(244, 67, 54)'} : {fontSize:'12px',color:colors.nexioOrange}}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.redmineTaskId}
                                        floatingLabelText = {EN.pricingPage.redmineTaskIdCol}
                                        onChange={(event, value) => this.onTextFieldChange('redmineTaskId', value)}
                                        id="redmineTaskId "
                                        type="number"
                                        />
                                <TextField className="popup-autofill-fix"
                                        multiLine
                                        errorText={this.state.task.name? "" : EN.pricingPage.requiredFieldMessage}
                                        style={taskDialogStyles.textAreaStyle.fieldJiraName}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.name}
                                        floatingLabelText = {EN.pricingPage.nameCol}
                                        onChange={(event, value) => this.onTextFieldChange('name', value)}
                                        id="name"/>
                            </div>
                            <div style={taskDialogStyles.textAreaStyle.pricingInsertRow}>
                                <AutoComplete
                                        floatingLabelText = {EN.pricingPage.analystCol}
                                        textFieldStyle={taskDialogStyles.textAreaStyle.fieldEmployeeName}
                                        style={taskDialogStyles.textAreaStyle.fieldEmployeeName}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        searchText={this.state.task.analyst}
                                        filter={AutoComplete.caseInsensitiveFilter}
                                        dataSource = {this.props.analystList}
                                        onUpdateInput={(searchText) => this.onTextFieldChange('analyst', searchText)}
                                        id="analyst"/>
                                <AutoComplete
                                        textFieldStyle={taskDialogStyles.textAreaStyle.fieldEmployeeName}
                                        style={taskDialogStyles.textAreaStyle.fieldEmployeeName}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        searchText={this.state.task.developEstimator}
                                        floatingLabelText = {EN.pricingPage.estimationsDeveloperCol}
                                        onUpdateInput={(searchText) => this.onTextFieldChange('developEstimator', searchText)}
                                        filter={AutoComplete.caseInsensitiveFilter}
                                        dataSource = {this.props.developerList}
                                        menuStyle={{ overflow: 'scroll', height: '250px' }}
                                        id="developEstimator"/>
                                <div className="col-md-3" style={taskDialogStyles.textAreaStyle.developRealizatorDivStyle}>
                                    <label className="label-name" style={taskDialogStyles.textAreaStyle.developRealizatorLabelStyle}>{EN.pricingPage.realizationsDeveloperCol}</label>
                                    <DropDownMenu
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                                        value = {this.state.task.developRealizatorId}
                                        selectedMenuItemStyle={{color: colors.nexioOrange}}
                                        labelStyle = {{color: colors.nexioOrange, opacity: 1, top: 0}}
                                        onChange={(event, index) => this.onDeveloperChange(this.props.developersList[index])}
                                        style={taskDialogStyles.textAreaStyle.developRealizatorListStyle}>
                                        {this.props.developersList.filter( item => {
                                                return item.status == 1 || item.id == this.state.task.developRealizatorId;
                                            }).map(
                                                (developer, index) => <MenuItem key={index} value={developer.id} primaryText={developer.name} />
                                        )}
                                    </DropDownMenu>
                                </div>
                            </div>
                            <div style={taskDialogStyles.textAreaStyle.nameOfPricing}  className="form-group-item label">{EN.pricingPage.analystsEstimationCol}</div>
                            <div style={taskDialogStyles.textAreaStyle.pricingInsertRow}>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldPricingCount}
                                        inputStyle={{textAlign:'center'}}
                                        type="number"
                                        min={0}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.analystPricing}
                                        floatingLabelText = {EN.pricingPage.analysisCol}
                                        onChange={(event, value) => this.onTextFieldChange('analystPricing', value)}
                                        id="analystPricing"/>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldPricingCount}
                                        inputStyle={{textAlign:'center'}}
                                        type="number"
                                        min={0}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.analystDevPricing}
                                        floatingLabelText = {EN.pricingPage.developmentCol}
                                        onChange={(event, value) => this.onTextFieldChange('analystDevPricing', value)}
                                        id="analystDevPricing"/>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldPricingCount}
                                        inputStyle={{textAlign:'center'}}
                                        type="number"
                                        min={0}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.testerPricing}
                                        floatingLabelText = {EN.pricingPage.testingCol}
                                        onChange={(event, value) => this.onTextFieldChange('testerPricing', value)}
                                        id="testerPricing"/>
                            </div>
                            <div style={taskDialogStyles.textAreaStyle.nameOfPricing}  className="form-group-item label">{EN.pricingPage.developerSection}</div>
                            <div style={taskDialogStyles.textAreaStyle.pricingInsertRow}>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldPricingCount}
                                        inputStyle={{textAlign:'center'}}
                                        type="number"
                                        min={0}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.developerPricing}
                                        floatingLabelText = {EN.pricingPage.estimation}
                                        onChange={(event, value) => this.onTextFieldChange('developerPricing', value)}
                                        id="developerPricing"/>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldPricingCount}
                                        inputStyle={{textAlign:'center'}}
                                        type="number"
                                        min={0}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.developerMd}
                                        floatingLabelText = {EN.pricingPage.developerMd}
                                        onChange={(event, value) => this.onTextFieldChange('developerMd', value)}
                                        id="developerMd"/>
                            </div>
                            <div style={taskDialogStyles.textAreaStyle.nameOfPricing} className="form-group-item label">{EN.pricingPage.estimationForClientCol}</div>
                            <div style={taskDialogStyles.textAreaStyle.pricingInsertRow}>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldPricingCount}
                                        inputStyle={{textAlign:'center'}}
                                        type="number"
                                        min={0}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.analystPricingForClient}
                                        floatingLabelText = {EN.pricingPage.analysisCol}
                                        onChange={(event, value) => this.onTextFieldChange('analystPricingForClient', value)}
                                        id="analystPricingForClient"/>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldPricingCount}
                                        inputStyle={{textAlign:'center'}}
                                        type="number"
                                        min={0}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.analystDevPricingForClient}
                                        floatingLabelText = {EN.pricingPage.developmentCol}
                                        onChange={(event, value) => this.onTextFieldChange('analystDevPricingForClient', value)}
                                        id="analystDevPricingForClient"/>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.fieldPricingCount}
                                        inputStyle={{textAlign:'center'}}
                                        type="number"
                                        min={0}
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.testerPricingForClient}
                                        floatingLabelText = {EN.pricingPage.testingCol}
                                        onChange={(event, value) => this.onTextFieldChange('testerPricingForClient', value)}
                                        id="testerPricingForClient"/>
                            </div>
                            <div style={taskDialogStyles.textAreaStyle.pricingInsertRow}>
                                <TextField className="popup-autofill-fix"
                                        style={taskDialogStyles.textAreaStyle.field}
                                        multiLine
                                        floatingLabelStyle={taskDialogStyles.textAreaStyle.floatingLabelFocusStyle}
                                        underlineFocusStyle={taskDialogStyles.textAreaStyle.underlineStyle}
                                        defaultValue={this.state.task.description}
                                        floatingLabelText = {EN.pricingPage.commentsCol}
                                        onChange={(event, value) => this.onTextFieldChange('description', value)}
                                        id="description"/>
                            </div>
                        </div>
                    </div>
                </form>
                {this.props.data && this.props.data.taskProgresses && this.props.data.taskProgresses.length > 0 &&
                    <ProgressGraph data={this.props.data.taskProgresses} />}
            </Dialog>
        );
    }
}

TaskDialog.propTypes = propTypes;
TaskDialog.defaultProps = defaultProps;

export default ReactRedux.connect()(TaskDialog);
