import React, { useMemo, useState, useCallback, useEffect, useRef, memo, useContext } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

/* 
Confirm dialog using react context

Usage in functional components: 

import { useConfirm } from ...

const Component = (props) => {
    const confirm = useConfirm({content: "Are you sure you want to delete?", yesText:"Delete", noText:"Cancel"});
    const handleDeleteClick = useCallback(
        () => confirm(props.onDelete), 
        [confirm, props.onDelete]);
    [...]
}

Usage in class components:

import { ConfirmContext } from ...

class Component extends React.Component {
    [...]
    const handleDelete() {
        const confirmDelete = this.context({content: "Are you sure you want to delete?", yesText:"Delete", noText:"Cancel"});
        confirmDelete(this.props.onDelete);
    }
    [...]
}

Component.contexType = ConfirmContext;

Confirm function can be used in promise constructor.
It is not a promise by default, because then you need to handle dialog rejection as an error.
*/
const ConfirmDialog = ({ open, onNo, onYes, content, yesText, noText }) => (
    <Dialog open={open} onClose={onNo}>
        <DialogContent>
            {content}
        </DialogContent>
        <DialogActions>
            <Button onClick={onNo} autoFocus>{noText}</Button>
            <Button onClick={onYes} variant="contained" color="primary">{yesText}</Button>
        </DialogActions>
    </Dialog>
);


const defaultItem = {
    yesText: "Yes",
    noText: "No",
    content: "Are you sure?",
    onYes: () => undefined,
    onNo: () => undefined,
};

export const ConfirmContext = React.createContext((_opts) => (resolve, _reject) => resolve());

export const useConfirm = (options) => useContext(ConfirmContext)(options);

export const confirmRejected = "Confirm rejected";

export const ConfirmDialogProvider = ({children}) =>{
    const [itemList, setItemList] = useState([]);
    const previousItemRef = useRef(defaultItem); //Don't change content when closing animation is playing
    const currentItem = itemList[0] || previousItemRef.current;
    previousItemRef.current = currentItem;
    const confirm = useCallback((options) => (resolve, reject) => 
        {
            const onYes = () => {
                setItemList(([_deleted, ...items]) => items);
                resolve();
            };
            const onNo = () => {
                setItemList(([_deleted, ...items]) => items);
                reject && reject(confirmRejected);
            };
            const newItem = { ...defaultItem, ...options, onYes, onNo };
            setItemList((items) => [...items, newItem]);
        }, []);
    return (
        <ConfirmContext.Provider value={confirm}>
            <ConfirmDialog open={itemList.length > 0} {...currentItem}/>
            {children}
        </ConfirmContext.Provider>);
};

export const deleteMessage = {
    content: "Are you sure you want to delete this item?",
    yesText: "Delete",
    noText: "Cancel"
};
