import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Components
import FooterDay from './FooterDay';
//3rd party
import _ from 'lodash';
import moment from 'moment';
//translations
import {EN} from '../../../../common/translations';
import {calendarPanel} from '../../../../common/styles';

const translations = EN.timesheetPage.calendarPanel;
const style = calendarPanel.calendar.body;

const propTypes = {
    selectedMonth: PropTypes.number, 
    selectedYear:PropTypes.number,
    rowId:PropTypes.number,
    weekNumber:PropTypes.number,
    date:PropTypes.object,
    totalHours: PropTypes.array,
};

const defaultProps = {
    selectedMonth: undefined, 
    selectedYear: undefined 
};

class FooterWeek extends React.Component {

    constructor(props){
        super(props);
    }
    
    render(){
        const dayNames = translations.days;
        let days = [];

        let { 
            rowId,
            weekNumber,
            date,
            selectedYear, 
            selectedMonth,
            totalHours,
            isTeamTimesheet,
            isDailyFooter
        } = this.props;

        const newDate = moment({month: selectedMonth, year: selectedYear});

        for (let i = 0; i < 7; i++) {

            let day = {
                weekNumber:{weekNumber},
                date: date,
                name: dayNames[date.day()],
                month: newDate.month(),
                number: date.date(),
                isCurrentMonth: date.month() === newDate.month(),
                isNextMonth: date.month() > newDate.month() ? "next-month" : "",
                isPrevMonth: date.month() < newDate.month() ? "prev-month" : "",
                isToday: date.isSame(new Date(), "day"),
                totalHours: totalHours[date.date()]
            };

            days.push(day);
            date = date.clone();
            date.add(1, "day");
        }

        return (
            <div className="week">
                {days.map( day => {
                    return (
                        <FooterDay 
                            key={_.uniqueId('day_')} 
                            day={day}
                            rowId={rowId}
                            weekNumber={weekNumber}
                            isTeamTimesheet={isTeamTimesheet}
                            isDailyFooter={isDailyFooter}
                        />
                    );
                })}
            </div>
        );
    }
}

FooterWeek.propTypes = propTypes;
FooterWeek.defaultProps = defaultProps;

export default FooterWeek;
