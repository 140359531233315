import React from 'react';
import PropTypes from 'prop-types';
//Redux
import * as Redux from 'redux';
import { Provider, ReactReduxContext, connect } from 'react-redux';
import _ from 'lodash';
//MDL
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
//
import IssuePanel from './IssuePanel/IssuePanel';
import CalendarReport from './CalendarReport/CalendarReport';
//
import userStruct from '../../common/user';
import { colors } from '../../common/styles';
import { EN } from '../../common/translations';
import './verifyDialog.scss';
import { onHideComment, onShowComment } from '../../../store/Comments';



const VerifyDialog = (props) => {
    //to prvent an error from getting userRef properties
    if (!props.userRef)
        return null;
    const dialogClassName = `dialog-wrapper${props.userRef.timeEntries.length ? "" : " noReport"}`;

    const buttonStyle = {
        color: colors.nexioOrange
    };

    const closeOnlyOneModal = () => {
        props.closeHandler(false);
    };

    const actions = (
        <span>
            {props.goBackBtn && <FlatButton primary label="Go back" onClick={closeOnlyOneModal} style={buttonStyle} />}
            <FlatButton primary label="Close" onClick={props.closeHandler} style={buttonStyle} />
        </span>
    );

    const CalendarElement = () => {
        const issues = _.sortBy(_.uniqBy(props.userRef.timeEntries, 'issueId'), function (e) { return e.projectId });
        const grouppedProjects = _.mapValues(_.groupBy(props.userRef.timeEntries, "projectId"));

        const calendarProps = {
            reportData: grouppedProjects,
            userHolidays: props.userRef.userHolidays,
            selectedMonth: props.selectedMonth,
            selectedYear: props.selectedYear,
            showComment: props.onShowComment,
            hideComment: props.onHideComment
        };

        if (props.userRef.timeEntries.length) {
            return (
                <div className="calendar">
                    <IssuePanel reportData={issues} />
                    <CalendarReport {...calendarProps} />
                </div>
            );
        }
        else {
            return (
                <div className="info">
                    <h2>{EN.workTimePage.irregular.noReports}</h2>
                </div>
            );
        }
    };

    return (
        <ReactReduxContext.Consumer>
            {
                //eslint-disable-next-line react/display-name
                ((ctx) => (
                    <Dialog actions={actions} className={dialogClassName}
                        open={props.show}
                        onRequestClose={props.closeHandler}
                        autoScrollBodyContent
                        modal
                    >
                        <Provider store={ctx.store}>
                            <div className="verifyDialog-inner">
                                <div className="person-name">{props.userRef.name}</div>
                                {CalendarElement()}
                            </div>
                        </Provider>
                    </Dialog>
                )).bind(this) // Dont forget to bind this
            }
        </ReactReduxContext.Consumer>
    );
};

VerifyDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    goBackBtn: PropTypes.bool,
    userRef: userStruct,
    closeHandler: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
    const actions = {
        onShowComment,
        onHideComment
    };

    return Redux.bindActionCreators(actions, dispatch);
};

export default connect(null, mapDispatchToProps)(VerifyDialog);