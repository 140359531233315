import React from 'react';
import PropTypes from 'prop-types';
//MDL
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
    TableFooter
  } from 'material-ui/Table';
import Slider from 'material-ui/Slider';
import { EN } from '../../common/translations';
import WarningIcon from '@material-ui/icons/Warning';

class ScrumTasksTable extends React.Component {
    constructor(props){
        super(props);
    }

    formatDate(date) {
        let monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ];
      
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
      
        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }

    getColorForBudgetProgress(budgetProgress, realProgress)
    {
        if(budgetProgress > 100)
            return 'red';
        else{
            let different =  realProgress - budgetProgress;
            if(different > 0) return 'blue';
            if(different < -20) return 'red';
            return 'orange';
        }
    }
    getColorForRealProgress(budgetProgress, realProgress)
    {
        if(realProgress - budgetProgress > 20)
            return 'green';
        return 'blue';
    }

    render(){
        return(
            <div className="scrumTableDiv">
                <Table className="scrum-table"
                    fixedHeader>
                    <TableHeader className="scrum-table-header"
                        displaySelectAll={false}
                        adjustForCheckbox={false}
                        enableSelectAll={false}>
                        <TableRow>
                            <TableHeaderColumn style={{color:'gray', width:'10%'}}>{EN.scrumManagementPage.scrumTableColumnName.taskRedmineId}</TableHeaderColumn>
                            <TableHeaderColumn style={{color:'gray', width:'35%'}}>{EN.scrumManagementPage.scrumTableColumnName.taskName}</TableHeaderColumn>
                            <TableHeaderColumn style={{color:'gray', width:'35%'}}>{EN.scrumManagementPage.scrumTableColumnName.realProgress}</TableHeaderColumn>
                            <TableHeaderColumn style={{color:'gray', width:'20%'}}>{EN.scrumManagementPage.scrumTableColumnName.deadline}</TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody
                        displayRowCheckbox={false}>
                        {this.props.rows.map( (row, idx) => (
                            <TableRow key={idx}>
                                <TableRowColumn style={{width:'10%'}}>{row.taskRedmineId}</TableRowColumn>
                                <TableRowColumn style={{width:'35%'}} title={row.taskName}>{row.taskName}</TableRowColumn>
                                <TableRowColumn style={{width:'35%', padding:'0px 0px 0px 0px'}}>
                                    <div>
                                        <div className="col-md-1" style={{padding:'8px 0px 0px 0px',height:'36px'}}>                    
                                            <span style={{fontSize:'9px'}}>0%</span>
                                        </div>
                                        <div className="col-md-10" style={{padding:'0px 7px 0px 5px'}}>      
                                            <div>
                                                <Slider className={'budget-slider budget-slider-' + this.getColorForBudgetProgress(row.budgetProgress, row.realProgress)} 
                                                    disabled 
                                                    step={1}
                                                    min={0} max={row.budgetProgress > 100 ? row.budgetProgress :100} 
                                                    value={row.budgetProgress} 
                                                    style={{paddingTop:'4px'}}
                                                    onChange={(object,newValue)=>this.props.onSliderChange('budgetProgress', newValue, row.taskRedmineId)}
                                                    sliderStyle={{margin: '0px 0px 0px 0px'}} />
                                                <Slider className={'real-slider real-slider-' + this.getColorForRealProgress(row.budgetProgress, row.realProgress)} 
                                                    value={row.realProgress}
                                                    step={1}  min={0} max={100} 
                                                    onChange={(object,newValue)=>this.props.onSliderChange('realProgress', newValue, row.taskRedmineId)}
                                                    style={{marginTop:'-8px'}}
                                                    sliderStyle={{margin: '0px 0px 0px 0px'}}/>
                                                <span>{row.realProgress}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-1" style={{padding:'8px 0px 0px 0px',height:'36px'}}>                     
                                            <span style={{fontSize:'9px'}}>{row.budgetProgress > 100 ? row.budgetProgress + '%' :'100%'}</span>
                                        </div>
                                    </div>   
                                </TableRowColumn>
                                <TableRowColumn style={{width:'20%', padding:'0px 0px 0px 0px'}}>
                                    <div>
                                        <div className="col-md-4" style={{width:'20%', padding:'0px 0px 0px 10px'}}>  
                                                <WarningIcon style={{float:'left', color:this.getColorForBudgetProgress(row.budgetProgress, row.realProgress),
                                                    display: this.getColorForBudgetProgress(row.budgetProgress, row.realProgress) == 'red' 
                                                                ||  this.getColorForBudgetProgress(row.budgetProgress, row.realProgress) == 'orange' ? 'block' : 'none'}} 
                                                    viewBox="0 0 25 25"/>  
                                        </div>
                                        <div className="col-md-8" style={{width:'20%', padding:'3px 0px 0px 10px'}}>  
                                                {this.formatDate(new Date(row.deadline))}
                                        </div>
                                    </div>
                                </TableRowColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default ScrumTasksTable;