import { API } from "./types";
import { EN } from "../components/common/translations";

const Actions = {
  ON_FETCH_WHITELIST: "WHITELIST_ON_FETCH_WHITELIST",
  ON_DELETE_WHITELIST: "WHITELIST_ON_DELETE_WHITELIST",
  ON_ADD_WHITELIST: "WHITELIST_ON_ADD_WHITELIST",
  ON_REPLACE_WHITELIST: "WHITELIST_ON_REPLACE_WHITELIST",
};

const initialState = {
  mails: [],
};

export const addMailWhitelist = (mails) => (dispatch) => {
  dispatch({
    type: API,
    payload: {
      url: "dev/mails/addMailWhitelist",
      method: "POST",
      text: EN.whitelist.savingMessage,
      successText: EN.whitelist.savedMessage,
      onSuccess: () => onAddMailWhitelist(mails),
      data: Array.isArray(mails) ? mails : [mails],
    },
  });
};

export const replaceMailWhitelist = (oldMail, newMail) => (dispatch) => {
  dispatch({
    type: API,
    payload: {
      url: "dev/mails/replaceMailWhitelist",
      method: "POST",
      text: EN.whitelist.savingMessage,
      successText: EN.whitelist.savedMessage,
      onSuccess: () => onReplaceMailWhitelist(oldMail, newMail),
      params: {
          oldMail,
          newMail
      },
    },
  });
};

export const removeMailsWhitelist = (mails) => (dispatch) => {
  dispatch({
    type: API,
    payload: {
      url: "dev/mails/removeMailsWhitelist",
      method: "DELETE",
      text: EN.whitelist.deletingMessage,
      successText: EN.whitelist.deletedMessage,
      onSuccess: (response) => onDeleteMailsWhitelist(mails),
      params: { mails: mails },
    },
  });
};

export const fetchWhitelist = () => (dispatch) => {
  dispatch({
    type: API,
    payload: {
      url: "dev/mails/getWhitelist",
      method: "GET",
      text: EN.whitelist.loadingWhitelist,
      onSuccess: (mails) => onFetchWhitelist(mails),
    },
  });
};

const onFetchWhitelist = (mails) => ({
  type: Actions.ON_FETCH_WHITELIST,
  mails,
});

const onAddMailWhitelist = (mails) => ({
  type: Actions.ON_ADD_WHITELIST,
  mails,
});

const onReplaceMailWhitelist = (oldMail, newMail) => ({
  type: Actions.ON_REPLACE_WHITELIST,
  oldMail,
  newMail
});

const onDeleteMailsWhitelist = (mails) => ({
  type: Actions.ON_DELETE_WHITELIST,
  mails,
});

export default function whitelist(state = initialState, action) {
  switch (action.type) {
    case Actions.ON_FETCH_WHITELIST:
      return {
        ...state,
        mails: action.mails,
      };
    case Actions.ON_ADD_WHITELIST:
      return {
        ...state,
        mails: [...state.mails, action.mails],
      };
    case Actions.ON_DELETE_WHITELIST:
      const deleteMails = action.mails;
      const slicedMails = state.mails.filter(
        (value) => !deleteMails.includes(value)
      );
      return {
        ...state,
        mails: slicedMails,
      };
    case Actions.ON_REPLACE_WHITELIST:
        const index = state.mails.indexOf(action.oldMail);
        const mails = [...state.mails];
        mails[index] = action.newMail;
      return {
        ...state,
        mails: mails,
      };
    default:
      return state;
  }
}
