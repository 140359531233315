import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import {onAddFlashMessage} from '../../../../../store/FlashMessage';
import {onRangeSelect , onDaySelect} from '../../../../../store/Calendar';
import {onIssueSelect} from '../../../../../store/Issuses';
//Components
import FooterWeek from './FooterWeek';
//3rd lib
import moment from 'moment';
import _ from 'lodash';
import { selectedProject } from '../../../../../store/Projects';
import {EN} from '../../../../common/translations';

const translations = EN.timesheetPage.calendarPanel;

const propTypes = {
    selectedMonth: PropTypes.number, 
    selectedYear: PropTypes.number,
    rowId: PropTypes.number,
    totalHours: PropTypes.array,
};

const defaultProps = {
    selectedMonth: undefined, 
    selectedYear: undefined,
    rowId:undefined
};

class FooterMonth extends React.Component {

    constructor(props){
        super(props);
         
        this.state = {
            month: moment(),
            selected: moment().startOf('day'),
        };
    }
   
    render(){
        const {
            selectedMonth, 
            selectedYear,
            rowId,
            totalHours,
            isTeamTimesheet,
            isDailyFooter
        } = this.props;

        const { selected, month, selectionEnabled } = this.state;

        let weeksInMonth = [];
        let done = false;
        let date = moment({month: selectedMonth, year: selectedYear}).startOf("month").add("w" - 1).day("Sunday");
        let count = 0;
        let monthIndex = date.month();

        while (!done) {
            
            weeksInMonth.push(
                <FooterWeek 
                    key={_.uniqueId('week_')}
                    date={date.clone()}
                    rowId={rowId}
                    weekNumber = {count+1}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    totalHours={totalHours}
                    isTeamTimesheet={isTeamTimesheet}
                    isDailyFooter={isDailyFooter}
                    />
            );

            date.add(1, "w");

            done = count++ > 2 && monthIndex !== date.month();
            monthIndex = date.month();
        }

        return (<div className="month">
                {weeksInMonth}
            </div>);
    }
}

FooterMonth.propTypes = propTypes;
FooterMonth.defaultProps = defaultProps;

export default FooterMonth;