import React, { useState } from 'react';
import FileUploadInput from "./FileUploadInput";
import Attachment from "./Attchment";
import "./style.scss";
import { Constants } from '../../../common/constants';
import { EN } from '../../../common/translations';

const fileList = ({ isNewRequest, list, addAttachment, deleteAttachment, holidayType }) => {
    let addAttachmentInput = null;
    let fileSizeInfo = null;
    const [displayError, setDisplayError] = useState(false);
    const attachmentProps = attachment => ({
        ...attachment,
        isNewRequest,
        deleteAttachment
    });
    const attachments = list.map(attachment => <Attachment key={attachment.guid} {...attachmentProps(attachment)} />);
    const headerText = list.length ? "Attachments:" : "No attachments";
    const errorText = displayError 
        ? EN.holidaysPage.omittedFile 
        : "";
    const errorText2 = holidayType === Constants.HolidayTypes.OTHERS && list.length === 0
        ? EN.holidaysPage.attachmentIsRequired
        : "";

    if (isNewRequest) {
        addAttachmentInput = <FileUploadInput addAttachment={addAttachment} setDisplayError={setDisplayError} />;
        fileSizeInfo = EN.holidaysPage.maxFileSize;
    }

    return (
        <div className="holiday-dialog-attachments container-fluid">
            <div className="row -files-header">
                <div className="col-xs-4 col-md-6 title">
                    <div className="summary-color">
                        {headerText}
                    </div>
                    <div className="small-text">
                        {fileSizeInfo}
                    </div>
                </div>
                <div className="col-xs-8 col-md-6 right">
                    {addAttachmentInput}
                </div>
            </div>
            <div className="small-text text-red after-header mb-0">
                {errorText}
            </div>
            <div className="small-text text-red after-header">
                {errorText2}
            </div>
            <div className="row -file-list">
                {attachments}
            </div>
        </div>
    );
};

export default fileList;