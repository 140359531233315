import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { API } from './types';

const types = {
    SELECT_DAY: 'SELECT_DAY',
    SELECT_YEAR: 'SELECTED_YEAR',
    SELECT_MONTH: 'SELECTED_MONTH',
    SELECT_DATE: 'SELECT_DATE',
    SELECT_RANGE: 'RANGE_SELECT',
    SELECT_DAYS: 'SELECT_DAYS',
    UNSELECT_DAYS: 'UNSELECT_DAYS',
    CLEAR_DAYS: 'CLEAR_DAYS',
    SELECTION_ENABLED: 'SELECTION_ENABLED',
    SET_SERIES_OPTION: 'SET_SERIES_OPTION',
    GET_DATE_LAST_UNSUBMITED_TIMESHEET: "GET_DATE_LAST_UNSUBMITED_TIMESHEET"
};

/** ACTIONS START */

export const onDaySelect = (selectedDay) => ({type: types.SELECT_DAY, selectedDay});
export const onMonthSelect = (selectedMonth) => ({type: types.SELECT_MONTH, selectedMonth});
export const onYearSelect = (selectedYear) => ({type: types.SELECT_YEAR, selectedYear});
export const onDateSelect = (selectedDate) => ({type: types.SELECT_DATE, selectedDate});
export const onRangeSelect = (selectionRange) => ({type: types.SELECT_RANGE, selectionRange});
export const onDaysSelect = (day) => ({type: types.SELECT_DAYS, day});
export const onDaysUnselect = (day) => ({type: types.UNSELECT_DAYS, day});
export const onDaysClear = () => ({type: types.CLEAR_DAYS});
export const onGetDateLastUnsubmitedTimesheet = (response) => ({ type: types.GET_DATE_LAST_UNSUBMITED_TIMESHEET, response});

/** ACTIONS END */

/** OPERATIONS START */

export const getDateLastUnsubmitedTimesheet = () => dispatch =>
    dispatch({
        type: API, payload: {
            url: "timesheet/dateLastUnsubmitedTimesheet",
            method: "GET",
            text: "Loading",
            onSuccess: (response) => dispatch(onGetDateLastUnsubmitedTimesheet(response))
        }
    });

/** OPERATIONS END */

/** REDUCER START */

const selectedDayModel={
    day:null,
    issueId:-1
};

export const selectedDay = (state = selectedDayModel, action) => {
    let newState = null;

    switch (action.type) {

        case types.SELECT_DAY:

            newState = {
                day: action.selectedDay.day,
                issueId: action.selectedDay.issueId
            };

            return Object.assign({}, state, newState);

        default:
            return state || {};
    }
};

const selectedMonthModel = moment().month();//0

export const selectedMonth = (state=selectedMonthModel, action) => {
    let newState = null;

    switch (action.type) {

        case types.SELECT_MONTH:

            newState = action.selectedMonth;
            return Object.assign({},state, newState);

        default:
            return state || {};
    }
};

const selectedYearModel = moment().year();//2018

export const selectedYear = (state=selectedYearModel, action) => {
    let newState = null;

    switch (action.type) {

        case types.SELECT_YEAR:

            return action.selectedYear;

        default:
            return state || {};
    }
};

const selectedDateModel = {
    selectedMonth: selectedMonthModel, 
    selectedYear: selectedYearModel
};

export const selectedDate = (state=selectedDateModel, action={}) => {
    let newState = null;

    switch (action.type) {

        case types.SELECT_DATE:

            return  Object.assign({}, state, action.selectedDate);

        default:
            return state || {};
    }
};

const selectedRangeModel={
    selectedYear: moment().year(),
    selectedMonth: moment().month(),
    selectedDays: [],
    startDay: moment().startOf('day'),
    endDay: moment().endOf('day'),
    isSeries: false,
    isWeekend: false
};

export const selectedRange = (state = selectedRangeModel, action = {}) => {
    let newState = null;

    switch (action.type) {
        case types.SELECT_RANGE:

            return Object.assign({}, state, action.selectionRange);
        
        default:
            return state || {};
    }
};

const selectedDaysModel=[];

export const selectedDays = (state = selectedDaysModel, action = {}) => {
    let newState = null;

    switch (action.type) {
        case types.SELECT_DAYS:

            newState = action.day;

            return [
                ...state,
                newState
            ];

        case types.UNSELECT_DAYS:

            newState = state.filter( day => day !== action.day);
            return newState;

        case types.CLEAR_DAYS:

            newState =  [];

            return newState;
        
        default:
            return state || [];
    }
};

const dateLastUnsubmitedTimesheetModel = {date: ''};

export const dateLastUnsubmitedTimesheet = (state = dateLastUnsubmitedTimesheetModel, action={}) => {
    let newState = null;

    switch (action.type){
        case types.GET_DATE_LAST_UNSUBMITED_TIMESHEET:
            newState = {
                date: action.response
            }

            return Object.assign({}, state, newState);
        default:
            return state || {};
    }
}

/** REDUCER END */
