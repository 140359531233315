import React from 'react';
import PropTypes from 'prop-types';

const propTypesDescription = {
    text: PropTypes.string
  };

const Description = (props) => {
    return <div className={`issue-name-chunk`} style={{borderBottom: '1px solid ' + props.color}}>{props.text}<span style={{display:"none"}}>...</span></div>;
};

Description.propTypes = propTypesDescription;

export default Description; 