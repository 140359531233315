import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import RaisedButton from 'material-ui/RaisedButton';
import userStruct from '../../../common/user';
//
import Pagination from '../../../common/partials/pagination/pagination';

const irregularWorkTable = (props) => 
{
    const page = props.TablePage;
    const pageSize = 10;
    const pagesCount = Math.ceil(props.count / pageSize);
    const pageStartsAt = (page - 1) * pageSize;

    const tableHeadersArray = ["No.", "Employee", "Contract type", "Supervisor", "Action"].map((header, index) => {
        return <TableHeaderColumn key={index} style={{ color: 'white' }}>{header}</TableHeaderColumn>;
    });

    const tableBody = props.users.map((user, index) => {
        const verifyHandler = () => props.verifyHandler(user.id);

        return (
            <TableRow key={lodash.uniqueId(`user_row_${user.id}`)}>
                <TableRowColumn>{pageStartsAt + index + 1}.</TableRowColumn>
                <TableRowColumn>{user.name}</TableRowColumn>
                <TableRowColumn>{user.contractName}</TableRowColumn>
                <TableRowColumn>{user.supervisorName}</TableRowColumn>
                <TableRowColumn>
                    <RaisedButton label="Verify" backgroundColor="rgb(255, 153, 0)" onClick={verifyHandler} />
                </TableRowColumn>
            </TableRow>
        );
    });

    const onPageChange = (newPage) => {
        props.pageUpdate(newPage);
    };

    return (
        <>
        <Table className="employeesTable"
            bodyStyle={{ overflowY: "scroll" }}
            fixedHeader
            selectable={false}
            multiSelectable={false}
            //48 is the height of the row
            height={`${pageSize * 48}px`}
        >
            <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                enableSelectAll={false}>
                <TableRow>
                    {tableHeadersArray}
                </TableRow>
            </TableHeader>
            <TableBody
                displayRowCheckbox={false}
                stripedRows={false}
            >
                {tableBody}
            </TableBody>
        </Table>
        <Pagination
            onChange={onPageChange}
            value={page}
            count={pagesCount}
        />
        </>
    );
};

irregularWorkTable.propTypes = {
    users: PropTypes.arrayOf(userStruct).isRequired,
    verifyHandler: PropTypes.func.isRequired
};

export default irregularWorkTable;