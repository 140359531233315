import React from 'react';

import Select from '@material-ui/core/Select';

const NexioSelect = ({ children, ...props }) => {
    return (
        <Select
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
                }
            }}
            {...props}
        >
            {children}
        </Select>
    );
};

export default NexioSelect;