import { API } from './types';

export const types = {
    ON_LOAD_SUPERVISED_EMPLOYEES: "ON_LOAD_SUPERVISED_EMPLOYEES",
    ON_FETCH_SUPERVISED_EMPLOYEES_TIMESHEETS: "ON_FETCH_SUPERVISED_EMPLOYEES_TIMESHEETS"
};

const onLoadSupervisedEmployees = (supervisorId, response) => 
    ({ type: types.ON_LOAD_SUPERVISED_EMPLOYEES, supervisorId, response });

const onFetchSupervisedEmployeesTimesheets = (response) => 
({ type: types.ON_FETCH_SUPERVISED_EMPLOYEES_TIMESHEETS, response });

export const loadSupervisedEmployees = (supervisorId) => (dispatch, getState) => {
    const state = getState();
    const { employees } = state.supervisedEmployees;
    if (employees[supervisorId]) {
        return;
    }
    if (supervisorId != 0) {
        dispatch({
            type: API,
            payload: {
                method: "GET",
                url: "User/supervisedEmployees",
                text: "Loading employees",
                onSuccess: (response) => onLoadSupervisedEmployees(supervisorId, response),
                params: {
                    supervisorId
                },
            }
        });
    } else {
        dispatch({  
            type: API,
            payload: {
                method: "GET",
                url: "User/supervisors",
                text: "Loading employees",
                onSuccess: (response) => onLoadSupervisedEmployees(0, 
                    response
                    //    .filter(x => !x.supervisorid) enable if only top level supervisors should be shown
                        .map(x => ({...x, workgroups:[2]})))
            }
        });
    }
};

export const fetchSupervisedEmployeesTimesheets = (startDate, endDate, supervisorId) =>
    dispatch =>
        new Promise(resolve => {
            const data = {                
                startDate,
                endDate,
                supervisorId
            };
            dispatch({
                type: API,
                payload: {
                    method: "GET",
                    url: "/Timesheet/supervisor",
                    text: "Loading Supervised Employees Timesheets",
                    onSuccess: (response) => {
                        dispatch(onFetchSupervisedEmployeesTimesheets(response));
                        resolve();
                    },
                    params: data
                }
            });
        });

const initialState = {
    employees: { },
    supervisedEmployeesTimesheets: []
};

export const supervisedEmployees = (state = initialState, action) => {
    switch(action.type) {
        case types.ON_LOAD_SUPERVISED_EMPLOYEES:
            if(Object.keys(action.response).length==0){
        
                return {
                    ...state,
                    employees: { ...state.employees, [action.supervisorId] : {myEmployees: action.response, noData:true}},
                };
            }
            return {
                ...state,
                employees: { ...state.employees, [action.supervisorId] : {myEmployees: action.response, noData:false}}
            }
        case types.ON_FETCH_SUPERVISED_EMPLOYEES_TIMESHEETS:
            return {
                ...state,
                supervisedEmployeesTimesheets: action.response
            };
        default:
            return state;
    }
};