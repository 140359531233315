import React from 'react';
import ReactDOM from 'react-dom';
import memoize from "memoize-one";
import { TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import _ from 'lodash';
import { Tooltip } from '@material-ui/core';
import { DetailsModalType, Units } from './enums';
import OvertimeChip from './Table/OvertimeChip';
import { redmineUrl } from '../../common/cfg';
import { Constants } from '../../common/constants';

const highlitedColumnId = 89;

export const displayProjName = (projName, length) =>{
    if(projName.length > length){
        return projName.substring(0, length).trim().concat('...');
    }
    return projName;
}


export const getHeader = memoize((projects, activeItems, onHeaderClick) => {
    let projs = [];
    if (projects !== undefined && projects.length === activeItems.length) {
        projs = projects;
    } else {
        activeItems.forEach(activeProjects => projects.forEach(allProjects => {
            if(activeProjects === allProjects.id){
                projs.push(allProjects);
            }
        }));
    }
    const cols = projs.map((proj) => (
        <TableHeaderColumn
            key={`header_col_${proj.id}`}
            className={"rotate-45 child report-table-cell-header" + (highlitedColumnId === proj.id ? ' highlighted-cell' : '')}
            style={{ zIndex: 2 }}>
            <Tooltip title={proj.name}>
                <div className="clickable" onClick={() => onHeaderClick({ id: proj.id, type: DetailsModalType.Projects })} >
                    <span>
                        {displayProjName(proj.name, 30)}
                    </span>
                </div>
            </Tooltip>
        </TableHeaderColumn>
    ));
    return [
        ...cols,
        <TableHeaderColumn key={`header_last_col`} className="rotate-45 child report-table-cell-header">
            <div>
                <span>
                    Sum
                </span>
            </div>
        </TableHeaderColumn>
    ];
});

export const formatValue = (val, unit) => {
    if (val) {
        if (unit === Units.MD)
            return (val / 8).toFixed(2);
        return val.toFixed(2);
    }
    return '';
};

export const getUserRedmineUrl = (month, year, userId) => {
    const day = new Date().getDate();
    return `${redmineUrl}/work_time/show/hr?day=${day}&month=${month + 1}&prj=false&user=${userId}&year=${year}`;
};

export const getUsers = memoize((projectsTime, users, activeUsers, activeProjects, onHeaderClick, unit, overtimes, holidaysInYear, isYearly) => {
    let filteredUsers = [];
    if (users.length === activeUsers.length) {
        filteredUsers = users;
    } else {
        filteredUsers = _.intersectionWith(users, activeUsers, (f, s) => f.id === s);
    }
    
    return filteredUsers.map(u => {
        let sum = 0;
        let cells = activeProjects.map(p => {
            const val = projectsTime[`p${p}u${u.id}`];
            if (val)
                sum += val;
            let displayValue = '';
            if (p === Constants.Issues.holidayProjectId && unit === Units.MD && isYearly) { 
                let holidays = holidaysInYear[u.id] ?? 0;                            
                displayValue = `${val ? (val / 8).toFixed(0) : 0}/${holidays}`;
            } else
                displayValue = formatValue(val, unit);
            return (
                <TableRowColumn key={`row_cell_${u.id}_${p}`} className={"report-table-cell" + (p === highlitedColumnId ? ' highlighted-cell' : '')}>
                    {displayValue}
                </TableRowColumn>
            );
        });
        const isOvertime = !!overtimes[u.id];
        return (
            <TableRow className="table-row" hoverable={false} selectable={false} key={`row_${u.id}`}>
                <TableRowColumn className={"report-table-row-header"} >
                    <div className={"clickable " + (isOvertime ? ' overtime-chip-container' : '')} onClick={() => onHeaderClick({ id: u.id, type: DetailsModalType.Users })}>
                        {isOvertime && <OvertimeChip className="overtime-chip" />}
                        <span>{u.name}</span>
                    </div>
                </TableRowColumn>
                {cells}
                <TableRowColumn className="report-table-cell">
                    <div>
                        {formatValue(sum, unit)}
                    </div>
                </TableRowColumn>
            </TableRow>
        );
    });
});

const getDataForProject = (params) => {
    const hours = params.projectsTime
        .filter(x => x[0].startsWith('p' + params.id))
        .map(x => ({
            userId: parseInt(x[0].match(/(\d)+$/)[0], 10),
            hours: x[1]
        }));
    const users = hours.map(x => {
        const user = params.users.find(u => u.id === x.userId);
        return {
            id: x.userId,
            name: user ? user.name : 'UnknownUser'
        };
    });
    return {
        headers: [params.projects.find(x => x.id === params.id).name],
        rows: [...users.map(x => {
            return [x.name, hours.find(h => h.userId === x.id).hours];
        }), ['Sum', _.sum(hours.map(x => x.hours))]]
    };
};

const getDataForUser = (params) => {
    const hours = params.projectsTime
        .filter(x => x[0].endsWith('u' + params.id))
        .map(x => {
            return ({
                projId: parseInt(x[0].match(/p\d+/)[0].match(/\d+/)[0], 10),
                hours: x[1]
            });
        });
    const projects = hours.map(x => {
        const proj = params.projects.find(p => p.id === x.projId);
        return {
            id: x.projId,
            name: proj ? proj.name : ""
        };
    });
    const user = params.users.find(x => x.id === params.id);
    if(user == null){
        return {
            headers: [],
            rows: [],
            additional: {}
        };
    }
    const hoursValues = hours.map(x => x.hours);
    const overtime = params.overtimes[user.id];
    return {
        headers: [...projects.map(x => x.name), 'Sum'],
        rows: [[user.name, ...hoursValues, _.sum(hoursValues)]],
        additional: {
            overtime,
            userId: user.id,
            month: params.month,
            year: params.year
        }
    };
};

export const getDetailsData = memoize((detailsDialogData, projects, projectsTime, users, overtimes, month, year) => {
    const { type, id } = detailsDialogData;
    const commonParams = { id, type, projects, projectsTime: Object.entries(projectsTime), users };
    if (type === DetailsModalType.Projects) {
        return getDataForProject(commonParams);
    } else if (type === DetailsModalType.Users) {
        return getDataForUser({ ...commonParams, overtimes, month, year });
    } else {
        return {
            headers: [],
            rows: []
        };
    }
});


export const setupScrollableTable = (component, bodySelector, headSelector) => {
    //eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(component);
    const tableBody = node.querySelector(bodySelector).parentNode.parentNode;
    const tableHead = node.querySelector(headSelector).parentNode.parentNode;
    const onScroll = () => {
        tableHead.scrollLeft = tableBody.scrollLeft;
    };
    tableBody.addEventListener('scroll', onScroll);
};

export const getDetailsTableRows = (row, index, unit) => {
    const firstRow = (
        <TableRowColumn key={`table_row_first_col_${index}`} className="report-table-row-header" style={{ width: "185px", paddingLeft: '0px !important' }}>
            <div>
                {row[0]}
            </div>
        </TableRowColumn>
    );
    const nextRows = _.drop(row).map((c, i) => (
        <TableRowColumn key={`table_row_col_${index}_${i}`} className="report-table-cell">
            {formatValue(c, unit)}
        </TableRowColumn>
    ));
    return [firstRow, ...nextRows];
};

export const getAllYears = () => {
    const date = new Date();
    const yearFor = date.getFullYear() - 2015;
    let yearValuesAll = [];
    for (let i = 0; i <= yearFor; i++) {
        const newYear = {
            id: i,
            value: `year${date.getFullYear() - i}`,
            name: `${date.getFullYear() - i}`
        };
        yearValuesAll = [
            ...yearValuesAll,
            newYear
        ];
    }
    return yearValuesAll;
}