import React from 'react';
import * as ReactRedux from 'react-redux';
import { fetchSupervisorSettings, updateSupervisorSettings, postTeamsTestMessage, postSlackTestMessage, fetchAllSupervisors } from '../../store/Settings';
import { fetchSettingsValues, updateSettingsValues } from '../../store/SettingsValues';
import moment from 'moment';
import { Formik } from 'formik';

import { addSupervisiorFromSettings } from '../../store/Holidays';
import { TextField, Button, Switch, InputAdornment, FormControlLabel } from '@material-ui/core';
import SelectReplacingPersons from './components/selectReplacingPersons';
import SelectSearch from 'react-select-search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearOutlined from '@material-ui/icons/ClearOutlined';
import settingsStyles from './settingsStyles';
import CountInputs from './countInputs';
import './settings.scss';
import InfoIcon from '@material-ui/icons/Info';
import {EN} from '../common/translations';
import { validateFunctions } from '../common/helpers/validateFunctions';
import { FormDatePicker } from '../common/controls/formik/FormControls';

const fieldStyle = {
    width: "100%"
};

class SupervisorSettings extends React.Component {

    state = {
        supervisorSettings: this.props.supervisorSettings,
        underlineStyle: settingsStyles.defaultUnderlineStyle,
        counter: this.props.counter,
        counterRaports: this.props.counterRaports,
        applicationSettings: this.props.settingsValues,
        dateFrom: null,
        selectedReplacingPerson: "",
        dateTo: null,
        replacementChecked: false,
        isFormValid: false
    };


    componentDidMount() {
        this.props.fetchAllSupervisors();
        this.props.fetchSupervisorSettings();
        this.props.fetchSettingsValues();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.supervisorSettings !== this.props.supervisorSettings) {
            const selectedSupervisior = this.props.allSupervisor.filter(item => item.id === newProps.supervisorSettings.replacementPersonId);
            const date = new Date();
            this.setState({
                supervisorSettings: newProps.supervisorSettings,
                dateFrom: newProps.supervisorSettings.replacementDateFrom ? moment(new Date(newProps.supervisorSettings.replacementDateFrom)).format("YYYY-MM-DD") : moment(date).format("YYYY-MM-DD"),
                dateTo: newProps.supervisorSettings.replacementDateTo ? moment(new Date(newProps.supervisorSettings.replacementDateTo)).format("YYYY-MM-DD") : moment(date).format("YYYY-MM-DD"),
                replacementChecked: newProps.supervisorSettings.replacementChecked,
                selectedReplacingPerson: selectedSupervisior.length > 0 ? selectedSupervisior[0].value : "",
            });
        }
        if (newProps.applicationSettings !== this.props.settingsValues) {
            this.setState({ applicationSettings: newProps.settingsValues });
        }
    }

    componentDidUpdate(prevProps) {
        onUpdate(prevProps, this);
    }

    handleHolidaysChange = (_event, checked) => {
        this.setState({
            supervisorSettings: {
                ...this.state.supervisorSettings,
                supervisorHolidaysEnabled: checked
            }
        });
    }

    handleManualTimesheetApprovalChange = (_event, checked) => {
        this.setState({
            supervisorSettings: {
                ...this.state.supervisorSettings,
                supervisorManualTimesheetApprovalEnabled: checked
            }
        });
    }

    handleSlackUrlChange = (event) => {
        const { value } = event.target;
        this.setState(prevState => ({
            supervisorSettings: {
                ...prevState.supervisorSettings,
                supervisorSlackDayHolidayUrl: value
            }
        }));
    }

    handleMSTeamsUrlChange = (event) => {
        const { value } = event.target;
        this.setState(prevState => ({
            supervisorSettings: {
                ...prevState.supervisorSettings,
                supervisorMSTeamsDayHolidayUrl: value
            }
        }));
    }


    handleReplacingPerson = (e) => {
        this.setState({
            replacingPerson: e.target.value
        });
    }

    handleClearTheReplacementPerson = () => {
        this.setState({
            selectedReplacingPerson: ""
        });
    }

    handleCounterOnChange = (e, valueName) => {
        let value = Number(e.target.value);
        if (value || value === '0') {
            let newCounter = this.state[valueName];

            newCounter[e.target.name] = value;
            this.setState({
                [valueName]: newCounter
            });
        }
    }

    handleOnKeyDown = (e, valueName) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            let newCounter = this.state[valueName];
            newCounter[e.target.name] = "";
            this.setState({
                [valueName]: newCounter
            });
        }
        if(e.keyCode>=48 && e.keyCode<=57){
            const value = Number(e.key);
            let newCounter = this.state[valueName];
            newCounter[e.target.name] = value;
            this.setState({
                [valueName]: newCounter
            });
        }
    }

    handleOnClickArrow = (action, valueName) => {
        let newCounter = this.state[valueName];
        const actionBool = action === 'plus';
        if (newCounter.firstCounter !== (actionBool ? 9 : 0)) {
            newCounter.firstCounter = actionBool ? newCounter.firstCounter + 1 : newCounter.firstCounter - 1;
        } else {
            if (newCounter.secondCounter !== (actionBool ? 9 : 0)) {
                newCounter.firstCounter = actionBool ? 0 : 9;
                newCounter.secondCounter = actionBool ? newCounter.secondCounter + 1 : newCounter.secondCounter - 1;
            } else {
                if (newCounter.thirdCounter !== (actionBool ? 9 : 0)) {
                    newCounter.secondCounter = actionBool ? 0 : 9;
                    newCounter.firstCounter = actionBool ? 0 : 9;
                    newCounter.thirdCounter = actionBool ? newCounter.thirdCounter + 1 : newCounter.thirdCounter - 1;
                }
            }
        }

        this.setState({
            [valueName]: newCounter
        });
    }

    handleCounterOnBlur = (valueName, name) => {
        let newCounter = this.state[valueName];
        if (!newCounter[name]) {
            newCounter[name] = 0;
        }

        this.setState({
            [valueName]: newCounter
        });
    }

    handleSelectOnChange = (value, formik) => {
        let newPerson = `${value.value}`;

        formik.setFieldValue('replacingPerson', value.name);
        this.setState({
            selectedReplacingPerson: newPerson
        });
    };

    handleChangeDate = ({ target }) => {
        const { value: date, name: valueState } = target;
        this.setState({
            [valueState]: date
        });
    }

    handleReplacementChecked = () => {
        this.setState(prevState => ({
            replacementChecked: !prevState.replacementChecked
        }), () => {
            this.props.onValidate(!this.state.replacementChecked || this.state.replacementChecked && this.state.isFormValid);
        });
    }

    validateForm = (data) => {
        let errors = {};

        if (!validateFunctions.isStringNotEmpty(data.dateFrom)) {
            errors.dateFrom = 'This field is required';
        }
        else if (!validateFunctions.isDateStringValid(data.dateFrom)) {
            errors.dateFrom = 'Date is invalid';
        }
        if (!validateFunctions.isStringNotEmpty(data.dateTo)) {
            errors.dateTo= 'This field is required';
        }
        else if (!validateFunctions.isDateStringValid(data.dateTo)) {
            errors.dateTo = 'Date is invalid';
        }
        if (!validateFunctions.validateDatesRange(data.dateFrom, data.dateTo)) {
            errors.dateFrom = 'Date from should not be larger than Date to';
        }
        if (!validateFunctions.isStringNotEmpty(data.replacingPerson) || !(/\S/.test(data.replacingPerson))) {
            errors.replacingPerson = 'This field is required';
        }

        this.setState({ isFormValid: Object.keys(errors).length === 0 },
            () => this.props.onValidate(!this.state.replacementChecked || this.state.replacementChecked && this.state.isFormValid)
        );

        return errors;
    }

    render() {
        const selectReplacingPerson = (formik) => {
            if(this.props.allSupervisor && this.props.supervisorSettings)
                return (
                    <SelectSearch 
                        name="supervisiors"
                        options={this.props.allSupervisor.filter(user => user.id !== 0)} 
                        value={this.state.selectedReplacingPerson}
                        onChange={e => this.handleSelectOnChange(e, formik)} />
                );
            
            return null;
        };

        const changeIconWhileSearching = () => {
            if (this.state.selectedReplacingPerson === "") {
                return (
                    <div className="arrow">
                        <div className="arrowPosition">
                            <ArrowDropDownIcon />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="clear">
                        <div className="clearPosition">
                            <ClearOutlined onClick={this.handleClearTheReplacementPerson} />
                        </div>
                    </div>
                );
            }
        };

        const inputsCounter = [
            {
                id: 1,
                text: EN.supervisiorSettings.inputCounter,
                textTooltip: EN.supervisiorSettings.tooltipCounter,
                valueName: 'counter',
                countFirst: {
                    value: this.state.counter.thirdCounter,
                    name: 'thirdCounter',
                    colorValue: this.state.counter.thirdCounter > 0,
                },
                countSecond: {
                    value: this.state.counter.secondCounter,
                    name: 'secondCounter',
                    colorValue: this.state.counter.secondCounter > 0 || this.state.counter.thirdCounter > 0,
                },
                countThird: {
                    value: this.state.counter.firstCounter,
                    name: 'firstCounter',
                    colorValue: this.state.counter.firstCounter > 0 || this.state.counter.secondCounter > 0 || this.state.counter.thirdCounter > 0,
                },
            },
            {
                id: 2,
                text: EN.supervisiorSettings.inputRaports,
                textTooltip: EN.supervisiorSettings.tooltipRaports,
                valueName: 'counterRaports',
                countFirst: {
                    value: this.state.counterRaports.thirdCounter,
                    name: 'thirdCounter',
                    colorValue: this.state.counterRaports.thirdCounter > 0,
                },
                countSecond: {
                    value: this.state.counterRaports.secondCounter,
                    name: 'secondCounter',
                    colorValue: this.state.counterRaports.secondCounter > 0 || this.state.counterRaports.thirdCounter > 0,
                },
                countThird: {
                    value: this.state.counterRaports.firstCounter,
                    name: 'firstCounter',
                    colorValue: this.state.counterRaports.firstCounter > 0 || this.state.counterRaports.secondCounter > 0 || this.state.counterRaports.thirdCounter > 0,
                },
            },
        ];

        const allCounters = inputsCounter.map(item => {
            return (
                <div className="col-12" key={item.id}>
                    <CountInputs
                        countFirst={item.countFirst}
                        countSecond={item.countSecond}
                        countThird={item.countThird}
                        valueName={item.valueName}
                        handleCounterOnChange={this.handleCounterOnChange}
                        handleOnKeyDown={this.handleOnKeyDown}
                        handleCounterOnBlur={this.handleCounterOnBlur}
                        handleOnClickArrow={this.handleOnClickArrow}
                        text={item.text}
                        textTooltip={item.textTooltip}
                    />
                </div>
            );
        });
        return (
            <Formik
                enableReinitialize
                initialValues={
                    {
                        dateFrom: this.state.dateFrom,
                        dateTo: this.state.dateTo,
                        replacingPerson: this.state.selectedReplacingPerson
                    }
                }
                validate={this.validateForm}
            >
                {(formik) => (
                    <form className="container marginTop">
                        <div className="row">
                            <div className="col-12">
                                <FormControlLabel
                                    id="switch"
                                    control={<Switch color="primary" />}
                                    label={EN.supervisiorSettings.manualAcceptHoliday}
                                    checked={this.state.supervisorSettings.supervisorHolidaysEnabled || false}
                                    onChange={this.handleHolidaysChange} />
                                <div className="infoIco">
                                    <div className="tooltip"><InfoIcon />
                                        <span className="tooltiptext">
                                            {EN.supervisiorSettings.tooltipSwitch}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <FormControlLabel
                                    id="switchManualTimesheetApproval"
                                    control={<Switch color="primary" />}
                                    label={EN.supervisiorSettings.enableManualTimesheetApproval}
                                    checked={this.state.supervisorSettings.supervisorManualTimesheetApprovalEnabled || false}
                                    onChange={this.handleManualTimesheetApprovalChange} />
                                <div className="infoIco">
                                    <div className="tooltip"><InfoIcon />
                                        <span className="tooltiptext">
                                            {EN.supervisiorSettings.switchManualTimesheetApproval}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {allCounters}
                            <div className="row">
                                <div className="col-sm-6 buttonClass">
                                    <TextField
                                        style={fieldStyle}
                                        InputProps={{
                                            endAdornment:
                                                (<InputAdornment>
                                                    <Button
                                                        onClick={() => this.props.postSlackTestMessage(this.state.supervisorSettings.supervisorSlackDayHolidayUrl)}
                                                    >
                                                        {EN.supervisiorSettings.testMessage}
                                                    </Button>
                                                </InputAdornment>)
                                        }}
                                        label={EN.supervisiorSettings.textSlack}
                                        value={this.state.supervisorSettings.supervisorSlackDayHolidayUrl || ''}
                                        onChange={this.handleSlackUrlChange} />
                                </div>
                                <div className="col-sm-6">
                                    <div className="buttonRightCreateUrl">
                                        <a rel="noopener noreferrer" href="https://slack.com/intl/en-pl/help/articles/115005265063-Incoming-WebHooks-for-Slack" target="_blank">
                                            <div className="buttonSlack">
                                                {EN.supervisiorSettings.createSlackUrl}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 buttonClass">
                                    <TextField
                                        style={fieldStyle}
                                        InputProps={{
                                            endAdornment:
                                                (<InputAdornment>
                                                    <Button
                                                        onClick={() => this.props.postTeamsTestMessage(this.state.supervisorSettings.supervisorMSTeamsDayHolidayUrl)}
                                                    >
                                                        {EN.supervisiorSettings.testMessage}
                                                    </Button>
                                                </InputAdornment>)
                                        }}
                                        label={EN.supervisiorSettings.textMSTeams}
                                        value={this.state.supervisorSettings.supervisorMSTeamsDayHolidayUrl || ''}
                                        onChange={this.handleMSTeamsUrlChange} />
                                </div>
                                <div className="col-sm-6">
                                    <div className="buttonRightCreateUrl">
                                        <a rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook" target="_blank">
                                            <div className="buttonSlack">
                                                {EN.supervisiorSettings.createMSTeamsUrl}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 replacementSection">

                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormControlLabel
                                            id="replacement"
                                            control={<Switch color="primary" />}
                                            label={EN.supervisiorSettings.replacement}
                                            checked={this.state.replacementChecked}
                                            onChange={this.handleReplacementChecked} />
                                    </div>
                                    {this.state.replacementChecked ?
                                    <div className="row">
                                        <div className="col-sm-4 postionRelative">
                                            <div className="relacingText">{EN.supervisiorSettings.replecingName}</div>
                                            {selectReplacingPerson(formik)}
                                            {changeIconWhileSearching()}
                                        </div>
                                        <div className="col-sm-4" style={{marginTop: 20}}>
                                            <FormDatePicker label="From*" name="dateFrom" onChange={e => this.handleChangeDate(e, formik)} />
                                        </div>
                                        <div className="col-sm-4" style={{marginTop: 20}}>
                                            <FormDatePicker label="To*" name="dateTo" onChange={e => this.handleChangeDate(e, formik)} />
                                        </div>
                                    </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

function onUpdate(prevProps, settings) {
    if (settings.props.submitForm && prevProps.submitForm != settings.props.submitForm) {
        const personValues = prevProps.allSupervisor.find(item => settings.state.selectedReplacingPerson === item.value);

        const data = {
            replacementDateFrom: new Date(settings.state.dateFrom).toISOString(),
            replacementDateTo: new Date(settings.state.dateTo).toISOString(),
            replacementPersonId: settings.state.selectedReplacingPerson ? personValues.id : null,
            replacementChecked: settings.state.replacementChecked
        };
        if (personValues) {
            prevProps.addSupervisiorFromSettings(personValues.id);
        }

        let newSettings = settings.state.supervisorSettings;
        const newWorktimeReminderDays = Number(`${settings.state.counter.thirdCounter}${settings.state.counter.secondCounter}${settings.state.counter.firstCounter}`);
        newSettings.supervisorWorktimeReminderDays = newWorktimeReminderDays;
        const addObject = {...newSettings, counter: settings.state.counter, counterRaports: settings.state.counterRaports, ...data};
        settings.props.updateSupervisorSettings(addObject);

        let newApplicationSettings = settings.state.applicationSettings;
        const newSettingValue = Number(`${settings.state.counterRaports.thirdCounter}${settings.state.counterRaports.secondCounter}${settings.state.counterRaports.firstCounter}`);
        newApplicationSettings[0].settingValue = `${newSettingValue}`;
        settings.props.updateSettingsValues(newApplicationSettings);
        settings.setState({ underlineStyle: settingsStyles.defaultUnderlineStyle});
    }
}

function mapStateToProps(state) {
    return {
        login: state.login,
        supervisorSettings: state.settings.supervisorSettings,
        counter: state.settings.counter,
        counterRaports: state.settingsValues.counterRaports,
        settingsValues: state.settingsValues.settingsValues,
        allSupervisor: state.settings.allSupervisor,
        selectedReplacingPerson: state.settings.selectedReplacingPerson
    };
}

const mapDispatchToProps = {
    fetchSupervisorSettings,
    updateSupervisorSettings,
    fetchSettingsValues,
    updateSettingsValues,
    postTeamsTestMessage,
    postSlackTestMessage,
    fetchAllSupervisors,
    addSupervisiorFromSettings
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(SupervisorSettings);
