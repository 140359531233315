import _ from 'lodash';
import { API } from './types';
import { Constants } from '../components/common/constants';

export const types = {
    ON_FETCH_HR_USER_HOLIDAYS: "ON_FETCH_HR_USER_HOLIDAYS",
    ON_HR_SOLVE_USER_HOLIDAY: "ON_HR_SOLVE_USER_HOLIDAY",
    ON_SHOW_HISTORY: "ON_SHOW_HISTORY",
};

const PAGE_SIZE = 10;

export function fetchHrUserHolidays(newPage, newOptions) {
    return (dispatch, getState) => {
        let { page, tableOptions} = getState().hrUserHolidays; 
        newPage = newPage || page;
        newOptions = newOptions || tableOptions;

        dispatch({type: API, payload: {
            url: 'hr/getHrUserHolidays',
            method: "GET",
            text: "Loading holidays HR table", 
            onSuccess: (response) => onfetchHrUserHolidays(response, newPage, newOptions), 
            params: {
                start: (newPage - 1) * PAGE_SIZE,
                count: PAGE_SIZE,
                ...newOptions
            }
        }});
    };
}

function onfetchHrUserHolidays(response, page, tableOptions) {
    return {type: types.ON_FETCH_HR_USER_HOLIDAYS, response, page, tableOptions};
}

export function hrSolveUserHoliday(id) {
    return (dispatch, getState) => {
        const state = getState();
        const hrUserHoliday= state.hrUserHolidays.tableData.filter(a=>{ return a.id===id;})[0]  ;
        dispatch({type: API, payload: {
            url: 'hr/hrSolveUserHoliday',
            method: "PUT",
            text: "Solving holiday",
            successText: "Holiday solved", 
            onSuccess: (response) => { 
                onHrSolveUserHoliday(response);
                this.fetchHrUserHolidays();
            },
            data: hrUserHoliday
        }});
    }; 
}

function onHrSolveUserHoliday(response) {
    return {type: types.ON_HR_SOLVE_USER_HOLIDAY, response};
}

export function hrRejectUserHoliday(userHolidaysId){
    return(dispatch) => {
        dispatch({
            type: API,
            payload: {
                url: 'hr/hrRejectUserHoliday',
                method: 'PUT',
                text: 'Rejecting request',
                successText: 'Request rejected',
                onSuccess: this.fetchHrUserHolidays,
                params: {
                    userHolidaysId
                }
            }
        });
    };
}

export function hrApproveUserHoliday(userHolidaysId, replacementPersonId){
    return(dispatch) => {
        dispatch({
            type: API,
            payload: {
                url: 'hr/hrApproveUserHoliday',
                method: 'PUT',
                text: 'Approving request',
                successText: 'Request approved',
                onSuccess: this.fetchHrUserHolidays,
                params: {
                    userHolidaysId,
                    replacementPersonId
                }
            }
        });
    };
}


export function showHistory(history) {
    return (dispatch) => { 
        dispatch(onShowHistory(history)); 
    };
}

function onShowHistory(history) {
    return {
        type: types.ON_SHOW_HISTORY,
        payload: {
            showHistory: history,
            page: 1
        }
    };
}

const initialState = {
    tableData:[],
    workerIds: [],
    tableIds: [],
    tableOptions: {
        employee: '',
        types: [],
        contractTypes: [],
        workerIds: [],
        statuses: [Constants.HolidayStatuses.ACCEPTED,Constants.HolidayStatuses.REJECTED, Constants.HolidayStatuses.NEW ],
        dateFrom: null,
        dateTo: null,
        showHistory: false,
    },
    page: 1,
    pageCount: 1,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
};

export function hrUserHolidays(state = initialState, action) {
    switch(action.type) {
        case types.ON_FETCH_HR_USER_HOLIDAYS:
            return {
                ...state,               
                tableIds: action.response.tableData.map(x => x.id),
                tableData: action.response.tableData,
                tableOptions: action.tableOptions,
                page: action.page,
                pageCount: Math.ceil((action.response.count) / PAGE_SIZE),
            };
        case types.ON_HR_SOLVE_USER_HOLIDAY:
            return {
                ...state        
            };
            case types.ON_SHOW_HISTORY:
                return {
                    ...state,
                    page: action.payload.page,
                    tableOptions: {
                        ...state.tableOptions, 
                        showHistory: action.payload.showHistory
                    }
                };          
        default:
            return state;
    }
}

