import React from 'react';
import PropTypes from 'prop-types';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import Preloader from './preloader/Preloader';
import {onLoadingStart,onLoadingEnd,onLoadingError} from '../store/Preloader';
//MDL
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; // v1.x
import V0MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
//Components
import NavigationBar from './navigationBar/NavigationBar';
import SideMenu from './sideMenu/SideMenu';
import FlashMessagesList from './flashMessagesList/FlashMessagesList';
import ContentWrapper from './contentWrapper/contentWrapper';
import { ConfirmDialogProvider } from './confirm/ConfirmDialog';
//styles
import 'react-tippy/dist/tippy.css';
import { darkTheme, themeOptions } from './common/styles';
import { withRouter } from "react-router-dom";
//MUI pickers
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Signalr from './common/Signalr';
import './app.scss';


const theme = createMuiTheme(themeOptions);

const v0muiTheme = getMuiTheme(darkTheme);

const propTypes = {
    login: PropTypes.object,
    preloader: PropTypes.object,
    sideMenu: PropTypes.object,
    children: PropTypes.object
};

class App extends React.Component {

    constructor(props){
        super(props);
    }

    renderWhenIsAuthenticated(){
        return(
            <div>
                <NavigationBar/>
                <SideMenu/>
            </div>
        );
    }
    render() {
        const {isAuthenticated} = this.props.login;
        const {loaders} = this.props.preloader;

        return (
            <MuiThemeProvider theme={theme}>
            <V0MuiThemeProvider muiTheme={v0muiTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
            <ConfirmDialogProvider>
                <div id="react-root" className="react-root-class">
                    <FlashMessagesList/>
                    {loaders != 0 ? <Preloader data={this.props.preloader}/>: null}
                    {isAuthenticated ? this.renderWhenIsAuthenticated() : null}
                    <ContentWrapper sideMenu={this.props.sideMenu}>
                        {this.props.children}
                    </ContentWrapper>
                    <Signalr/>
                </div>
            </ConfirmDialogProvider>
            </MuiPickersUtilsProvider>
            </V0MuiThemeProvider>
            </MuiThemeProvider>
        );
    }
}

App.propTypes = propTypes;

const mapStateToProps = (state) => ({
    login: state.login,
    preloader: state.preloader
});

function mapDispatchToProps(dispatch){
    return Redux.bindActionCreators({
        onLoadingStart,
        onLoadingEnd,
        onLoadingError
    }, dispatch);
}

export default withRouter(ReactRedux.connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
