import { host } from '../components/common/cfg';
import axios from 'axios';

const types = {
    SELECTED_ISSUE: 'SELECTED_ISSUE',
    SET_ISSUES_LIST: 'SET_ISSUES_LIST',
    GET_ISSUES_LIST: 'GET_ISSUES_LIST'
};

/** ACTIONS START */
export const onIssueSelect = (issue) => ({ type: types.SELECTED_ISSUE, issue });
export const onSetIssues = (issues) => ({ type: types.SET_ISSUES_LIST, issues });
export const onGetIssuesAjax = (projectId, startDate, endDate) => {
    return (dispatch) => {
        let headers = {
            // 'Content-Type': 'application/json;'
            // 'Accept': 'application/json',
        };

        let params = { projectId, startDate, endDate };
       
        return axios
            .get(`${host}/api/timesheet`, { params }, { headers })
            .then(response => {
                return response;
            });
    };
};
export const onAddIssueHoursAjax = (timeEntry, newAttachments) => {
    return (dispatch) => {

        let formData = new FormData();
        formData.append('issues', JSON.stringify([timeEntry]));
        for (let key of Object.keys(newAttachments)) {
            formData.append('files', newAttachments[key]);
        }

        return axios
            .put(`${host}/api/timesheet`, formData)
            .then(response => {              
                return response;
            });
    };
};
/** ACTIONS END */

// /** REDUCER START */
export const selectedIssue = (state = {}, action) => {
    let newState = null;

    switch (action.type) {

        case types.SELECTED_ISSUE:

            newState = action.issue;

            return Object.assign({}, state, newState);

        default:
            return state || {};
    }
};

export const issues = (state = [], action) => {
    switch (action.type) {

        case types.SET_ISSUES_LIST:
            return action.issues;

        default:
            return state;
    }

};
/** REDUCER END */
