import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

const WarningDialog = ({ open, onClose, content, buttonText, tmsLink }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogContent>
            {content}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} autoFocus>{buttonText}</Button>
            {tmsLink && <Button href="https://tms2.nexio.pl" color="primary" variant="contained">Przejdź do TMS 2.0</Button>}
        </DialogActions>
    </Dialog>
);

export default WarningDialog;
