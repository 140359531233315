import {host} from '../components/common/cfg';
import axios from 'axios';

const types = {
    SELECTED_PROJECT: 'SELECTED_PROJECT',
    SET_PROJECTS_LIST: 'SET_PROJECTS_LIST',
    GET_PROJECTS_LIST: 'GET_PROJECTS_LIST'
};

/** ACTIONS START */
export const onProjectSelect = (selectedProject) => ({type: types.SELECTED_PROJECT, selectedProject});
export const onSetProjects = (projects) => ({type: types.SET_PROJECTS_LIST, projects});
export const onGetProjects = (month, year) => {
    return (dispatch) => {
        let headers = {
            'headers': {
                'Content-Type': 'application/json;',
                // 'Accept': 'application/json',
            },
            params: { month: month,
                year: year
            }
        };

        return axios
            .get(`${host}/api/project`, headers)
            .then(response => {
                return response;
            });
    };
};
/** ACTIONS END */

// /** REDUCER START */
export const selectedProject = (state = {}, action) => {
    switch (action.type) {

        case types.SELECTED_PROJECT:
            return action.selectedProject;

        default:
            return state || {};
    }
};

export const projects = (state = [], action) => {
    switch (action.type) {
        
        case types.SET_PROJECTS_LIST:

            return action.projects;

        default:
            return state || [];
    }

};
/** REDUCER END */
