import React from 'react';
import axios from 'axios';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from 'material-ui/IconButton';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import {colors} from '../../common/styles';
import { saveAs } from 'file-saver';
import {host} from '../../common/cfg';
import {httpReqErrorHandler} from '../../common/helpers/errorHandler';
import {onAddFlashMessage} from '../../../store/FlashMessage';
import {onLoadingStart, onLoadingEnd, onLoadingError} from '../../../store/Preloader';

const extractFileName = (contentDispositionValue) => {
    let filename = "file";
    const match = contentDispositionValue.replace(/.*filename="*/,"").replace(/"/,"");
    if(match)
        return match;
    return filename;
};

const labelStyle={
    fontSize:"0.8em",
    width:'146px',
    height: '36px',
    opacity: 1,
    top: 0
};

const style={
    display: 'inline-block',
};

class ExportToExcel extends React.PureComponent{
  getExcel = (url) => {
    this.props.onLoadingStart("Generating report");
    return axios
      .post(`${host}${url.url}`, url.generateExcelHelper, {
        responseType: "blob",
      })
      .then((response) => {
        let filename = extractFileName(response.headers["content-disposition"]);
        this.props.onLoadingEnd();
        saveAs(response.data, filename);
      })
      .catch((reject) => {
        this.props.onLoadingError(
          reject.response ? reject.response.data.error : reject
        );
        this.props.onAddFlashMessage(httpReqErrorHandler(reject));
      });
  };


    render(){
        const props = this.props;
        return (
            <div style={style}>
            <IconButton className="control controls-button" tooltip="Download" onClick={() => this.getExcel(props.getUrl())}>
                <DownloadIcon />
            </IconButton>
            {
                !!(props.filesData && props.filesData.length) &&
                <DropDownMenu
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                    className="control file-btn"
                    value={props.selectedFileIndex}
                    onChange={(evt, index) => props.onFileChange(index)}
                    labelStyle={labelStyle}
                    underlineStyle={{border:'none'}}
                    menuItemStyle={{fontSize:"0.8em"}}
                    selectedMenuItemStyle={{color: colors.nexioOrange}}
                    >
                    {props.filesData.map( (file, i)=> {
                        return <MenuItem key={"file_"+ file.id} value={i} primaryText={file.fileName}/>;
                    })}
                </DropDownMenu>
            }
        </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onAddFlashMessage,
        onLoadingStart,
        onLoadingEnd,
        onLoadingError
    }, dispatch);
}

export default ReactRedux.connect(
    null,
    mapDispatchToProps
)(ExportToExcel);
