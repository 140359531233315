import PropTypes from 'prop-types';
import {colors} from '../../common/styles.js';
import { black, white } from 'material-ui/styles/colors';

const taskDialogStyles = {
    buttonStyle:{
        color: colors.nexioOrange
    },
    buttonDeleteStyle:{
        color: 'white',
        backgroundColor: 'rgb(244, 67, 54)',
        float: 'left'
    },

    textAreaStyle: {
        nameOfPricing: {
            display:'flex',
            paddingTop: '20px',
            marginBottom: '-10px',
            color: colors.nexioOrange
        },
        pricingInsertRow: {
            display: 'flex'
        },
        fieldJiraNum: {
            width: 150,
            marginRight: '20px'
        },
        fieldJiraName: {
            width: 450
        },
        fieldRedmineTask:{
            marginRight: '20px',
            width: 150
        },
        fieldEmployeeName: {
            width: 200,
            marginRight: '10px'
        },
        fieldPricingCount: {
            width: 100,
            marginRight: '20px'
        },
        field: {
            width: 624
        },
        floatingLabelFocusStyle: {
            fontSize: 12,
            fontWeight: 'normal',
            color: 'white'
        },
        underlineStyle: {
            borderColor: colors.nexioOrange
        },
        developRealizatorDivStyle: {
            marginTop: '10px'
        },
        teamDropDownBoxDivStyle: {
            marginTop: '33px',
            marginLeft: '15px'
        },
        developRealizatorLabelStyle: {
            color:white,
            fontSize: '9px',
            fontWeight: 'normal'
        },
        developRealizatorListStyle: {
            marginTop: '-18px',
            marginLeft: '-20px'
        }
    }
};

export default taskDialogStyles;