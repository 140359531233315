import React from 'react';
import logoNexio from '../../assets/logo_nexio.svg';
import logoMicrosec from '../../assets/Microsec-Logo.png';
import { company } from '../common/cfg';
import './logo.scss';

export const Logo = () => {
    return (
        (company === "Nexio") ?
            <div className="login-logo logo-container">
                <h2>NEXIO TimeSheet Management</h2>
                <img src={logoNexio} />
            </div>
            :
            <div className="microsec-logo col col-md-8"><img src={logoMicrosec} width="400px" /></div>
    );
};

export default Logo;