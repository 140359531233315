import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { components } from "react-select";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    )
  );
};

export default DropdownIndicator;
