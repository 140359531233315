/*React Libs*/
import React from 'react';
import PropTypes from 'prop-types';
/*Material*/
import Drawer from 'material-ui/Drawer';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import IconButton from 'material-ui/IconButton';
import AutoComplete from 'material-ui/AutoComplete';
/*Icons*/
import SearchIcon from '@material-ui/icons/Search';
/*Styles*/
import pricingFilterStyles from './pricingFilterStyles';
/*Translations*/
import { EN } from '../../common/translations';
const propTypes = {
    openFilter:PropTypes.bool,
    searchData:PropTypes.object,
    searchFieldChange: PropTypes.func.isRequired,
    onSearchButtonClick: PropTypes.func.isRequired
};

const defaultProps = {
};


class PricingFilter extends React.Component {
    constructor(props) {
        super(props);
    }
    render(){
        return(
            <Drawer width={300} openSecondary open={this.props.openFilter} containerStyle={{top:'95px', height: 'calc(100% - 95px)'}} className="pricingFilter">
                <h5 style={{textAlign:'center', color:'white'}}>Filter</h5>
                <TextField  defaultValue={this.props.searchData.jiraTaskId}
                            onChange={(event, value) => this.props.searchFieldChange('jiraTaskId', value)}
                            floatingLabelStyle={pricingFilterStyles.floatingLabelFocusStyle}
                            floatingLabelText = {EN.pricingPage.jiraCol}
                            style={pricingFilterStyles.textField}
                            underlineFocusStyle={pricingFilterStyles.underlineStyle}/>
                <TextField  defaultValue={this.props.searchData.redmineTaskId}
                            onChange={(event, value) => this.props.searchFieldChange('redmineTaskId', value)}
                            floatingLabelStyle={pricingFilterStyles.floatingLabelFocusStyle}
                            floatingLabelText = {EN.pricingPage.redmineTaskIdCol}
                            style={pricingFilterStyles.textField}
                            underlineFocusStyle={pricingFilterStyles.underlineStyle}/>
                <AutoComplete   searchText={this.props.searchData.analyst}
                                onUpdateInput={(searchText) => this.props.searchFieldChange('analyst', searchText)}
                                filter={AutoComplete.caseInsensitiveFilter}
                                dataSource = {this.props.analystList}
                                floatingLabelStyle={pricingFilterStyles.floatingLabelFocusStyle}
                                floatingLabelText = {EN.pricingPage.analystCol}
                                style={pricingFilterStyles.textField}
                                underlineFocusStyle={pricingFilterStyles.underlineStyle}/>
                <AutoComplete   searchText={this.props.searchData.developEstimator}
                                onUpdateInput={(searchText) => this.props.searchFieldChange('developEstimator', searchText)}
                                filter={AutoComplete.caseInsensitiveFilter}
                                dataSource = {this.props.developerList}
                                floatingLabelStyle={pricingFilterStyles.floatingLabelFocusStyle}
                                floatingLabelText = {EN.pricingPage.estimationsDeveloperCol}
                                style={pricingFilterStyles.textField}
                                underlineFocusStyle={pricingFilterStyles.underlineStyle}
                                menuStyle={{ overflow: 'scroll', height: '250px' }}/>
                <AutoComplete   searchText={this.props.searchData.developRealizator}
                                onUpdateInput={(searchText) => this.props.searchFieldChange('developRealizator', searchText)}
                                filter={AutoComplete.caseInsensitiveFilter}
                                dataSource = {this.props.developerList}
                                floatingLabelStyle={pricingFilterStyles.floatingLabelFocusStyle}
                                floatingLabelText = {EN.pricingPage.realizationsDeveloperCol}
                                style={pricingFilterStyles.textField}
                                underlineFocusStyle={pricingFilterStyles.underlineStyle}/>
                <Toggle defaultToggled={this.props.searchData.onlyNotSolved}
                        onToggle={(event, isInputChecked) => this.props.searchFieldChange('onlyNotSolved', isInputChecked)}
                        label="Only not solved"
                        labelPosition="right"
                        labelStyle={pricingFilterStyles.toggleLabel}
                        style={pricingFilterStyles.toggle}
                        thumbStyle={pricingFilterStyles.thumbOff}
                        trackStyle={pricingFilterStyles.trackOff}
                        thumbSwitchedStyle={pricingFilterStyles.thumbSwitched}
                        trackSwitchedStyle={pricingFilterStyles.trackSwitched}/>
                <Toggle defaultToggled={this.props.searchData.onlyNotEstimated}
                        onToggle={(event, isInputChecked) => this.props.searchFieldChange('onlyNotEstimated', isInputChecked)}
                        label="Only not estimated"
                        labelPosition="right"
                        labelStyle={pricingFilterStyles.toggleLabel}
                        style={pricingFilterStyles.toggle}
                        thumbStyle={pricingFilterStyles.thumbOff}
                        trackStyle={pricingFilterStyles.trackOff}
                        thumbSwitchedStyle={pricingFilterStyles.thumbSwitched}
                        trackSwitchedStyle={pricingFilterStyles.trackSwitched}/>
                <IconButton style={pricingFilterStyles.largeIcon} onClick={this.props.onSearchButtonClick}><SearchIcon/></IconButton >
                <button style={pricingFilterStyles.cancleButton} onClick={this.props.onCloseButtonClick}>Close</button>
            </Drawer>
        );
    }
}

PricingFilter.propTypes = propTypes;
PricingFilter.defaultProps = defaultProps;

export default PricingFilter;