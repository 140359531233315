let host;
let identityServer;
let tmsClient;
let isDemo;
let intranetClient;

const environments = {
    PROD: "production",
    DEV: "development",
    LOCAL: "none"
};

switch (process.env.NODE_ENV) {
    //prod
    case environments.LOCAL:
    case environments.PROD:
        host = "https://tmsnexioapi-eu.azurewebsites.net";
        identityServer = 'https://nexioidentityserver.azurewebsites.net';
        intranetClient = 'https://intranet.nexio.pl';
        tmsClient =  'https://tms.nexio.pl';
        isDemo = false;
        break;

    //dev
    case environments.DEV:
        host = "https://tmsapi-dev.azurewebsites.net";
        identityServer = 'https://nexioidentityserver-dev.azurewebsites.net';
        intranetClient = 'https://nexiointranet-dev.azurewebsites.net';
        tmsClient =  'https://nexiotms-dev.azurewebsites.net';
        isDemo = true;
        break;

    //local
    /*case environments.LOCAL:*/
    default:
        host = "http://localhost:49824";
        identityServer = 'http://localhost:63315';
        intranetClient = 'http://localhost:8080';
        tmsClient = 'http://localhost:3000';
        isDemo = true;
        break;
}

//Identity Server i OICD
export const authorityCfg = `${identityServer}`;
export const clientId = 'NexioTMS';
export const redirectUri = `${tmsClient}/signed-in`;
export const postLogoutRedirectUri = `${tmsClient}`;
export const responseTypeToken = "token";
export const responseTypeIdToken = "id_token";
export const scopeOpenId = 'openid';
export const scopeProfile = 'profile';
export const scopeApiTms = 'apiTms';
export const loadUserInfo = true;
export const monitorSession = false;
export const sessionStorageKey = `oidc.user:${authorityCfg}:${clientId}`;
export const timesheetProposalsStartDate= new Date(2020, 5, 1);

export const hrCfg = {
    maxHolidays: 20,
    maxOnDemand: 4
};

export const redmineUrl = 'http://redmine.nexio.pl';

export const company = "Nexio"; // Microsec v Nexio

export {
    host,
    identityServer,
    tmsClient,
    isDemo,
    intranetClient,
};

export const apiExcelsPackFromSettledEmployees = '/api/teamTimesheet/excelsPackFromSettledEmployees';
export const apiExcelPerApplication = '/api/teamTimesheet/excelPerApplication';