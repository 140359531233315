import React, { useEffect, useState, useCallback, useRef, memo, useMemo } from 'react';
import { connect } from 'react-redux';

import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import RootRef from '@material-ui/core/RootRef';
import Typography from '@material-ui/core/Typography';
import Button from './TeamButton';

import Add from '@material-ui/icons/Add';

import { fetchTeam, updateTeam, setMailDialogOpen } from '../../store/Teams';
import { Constants } from '../common/constants';
import AddUserButton from './AddUserButton';
import AdminButtons from './AdminButtons';
import SendMailDialog from './SendMailDialog';
import {EN} from '../common/translations';


const getChips = (ids, onRemove) => {
    return ids.map((x) => (<Chip 
        key={x}
        label={Constants.TeamRolesTextMapping[x]} 
        onDelete={() => onRemove(x)}
        color={x == Constants.TeamRoles.teamLeader ? "primary": "default"}
        />));
};

const transitionProps = {
    mountOnEnter: true,
    unmountOnExit: true
};

const AddMenu = ({open, onSelect, onClose, anchorEl, selected}) => {
    const elements = useMemo(() => 
        Object.keys(Constants.TeamRolesTextMapping).filter(x => !selected.includes(+x)),
        [selected]);
    return (
        <Menu TransitionProps={transitionProps} open={open} onClose={onClose} anchorEl={anchorEl}>
            {elements.map((x) => (
                <MenuItem key={x} onClick={() => onSelect(x)}>
                    {Constants.TeamRolesTextMapping[x]}
                </MenuItem>))}
        </Menu>);
};
const teamRoleCount = Object.keys(Constants.TeamRolesTextMapping).length;

let UserRow = ({user, idx, setUser, openMenu}) => {
    const onRemove = useCallback((id) => {
        const userTeamRoles = user.userTeamRoles.filter(x => x != id);
        setUser({...user, userTeamRoles});
    }, [user, setUser]);
    const addChipRef = useRef();
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenuSelect = useCallback((id) => {
        user.userTeamRoles.includes(id) || 
            setUser({...user, userTeamRoles: [...user.userTeamRoles, +id]});
    }, [user, setUser, setMenuOpen]);
    const handleAddClick = useCallback(() => openMenu({ onSelect: handleMenuSelect, ref: addChipRef, user: user})
        , [setMenuOpen, handleMenuSelect, user, addChipRef]);

    return (
        <ListItem>
            <ListItemText>
                <Grid container >
                    <Grid item sm={6} md={3} >
                        <Typography color={user.userTeamRoles.length > 0 ? "default" : "error"}>
                            {user.firstname} {user.lastname}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} md={9} >
                        {getChips(user.userTeamRoles, onRemove)}
                        {teamRoleCount > user.userTeamRoles.length ?
                            <RootRef rootRef={addChipRef}>
                                <Chip 
                                    icon={<Add/>} 
                                    clickable 
                                    variant="outlined" 
                                    label={EN.teamManagement.addRole}
                                    onClick={handleAddClick} />
                            </RootRef> :
                            null}
                    </Grid>
                </Grid>
            </ListItemText>
        </ListItem>);
};
UserRow = memo(UserRow);

let TeamList = ({ users, setUser, openMenu }) => (
    <List>
        {users && users.map((u) => <UserRow key={u.userId} setUser={setUser} user={u} openMenu={openMenu}/>)}
    </List>
);
TeamList = memo(TeamList);

const TeamManagement = (props) => {

    //Users management
    const [users, setUsers] = useState(props.users);
    const usersRef = useRef(users);
    usersRef.current = users;
    useEffect(() => setUsers(props.users), [props.users]);
    useEffect(() => props.id && props.fetchTeam(props.id), [props.id]);
    const setUser = useCallback((user) => {
        let idx = usersRef.current.findIndex(x => x.userId == user.userId);
        if (idx == -1) {
            idx = usersRef.current.length;
        }
        setUsers([
            ...usersRef.current.slice(0, idx), 
            user, 
            ...usersRef.current.slice(idx + 1)
        ]);
    }, [usersRef, setUsers]);

    //Menu management, one for all rows to save on rendering
    const [menuOptions, setMenuOptions] = useState({ 
        ref: { current: undefined }, 
        onSelect: () => undefined, 
        user: { userTeamRoles: [] }
    });
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenuClose = useCallback(() => setMenuOpen(false), [setMenuOpen]);
    const handleMenuSelect = useCallback((id) => { 
        menuOptions.onSelect(id); 
        setMenuOpen(false); 
    }, [menuOptions, setMenuOpen]);
    const openMenu = useCallback((menuOptions) => { 
        setMenuOptions(menuOptions); 
        setMenuOpen(true);
    }, []);

    return (
        <>
            <Button 
                variant="contained" 
                color="primary" 
                disabled={props.users === users}
                onClick={() => props.updateTeam(props.id, users)}
            >
                {EN.common.save}
            </Button>
            <AddMenu 
                anchorEl={menuOptions.ref.current}
                open={menuOpen} 
                onClose={handleMenuClose} 
                onSelect={handleMenuSelect}
                selected={menuOptions.user.userTeamRoles} />
            <AddUserButton users={users} setUsers={setUsers}/>
            <Button variant="outlined" onClick={() => setUsers(users.map(u => ({ ...u, userTeamRoles: [] })))}>
                {EN.teamManagement.removeAllRoles}
            </Button>
            <Button variant="outlined" onClick={() => setUsers(props.users)} disabled={users==props.users}>
                {EN.teamManagement.reset}
            </Button>
            <Button variant="outlined" onClick={props.openMailDialog}>
                Send mail to team members
            </Button>

            {props.isAdmin && <AdminButtons />}
            {users !== props.users && <Typography color="error">{EN.teamManagement.unsavedChangesInfo}</Typography>}
            <TeamList users={users} setUser={setUser} openMenu={openMenu} />
            <SendMailDialog/>
        </>);
};

const mapStateToProps = (state) => ({
    users: state.teams.teamUsers[state.teams.selectedTeam],
    id: state.teams.selectedTeam,
    isAdmin: state.login.user.roles.includes(Constants.Roles.admin)
});


const mapDispatchToProps = {
    fetchTeam,
    updateTeam,
    openMailDialog: () => setMailDialogOpen(true),
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagement);