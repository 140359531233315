import {EN} from '../translations';

export const httpReqErrorHandler = (error) => {
    if (!error.response) {
        // Exception in promise, log it like normal uncaught exception
        // eslint-disable-next-line no-console
        console.error(error);
        return {type: 'error', text: error};
    }
    
    switch(error.response.status)
    {
        case 401:
            if(error.response.data.error.toLowerCase() === "username" || error.response.data.error.toLowerCase() === "password" )
                return {type: 'error', text: EN.flashMessages.loginError};
            return {type: 'error', text: EN.flashMessages.unathorizedError};
        case 403:
            return {type: 'error', text: EN.general.forbidden};
        case 404:
            return {type: 'error', text: EN.general.serverNotFound};
        case 422:
            return {type: 'error', text: EN.general.validationError};
        case 500:
        case 400:
            return {
                type: 'error', 
                text: error.response.data.description ? error.response.data.description : error.response.data
        };
        default:
            return {type: 'error', text: error};
    }
};
