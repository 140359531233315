import React from 'react';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import _ from 'lodash';
import { CornerHeader } from './CornerHeader';
import { setupScrollableTable, getDetailsTableRows, displayProjName } from '../helpers';

export class DetailsTable extends React.PureComponent {
    componentDidMount() {
        setupScrollableTable(this, '.details-sync-scroll-base', '.details-sync-scroll-follow');
    }

    getBodyStyle = () => {
        let result = { height: '350px', overflow: 'auto' };
        if (this.props.shorten) {
            result.height = '65px';
        }
        return result;
    }

    getProjectDetailsNameProps = (tooltipText) => {
        const props = {
            title: tooltipText || ""
        };

        if (!this.props.shorten) {
            props.className = "singleProjectName";
        }
        return props;
    }

    render() {
        const props = this.props;
        return (
            <Table
                className="table table-header-rotated"
                wrapperStyle={{ overflow: 'visible' }}
                fixedHeader
                bodyStyle={this.getBodyStyle()}
                headerStyle={{ overflow: 'hidden' }}
            >
                <TableHeader
                    displaySelectAll={false}
                    adjustForCheckbox={false}
                    className="details-sync-scroll-follow"
                    style={{ marginTop: "50px" }}>
                    <TableRow>
                        <TableHeaderColumn className="report-table-corner-header">
                            <CornerHeader />
                        </TableHeaderColumn>
                        {
                            props.data.headers.map((x, i) => (
                                <TableHeaderColumn key={`table_header_${i}`}
                                    className="rotate-45 child dialog-table-cell-header">
                                    <div>
                                        <span {...this.getProjectDetailsNameProps(x)}>
                                            {displayProjName(x,21)}
                                        </span>
                                    </div>
                                </TableHeaderColumn>
                            ))
                        }
                    </TableRow>
                </TableHeader>
                <TableBody className="details-sync-scroll-base"
                    displayRowCheckbox={false}>
                    {
                        props.data.rows.map((row, i) => (
                            <TableRow key={`table_row_${i}`} hoverable selectable={false} >
                                {getDetailsTableRows(row, i, this.props.unit)}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        );
    }
}