import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { EN } from "../common/translations";
import { colors } from "../common/styles";

const textAreaStyle = {
  field: {
    width: 450,
  },
  floatingLabelFocusStyle: {
    fontSize: 12,
    fontWeight: "normal",
    color: "white",
  },
  underlineStyle: {
    borderColor: colors.nexioOrange,
  },
};

const MailDialog = ({ onCloseClick, onSubmitClick, mail, isOpen }) => {
  const [editMail, setEditMail] = useState(mail);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(()=>{
    setEditMail(mail);
  }, [mail]);

  useEffect(()=>{
    setErrorMessage(null);
  }, [editMail]);

  const isMailValid = (mail) => {
    // validate for _@_._
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail);
  };

  const handleSubmit = () => {
    if (!editMail || !isMailValid(editMail)) {
      setErrorMessage(EN.whitelist.invalidEmail);
    } else {
      setErrorMessage(null);
      onSubmitClick(editMail);
    }
  };

  const handleMailChange = (event, text) => {
    setEditMail(text);
    setErrorMessage(null);
  };

  const actions = [
    <FlatButton key="close" label="Close" primary onClick={onCloseClick} />,
    <FlatButton key="submit" label="Submit" primary onClick={handleSubmit} />,
  ];

  return (
    <Dialog
      title={EN.whitelist.dialogTitle}
      actions={actions}
      modal={false}
      open={isOpen}
      contentStyle={{
        maxWidth: "512px",
      }}
      bodyStyle={{ paddingBottom: "0px" }}
    >
      <TextField
        autoFocus
        defaultValue={mail}
        onChange={handleMailChange}
        floatingLabelText={EN.whitelist.mailHint}
        style={textAreaStyle.field}
        floatingLabelStyle={textAreaStyle.floatingLabelFocusStyle}
        underlineFocusStyle={textAreaStyle.underlineStyle}
        errorText={errorMessage}
        error={errorMessage ? 1 : 0}
      />
    </Dialog>
  );
};

MailDialog.propTypes = {
  onCloseClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  isOpen: PropTypes.bool,
  mail: PropTypes.string,
};

export default MailDialog;
