import { Constants } from '../../../common/constants';

export const getHolidayColor = (type, status, highlighted) => {
    return holidayColors[type] && holidayColors[type][status]
        ? holidayColors[type][status][highlighted]
        : "#ffffff";
};

const makeColor = (hue) => ({
    [Constants.HolidayStatuses.ACCEPTED]: {
        "true": `hsl(${hue}, 100%, 55%)`,
        "false": `hsl(${hue}, 100%, 35%)`
    },
    [Constants.HolidayStatuses.NEW]: {
        "true": `hsl(${hue}, 100%, 50%)`,
        "false": `hsl(${hue}, 70%, 75%)`
    }
});

export const holidayColors = {
    [Constants.HolidayTypes.HOLIDAY]:
        makeColor(240),
    [Constants.HolidayTypes.REMOTE]:
        makeColor(60),
    [Constants.HolidayTypes.SICK_LEAVE]:
        makeColor(120),
    [Constants.HolidayTypes.OTHERS]:
        makeColor(300),
    [Constants.HolidayTypes.UNPAID_HOLIDAY]:
        makeColor(20)
};