import _ from 'lodash';

export function calculateTopBarHeight(state) {
    return state.flashMessages.length * 74 + 64 + (state.notificationStore.unreadNotifications.length > 0 ? 2 : 0);
}

export function addObjectsWithCheck(original, newTable, idField = "id") {
    let ret = {};
    let changed = false;
    for(let el of newTable) {
        if (_.isEqual(el, original[el[idField]]))
            continue;
        ret[el[idField]] = el;
        changed = true;
    }
    return changed ? { ...original, ...ret } : original;
}
