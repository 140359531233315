import React, {useRef} from 'react';
import Clear from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const ClearAdornment = ({onClick}) => (
    <InputAdornment>
        <IconButton onClick={onClick} tabIndex="-1">
            <Clear color="disabled"/>
        </IconButton>
    </InputAdornment>
);

export default ClearAdornment;