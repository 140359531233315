import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { fetchTeams, selectTeam } from '../../store/Teams';
import Select from '../common/controls/Select';

const selectStyle = {
    visibility: "hidden"
};

const TeamSelect = ({ selectedTeam, availableTeams, fetchTeams, selectTeam, teams, canSelectTeam }) => {
    const [open, setOpen] = useState(false);
    const canChoose = useMemo(() => availableTeams && availableTeams.length > 1, [availableTeams]);
    const handleClick = useCallback(() => canChoose && setOpen(true), [canChoose]);
    const handleChange = useCallback((e) => {
        selectTeam(e.target.value);
        setOpen(false);
    }, [selectTeam]);
    const handleClose = useCallback(() => setOpen(false), []);

    const options = useMemo(() => availableTeams.map(x => teams[x]).filter(x => x),
        [availableTeams, teams]);
    useEffect(() => { canSelectTeam && fetchTeams(); }, [canSelectTeam]);

    if (!availableTeams || !selectedTeam || !canSelectTeam || !teams[selectedTeam])
        return null;
    return (
        <>
            <div className="nav-project">
                <Select onChange={handleChange} value={selectedTeam} open={open} onClose={handleClose} onOpen={handleClick}>
                    {options.map((o) => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
                </Select>
            </div>
        </>);
};


const mapStateToProps = (state) => ({
    selectedTeam: state.teams.selectedTeam,
    availableTeams: state.teams.availableTeamIds || state.teams.ids,
    teams: state.teams.teams,
    canSelectTeam: state.teams.canSelectTeam
});

const mapDispatchToProps = {
    fetchTeams,
    selectTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSelect);