import React from 'react';
import { Chip } from 'material-ui';

const style = {
  labelStyle: {
    fontSize: '9px',
    lineHeight: '18px',
    paddingLeft: '9px',
    paddingRight: '9px'
  }, containerStyle: {
    backgroundColor: '#FF9900'
  }
};


const OvertimeChip = (props) => (
  <Chip className={props.className} labelStyle={style.labelStyle} style={style.containerStyle}>
    Overtime
  </Chip>
);

export default OvertimeChip;