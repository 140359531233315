import React from 'react';
import PropTypes from 'prop-types';
import AttachmentIcon from '@material-ui/icons/Attachment';

const attachmentIcon = ({ holidayRequest, text }) => {
    const isAnyAttachment = !!(holidayRequest.attachments || []).length;
    const textFunc = "function" === typeof text ? text(holidayRequest) : null;
    if (isAnyAttachment)
        return (
            <>
                <AttachmentIcon style={{ position: 'absolute', transform: 'translate(-115%, -15%)' }} />
                {textFunc}
            </>
        );

    return textFunc;
};

attachmentIcon.propTypes = {
    holidayRequest: PropTypes.object.isRequired, //must be an instance of userHolidays
    text: PropTypes.func // func to display additional text, as a parameter takes holidayRequest object
};

export default attachmentIcon;