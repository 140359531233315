import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Components
import FooterMonth from './Footer/FooterMonth';
import Comments from './comments/Comments';
//3rd lib
import moment from 'moment';
import _ from 'lodash';
import memoize from 'memoize-one';

import { EN } from '../../../common/translations';
import { dateFormat } from '../../../common/helpers/date';
import { Constants } from '../../../common/constants';

const translations = EN.timesheetPage.calendarPanel;

const propTypes = {
    selectedMonth: PropTypes.number, 
    selectedYear: PropTypes.number,
    issues: PropTypes.array,
    selectedProject: PropTypes.object,
    onDayClicked: PropTypes.func
    //   classes: PropTypes.string,
    //   dayHovered: PropTypes.func.isRequired,
    //   day: momentObj
};

const defaultProps = {
    selectedMonth: undefined, 
    selectedYear: undefined,
    issues: undefined,
    selectedProject: undefined,
    onDayClicked: undefined
};

class CalendarFooter extends React.Component {

    constructor(props){
        super(props);
        this.getTotalHours = memoize(this.getTotalHours);
    }

    getTotalHours(month, year, issues) {
        const daylyHours = [];
        for (let issue of issues) {
            for (let timesheet of issue.timesheetRows) {
                let date = moment(timesheet.date, dateFormat).date();
                daylyHours[date] = (daylyHours[date] || 0) + timesheet.hours;
            }
        }
        const weeklyHours = [];
        let totalHours = 0;
        let weeklyTotal = 0;
        for (let d = moment({year, month, day: 1}); d.month() === month; d.add(1, 'days')) {
            let hours = daylyHours[d.date()] || 0;
            totalHours += hours;
            weeklyTotal += hours;
            if (d.day() === 0) {
                weeklyHours[d.date()] = weeklyTotal;
                weeklyTotal = 0;
            }
        }
        let totalHoursArray = [];
        totalHoursArray[new Date(year, month + 1, 0).getDate()] = totalHours;
        return { daylyHours, weeklyHours, totalHours: totalHoursArray };
    }

    render(){
        const {
            selectedMonth, 
            selectedYear, 
            selectedProject,
            issues,
            grouppedIssues,
            isTeamTimesheet
        } = this.props;

        //for properly calculate of all issues in grouped All Issues Tab
        let issuesToCalc = issues;
        if(selectedProject.id === Constants.Issues.allProjectsId && grouppedIssues.length)
        {
            const reducer = (prev, current) => {
                prev.push(...current.issues);
                return prev;
            };
            issuesToCalc = grouppedIssues.reduce(reducer, []);
        }

        const {
            daylyHours,
            weeklyHours,
            totalHours
        } = this.getTotalHours(selectedMonth, selectedYear, issuesToCalc);

        const renderRows = () => {
            let monthProps = {
                selectedMonth, 
                selectedYear
            };

            let dailyMonthProps = {
                selectedMonth, 
                selectedYear,
                isTeamTimesheet,
            };
            return (
                <div>
                    <FooterMonth key={_.uniqueId("daily_hours_")} totalHours ={daylyHours} {...dailyMonthProps} isDailyFooter/>
                    <div className="summary-label">{translations.dailyHoursLabel}</div>
                    <FooterMonth key={_.uniqueId("weekly_hours_")} totalHours ={weeklyHours} {...monthProps}/>
                    <div className="summary-label">{translations.weeklyHoursLabel}</div>
                    <FooterMonth key={_.uniqueId("monthly_hours_")} totalHours ={totalHours} {...monthProps}/>
                    <div className="summary-label">{translations.monthlyHoursLabel}</div>
                    <Comments/>
                    <div className="summary-label">{translations.comments}</div>
                </div>
            );
        };

        return(
            <div className="calendar-footer">
                   {renderRows()}
            </div>
        );
    }
}

CalendarFooter.propTypes = propTypes;
CalendarFooter.defaultProps = defaultProps;

export default CalendarFooter;