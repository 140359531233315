/*React Libs*/
import PropTypes from 'prop-types';
import React from 'react';

//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
//helpers
import taskDialogValidation from './helpers/taskDialogValidation';
/*Store*/
import { onLoadingStart, onLoadingEnd, onLoadingError } from '../../store/Preloader';
import { onAddFlashMessage, onClearFlashMessages } from '../../store/FlashMessage';
import { onGetEmployees, onSaveEmployee, onChangeEmployeeSupervisor, onGetUpdateChanges, onGetSupervisors, onGetCompanies } from '../../store/Employees';
import { onLoginRequest } from '../../store/Login';
/*Material*/
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';
import Grid from '@material-ui/core/Grid';
//Common
import { colors } from '../common/styles.js';
import { EN } from '../common/translations';
import { Constants } from '../common/constants';
import { httpReqErrorHandler } from '../common/helpers/errorHandler';
//Custom
import UserEditDialog from './Dialogs/UserEditDialog/UserEditDialog';
import ChangeSupervisorDialog from './Dialogs/ChangeSupervisorDialog/ChangeSupervisorDialog';
import employeesStyles from './employeesStyles';
import Pagination from '../common/partials/pagination/pagination';
//Styles
import './Employees.scss';
//Filters
import EmployeesFilters from './EmployeesFilters';
import { Checkbox } from '@material-ui/core';
import { uniqWith } from 'lodash';

const propTypes = {
    onLoadingStart: PropTypes.func,
    onGetEmployees: PropTypes.func,
    onGetSupervisors: PropTypes.func,
    onGetCompanies: PropTypes.func,
    onLoadingEnd: PropTypes.func,
    onSaveEmployee: PropTypes.func,
    onChangeEmployeeSupervisor: PropTypes.func,
    onAddFlashMessage: PropTypes.func,
    onClearFlashMessages: PropTypes.func,
    onGetUpdateChanges: PropTypes.func,
    login: PropTypes.object,
    supervisorsList: PropTypes.array
};

const PAGE_SIZE = 10;

class Employees extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fixedHeader: false,
            stripedRows: false,
            showRowHover: true,
            selectable: false,
            multiSelectable: false,
            enableSelectAll: false,
            deselectOnClickaway: true,
            showCheckboxes: false,
            height: '100%',
            openModal: false,
            openChangeSupervisor: false,
            editData: {},
            sorting: 'nameDesc',
            tableData: [],
            snackMessage: '',
            snackOpen: false,
            b2bCheck: false,
            civilCheck: false,
            employmentCheck: false,
            updateChanges: [],
            companiesData: [],
            usersCount: 0,
            page: 1,
            search: {
                supervisor: '',
                employee: '',
                contractType: [],
                status
            },
            selectedUserIds: []
        };
        this.refTable = React.createRef();
        this.dataCopy = [];
        this.addBtnStyle = {
            color: colors.nexioOrange
        };

        this.filterTasks = () => {
            this.setState({ page: 1 }, () => this.getUsers());
        };

        this.getUsers = () => {
            this
                .props
                .onLoadingStart('Loading employees');
            this.props
                .onGetEmployees(
                    (this.state.page - 1) * PAGE_SIZE,
                    PAGE_SIZE,
                    this.state.search
                )
                .then((response) => {
                    this.setState({tableData: response.data.users, usersCount: response.data.count},
                        () => {this.dataCopy = this.state.tableData;});
                    this
                        .props
                        .onLoadingEnd();
                },
                ()=> this.props.onLoadingEnd()
                );
        };

        this.getCompanies = () => {
            this
                .props
                .onLoadingStart('Loading companies');
            this
                .props
                .onGetCompanies()
                .then((response) => {
                    this.setState({ companiesData: response.data });
                    this
                        .props
                        .onLoadingEnd();
                },
                    () => this.props.onLoadingEnd());
        };

        this.handleClose = () => {
            this.setState({ openModal: false });
        };

        this.handleChangeEmployeeSupervisorClose = () => {
            this.setState({ openChangeSupervisor: false });
        };

        this.handleFormIsValid = (user) => {
            let { errorsTask, isValid } = taskDialogValidation(user);
            if (!isValid) {
                this.setState({ errorsTask });
            }
            return isValid;
        };

        this.onChangeSupervisorClickHandler = () => {
            this.setState({ openChangeSupervisor: true });
        };

        this.handleChangeEmployeeSupervisor = (supervisorId) => {
            const userIds = this.state.selectedUserIds;
            this.props.onLoadingStart('Updating employees supervisor');
            this.props.onChangeEmployeeSupervisor(userIds, supervisorId)
                .then((response) => {
                    if (response.status === 200) {
                        this.props.onAddFlashMessage({ type: 'success', text: 'Users saved successfully.' });
                        setTimeout(() => {
                            this.props.onClearFlashMessages();
                        }, 5000);
                        this.props.onGetSupervisors();
                        if (userIds.indexOf(this.props.login.user.id) >= 0) {
                            this.props.onLoginRequest(this.props.login.user.id);
                        }
                    } else {
                        this.props.onAddFlashMessage(httpReqErrorHandler(response));
                        setTimeout(() => {
                            this.props.onClearFlashMessages();
                        }, 5000);
                    }
                    this.getUsers();
                    this.props.onLoadingEnd();
                });
            this.setState({ openChangeSupervisor: false });
        };

        this.handleSubmit = (user) => {
            let isValid = this.handleFormIsValid(user);
            if (!isValid) {
                this.setState({ editData: user });
                return;
            }

            if (!user.contractName) {
                this.props
                    .onAddFlashMessage({ type: 'error', text: "Contract cannot be empty." });

                setTimeout(() => {
                    this.props
                        .onClearFlashMessages();
                }, 5000);
                return;
            }
            this
                .props
                .onLoadingStart('Updating employee');
            this
                .props
                .onSaveEmployee(user)
                .then((response) => {
                    if (response.status === 200) {
                        let userIndex = this.state.tableData.findIndex(x => x.id === user.id);
                        let userCopyIndex = this.dataCopy.findIndex(x => x.id === user.id);
                        let copy = this.state.tableData.slice();
                        if (userIndex !== -1) {
                            copy[userIndex] = response.data;
                            this.dataCopy[userCopyIndex] = response.data;
                            this.setState({ tableData: copy });
                        }
                        else {
                            copy[copy.length] = response.data;
                            this.dataCopy[this.dataCopy.length] = response.data;
                            this.setState({ tableData: copy });
                        }
                        this
                            .props
                            .onAddFlashMessage({ type: 'success', text: 'User saved successfully.' });
                        setTimeout(() => {
                            this
                                .props
                                .onClearFlashMessages();
                        }, 5000);
                        this.props.onGetSupervisors();
                        if (user.id === this.props.login.user.id) {
                            this.props.onLoginRequest(user.id);
                        }
                    } else {
                        this
                            .props
                            .onAddFlashMessage(httpReqErrorHandler(response));
                        setTimeout(() => {
                            this
                                .props
                                .onClearFlashMessages();
                        }, 5000);
                    }
                    this.getUsers();
                    this.props.onLoadingEnd();
                });
            this.setState({ openModal: false });
        };

        this.onAddClickHandler = () => {
            let editData = {};
            this.setState({ openModal: true, editData: editData });
        };

        this.onCellClickHandler = (row, col) => {
            const isCheckboxCell = col === 0; // check this to prevent run this handler when checkbox was changed 
            if (isCheckboxCell) {
                return;
            }
            let dataToEdit = this.state.tableData[row];
            const holidaysSettingsCurrentYear = dataToEdit.holidaysSettingsResources.find(s => s.year === new Date().getFullYear());
            const holidaysSettingsNextYear = dataToEdit.holidaysSettingsResources.find(s => s.year === new Date().getFullYear() + 1);

            let editData = {
                ...dataToEdit,
                prevYearHolidaysCurrentYear: holidaysSettingsCurrentYear != null && holidaysSettingsCurrentYear.prevYearHolidays ? holidaysSettingsCurrentYear.prevYearHolidays : 0,
                startingHolidaysCurrentYear: holidaysSettingsCurrentYear != null && holidaysSettingsCurrentYear.startingHolidays ? holidaysSettingsCurrentYear.startingHolidays : 0,
                prevYearHolidaysNextYear: holidaysSettingsNextYear != null && holidaysSettingsNextYear.prevYearHolidays ? holidaysSettingsNextYear.prevYearHolidays : 0,
                startingHolidaysNextYear: holidaysSettingsNextYear != null && holidaysSettingsNextYear.startingHolidays ? holidaysSettingsNextYear.startingHolidays : 0,
                holidaysUsedCurrentYear: holidaysSettingsCurrentYear != null && holidaysSettingsCurrentYear.usedHolidays ? holidaysSettingsCurrentYear.usedHolidays : 0,
                holidaysPlannedCurrentYear: holidaysSettingsCurrentYear != null && holidaysSettingsCurrentYear.plannedHolidays ? holidaysSettingsCurrentYear.plannedHolidays : 0,
                holidaysPlannedNextYear: holidaysSettingsNextYear != null && holidaysSettingsNextYear.plannedHolidays ? holidaysSettingsCurrentYear.plannedHolidays : 0,
                displaySupervisorHistory: dataToEdit.userSupervisorHistoryResource && dataToEdit.userSupervisorHistoryResource.length > 0
            };

            this.setState({
                openModal: true, editData: editData, snackOpen: false,
                errorsTask: {},
            });
        };

        this.onPageChange = value => {
            this.setState({ page: value }, () => {
                this.getUsers();
            });
        };

        this.handleEmployeeChange = (event) => {
            this.setState({search: {...this.state.search, employee: event.target.value}});
        };

        this.handleSupervisorChange = (event) => {
            this.setState({search: {...this.state.search, supervisor: event.target.value}});
        };

        this.handleContractTypesChange = (event) => {
            this.setState({search: {...this.state.search, contractType: event.target.value}});
        };

        this.handleStatusChange = (event) => {
            this.setState({search: {...this.state.search, status: event.target.value}});
        };
    }

    componentDidMount() {
        this.props.onGetSupervisors();
        this.getCompanies();
        this.getUsers();
    }

    changeOldUser = (oldUser, idOldUser) =>{
        this.setState({
            editData: oldUser
        });
    }

    createSupervisiorsList = () => this.props
        .supervisorsList
        .map(x => ({
            id: x.id,
            name: `${x.firstname} ${x.lastname}`,
            value: `${x.firstname}${x.lastname}(${x.username})`,
            login: x.username,
            firstname: x.firstname,
            lastname: x.lastname
        }));

    handleSelect = (event, items) => {
        const checked = event.target.checked;
        let newSelectedUsers = this.state.selectedUserIds;
        if (checked) { 
            newSelectedUsers = uniqWith(newSelectedUsers.concat(items));
        } else {
            if(Array.isArray(items)) {
                newSelectedUsers = newSelectedUsers.filter((item) => items.indexOf(item) < 0);
            } else {
                const index = newSelectedUsers.indexOf(items);
                if (index >= 0) {
                    newSelectedUsers.splice(index, 1);
                }
            }
        }
        this.setState({ selectedUserIds: newSelectedUsers });
        event.stopPropagation();
    };

    render() {
        if(this.refTable.current){
            this.refTable.current.refs.tableDiv.style.overflow = 'auto';
        }
        return (
            <div style={employeesStyles.tableDivStyle}>
                <div className="userEditDialog">
                    {this.state.openModal && (<UserEditDialog
                        isOpened={this.state.openModal}
                        onCancelClick={this.handleClose}
                        onSubmitClick={this.handleSubmit}
                        formIsValid={this.handleFormIsValid}
                        user={this.state.editData}
                        allWorkgroups={this.props.login.user.allWorkgroups}
                        userOld={this.state.editData}
                        companiesData={this.state.companiesData}
                        errorsTask={this.state.errorsTask}
                        onLoadingEnd={this.props.onLoadingEnd}
                        onGetEmployees={this.props.onGetEmployees}
                        onLoadingStart={this.props.onLoadingStart}
                        page={this.state.page}
                        search={this.state.search}
                        PAGE_SIZE={PAGE_SIZE}
                        changeOldUser={this.changeOldUser}
                        edited
                        canelClickText="All employees"
                    />)}
                </div>
                <div className="userEditDialog">
                    {this.state.openChangeSupervisor && (<ChangeSupervisorDialog
                        isOpen={this.state.openChangeSupervisor}
                        onCloseClick={this.handleChangeEmployeeSupervisorClose}
                        onSubmitClick={this.handleChangeEmployeeSupervisor}
                        supervisors={this.createSupervisiorsList()}
                    />)}
                </div>
                <div style={{
                    boxSizing: 'border-box',
                    width: '100%',
                    position: 'relative',
                    display: 'block',
                    height: 54,
                    padding: 4,
                }}>
                    <Grid item lg={12} container spacing={16} style={{ width: "100%" }}>
                    <Grid item lg={9} sm={9} xs={12}>
                        <EmployeesFilters
                            search={this.state.search}
                            onSupervisorChange={this.handleSupervisorChange}
                            onEmployeeChange={this.handleEmployeeChange}
                            onContractTypeChange={this.handleContractTypesChange}
                            onStatusChange={this.handleStatusChange}
                            onSearch={this.filterTasks}/>
                    </Grid>
                    <Grid item lg={2} sm={2} xs={12}>
                    <span style={{ float:"right", marginTop: 5 }}>
                        <RaisedButton
                            label="Change supervisor"
                            backgroundColor={colors.nexioOrange}
                            onClick={() => this.onChangeSupervisorClickHandler()} />
                    </span>
                    </Grid>
                    <Grid item lg={1} sm={1} xs={12}>
                    <span style={{ float:"right", marginTop: 5 }}>
                        <RaisedButton
                            label="Add"
                            backgroundColor={colors.nexioOrange}
                            onClick={() => this.onAddClickHandler()} />
                    </span>
                    </Grid>
                    </Grid>
                </div>
                <Table
                    style={{ clear: 'both', position: 'relative', paddingTop: 4, tableLayout: 'auto' }}
                    className={'clearFix'}
                    height={this.state.height}
                    fixedHeader={this.state.fixedHeader}
                    fixedFooter={this.state.fixedFooter}
                    selectable={this.state.selectable}
                    multiSelectable={this.state.multiSelectable}
                    onCellClick={(row, col) => this.onCellClickHandler(row, col)}
                    ref={this.refTable}
                    >
                    <TableHeader
                        displaySelectAll={this.state.showCheckboxes}
                        adjustForCheckbox={this.state.showCheckboxes}
                        enableSelectAll={this.state.enableSelectAll}>
                        <TableRow>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                <Checkbox color="primary" 
                                    onChange={(event) => this.handleSelect(event, this.state.tableData.map(t => t.id))}/>
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                {EN.employeesPage.username}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>{EN.employeesPage.firstname}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>{EN.employeesPage.lastname}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                {EN.employeesPage.defaultHolidays} {new Date().getFullYear()}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                {EN.employeesPage.prevYearHolidays} {new Date().getFullYear() - 1}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                {EN.employeesPage.availableHolidays}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                {EN.employeesPage.contract}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                {EN.employeesPage.company}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                {EN.employeesPage.supervisor}
                            </TableHeaderColumn>
                            <TableHeaderColumn style={employeesStyles.headerColStyle}>
                                {EN.employeesPage.status}
                            </TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody
                        displayRowCheckbox={this.state.showCheckboxes}
                        deselectOnClickaway={this.state.deselectOnClickaway}
                        showRowHover={this.state.showRowHover}
                        stripedRows={this.state.stripedRows}>
                        {this
                            .state
                            .tableData
                            .map((row) => {
                                const holidaysSettings = row.holidaysSettingsResources.find(s => s.year === new Date().getFullYear());
                                const isRowSelected = this.state.selectedUserIds.indexOf(row.id) >= 0;
                                return (
                                    <TableRow key={row.id}>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>
                                        <Checkbox color="primary" 
                                            onChange={(event) => this.handleSelect(event, row.id)}
                                            checked={isRowSelected}
                                        />
                                        </TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>{row.username}</TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>{row.firstname}</TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>{row.lastname}</TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>
                                            {holidaysSettings != null ? holidaysSettings.startingHolidays : ''}
                                        </TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>
                                            {holidaysSettings != null ? holidaysSettings.prevYearHolidays : ''}
                                        </TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>
                                            {holidaysSettings != null ? holidaysSettings.startingHolidays + holidaysSettings.prevYearHolidays - holidaysSettings.usedHolidays - holidaysSettings.plannedHolidays : ''}
                                        </TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>
                                            {row.contractName}
                                        </TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>{row.companyname}</TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>{row.supervisorfirstname} {row.supervisorlastname}</TableRowColumn>
                                        <TableRowColumn style={employeesStyles.employeesColStyle}>{Constants.UserStatusMapping[row.status]}</TableRowColumn>
                                    </TableRow>
                                );
                        })}
                    </TableBody>
                </Table>
                <Pagination
                    count={Math.ceil(this.state.usersCount / PAGE_SIZE)}
                    onChange={this.onPageChange}
                    value={this.state.page}
                />
                <Snackbar
                    open={this.state.snackOpen}
                    message={this.state.snackMessage}
                    autoHideDuration={4000}
                    onRequestClose={this.handleRequestClose}
                    bodyStyle={employeesStyles.snackStyle}
                    contentStyle={employeesStyles.snackStyle} />
            </div>
        );
    }
}

Employees.propTypes = propTypes;

function mapStateToProps(state) {
    return {
        login: state.login,
        supervisorsList: state.employees.supervisorsList,
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onLoadingStart,
        onLoadingEnd,
        onLoadingError,
        onAddFlashMessage,
        onClearFlashMessages,
        onGetEmployees,
        onGetSupervisors,
        onGetCompanies,
        onSaveEmployee,
        onChangeEmployeeSupervisor,
        onGetUpdateChanges,
        onLoginRequest
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Employees);