import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import memoize from "memoize-one";

class DataTable extends React.PureComponent {

    constructor(props) {
        super(props);
        this.readRow = this.readRow.bind(this);
        this.getHeaderCells = memoize(this.getHeaderCells);
        this.getTableBody = memoize(this.getTableBody);
        this.onCellClick = this.readRow(this.props.onCellClick);
    }

    readRow(f){
        return (rowId) =>
            f && f(this.props.data[rowId]);
    }

    getHeaderCells(columns, {style, className}) {
        const ret = [];
        for (let i = 0; i < columns.length; i++){
            ret.push(
                <TableCell key={i} style={style} className={className}>
                    {columns[i].header}
                </TableCell>);
        }
        return ret;
    }

    getCells(columns, row, cellStyle, className) {
        let cells = [];
        for (let i = 0; i < columns.length; i++) {
            let column = columns[i];
            cells.push(
                <TableCell key={i}
                    style={cellStyle}
                    className={className}>
                    {column.getValue && column.getValue(row) || 
                        column.valueProperty && row[column.valueProperty] || 
                        column.value}
                </TableCell>);
        }
        return cells;
    }

    getTableBody(columns, getId, data, cellStyle, cellClass, onCellClick) {
        return data.map((row) => (
            <TableRow key={getId(row)} hover onClick={() => onCellClick && onCellClick(row)}>
                {this.getCells(columns, row, cellStyle, cellClass)}
            </TableRow>
        ));
    }

    render() {
        return (
            <Table
                style={this.props.tableStyle}
                className={'clearFix ' + (this.props.tableClass || "")}
            >
                <TableHead>
                    <TableRow>
                        {this.getHeaderCells(this.props.columns, {style: this.props.headerStyle, className: this.props.headerClass})}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.getTableBody(this.props.columns, this.props.getId, this.props.data, this.props.cellStyle, this.props.cellClass, this.props.onCellClick)}
                </TableBody>
            </Table>);

    }
}



DataTable.propTypes = {
    cellStyle: PropTypes.object,
    headerStyle: PropTypes.object,
    tableStyle: PropTypes.object,
    cellClass: PropTypes.string,
    headerClass: PropTypes.string,
    tableClass: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCellClick: PropTypes.func,
    getId: PropTypes.func,
};

DataTable.defaultProps = {
    getId: (row) => row.id
};

export default DataTable;