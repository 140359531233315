/*React Libs*/
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import RootRef from '@material-ui/core/RootRef';
import Grid from '@material-ui/core/Grid';

import Check from '@material-ui/icons/Check';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';


import ProgressGraph from '../pricing/taskDialogPanel/ProgressGraph';
import { jiraTaskUrl, redmineIssueUrl } from '../common/helpers/urls';

const inputProps = {
    readOnly: true,
};

const labelProps = {
    shrink: true,
};

const rowStyle = {
    cursor: "pointer"
};

const iconCellStyle = {
    whiteSpace: "nowrap"
};

const ReadOnlyField = ({label, value, md=6, ...rest}) => (
<Grid item md={md}>
    <TextField
        inputProps={inputProps}
        InputLabelProps={labelProps}
        placeholder="None"
        margin="normal"
        value={value || ""}
        label={label}
        fullWidth
        {...rest}
    />
</Grid>);

const LinkField = ({label, id, url}) => (
<Grid item md={6}>
    <TextField
        inputProps={inputProps}
        InputProps={{inputComponent: function AInput(props) {
            return id ? <a href={url} target="_blank" rel="noopener noreferrer" {...props}>{id}</a> : <input {...props}/>;
        }}}
        InputLabelProps={labelProps}
        placeholder="None"
        margin="normal"
        value={""}
        label={label}
        fullWidth
    />
</Grid>);

const collapseRow = (colSpan) => function CollapseRow(props) {return (
    <TableRow>
        <TableCell colSpan={colSpan}>
            <div {...props}/>
        </TableCell>
    </TableRow>);};

const UserTask = (props) => {
    const {
        id,
        taskSolved, 
        redmineClosed, 
        taskName, 
        redmineName, 
        totalRedmineTime, 
        budget, 
        taskProgresses, 
        jiraTaskId,
        redmineDescription
    } = props.data;

    const budgetPercent = budget && (totalRedmineTime / (8 * budget) * 100).toFixed(0);
    const domRef = React.createRef();
    const handleEntered = () => {
        if (props.in) domRef.current.scrollIntoView({behavior: "smooth", block: "nearest"});
      };
    return (<>
            <TableRow onClick={props.onClick} hover style={rowStyle}>
                <TableCell style={iconCellStyle}> 
                    {props.in ? <ExpandLess/> : <ExpandMore/>}
                    {taskSolved === true || taskSolved !== false && redmineClosed ? <Check /> : null} 
                </TableCell>
                <TableCell> {redmineName} </TableCell>
                <TableCell> {budgetPercent ? budgetPercent + "%" : null} </TableCell>
            </TableRow>
            <RootRef rootRef={domRef}>
                <Collapse in={props.in} mountOnEnter unmountOnExit component={collapseRow(3)} onEntered={handleEntered}>
                    <Grid container spacing={16}>
                        <LinkField label="Inner Task Number" id={jiraTaskId} url={jiraTaskUrl(jiraTaskId)}/>
                        <ReadOnlyField label="Task name" value={taskName}/>
                        <LinkField label="Redmine issue" id={id} url={redmineIssueUrl(id)}/>
                        <ReadOnlyField label="Redmine issue name" value={redmineName}/>
                        <ReadOnlyField label="Description" value={redmineDescription} md={12} multiline/>
                        <ReadOnlyField label="Budget" value={budget} md={3}/>
                        <ReadOnlyField label="Declared time" value={Math.round(totalRedmineTime / 8 * 100) / 100} md={3}/>
                    </Grid>
                    {taskProgresses && taskProgresses.length > 0 && 
                        (<div style={{display: "flex", justifyContent: "center"}}>
                                <ProgressGraph data={taskProgresses}/>
                        </div>)}
                </Collapse>
            </RootRef>
        </>);
};


UserTask.propTypes = {
    data: PropTypes.object,
    in: PropTypes.bool
};
export default memo(UserTask);