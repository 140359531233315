import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import { colors } from '../common/styles';
import { EN } from '../common/translations';
import { ConfirmContext, deleteMessage } from '../confirm/ConfirmDialog';
import Button from '@material-ui/core/Button';
import changeLogStyles from './changeLogStyles';
import './changeLogEditModal.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningDialog from '../common/WarningDialog';

const defaultProps = {
    buttonBottomStyle: colors.nexioOrange
};

const propTypes = {
    isOpen: PropTypes.bool,
    changeLogs: PropTypes.object,
    selectedFile: PropTypes.object,
    onSubmitClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired
};

const maxFileSize = 10 * 1024 * 1024;

class ChangeLogEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changeLogs: this.props.changeLogs,
            originalChangeLogs: this.props.changeLogs,
            selectedFile: this.props.selectedFile,
            fileDeleted: false,
            fileSizeWarningDialogOpen: false
        };

        this.onChangeLogTextFieldChange = (field, value) => {
            const { ...logs } = this.state.changeLogs;
            logs[field] = value;
            this.setState({ changeLogs: logs });
        };

        this.buttonClicked = () => {
            this.inputRef.current.click();
        };

        this.fileChanged = (event) => {
            const target = event.target;
            if (target.files.length === 1 && target.files[0].size <= maxFileSize) {
                this.setState({
                    selectedFile: event.target.files[0]
                });
            } else {
                this.setState({fileSizeWarningDialogOpen: true});
                event.target.value = null;
            }
        };

        this.removeFile = () => {
            if(this.state.selectedFile){
                this.setState({selectedFile: null});
            } else {
                this.setState({fileDeleted: true});
            }
            this.inputRef.current.value = "";
        };

        this.onFormSubmit = (e) => {
            e.preventDefault();
            
            const { changeLogs, selectedFile, fileDeleted } = this.state;
            this.props.onSubmitClick(changeLogs, selectedFile, fileDeleted);
        };

        this.closeFileSizeWarningDialog = () => {
            this.setState({fileSizeWarningDialogOpen: false});
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.changeLogs != state.originalChangeLogs) {
            return { changeLogs: props.changeLogs, originalChangeLogs: props.changeLogs, selectedFile: props.selectedFile, fileDeleted: false };
        }
        return null;
    }

    inputRef = React.createRef();

    render() {
        const { onCancelClick, onSubmitClick, onDeleteClick, isOpen, buttonBottomStyle: colorStyle } = this.props;
        const { changeLogs, selectedFile, fileDeleted, fileSizeWarningDialogOpen } = this.state;
        const isChangeLogsExists = changeLogs.id !== undefined;
        const { id, addedDate, userId, filename, ...cutArray } = changeLogs;
        const confirm = this.context;

        let displayFilename = fileDeleted ? '' : filename;
        if(selectedFile && selectedFile.name)
        {
            displayFilename = selectedFile.name;
        }

        const bottomActions = [
            <FlatButton key="cancel" label="Cancel" primary onClick={onCancelClick} style={{ color: colorStyle }} />,
            <FlatButton key="submit" label="Submit" primary form="newChangeLogForm" type="submit" style={{ color: colorStyle }} />,
            <FlatButton key="delete" label="Delete" primary onClick={() => confirm(deleteMessage)(() => onDeleteClick(changeLogs.id))} style={{ color: "red" }} />
        ];
        
        return (
            <Dialog
                title={EN.changeLogs.title}
                modal={false}
                actions={isChangeLogsExists
                    ? bottomActions
                    : bottomActions.filter(function (button) { return button.key !== "delete"; })}
                open={isOpen}
                autoScrollBodyContent
                onRequestClose={this.handleClose}>
                <form id="newChangeLogForm" onSubmit={this.onFormSubmit}>
                    <div className="form-group">
                        <div className="form-group-item">
                            {Object.keys(cutArray).map((item, index) => {
                                let itemName = item;
                                if (itemName === 'title')
                                    itemName = 'subject';
                                return (<div key={index}>
                                    <div className="form-group-item label">{EN.changeLogs[itemName]}:</div>
                                    <div className="mdl-text-area">
                                        <TextField
                                            required
                                            fullWidth
                                            multiLine
                                            defaultValue={changeLogs[item]}
                                            onChange={(event, value) => this.onChangeLogTextFieldChange(item, value)}
                                            id={item} />
                                    </div>
                                </div>);
                            })}
                        </div>
                        <div>
                            <div className="form-group-item label">{EN.changeLogs.documentFile}:</div>
                            <input type="file" hidden ref={this.inputRef} onChange={this.fileChanged} />
                            <div style={changeLogStyles.addBtn}>
                                {displayFilename ?
                                    <span className={"filename"}>{displayFilename}<span onClick={this.removeFile}><DeleteIcon /></span></span> :
                                    <Button color="primary" onClick={this.buttonClicked} variant="contained">Attach file</Button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
                <WarningDialog 
                    open={fileSizeWarningDialogOpen} 
                    content={EN.changeLogs.warningFileSizeDialog.content} 
                    onClose={this.closeFileSizeWarningDialog} 
                    buttonText={EN.changeLogs.warningFileSizeDialog.closeButton}
                />
            </Dialog>
        );
    }
}

ChangeLogEditModal.defaultProps = defaultProps;
ChangeLogEditModal.propTypes = propTypes;
ChangeLogEditModal.contextType = ConfirmContext;

function mapStateToProps(state) {
    return { login: state.login };
}

export default connect(mapStateToProps, null)(ChangeLogEditModal);