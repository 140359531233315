import React, { Component } from 'react';

const asyncComponent = (importComponent) => {
    //eslint-disable-next-line react/display-name
    return class extends Component {

        constructor(...props) {
            super(...props);
            this.state = {
                component: null
            };
            this._isMount = false;
        }
        componentDidMount() {
            this._isMount = true;
            importComponent()
                .then(cmp => {
                    this.setState({ component: cmp.default });
                });
        }

        componentWillUnmount() {
            this._isMount = false;
        }

        render() {
            const C = this.state.component;
            return C ? <C {...this.props} /> : null;
        }
    };
};

export default asyncComponent;