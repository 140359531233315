import React, { useCallback, useMemo, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { DatePicker, TimePicker, TextField } from '../';
import { connect, getIn } from 'formik';

//If Grid is inside connect from formik it doesn't work
const gridify = (Component) => {
    const Wrapped = ({xs, sm, md, lg, xl, gridProps, ...rest}) =>(
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} {...gridProps}>
            <Component {...rest} />
        </Grid>);
    Wrapped.displayName = `Grid(${Component.displayName})`;
    Wrapped.defaultProps = {
        xs: 12
    };
    return Wrapped;
};

// Connect MUI control to Formik form. It requires name props and
// automatically handles value, onChange, onBlur, error and helperText.
//
// When given onChange it calls it with event and FormikBag after changing
// internal state which allows to get or set other fields in form.

export const makeControl = (Component) => gridify(connect(({ formik, name, onChange, ...props }) => {
        const formikRef = useRef(formik);
        formikRef.current = formik;
        const handleChange = useCallback((e) => { formikRef.current.handleChange(e); onChange && onChange(e, formikRef.current); }, [onChange]);
        const error = getIn(formik.touched, name) && getIn(formik.errors, name);
        const selectProps = {
            MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                }
            },
            multiple: props.multiple || false,
        };

        return (
        <Component
            name={name}
            value={getIn(formik.values, name) || ''}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={!!error}
            helperText={error}
            SelectProps={
                props.select ? selectProps : undefined
            }
            {...props}/>
    );}));

export const FormReadOnlyField = gridify((props) => (
    <TextField readOnly {...props}/>
));

export const FormTextField = makeControl(TextField);
export const FormDatePicker = makeControl(DatePicker);
export const FormTimePicker = makeControl(TimePicker);

export const FormButton = connect(({ formik, name, onClick, ...props }) => {
    const formikRef = useRef(formik);
    formikRef.current = formik;
    const onClickWithValues = useCallback(() => onClick && onClick(formikRef.current.values, formikRef.current), [onClick]);
    return <Button onClick={onClickWithValues} disabled={formik.isSubmitting || props.disabled} {...props}/>;
});
