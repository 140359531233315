import React from 'react';
import './settings.scss';

//eslint-disable-next-line react/display-name
const NumberCounter = React.forwardRef((props, ref) => {

    const classColor = props.colorValue ? "colorOrange" : "colorWhite";

    return (

        <div className={`numberCounter `}>
            <input
                type="text"
                ref={ref}
                maxLength="1"
                className={classColor}
                name={props.name}
                value={props.value}
                onChange={props.handleCounterOnChange}
                onKeyDown={(e) => props.handleOnKeyDown(e, props.valueName)}
                onBlur={() => props.handleCounterOnBlur(props.valueName, props.name)}
                onKeyPress={(e) => props.handleKeyPress(e, props.nextRef, props.prevRef)}
            />
        </div>

    );

});

export default NumberCounter;