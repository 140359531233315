import {host} from '../components/common/cfg';
import { Constants } from '../components/common/constants';
import axios from 'axios';
import {ConvertBase64toBlob, GetExtensionFromMimeType} from '../components/common/helpers/filesUtils.js';
import { API } from './types';

const types = {
    EMPLOYEES_FETCH_AVATAR_FINISHED: "EMPLOYEES_FETCH_AVATAR_FINISHED",
    EMPLOYEES_FETCH_USER_EDIT_AVATAR_FINISHED: "EMPLOYEES_FETCH_USER_EDIT_AVATAR_FINISHED",
    EMPLOYEES_UPDATE_AVATAR: "EMPLOYEES_UPDATE_AVATAR",
    GET_SUPERVISORS_LIST: "GET_SUPERVISORS_LIST",
    DELETE_USER_AVATAR: "DELETE_USER_AVATAR"
};

export const onFetchAvatarFinished = (response, userId) => ({ type: types.EMPLOYEES_FETCH_AVATAR_FINISHED, data: response, userId: userId });
export const onFetchUserEditAvatarFinished = (response, userId) => ({ type: types.EMPLOYEES_FETCH_USER_EDIT_AVATAR_FINISHED, data: response, userId: userId });
export const onUpdateAvatar = (image) => ({ type: types.EMPLOYEES_UPDATE_AVATAR, data: image });

export const onGetContracts = () => {
    return (dispatch) => {
        return axios
            .get(`${host}/api/user/getcontractstype`)
            .then(response => {
                return response;
            });
    };
};

export const onGetEmployees = (start, count, search) => {
    return (dispatch) => {
        let parameters = new URLSearchParams();
        parameters.append("Start", start);
        parameters.append("Count", count);
        parameters.append("Supervisor", search.supervisor);
        parameters.append("Employee", search.employee);
        parameters.append("ContractType", search.contractType);
        if(search.status){
            let selectedStatus = Object.entries(Constants.UserStatusMapping).filter(([key, val]) => val == search.status);
            parameters.append("UserStatus", selectedStatus ? selectedStatus[0][0] : null);
        }
        return axios
            .get(`${host}/api/user/users/`, {'params': parameters })
            .then(response => {
                return response;
            });
    };
};

export const onGetSupervisors = () => dispatch => dispatch({
    type: API,
    payload: {
        text: "Loading supervisors",
        url: "user/supervisors",
        method: "GET",
        onSuccess: response => {
            const supervisorsList = response
                .map((row, index) => ({
                    id: row.id,
                    firstname: row.firstname,
                    lastname: row.lastname,
                    username: row.username
                }))
                .sort((a, b) => {
                    const x = a.lastname
                        .split(" ")
                        .join("")
                        .toLowerCase();
                    const y = b.lastname
                        .split(" ")
                        .join("")
                        .toLowerCase();
                    const numberReturn = x > y ? 1 : x < y ? -1 : 0;
                    return numberReturn;
                });

            dispatch(onSupervisiorLoaded([
                { id: null, firstname: '', lastname: '', username: '--- empty ---' },
                ...supervisorsList
            ]));
        }
    }
});

export const onGetWorkgroups = () => {
    return dispatch => {
        return axios
            .get(`${host}/api/user/workgroups`)
            .then(res => res);
    };
};

export const onPutUserImage = (image) => {
    return (dispatch) => {
        const headers = {
            'Accept': 'application/json image/jpeg image/png',
        };
        let ImageURL = image.file;
        let block = ImageURL.split(";");
        let contentType = block[0].split(":")[1];
        let realData = block[1].split(",")[1];
        let blob = ConvertBase64toBlob(realData, contentType);
        let extension = GetExtensionFromMimeType(contentType);
        let fileName = 'UserPhoto'.concat(extension ? '.'.concat(extension) : '');

        const form = new FormData();
        form.append("file", blob, fileName);

        return axios
            .put(`${host}/api/UserProfile/AddUserPhoto`, form, {headers})
            .then(response => {
                dispatch(onUpdateAvatar(ImageURL));
                return response;
            })
            .catch(response => {
                return response;
            });
    };
};

export const deleteUserImage = () => {
    return (dispatch) => {
        return axios
            .delete(`${host}/api/UserProfile/DeleteUserPhoto`)
            .then(response => {
                dispatch(deleteUserAvatar());
                return response;
            })
            .catch(response => {
                return response;
            });
    };
};

export const fetchAvatar = (userId, userEditView) => {
    return (dispatch) => {
        return axios
            .get(`${host}/api/UserProfile/GetUserPhotoContent?userId=${userId}`)
            .then(response => {
                if (userEditView) {
                    dispatch(onFetchUserEditAvatarFinished(response, userId));
                } else {
                    dispatch(onFetchAvatarFinished(response, userId));
                }
            })
            .catch(response => {
                return response;
            });
    };
};

export const onGetCompanies = () => {
    return (dispatch) => {
        return axios
            .get(`${host}/api/user/companies`)
            .then(response => {
                return response;
            });
    };
};

export const onSaveEmployee = (user) => {
    return (dispatch) => {
        const headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .put(`${host}/api/user/save`, user, {headers})
            .then(response => {
                return response;
            })
            .catch(response => {
                return response;
            });
    };
};

export const onChangeEmployeeSupervisor = (userIds, supervisorId) => {
    return (dispatch) => {
        const headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .put(`${host}/api/user/changeSupervisor`, { userIds, supervisorId }, {headers})
            .then(response => {
                return response;
            })
            .catch(response => {
                return response;
            });
    };
};

export const onGetUpdateChanges = (username) => {
    return (dispatch) => {
        return axios
            .post(`${host}/api/user/holidaysChanges?userName=${username}`)
            .then(response => {
                return response;
            })
            .catch(response=> response);
    };
};

export const convertResponseToB64 = (response) => {
    if (response && response.status === Constants.HttpStatusCodes.OK
        && response.data && response.data.fileSize > 0 ){
        return `data:image;base64,${response.data.fileContent}`;
    } else{
        return null;
    }
};

const deleteUserAvatar = () => {
    return {
        type: types.DELETE_USER_AVATAR,
    };
};

const onSupervisiorLoaded = supervisorsList => ({
    type: types.GET_SUPERVISORS_LIST,
    supervisorsList,
});

const initialState = {
    currentAvatar: null,
    userEditAvatar: null,
    supervisorsList: [],
};

export function employees(state = initialState, action) {
     switch (action.type) {

        case types.EMPLOYEES_FETCH_AVATAR_FINISHED:
            return{
                ...state,
                currentAvatar: convertResponseToB64(action.data)
            };
         case types.EMPLOYEES_FETCH_USER_EDIT_AVATAR_FINISHED:
             return {
                 ...state,
                 userEditAvatar: convertResponseToB64(action.data)
             };
        case types.EMPLOYEES_UPDATE_AVATAR:
            return {
                ...state,
                currentAvatar: action.data
            };

        case types.GET_SUPERVISORS_LIST:
            return {
                ...state,
                supervisorsList: action.supervisorsList,
            };
        case types.DELETE_USER_AVATAR:
            return{
                ...state,
                currentAvatar: null,
            };

        default:
             return state;
     }
}
