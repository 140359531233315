import React from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { loginFromDifferentDomain } from '../../store/Login';

const TOKEN_COUNT = 2;

const LoginWithToken = (props) => {
    if (window.location.pathname.startsWith('/setToken')) {
        const spllitted = window.location.pathname.split('/');
        if (spllitted.length < TOKEN_COUNT) {
            return <Redirect to={{pathname: '/login'}}/>;
        }
        const accessToken = spllitted[spllitted.length-2];
        const refreshToken = spllitted[spllitted.length-1];
        loginFromDifferentDomain(accessToken, refreshToken);
    }   
   return <Redirect to={{pathname: '/signed-in'}}/>;
};

export default withRouter(LoginWithToken);