import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Components
import BodyWeek from './BodyWeek';
//3rd lib
import moment from 'moment';
import _ from 'lodash';
import { selectedProject } from '../../../../store/Projects';

const propTypes = {
    selectedYear: PropTypes.number,
    selectedMonth: PropTypes.number, 
    selectedDay: PropTypes.object,
    selectedDays: PropTypes.array,
    selectedProject:PropTypes.object,
    issue: PropTypes.object,
    selectionEnabled:PropTypes.bool,
    selectMode:PropTypes.string,
    selectDay:PropTypes.func.isRequired,
    selectRange:PropTypes.func.isRequired,
    showComments:PropTypes.func.isRequired,
    hideComments:PropTypes.func.isRequired,
    onDaySelectStart:PropTypes.func.isRequired,
    onDaySelectEnd:PropTypes.func.isRequired
};

const defaultProps = {

};

const BodyMonth = (props) => {
  
    const {
        selectedMonth, 
        selectedYear
    } = props;

    let weeksInMonth = [];
    let done = false;
    let date = moment({month: selectedMonth, year: selectedYear}).startOf("month").add("w" - 1).day("Sunday");
    let count = 0;
    let monthIndex = date.month();

    while (!done) {
        
        weeksInMonth.push(
            <BodyWeek 
                key={_.uniqueId('week_')}
                date={date.clone()}
                weekNumber = {count+1}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                {...props}
                />
        );

        date.add(1, "w");

        done = count++ > 2 && monthIndex !== date.month();
        monthIndex = date.month();
    }

    const monthClasses = `month${props.isProject ? " --project" : ''}`;

    return <div className={monthClasses}>{weeksInMonth}</div>;
};

BodyMonth.propTypes = propTypes;
BodyMonth.defaultProps = defaultProps;

export default BodyMonth;