import React, { useState, useEffect } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Logo } from './Logo';
import tmsFhd from '../../assets/bg_tms_fhd.jpg';
import tms4k from '../../assets/bg_tms_4k.jpg';
import './login.scss';
import { EN } from '../common/translations';
import { onLogin, onLoginRequest } from '../../store/Login';
import { withRouter } from 'react-router';
import { onLoadingStart, onLoadingEnd } from '../../store/Preloader';
import ForgotPassword from '../forgotPassword/ForgotPassword';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

const translations = EN.loginPage;

const LoginForm = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [usernameInvalid, setUsernameInvalid] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const loginUser = (e) => {
        e.preventDefault();
        if (username === '' || password === '') {
            markInvalid(true);
            return;
        }
        props.onLoadingStart(`${translations.preloader}`);
        setDisableSubmit(true);
        props.onLogin(username, password, rememberMe);
    };

    const markInvalid = (checkContent) => {
        setUsernameInvalid(!checkContent || username === '');
        setPasswordInvalid(!checkContent || password === '');
    };

    useEffect(() => {
        props.onLoadingEnd();
        setDisableSubmit(false);
        if(props.login.errorMessage){
            markInvalid(false);
        }
    }, [props.login.errorMessage]);

    useEffect(() => {
        if (props.login.loginData?.access_token) {
            props.onLoadingEnd();
            setDisableSubmit(false);
            const returnUrl = new URLSearchParams(document.location.search).get('returnUrl');
            if (returnUrl) {
                sessionStorage.setItem('returnUrl', returnUrl);
            }
            props.history.push({ pathname: '/signed-in' });
        }
    }, [props.login.loginData]);

    return (
        <div className="background" style={{ backgroundImage: `url(${window.innerWidth > 2560 ? tms4k : tmsFhd})` }}>
            <div className="login-bar">
                <div className="login-centered">
                    <div className="login-container">
                        <Logo />
                        <div className="form-wrapper">
                            {isForgotPassword ?
                                <div>
                                    <ForgotPassword />
                                    <div style={{ textAlign: 'center' }}>
                                        <span className="forgot-link" onClick={() => setIsForgotPassword(false)}>
                                            Back to login
                                    </span>
                                    </div>
                                </div>
                                :
                                <form onSubmit={loginUser}>
                                    {props.login.errorMessage && <div className="error-message">
                                        {props.login.errorMessage}
                                    </div>}
                                    {!props.login.errorMessage && (usernameInvalid || passwordInvalid) &&
                                    <div className="error-message">
                                        Please fill both fields
                                    </div>}
                                    <div className="form-group">
                                        <label htmlFor={translations.username}>{translations.username}</label>
                                        <div>
                                            <input
                                                id={translations.username}
                                                onChange={(e) => {setUsername(e.target.value); setUsernameInvalid(false);}}
                                                placeholder={translations.username}
                                                className={usernameInvalid ? 'input-error' : ''} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor={translations.password}>{translations.password}</label>
                                        <div>
                                            <input
                                                id={translations.password}
                                                type="password" onChange={(e) => {setPassword(e.target.value); setPasswordInvalid(false);}}
                                                 placeholder={translations.password}
                                                 className={passwordInvalid ? 'input-error' : ''} />
                                        </div>
                                    </div>
                                    <div className="form-footer">
                                        <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                checked={rememberMe}
                                                onChange={(e) => setRememberMe(e.target.checked)}
                                                color="primary"
                                                />
                                            }
                                            label={translations.rememberMe}
                                        />
                                        </div>
                                        <div>
                                            <span className="forgot-link" onClick={() => setIsForgotPassword(true)} style={{ float: 'right' }}>
                                                {translations.forgotPasswordLabel}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button disabled={disableSubmit} type="submit">{translations.loginButton}</button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onLogin,
        onLoadingStart,
        onLoadingEnd,
        onLoginRequest
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        login: state.login,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm));