import React, { useState, useMemo, useEffect } from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import Eye from '@material-ui/icons/RemoveRedEye';
import IconButton from '@material-ui/core/IconButton';
import FormDialog from '../../common/controls/formik/FormDialog';
import HolidaySummary from '../../holidays/HolidaySummary';
import AttachmentList from '../../holidays/Dialogs/Attachments/FileList';
import { EN } from '../../common/translations';
import { Constants } from '../../common/constants';
import { HolidayPanelStyles } from '../../holidays/HolidayStyles';
import { FormTextField, FormReadOnlyField } from '../../common/controls/formik/FormControls';
import { countDayOff } from '../../common/helpers/date';
import SelectSearch from 'react-select-search';
import { setHhistoryOfSearchingReplacements } from '../../../store/Holidays';
import moment from 'moment';

const HolidayPreviewDialog = (props) => {
    const { 
        hideDialog, 
        actions, 
        disableComment, 
        publicHolidays, 
        getUserHolidaysSummary, 
        allSupervisor, 
        disableReplacementSelect, 
        historyOfSearchingReplacements,
        setHhistoryOfSearchingReplacements
    } = props;
    let holiday = props.holiday;

    const [transparent, setTransparent] = useState(false);
    const modalStyle = useMemo(() => {
        return transparent ? 
            { opacity: 0.2, transition: "opacity 0.2s ease-in-out" } : 
            { transition: "opacity 0.3s ease-in-out" };
    }, [transparent]);

    const modalActions = [
        (
            <IconButton key={1} onMouseEnter={() => setTransparent(true)} onMouseLeave={() => setTransparent(false)}>
                <Eye style={HolidayPanelStyles.largeIcon} />
            </IconButton>
        )
    ];
    //#region Additional actions
    if(Array.isArray(actions) && actions.length)
        modalActions.push(...actions);
    //#endregion

    const holidaySummary = () => {
        if (holiday.type === Constants.HolidayTypes.HOLIDAY && publicHolidays) {
            const currentHolidays = countDayOff(holiday.dateFrom, holiday.dateTo, publicHolidays);
            return (
                <HolidaySummary currentHolidays={currentHolidays}
                    status={holiday.status}
                    username={holiday.userFirstName} />
            );
        }
        return null;
    };

    const timeElement = () => {
        if (holiday.type === Constants.HolidayTypes.REMOTE)
            return (
                <>
                    <FormReadOnlyField sm={6} label={EN.holidaysPage.timeFrom} key="timeFrom" value={holiday.timeFrom} />
                    <FormReadOnlyField sm={6} label={EN.holidaysPage.timeTo} key="timeTo" value={holiday.timeTo} />
                </>
            );
        return null;
    };

    useEffect(() => {
        const replacementId = holiday.replacementPersonId || 0;
        setSelectedReplacingPersonId(`${replacementId}`);

        userHolidaysSummary();
    }, [holiday]);
    
    const [selectedReplacementPersonId, setSelectedReplacingPersonId] = useState('0');

    const handleSelectReplacingPersonOnChange = (e) => {
        if(e){
            const eventId = parseInt(e.value, 10);
            const selectedId = Number.isInteger(eventId) ? eventId : 0;
            holiday.replacementPersonId = selectedId;

            setSelectedReplacingPersonId(e.value);

            if(selectedId > 0){
                setHhistoryOfSearchingReplacements(selectedId);
            }
        }
    };

    const getReplacementSelect = () => {
        const dropdownOptionCallback = ({ id, login, firstname, lastname }) => ({
            id: `${id}|${login}`,
            name: id ? `${firstname} ${lastname} (${login})` : `${firstname} ${lastname}`,
            value: `${id}`
        });

        const fromHistory = allSupervisor
        .filter(x => historyOfSearchingReplacements.includes(x.id))
        .map(dropdownOptionCallback);

        const restOfSupervisors = allSupervisor
        .filter(x => !historyOfSearchingReplacements.includes(x.id) && x.id !== 0)
        .map(dropdownOptionCallback);

        const empty = dropdownOptionCallback(allSupervisor.find(x => x.id === 0));

        const options = [
            empty,
            { type: "group", name: EN.holidaysPage.replacementGroups.lastSelected, items: fromHistory },
            { type: "group", name: EN.holidaysPage.replacementGroups.others, items: restOfSupervisors }
        ];

        const label = selectedReplacementPersonId === '0' ? EN.holidaysPage.selectReplacement : EN.holidaysPage.selectedReplacement;

        return (
            <div className="HolidayDialogEditSelectSearch">
                <label className="HolidayDialogEditSelectSearchLabel">{label}</label>
                <SelectSearch
                    options={options}
                    value={selectedReplacementPersonId}
                    name="replacements"
                    onChange={handleSelectReplacingPersonOnChange}
                />
            </div>
        );
    };

    const getReplacmentValue = () => {
        let replacementList = allSupervisor.filter(x=>x.id === holiday.replacementPersonId);
        if(replacementList && replacementList.length > 0){
            let replacementUser = replacementList[0];
            return replacementUser.id ? `${replacementUser.firstname} ${replacementUser.lastname} (${replacementUser.login})` : ` `;
        } else {
            return ' ';
        }
    };

    const replacementElement = () => {
        if (allSupervisor && allSupervisor.length > 0)
            if(disableReplacementSelect || 
                holiday.status == Constants.HolidayStatuses.ACCEPTED || 
                holiday.status == Constants.HolidayStatuses.REJECTED || 
                holiday.type == Constants.HolidayTypes.REMOTE){
                return (
                    <>
                        <FormReadOnlyField label={EN.holidaysPage.replacement} value={getReplacmentValue()} />
                    </>
                );
            }
            else{
                return getReplacementSelect();
            }

        return null;
    };

    const userHolidaysSummary = () => {
        if(holiday.userId != null && holiday.userId > 0 && getUserHolidaysSummary)
            return getUserHolidaysSummary(holiday.userId, holiday.id, new Date(holiday.dateFrom).getUTCFullYear());

        return null;
    };

    const commentField = () => {
        if(disableComment)
            return (
                <FormReadOnlyField label={EN.holidaysPage.comment} key="comment"
                    multiline value={holiday.comment} />
            );
            return (
                <FormTextField label={EN.holidaysPage.comment + " (max 255)"} key="comment"
                    name="comment" multiline autoFocus inputProps={{
                    maxLength:255 }}/>
            );
         };
    
    if(!holiday)
        holiday = {};
    
    return (
        <FormDialog
            open={holiday && holiday.id > 0}
            initialValues={holiday}
            style={modalStyle}
            onClose={hideDialog}
            actions={modalActions}>
            
            <FormReadOnlyField label={EN.holidaysPage.type} key="type"
                value={holiday.type} />
            <FormReadOnlyField label={EN.holidaysPage.applicant} key="applicant"
                value={holiday.userFirstName + ' ' + holiday.userLastName} />
            <FormReadOnlyField sm={6} label={EN.holidaysPage.dateFrom} key="dateFrom"
                value={holiday.dateFrom && moment.utc(holiday.dateFrom).local().format("YYYY-MM-DD")} />
            <FormReadOnlyField sm={6} label={EN.holidaysPage.dateTo} key="dateTo"
                value={holiday.dateTo && moment.utc(holiday.dateTo).local().format("YYYY-MM-DD")} />
                
            <div style={HolidayPanelStyles.warning}>{holiday.isPastRequest ? EN.holidaysPage.pastHolidayWarning : ''}</div>
            {timeElement()}
            <FormReadOnlyField label={EN.holidaysPage.request} key="request"
                value={holiday.request} multiline />
            <FormReadOnlyField label={EN.holidaysPage.accepted} key="accepted"
                value={holiday.userAcceptedFirstName ? holiday.userAcceptedFirstName + ' ' + holiday.userAcceptedLastName : ''} />
            {replacementElement()}
            <FormReadOnlyField label={EN.holidaysPage.status} key="status"
                value={holiday.status} />
            {commentField()}
            <AttachmentList isNewRequest={false} list={holiday.attachments || []} />
            {holidaySummary()}
        </FormDialog>
    );
};

function mapStateToProps(state){
    return {
        historyOfSearchingReplacements: state.holidays.historyOfSearchingReplacements
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        setHhistoryOfSearchingReplacements,
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HolidayPreviewDialog);