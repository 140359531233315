import React, { useCallback, useState, useMemo, memo, Fragment } from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { EN } from '../../common/translations';
import { Constants } from '../../common/constants';
import { HolidayPanelStyles } from '../HolidayStyles';
import { hideApproveDialog, approveHoliday, rejectHoliday } from '../../../store/HolidayPanel';
import { useConfirm } from '../../confirm/ConfirmDialog';
import { FormButton } from '../../common/controls/formik/FormControls';
import { getUserHolidaysSummary } from '../../../store/Holidays';
import WarningDialog from '../../common/WarningDialog';
import HolidayPreviewDialog from '../../common/holidays/HolidayPreviewDialog';

const HolidayApprovalDialog = ({ holiday, hideApproveDialog, approveHoliday, rejectHoliday, publicHolidays, getUserHolidaysSummary, allSupervisor }) => {
    const [warningDialogOpen, setWarningDialogOpen] = useState(false);
    const approve = (v) => approveHoliday(holiday.id, v.comment, v.replacementPersonId);
    const confirm = useConfirm({ content: "Are you sure you want to reject holiday?" });
    const reject = (v) => confirm(() => rejectHoliday(holiday.id, v.comment));
    const commentCheck = useCallback((v) => { !v.comment ? setWarningDialogOpen(true) : reject(v); });
    
    const modalActions = [
        <FormButton key={2} onClick={hideApproveDialog}>Cancel</FormButton>,
            holiday.status !== Constants.HolidayStatuses.REJECTED &&
        <FormButton key={3} style={HolidayPanelStyles.buttonDeleteStyle} onClick={commentCheck}>Reject</FormButton>,
            holiday.status === Constants.HolidayStatuses.NEW &&
        <FormButton key={4} color="primary" onClick={approve}>Approve</FormButton>
    ];

    return (
        <>
            <WarningDialog
                open={warningDialogOpen}
                onClose={() => setWarningDialogOpen(false)}
                content={EN.holidaysPage.commentWarning}
                buttonText="OK" />
            <HolidayPreviewDialog
                hideDialog={hideApproveDialog}
                holiday={holiday}
                actions={modalActions}
                disableComment={false}
                publicHolidays={publicHolidays}
                getUserHolidaysSummary={getUserHolidaysSummary}
                allSupervisor={allSupervisor}
                disableReplacementSelect={false}
                />
        </>
    );
};

let emptyHoliday = {};
function mapStateToProps(state) {
    return {
        holiday: state.holidayPanel.openHolidayId > 0 && state.holidays.data[state.holidayPanel.openHolidayId] || emptyHoliday,
        publicHolidays: state.publicHolidays.wtPublicHolidays,
        allSupervisor: state.settings.allSupervisor,
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        hideApproveDialog,
        approveHoliday,
        rejectHoliday,
        getUserHolidaysSummary,
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HolidayApprovalDialog);