import React from 'react';
import { connect } from 'react-redux';
//MDL
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
  } from 'material-ui/Table';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import AlertIcon from '@material-ui/icons/ErrorOutline';
import { calculateTopBarHeight } from '../../common/helpers/storeUtils';

class EmployeesTable extends React.Component {
    render(){
        let headers = this.props.headers;
        return(
            <Table className="employeesTable"
                onCellClick={(row, cell) => this.props.onCellClick(row, cell)}
                height={`calc(100vh - ${this.props.topBarHeight}px - 110px)`}
                bodyStyle={{overflowY: "scroll"}}
                fixedHeader
                selectable
                multiSelectable={false}>
                <TableHeader
                    displaySelectAll={false}
                    adjustForCheckbox={false}
                    enableSelectAll={false}>
                    <TableRow>
                        {
                            Object.keys(headers).map(function(key, index) {
                                return <TableHeaderColumn key={index} style={{color:'white'}}>{headers[key]}</TableHeaderColumn>;
                        })}
                    </TableRow>
                </TableHeader>
                <TableBody
                    displayRowCheckbox={false}
                    stripedRows={false}>
                    {this.props.rows.map( (row, idx) => (
                        <TableRow key={idx}>
                            {
                                Object.keys(headers).map(function(key, index) {
                                    return (<TableRowColumn className="employeesRowColumn" key={index}>
                                                {
                                                    key === 'availability' ? <span className={'dot ' + (row[key] == 0 ? 'green' : (row[key] == 1 ? 'yellow' : 'red'))} />
                                                    : (key === 'scrumStatus' ? (row[key]? <DoneIcon nativeColor="green"/> : <AlertIcon nativeColor="red"/>)
                                                    : row[key])
                                                }
                                            </TableRowColumn>);})
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
}

const mapStateToProps = (state) => ({
    topBarHeight: calculateTopBarHeight(state)
});

export default connect(mapStateToProps)(EmployeesTable);