import React, { useMemo, useState, useCallback, useEffect, useRef, memo } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import RootRef from '@material-ui/core/RootRef';
import { colors } from './styles';

const stroke = { stroke: colors.nexioOrange, strokeWidth: 2, strokeLinecap: "round"};
const padding = 11;

class Point{
    constructor(x, y){
        this.x = x;
        this.y = y;
    }
    translate(dx, dy) {
        return new Point(this.x + dx, this.y + dy);
    }
}

const SvgLine = ({ points }) => (
    <line x1={points[0].x} x2={points[1].x} y1={points[0].y} y2={points[1].y} {...stroke}/>);

const getPointsProp = (points) =>
    points.map(p => `${p.x} ${p.y}`).join(' ');

const getTrianglePoints = (center, side) => {
    const h = side * Math.sqrt(3/4);
    return [
        center.translate(- side / 2, - h / 2),
        center.translate(0, h / 2),
        center.translate(side / 2, - h / 2)
    ];
};

const SvgDecorator = ({ levels, canExpand, open, height }) => {
    const lines = [];
    const width = 20;
    let p1 = new Point(width, -padding);
    let p2 = p1.translate(0, height);
    for (let i = 0; i < levels.length; i++) {
        if (levels[i]) {
            lines.push([p1, p2]);
        }
        p1 = p1.translate(width, 0);
        p2 = p2.translate(width, 0);
    }
    const iconCenter = p1.translate(0, height / 2);
    const triangle = getTrianglePoints(iconCenter, height * 0.3);
    const downLine = [triangle[1], new Point(triangle[1].x, height - padding)];

    const lineToIcon = [p1.translate(-width, 0), iconCenter];

    return (
    <svg width={levels.length * width + height / 2} height={height - padding * 2} overflow="visible" style={{ overflow: "visible" }}>
        {lines.map((l, i) => <SvgLine key={i} points={l}/>)}
        {levels.length > 0 && (<SvgLine points={lineToIcon}/>)}
        {canExpand ? 
            <polygon points={getPointsProp(triangle)}
                fill={open ? colors.nexioOrange : "black"}
                {...stroke}/> :
            <circle cx = {iconCenter.x} cy={iconCenter.y} r={height * 0.1} fill={colors.nexioOrange}/>}
        {open && 
            <SvgLine points={downLine}/>}

    </svg>);
};


// wrapper - maps id prop to data, for use with e.g. redux
// getId - get Id from data element
// calculateCanExpand - accepts data returns if a row should be expanded
// Returns component which needs a children, that is a function accepting
// one data row and returning content of menu item.
export const getTreeList = ({ wrapper, getId, calculateCanExpand}) => {

    const TreeElement = (props) => {
        const {data, levels, id, children} = props;
        const [open, setOpen] = useState(false);
        const canExpand = calculateCanExpand(data);
        const handleClick = useCallback(() => canExpand && setOpen(!open), [open, canExpand]);
        const itemRef = useRef(null);
        const [itemHeight, setItemHeight] = useState(48);
        useEffect(() => itemRef.current && 
            setItemHeight(itemRef.current.getBoundingClientRect().height), [data, children]);
        return (
            <> 
                <RootRef rootRef={itemRef}>
                    <ListItem button={canExpand} onClick={handleClick}>
                        <SvgDecorator levels={levels} canExpand={canExpand} open={open} height={itemHeight}/> 
                        {children(data)}
                    </ListItem>
                </RootRef>
                {canExpand && (
                    <Collapse in={open} mountOnEnter unmountOnExit>
                        <WrappedTreeList id={id} levels={levels}>{children}</WrappedTreeList>
                    </Collapse>)}
            </>
        );
    };

    const emptyArray = [];

    const TreeList = ({ data, levels, onExpand, id, children,noData }) => {
        useEffect(() => onExpand && onExpand(id), []);
        const newLevels = useMemo(() => levels ? [...levels, 1] : emptyArray, [levels]);
        const endLevels = useMemo(() => levels ? [...levels, 0] : emptyArray, [levels]);
        return (<List disablePadding>
                    {data.map((x, i) => (
                        <TreeElement 
                            key={getId(x)} 
                            id={getId(x)}
                            data={x} 
                            levels={i === data.length - 1 ? endLevels : newLevels}
                        >
                            {children}
                        </TreeElement>))}
                    {noData && (
                        <ListItem>
                            <SvgDecorator height={46} levels={endLevels}/>
                            <ListItemText primaryTypographyProps={{noWrap:true}}>Empty</ListItemText>
                        </ListItem>)}
                </List>
            );
    };
    const WrappedTreeList = wrapper(TreeList);
    return WrappedTreeList;
};
