import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Components
import HeaderDay from './HeaderDay';
//cfg
import {dateFormat} from '../../../common/helpers/date';
//3rd party
import _ from 'lodash';
import moment from 'moment';
//translations
import { EN } from '../../../common/translations';

const translations = EN.timesheetPage.calendarPanel;

const propTypes = {
    date: PropTypes.object,
    selectedMonth: PropTypes.number,
    selectedYear: PropTypes.number,
    issuesNum: PropTypes.number,
    holidaysRows:PropTypes.array, 
    sickLeaveRows:PropTypes.array,
    remoteDays: PropTypes.array
};

const defaultProps = {
    date: null,
    selectedMonth: moment().month(),
    selectedYear: moment().year(),
    holidaysRows:[], 
    sickLeaveRows:[]
};

const HeaderWeek = (props) => {

    const dayNames = translations.days;
    let days = [];

    let { date } = props;
    const { selectedYear, selectedMonth, issuesNum, holidaysRows, sickLeaveRows, remoteDays} = props;

    const newDate = moment({ month: selectedMonth, year: selectedYear });

    for (let i = 0; i < 7; i++) {

        let isHolidays = undefined;
        let isSickLeave = undefined;

        if(holidaysRows.length > 0){
            isHolidays = (date.month() === newDate.month()) ? 
                        holidaysRows.some( (timesheet) => timesheet.date === date.format(dateFormat)) : //date issue must be solved on server side
                        false;
        }
        
        if(sickLeaveRows.length > 0){
            isSickLeave = (date.month() === newDate.month()) ? 
                        sickLeaveRows.some( (timesheet) => timesheet.date === date.format(dateFormat)) : //date issue must be solved on server side
                        false;
        }

        let day = {
            name: dayNames[date.day()],
            //name: date.format("dd").substring(0, 1),
            number: date.date(),
            isCurrentMonth: date.month() === newDate.month(),
            isNextMonth: date.month() > newDate.month() ? "next-month" : "",
            isPrevMonth: date.month() < newDate.month() ? "prev-month" : "",
            isToday: date.isSame(new Date(), "day"),
            date,
            isHolidays,
            isSickLeave
        };

        days.push(day);
        date = date.clone();
        date.add(1, "day");
    }

    return (
        <div className="week">
            {days.map(day => {

                let dayProps ={
                    day,
                    issuesNum,
                    isRemote: remoteDays.some(remote => remote === day.number)
                };

                return (
                    <HeaderDay
                        key={_.uniqueId('day_')}
                        {...dayProps}
                    />
                );
            })}
        </div>
    );
};

HeaderWeek.propTypes = propTypes;
HeaderWeek.defaultProps = defaultProps;

export default HeaderWeek;
