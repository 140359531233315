import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import memoize from "memoize-one";
import { showApproveDialog } from '../../../../store/HolidayPanel';
import Day from '../Presentational/Day';
import { HolidayPanelStyles } from '../../HolidayStyles';
import { Constants } from '../../../common/constants';
import { handleGlobalDrag } from '../../../common/helpers/handleGlobalDrag';
import { openAddDialog, openSummaryDialog,   openSummaryDialogTeam, getUserHolidaysSummary } from '../../../../store/Holidays';
import { dateToIso } from '../../../common/helpers/date';
import '../../Holidays.scss';
import { EN } from '../../../common/translations';
import Details from '@material-ui/icons/Details';
import IconButton from '@material-ui/core/IconButton';

const emptyArray = [];

export class UserMonthBase extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            focusedHoliday: undefined,
            selectionStart: undefined,
            selectionEnd: undefined,
            selection: false,
            startClick: false,
            clickDate: undefined,
            clickHolidayId: undefined
        };
        this.handleDayMouseMove = this.handleDayMouseMove.bind(this);
        this.handleDayMouseLeave = this.handleDayMouseLeave.bind(this);
        this.handleDayMouseDown = this.handleDayMouseDown.bind(this);
        this.handleDayMouseUp = this.handleDayMouseUp.bind(this);
        this.getDays = memoize(this.getDays);
        this.getHolidays = memoize(this.getHolidays);
    }

    getHolidays(holidays, ids) {
        return ids ?
            ids.map(x => holidays[x]).filter(x => x && x.status != Constants.HolidayStatuses.REJECTED) :
            emptyArray;
    }

    getDays(year, month, userHolidays) {
        let numberOfDays = (new Date(year, month + 1, 0)).getDate();

        let days = [];
        for (let i = 1; i <= numberOfDays; i++) {
            let thisDay = new Date(year, month, i);
            let day = {
                date: thisDay,
                holidays: []
            };
            days.push(day);
        }
        for (let holiday of userHolidays) {
            const dateFrom = new Date(holiday.dateFrom);
            const dateTo = new Date(holiday.dateTo);
            const startDay = (dateFrom.getMonth() === month && dateFrom.getFullYear() === year) ? dateFrom.getDate() : 1;
            const lastDay = (dateTo.getMonth() === month && dateTo.getFullYear() === year) ? dateTo.getDate() : numberOfDays;

            for (let i = startDay - 1; i < lastDay; i++) {
                days[i].holidays.push(holiday);
            }
        }
        return days;
    }

    handleDayMouseMove(holidayId) {
        this.setState({ focusedHoliday: holidayId });
    }

    handleDayMouseLeave() {
        this.setState({ focusedHoliday: undefined });
    }

    handleDayMouseDown(event, holidayId, date) {
        if (event.button !== 0)
            return;
        if (holidayId) {
            this.setState({ startClick: true, clickHolidayId: holidayId });
        } else if (this.props.enableSelection) {
            const initialIndex = date.getDate() - 1;
            const maxDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
            const { left, width } = event.target.getBoundingClientRect();
            const tableStart = left - initialIndex * width;
            this.setState({ selection: true, selectionStart: initialIndex, selectionEnd: initialIndex });
            handleGlobalDrag({
                handleMouseMove: (e) => {
                    const { clientX } = e;
                    const index = Math.max(Math.min(Math.floor((clientX - tableStart) / width), maxDay), 0);
                    const selection = (index < initialIndex) ?
                        [index, initialIndex] :
                        [initialIndex, index];
                    this.setState({ selectionStart: selection[0], selectionEnd: selection[1] });
                },
                handleMouseUp: (e) => {
                    if (e.button !== 0)
                        return;
                    this.props.onDaysSelect(new Date(date.getFullYear(), date.getMonth(), this.state.selectionStart + 1),
                        new Date(date.getFullYear(), date.getMonth(), this.state.selectionEnd + 1));
                },
                handleCleanup: () => this.setState({ selection: false, selectionStart: undefined, selectionEnd: undefined })
            });
        }
        event.preventDefault();
        event.stopPropagation();
    }

    handleDayMouseUp(_event, holidayId) {
        if (this.state.startClick && this.state.clickHolidayId === holidayId && this.props.onDayClick) {
            this.props.onDayClick(holidayId);
        }
        this.setState({
            selection: false,
            startSelectionDayIndex: undefined,
            startClick: false,
            clickDate: undefined,
            clickHolidayId: undefined
        });
    }

    render() {
        const holidays = this.getHolidays(this.props.holidays, this.props.workerHolidayIds);
        const days = this.getDays(this.props.year, this.props.month, holidays);
        return (
            <div style={{ display: 'flex', flexFlow: 'row wrap', width: '1700px'}}>
                <div className="nametooltip" style={HolidayPanelStyles.userFullNameCell} title = {
                    this.props.worker ? this.props.worker.firstname + ' ' + this.props.worker.lastname : null}>
                    {this.props.worker ? this.props.getWorkerDescription(this.props.worker) : null}
                </div>
                <div ref={(div) => this.daysRef = div} style={this.props.enableSelection ? HolidayPanelStyles.pointerStyle : { minHeight: '25px', height: '25px' }}>
                    {days.map((day, i) => {
                        return (
                            <Day
                                key={i}
                                date={day.date}
                                index={this.props.index}
                                focusedHoliday={this.state.focusedHoliday}
                                highlightedHoliday={this.props.highlightedHoliday}
                                highlighted={this.state.selectionStart <= i && this.state.selectionEnd >= i}
                                holidays={day.holidays}
                                onMouseEnter={this.handleDayMouseMove}
                                onMouseMove={this.handleDayMouseMove}
                                onMouseLeave={this.handleDayMouseLeave}
                                onMouseDown={this.handleDayMouseDown}
                                onMouseUp={this.handleDayMouseUp} />
                        );
                    })}
                        <div style={HolidayPanelStyles.headerSummaryCol}>
                            {this.props.enableButton && 
                                <IconButton tooltip={EN.common.summary} style={HolidayPanelStyles.largeIcon} 
                                    onClick={() => { this.props.getUserHolidaysSummary(this.props.worker.id, null, this.props.year);
                                                        this.props.openSummaryDialogTeam(this.props.worker,this.props.year,this.props.worker.firstname,this.props.worker.lastname);}}>
                                    <Details />
                                </IconButton>
                            }
                      </div>
                </div>
                <div style={HolidayPanelStyles.clearBoth} />
            </div>
        );
    }
}


UserMonthBase.defaultProps = {
    getWorkerDescription: (w) => {
        const name = w.firstname + ' ' + w.lastname;
        if (name.length <= 24) {
            return w.firstname + ' ' + w.lastname;
        }
        w.sliced = true;
        return name.substring(0, 25) + '...';
    }
};

function mapStateToProps(state, { workerId, disableDayInteraction }) {
    return {
        worker: state.holidays.workers[workerId],
        holidays: state.holidays.data,
        workerHolidayIds: state.holidayPanel.calendarIds[workerId],
        year: state.holidayPanel.year,
        month: state.holidayPanel.month,
        highlightedHoliday: workerId === (state.holidays.data[state.holidayPanel.openHolidayId] || {}).userId ?
            state.holidayPanel.openHolidayId : 0,
        enableSelection: !disableDayInteraction
    };
}

function mapDispatchToProps(dispatch, { workerId, disableDayInteraction }) {
    if (disableDayInteraction) return ({
        onDayClick: () => undefined,
        onDaysSelect: () => undefined,
    });
    return Redux.bindActionCreators({
        getUserHolidaysSummary,
        openSummaryDialog,
        openSummaryDialogTeam,
        onDayClick: showApproveDialog,
        onDaysSelect: (start, end) => openAddDialog(true, { dateFrom: dateToIso(start), dateTo: dateToIso(end), userId: workerId })
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(UserMonthBase);
