import React, { Component } from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { dateToIso } from '../../../common/helpers/date';
import PropTypes from 'prop-types';
//3rd party
import _ from 'lodash';
import {Tooltip} from 'react-tippy';
import { holidayColors } from '../../../holidays/Panel/Presentational/HolidayColors';
import { Constants } from '../../../common/constants';
import { EN } from '../../../common/translations';

const propTypes = {
  day: PropTypes.object,
  issuesNum: PropTypes.number
};

const defaultProps = {
  day: null,
  issuesNum: 0
};

const HeaderDay = (props) => {

  const {
        day: {
            name,
            number,
            date,
            isCurrentMonth,
            isNextMonth,
            isPrevMonth,
            isToday,
            isHolidays,
            isSickLeave
          },
          issuesNum,
          isRemote,
          publicHolidays
      } = props;
      
  const dayJSX = (
    <div
      className={"day" + (isToday ? " today" : "") + " headerday-num-" + number +
        (issuesNum > 0 ? " issues-loaded" : "") +
        (isCurrentMonth ? " current-month" : " different-month") +
        (publicHolidays.has(dateToIso(date.toDate()).substr(0, 10)) ? " public-holidays " : "") +
        (isSickLeave ? " sick-leave" : "") +
        (isPrevMonth ? " prev-month" : "")}
        style={isRemote ? {"border": `1px solid ${holidayColors[Constants.HolidayTypes.REMOTE][Constants.HolidayStatuses.ACCEPTED][false]}`} : null}
    >
      <div className="day-item-content">{number}</div>
      <div className="day-item-content">{name}</div>
    </div>
  );

  if(isRemote)
    return (
      <Tooltip title={EN.timesheetPage.remoteWork} position="top" trigger="mouseenter">
        {dayJSX}
      </Tooltip>
    );
  else
    return dayJSX;
};

function mapStateToProps(state) {
  return {
      publicHolidays: state.publicHolidays.wtPublicHolidays
  };
}

function mapDispatchToProps(dispatch) {
  return Redux.bindActionCreators({
  }, dispatch);
}

HeaderDay.propTypes = propTypes;
HeaderDay.defaultProps = defaultProps;

export default ReactRedux.connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderDay);