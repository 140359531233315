import React, { Component } from 'react';
import TeamTimesheetAside from './aside/teamTimesheetAside';
import TeamTimesheetMain from './main/teamTimesheetMain';
import "./teamTimesheet.scss";


export default class TeamTimesheet extends Component {

    render() {
        return (
            <div className="teamTimesheet-wrapper">
                <TeamTimesheetAside/>
                <TeamTimesheetMain/>
            </div>
        );
    }
}

