import {host} from '../components/common/cfg';
import axios from 'axios';
import { dateToApi } from '../components/common/helpers/date';

const types = {
    SET_AVAIABLE_HR_ISSUES: 'SET_AVAIABLE_HR_ISSUES',
    SET_HR_ISSUES: 'SET_HR_ISSUES',
    GET_HR_ISSUES: 'GET_HR_ISSUES'
};

/** ACTIONS START */
export const onSetAvaiableHrIssues = (hrIssuesAvaiable) => ({type: types.SET_AVAIABLE_HR_ISSUES, hrIssuesAvaiable});
export const onSetHrIssues = (hrIssues) => ({type: types.SET_HR_ISSUES, hrIssues});
export const onGetHrIssuesAjax = (projectId, year) => {

    return (dispatch) => {
        let headers = {
            // 'Content-Type': 'application/json;'
            // 'Accept': 'application/json',
        };
        
        const startDate = dateToApi(new Date(year, 0, 1));
        const endDate = dateToApi(new Date(year, 12, 0));

        let params = {projectId, startDate, endDate};
        return axios
            .get(`${host}/api/timesheet`, { params }, {headers})
            .then(response => {
                return response;
            });
    };
};
/** ACTIONS END */

// /** REDUCER START */
const hrIssuesAvaiableModel = [];

export const hrIssuesAvaiable = (state = hrIssuesAvaiableModel, action) => {
    let newState = null;

    switch (action.type) {
        
        case types.SET_AVAIABLE_HR_ISSUES:

            return action.hrIssuesAvaiable;

        default:
            return state || [];
    }
};

const hrIssuesModel = [];

export const hrIssues = (state = hrIssuesModel, action) => {
    let newState = null;

    switch (action.type) {
        
        case types.SET_HR_ISSUES:

            return action.hrIssues;

        default:
            return state || [];
    }
};
/** REDUCER END */
