import React from 'react';
import SelectSearch from 'react-select-search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const SearchInput = ({disabledInputs, options, handleChange, handleInputOnChange, valueInput, valueSearch, multiple=false}) => {
    const selectSupervisiorSearchAndInput = !disabledInputs ? (options.length ? (
        <div className="selectPosition">
            <SelectSearch
                options={options}
                value={valueSearch}
                onChange={handleChange}
                multiple={multiple}
            />
            <div className="arrowSelect">
                <ArrowDropDownIcon/>
            </div>
        </div>
        ) : null) : (
            <input value={valueInput} onChange={handleInputOnChange} disabled />
        );

    return selectSupervisiorSearchAndInput;

};

export default SearchInput;