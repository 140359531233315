import {host} from '../components/common/cfg';
import axios from 'axios';

const types = {
    SELECTED_FILE: 'SELECTED_FILE',
    GET_FILES_LIST: 'GET_FILES_LIST'
};

/** ACTIONS START */
export const onFileSelect = (selectedFile) => ({type: types.SELECTED_FILE, selectedFile});
export const onGetFiles = () => {
    return (dispatch) => {
        let headers = {
            'headers': {
                'Content-Type': 'application/json;',
                'Accept': 'application/json'
            }
        };

        return axios
            .get(`${host}/api/timesheet/getfiles`, headers)
            .then(response => {
                return response;
            });
    };
};
/** ACTIONS END */

// /** REDUCER START */
export const selectedFile = (state = {}, action) => {
    switch (action.type) {

        case types.SELECTED_FILE:
            return action.selectedFile;

        default:
            return state || {};
    }
};

export const files = (state = [], action) => {
    switch (action.type) {
        
        case types.SET_FILES_LIST:

            return action.files;

        default:
            return state || [];
    }

};