import {host} from '../components/common/cfg';
import axios from 'axios';
import { getAccessToken } from './Login';

/** CONSTANTS START */

const endPoints = {
    CHANGE_LOG_LIST_FETCH: '/api/changeLog/getAll',
    CHANGE_LOG_FILE_DOWNLOAD: '/api/changeLog/downloadFile',
    CHANGE_LOG_CREATE: '/api/changeLog/save',
    CHANGE_LOG_LIST_DELETE: '/api/changeLog/deleteAll'
};

const types = {
    SET_CHANGE_LOG: 'SET_CHANGE_LOG',
    OPEN_CHANGE_LOG: 'OPEN_CHANGE_LOG',
    CLOSE_CHANGE_LOG: 'CLOSE_CHANGE_LOG'
};

const defaultHeadersRequest = {
    'headers': {
        'Content-Type': 'application/json'
    }
};

/** CONSTANTS END */

/** ACTIONS START */

export const setChangeLog = (changeLog) => ({
    type: types.SET_CHANGE_LOG,
    payload: changeLog
});

export const openChangeLog = (changeLog) => ({
    type: types.OPEN_CHANGE_LOG,
    payload: changeLog
});

export const closeChangeLog = () => ({
    type: types.CLOSE_CHANGE_LOG
});

export const getAllChangeLogs = () => 
(dispatch) =>
    axios
        .get(`${host + endPoints.CHANGE_LOG_LIST_FETCH}`, defaultHeadersRequest);


export const downloadFileById = (id) => {
    const accessToken = getAccessToken();
    window.open(`${host + endPoints.CHANGE_LOG_FILE_DOWNLOAD}/${id}?access_token=${accessToken}`);
};
export const addChangeLog = (changeLog, file, fileDeleted) =>
(dispatch) => {
    const data = new FormData();
    if(file){
        data.append("file", file);
    } else if(fileDeleted){
        data.append('fileDeleted', fileDeleted);
    }
    data.append("title", changeLog.title);
    data.append("description", changeLog.description);
    if(changeLog.id){
        data.append("id", changeLog.id);
    }

    return axios
        .put(`${host + endPoints.CHANGE_LOG_CREATE}`, data, {headers: {  'Content-Type': 'multipart/form-data'}});
    };

export const deleteAllChangeLog = (changeLogId) => 
(dispatch) =>
    axios
        .delete(`${host + endPoints.CHANGE_LOG_LIST_DELETE}?changeLogId=${changeLogId}`, defaultHeadersRequest);

export const onChangeLogsGet = () => 
(dispatch) =>
    axios
        .get(`${host + endPoints.CHANGE_LOG_LIST_FETCH}`, defaultHeadersRequest)
        .then(response => {
            dispatch(openChangeLog(response.data));
        });

/** ACTIONS END */

/** REDUCER START */

const INITIAL_STATE = {
    changeLogs: [],
    isChangeLogsOpen: false
};

export const changeLogStore = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.SET_CHANGE_LOG:
            return {...state, changeLogs: action.payload};
        case types.OPEN_CHANGE_LOG:
            return {...state, changeLogs: action.payload, isChangeLogsOpen: true};
        case types.CLOSE_CHANGE_LOG:
            return {...state, isChangeLogsOpen: false};
        default:
            return state;
    }
};
/** REDUCER END */