import React, {useMemo, memo} from 'react';
import PropTypes from 'prop-types';
//Redux
import * as ReactRedux from 'react-redux';
//styles
import {sideMenuStyle} from '../common/styles';
//3rd party libs
import { withRouter } from "react-router-dom";
import { calculateTopBarHeight } from '../common/helpers/storeUtils';

//CONTENT WRAPPER START
const propTypes = {
    topBarHeight: PropTypes.number, 
    sideMenuOpen: PropTypes.bool, 
    dispatch: PropTypes.func
  };

const defaultProps = {
    topBarHeight: 0, 
    sideMenuOpen: false, 
    dispatch: undefined
};

const ContentWrapper = (props) => 
{
    const { topBarHeight, sideMenuOpen, children } = props;
    const commonStyle = useMemo(() => ({
        height: `calc(100vh - ${topBarHeight}px)`
    }), [topBarHeight]);

    //transition jest analogiczny do transformacji z MUI
    const styleSideMenuOpen = useMemo(() => ({
        overflow: 'hidden', 
        transform: `translateX(${sideMenuStyle.width}px)`,
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        ...commonStyle 
    }), [commonStyle]);

    const styleSideMenuClose = useMemo(() => ({
        overflow: 'hidden', 
        transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        ...commonStyle
    }), [commonStyle]);

    const overflowStyle = props.location.pathname === '/report' ? {} : { overflow: 'auto'};
    return(
        <div style={sideMenuOpen ? styleSideMenuOpen : styleSideMenuClose}>
            {/*Użycie animacji powoduje dodanie atrybutu transform, który z kolei zmienia
            initial containing block dla elementów z display: fixed. Snackbar podczas ukrywania
            przemieszcza się poniżej ekranu, co przy normalnym initial containing block (<html>)
            powoduje że nie wpływa na scrollowanie, jednak gdy jest div z animacją, to wtedy on jest
            rozszerzany przez ten ukryty element. Ten dodatkowy div powoduje, że w tym przypadku nie
            pojaiwają się nadmiarowe paski przewijania*/}
            <div style={{ ...commonStyle, ...overflowStyle }}>
                {children}
            </div>
        </div>
    );
};

ContentWrapper.propTypes = propTypes;
ContentWrapper.defaultProps = defaultProps; 

function mapStateToProps(state){
    return {
        sideMenuOpen: state.sideMenu.isOpen,
        topBarHeight: calculateTopBarHeight(state)
    };
}

export default withRouter(ReactRedux.connect(
    mapStateToProps
)(memo(ContentWrapper)));
//CONTENT WRAPPER END
