import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import memoize from "memoize-one";

import { HolidayPanelStyles } from './HolidayStyles';
import { onGetPublicHolidays } from '../../store/WtHolidays';
import HolidayUserMonth from './Panel/Container/HolidayUserMonth';
import CalendarHeader from './Panel/Presentational/CalendarHeader';
import Legend from './Panel/Presentational/Legend';
import CalendarControls from '../timeSheet/calendarPanel/CalendarControls';
import { Constants } from '../common/constants';
import { EN } from '../common/translations';

import { fetchCalendarData, fetchWorkers } from '../../store/Holidays';
import HideableSection from '../common/partials/HideableSection';

class HolidayMyTeam extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openworkers: true,
            openteam: true,
            opensupervisor: true
        };
    }

    componentDidMount() {
        this.props.fetchWorkers(this.props.userId);
        this.props.fetchCalendarData(this.props.month, this.props.year);
        this.props.onGetPublicHolidays();
    }

    render() {
        const calendarControlsProps = {
            month: this.props.month,
            year: this.props.year,
            onChange: this.props.fetchCalendarData
        };

        const GetUserMonth = (id, index) =>
            (<HolidayUserMonth
                key={id}
                workerId={id}
                index={index}
            />);

        let supervisor, myteam, workers;

        if (this.props.supervisorId)
            supervisor = (
                <div>
                    <HideableSection text={EN.holidaysPage.supervisor}
                                    onToggle={()=>this.setState({ opensupervisor: !this.state.opensupervisor })} 
                                    in={this.state.opensupervisor}>
                        <div className="calendar-header calendar-item">
                            {GetUserMonth(this.props.supervisorId)}
                        </div>
                    </HideableSection>
                </div>);

        myteam = (
            <div>
                <HideableSection text={EN.holidaysPage.myteam}
                                onToggle={() => this.setState({ openteam: !this.state.openteam })}
                                in={this.state.openteam}>
                    <div className="calendar-item">
                        {
                            this.props.teamIds.map((id, index) => {
                                return GetUserMonth(id, index);
                            })
                        }
                    </div>
                </HideableSection>
            </div>);

        if (this.props.login.user.roles.indexOf(Constants.Roles.supervisor) !== -1)
            workers =
                (<div>
                    <div style={HolidayPanelStyles.clearBoth} />
                    <HideableSection text={EN.holidaysPage.myworkers}
                                    onToggle={()=>this.setState({ openworkers: !this.state.openworkers })} 
                                    in={this.state.openworkers}>
                        <div className="calendar-item">
                            {this.props.workerIds.map(GetUserMonth)}
                        </div>
                    </HideableSection>
                </div>);

        return (
            <div className="calendar-panel">
                <div className="calendar">
                    <div className="calendar-controls">
                        <CalendarControls
                            {...calendarControlsProps} />
                    </div>
                    <CalendarHeader
                        selectedYear={this.props.year}
                        selectedMonth={this.props.month} 
                        holidaySummary={false}/>
                    {supervisor}

                    {myteam}

                    {workers}

                </div>
                <Legend />
            </div>
        );
    }
}
const getUserIds = (workerIds, supervisorId, workers) =>
    workerIds ? workerIds.filter(x => workers[x].supervisorid === supervisorId) : [];

const getWorkerIds = memoize(getUserIds);

const getTeamIds = memoize(getUserIds);

function mapStateToProps(state) {
    const userId = state.login.user.id;
    const user = state.holidays.workers[userId];
    const supervisorId = user ? user.supervisorid : undefined;
    const workerIds = getWorkerIds(state.holidays.workerIds, userId, state.holidays.workers);
    const teamIds = getTeamIds(state.holidays.workerIds, supervisorId, state.holidays.workers);
    return {
        login: state.login,
        year: state.holidays.year,
        month: state.holidays.month,
        userId,
        supervisorId,
        workerIds,
        teamIds,
        publicHolidays: state.publicHolidays.wtPublicHolidays,
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        fetchCalendarData,
        fetchWorkers,
        onGetPublicHolidays
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HolidayMyTeam);