import React from "react";
import Select, { components } from "react-select";
import searchableSelectStyles from "./searchableSelectStyles";
import DropdownIndicator from "./DropdownIndicator";

const SupervisorSelect = (props) => {
  return (
    <Select
      components={{ DropdownIndicator }}
      placeholder=""
      styles={searchableSelectStyles}
      menuPortalTarget={document.body}
      options={props.options}
      onChange={props.onChange}
    />
  );
};

export default SupervisorSelect;
