import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { Link } from 'react-router-dom';
import { onSideMenuBtnClick } from '../../store/SideMenu';
//MDL
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import RootRef from '@material-ui/core/RootRef';

import Divider from '@material-ui/core/Divider';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import PermIdentity from '@material-ui/icons/PermIdentity';
import MailOutline from '@material-ui/icons/MailOutline';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import Airplain from '@material-ui/icons/AirplanemodeActive';
import People from '@material-ui/icons/People';
import Lock from '@material-ui/icons/Lock';
import Book from '@material-ui/icons/Book';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Settings from '@material-ui/icons/Settings';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Assessment from '@material-ui/icons/Assessment';
import Assignment from '@material-ui/icons/Assignment';
import BeachAccess from '@material-ui/icons/BeachAccess';
import Work from '@material-ui/icons/Work';
import WorkOff from '@material-ui/icons/WorkOff';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';

//Common
import { sideMenuStyle, colors as customColors } from '../common/styles';
import { EN } from '../common/translations';
import { Constants } from '../common/constants';
import { checkRolesAndTeamRoles } from '../common/helpers/authorization';
import { Badge } from 'material-ui';

const propTypes = {
    sideMenu: PropTypes.object,
    onSideMenuBtnClick: PropTypes.func
};

const styleProp = {
    style: {
        width: sideMenuStyle.width,
        height: 'calc(100% - 64px)',
        top: '64px',
    }
};

const badgeStyle = {
    'backgroundColor': customColors.nexioOrange,
    'top': '-11px',
    'right': '-25px',
    'zIndex': '5'
};

const linkStyle = { textDecoration: 'none' };

const ListItemTextWithBadge = (props) => {
    if (props.badge)
        return (
            <ListItemText>
                <Badge badgeStyle={badgeStyle} style={{ 'padding': '0' }} badgeContent={props.badge}>
                    {props.children}
                </Badge>
            </ListItemText>
        );
    return <ListItemText>{props.children}</ListItemText>;
};

class SideMenu extends React.Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.closeSubMenu = () => undefined;
    }

    handleClose() {
        this.props.onSideMenuBtnClick();
    }

    render() {
        const LinkItem = ({ Icon, text, to, roles, badge, teamRoles, linkRef, activeReplacements = false }) =>
            checkRolesAndTeamRoles(this.props.user, roles, teamRoles) || activeReplacements ?
                (<MenuItem component={(props) => (<Link
                    to={to}
                    style={linkStyle}
                    onClick={this.handleClose}
                    tabIndex={this.props.sideMenu.isOpen ? "0" : "-1"}
                    innerRef={linkRef} {...props} />)}>
                    <ListItemIcon><Icon color="primary" /></ListItemIcon>
                    <ListItemTextWithBadge badge={badge}>{text}</ListItemTextWithBadge>
                </MenuItem>) :
                null;

        const NestedMenu = ({ Icon, text, roles, teamRoles, children, badge }) => {
            const [open, setOpen] = useState(false);
            const parentRef = useRef();
            if (!checkRolesAndTeamRoles(this.props.user, roles, teamRoles)) return null;
            return (
                <>
                    <RootRef rootRef={parentRef}>
                        <MenuItem button onClick={() => setOpen(true)}>
                            <ListItemIcon><Icon color="primary" /></ListItemIcon>
                            <ListItemTextWithBadge badge={badge}>{text}</ListItemTextWithBadge>
                        </MenuItem>
                    </RootRef>
                    <Menu open={open} onClose={() => setOpen(false)} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} anchorEl={parentRef.current}>
                        {children}
                    </Menu>
                </>);
        };
        const { requestsToAccept } = this.props.notificationCount;

        return (
            <Drawer
                onClose={() => this.props.onSideMenuBtnClick()}
                open={this.props.sideMenu.isOpen}
                PaperProps={styleProp}
                SlideProps={styleProp}>
                <MenuList>
                    <LinkItem to="/timesheet" Icon={FormatListNumbered} text={EN.sideMenu.projectsBtn} />
                    <LinkItem to="/scrum" Icon={People} text={EN.sideMenu.scrumBtn} teamRoles={[Constants.TeamRoles.scrumMaster]} />
                    <NestedMenu Icon={Assessment} text={EN.sideMenu.projectManagmentBtn} roles={[Constants.Roles.evaluator, Constants.Roles.admin, Constants.Roles.supervisor]}>
                        <LinkItem to="/pricing" Icon={MonetizationOn} text={EN.sideMenu.pricingBtn} roles={[Constants.Roles.evaluator]} />
                        <LinkItem to="/supervisedEmployees" Icon={SupervisedUserCircle} text={EN.sideMenu.employeesTasksBtn} roles={[Constants.Roles.admin, Constants.Roles.supervisor]} />
                    </NestedMenu>
                    <NestedMenu Icon={Airplain} badge={requestsToAccept} text={EN.sideMenu.holiday.mainBtn} roles={[Constants.Roles.holiday, Constants.Roles.supervisor]}>
                        <LinkItem to="/holidays" Icon={Airplain} text={EN.sideMenu.holiday.myBtn} roles={[Constants.Roles.holiday]} />
                        <LinkItem to="/holidaypanel" badge={requestsToAccept} Icon={Airplain} text={EN.sideMenu.holiday.otherBtn} roles={[Constants.Roles.supervisor]} />
                    </NestedMenu>
                    <LinkItem to="/teamTimesheet" badge={this.props.teamTimesheetCount} Icon={AccessTimeIcon} text={EN.sideMenu.teamTimesheetBtn} roles={[Constants.Roles.supervisor, Constants.Roles.admin,Constants.Roles.hr]} />
                    <LinkItem to="/settings" Icon={Settings} text={EN.sideMenu.settingsBtn} roles={[Constants.Roles.supervisor, Constants.Roles.admin]} />
                    <NestedMenu Icon={VerifiedUser} text={EN.sideMenu.adminBtn} roles={[Constants.Roles.admin]} teamRoles={[Constants.TeamRoles.teamLeader]}>
                        <LinkItem to="/changeLogs" Icon={Book} text={EN.sideMenu.changeLogs} roles={[Constants.Roles.admin]} />
                        <LinkItem to="/employees" Icon={PermIdentity} text={EN.sideMenu.employeesBtn} roles={[Constants.Roles.admin]} />
                        <LinkItem to="/teamManagement" Icon={People} text={EN.sideMenu.teamManagementButton} roles={[Constants.Roles.admin]} teamRoles={[Constants.TeamRoles.teamLeader]} />
                        <LinkItem to="/notifications" Icon={MailOutline} text={EN.sideMenu.notifications} roles={[Constants.Roles.admin]} />
                        {process.env.NODE_ENV !== 'production' &&
                            <LinkItem to="/mailWhitelist" Icon={ContactMailOutlinedIcon} text={EN.sideMenu.mailWhitelist} roles={[Constants.Roles.admin]} />
                        }
                    </NestedMenu>
                    <NestedMenu Icon={People} text={EN.sideMenu.hr.mainBtn} roles={[Constants.Roles.hr]}>
                        <LinkItem to="/hrHolidays" Icon={BeachAccess} text={EN.sideMenu.hr.hrHolidays} />
                        <NestedMenu Icon={Assignment} text={EN.sideMenu.hr.reports}>
                            <LinkItem to="/workTimeReport" Icon={Work} text={EN.sideMenu.hr.workTime.main} />
                            <LinkItem to="/workTimeIrregular" Icon={WorkOff} text={EN.sideMenu.hr.workTime.irregular} />
                        </NestedMenu>
                    </NestedMenu>
                    <Divider />
                    <LinkItem to="/changePassword" Icon={Lock} text={EN.sideMenu.changePasswordBtn} />
                </MenuList>
            </Drawer>);
    }
}

SideMenu.propTypes = propTypes;

function mapStateToProps(state) {
    return {
        sideMenu: state.sideMenu,
        user: state.login.user,
        notificationCount: state.holidays.notificationCount,
        teamTimesheetCount: state.teamTimesheet.teamTimesheetCount

    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onSideMenuBtnClick
    }, dispatch);
}
export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(SideMenu);