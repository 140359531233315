import React from 'react';
//redux
import * as ReactRedux from 'react-redux';
//Translations
import { EN } from '../../../common/translations';
import { Constants } from '../../../common/constants';
import { holidayColors } from './HolidayColors';
import './legend.scss';

const translations = EN.holidaysPage.legend;

const Circle = (props) =>
    (<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-5 -5 10 10" style={{ width: props.radius * 2, height: props.radius * 2 }}>
        <circle r="5" stroke="#333" strokeWidth="1" fill={props.color} />
    </svg>);

const Row = ({ empty, type, status, text }) => {
    let circle = null;

    if (!empty)
        circle = (<>
            <Circle color={holidayColors[type][status][false]} radius="8" /> {text}
        </>);

    return (
        <div className="legend-element col col-sm-3">
            {circle}
        </div>
    );
};

const newHolidayTypesForEmploees = props => {
    let rows = (<>
        {newUnpaidHoliday()}
        <Row empty />
    </>);

    //on Absences tab always full legend should be visible (isCalendar === false)
    if (isEmployeeOrAbsencesPanelView(props)) {
        rows = (<>
            <Row
                text={translations.newSickLeave}
                type={Constants.HolidayTypes.SICK_LEAVE}
                status={Constants.HolidayStatuses.NEW} />
            <Row
                text={translations.newOtherHoliday}
                type={Constants.HolidayTypes.OTHERS}
                status={Constants.HolidayStatuses.NEW} />
        </>);
    }
    return rows;
};

const AcceptedHolidayTypesForEmploees = props => {
    let rows = (<>
        {acceptedUnpaidHoliday()}
        <Row empty />
    </>);

    //on Absences tab always full legend should be visible (isCalendar === false)
    if (isEmployeeOrAbsencesPanelView(props)) {
        rows = (<>
            <Row
                text={translations.acceptedSickLeave}
                type={Constants.HolidayTypes.SICK_LEAVE}
                status={Constants.HolidayStatuses.ACCEPTED} />
            <Row
                text={translations.acceptedOtherHoliday}
                type={Constants.HolidayTypes.OTHERS}
                status={Constants.HolidayStatuses.ACCEPTED} />
        </>);
    }
    return rows;
};

const newUnpaidHoliday = () => <Row text={translations.newUnpaidHoliday} type={Constants.HolidayTypes.UNPAID_HOLIDAY} status={Constants.HolidayStatuses.NEW} />;
const acceptedUnpaidHoliday = () => <Row text={translations.acceptedUnpaidHoliday} type={Constants.HolidayTypes.UNPAID_HOLIDAY} status={Constants.HolidayStatuses.ACCEPTED} />;
const isEmployeeOrAbsencesPanelView = ({isCalendar, contractName}) => !isCalendar || contractName === Constants.ContractTypes.Employment;

const Legend = (props) => {
    return (
        <div className="legend container-fluid" style={props.isCalendar ? { marginTop: '40px', marginLeft: '-15px' } : {}}>
            <div className="row">
                <Row text={translations.newHoliday} type={Constants.HolidayTypes.HOLIDAY} status={Constants.HolidayStatuses.NEW} />
                <Row text={translations.newRemote} type={Constants.HolidayTypes.REMOTE} status={Constants.HolidayStatuses.NEW} />
                {newHolidayTypesForEmploees(props)}
                {isEmployeeOrAbsencesPanelView(props) ? newUnpaidHoliday() : null}
            </div>
            <div className="row">
                <Row text={translations.acceptedHoliday} type={Constants.HolidayTypes.HOLIDAY} status={Constants.HolidayStatuses.ACCEPTED} />
                <Row text={translations.acceptedRemote} type={Constants.HolidayTypes.REMOTE} status={Constants.HolidayStatuses.ACCEPTED} />
                {AcceptedHolidayTypesForEmploees(props)}
                {isEmployeeOrAbsencesPanelView(props) ? acceptedUnpaidHoliday() : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ contractName: state.login.user.contractName });

export default ReactRedux.connect(mapStateToProps, null)(Legend);