import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { connect } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from './TeamButton';
import RootRef from '@material-ui/core/RootRef';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

import { fetchActiveUsers } from '../../store/Teams';
import {EN} from '../common/translations';

//Supports dynamic mounting to allow for fast user roles editing

const menuCount = 10;

const UsersControl = ({ onSelect, teamUsers, allUsers, unmount, anchorEl }) => {
    const [open, setOpen] = useState(true);
    const [extended, setExtended] = useState(false);
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const usedUsersSet = useMemo(() => new Set(teamUsers.map(x => x.userId)), [teamUsers]);
    const timeoutRef = useRef();
    const handleSearchChange = useCallback((e) => {
        const search = e.target.value;
        setSearch(search);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
        timeoutRef.current = setTimeout(() => {
            setSelectedIndex(0); 
            setDebouncedSearch(search.toUpperCase());
        }, 300);
    }, []);

    const [users, isOverflow] = useMemo(() => {
        let cnt = 0;
        const ret = [];
        for (let u of allUsers) {
            if (usedUsersSet.has(u.id) ||  
                debouncedSearch !== "" &&
                !u.firstname.toUpperCase().includes(debouncedSearch) && 
                !u.lastname.toUpperCase().includes(debouncedSearch)) {
                continue;
            }
            ret.push(u);
            cnt++;
            if (cnt > menuCount && extended === false)
                return [ret, true];
        }
        return [ret, false];
    }, [debouncedSearch, usedUsersSet, allUsers, extended]);
    const searchRef = useRef();
    const focusInput = useCallback(() => searchRef.current && searchRef.current.focus(), []);
    useEffect(() => focusInput, [teamUsers, searchRef.current]);
    const handleKeyDown = useCallback((e) => {
        if (e.key === "ArrowDown") {
            setSelectedIndex((selectedIndex + 1) % users.length);
        } else if (e.key === "ArrowUp") {
            setSelectedIndex((selectedIndex + users.length - 1) % users.length);
        } else {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
    }, [selectedIndex]);
    const extendSearch = () => {
        setExtended(true);
    };

    return (
        <Menu 
           TransitionProps={{onExited: unmount, appear: true}} 
            open={open}
            disableAutoFocusItem
            onClose={() => setOpen(false)}
            anchorEl={anchorEl}
            >
            <MenuItem dense>
                <form onSubmit={(e) => { e.preventDefault(); users[selectedIndex] && onSelect(users[selectedIndex]); }}>
                    <TextField 
                        onKeyDown={handleKeyDown}
                        fullWidth 
                        onChange={handleSearchChange} 
                        value={search} 
                        autoFocus 
                        inputRef={searchRef}/>
                </form>
                <Search/> 
            </MenuItem>
            {users.map((x, i) => (
                <MenuItem dense key={x.id} onClick={() => onSelect(x)} selected={selectedIndex === i}>
                    {x.lastname} {x.firstname}
                </MenuItem>))}
            {isOverflow && <MenuItem dense onClick={() => extendSearch()}>... more than {menuCount} results</MenuItem>}
        </Menu>);
};

const MountableUsersControl = ({ open, onClose, ...rest }) => {
    return (open ? <UsersControl unmount={onClose} {...rest}/> : null); 
};

const AddUserButton = ({users, allUsers, setUsers, fetchActiveUsers}) => {
    useEffect(() => fetchActiveUsers(), []);
    const [open, setOpen] = useState(false);
    const buttonRef = useRef();
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleSelect = useCallback((u) => {
        setUsers([{
            userId: u.id, 
            firstname: u.firstname, 
            lastname: u.lastname, 
            userTeamRoles: []
        }, ...users]);
    }, [users, setUsers, setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    return (<>
        <RootRef rootRef={buttonRef}>
            <Button 
                variant="outlined" 
                color="primary" 
                onClick={handleOpen}
            >
                {EN.teamManagement.addUser}
            </Button>
        </RootRef>
        <MountableUsersControl 
            open={open} 
            onClose={handleClose}
            onSelect={handleSelect} 
            teamUsers={users} 
            allUsers={allUsers} 
            anchorEl={buttonRef.current}/>
        </>);
};

const mapStateToProps = (state) => ({
    allUsers: state.teams.activeUsers,
});

const mapDispatchToProps = {
    fetchActiveUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserButton);