import React from 'react';
import PropTypes from 'prop-types';
//Material UI
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import DatePicker from '../../common/controls/DatePicker';
//Translations
import { EN } from '../../common/translations';
//styles
import {colors} from '../../common/styles.js';
import { white, black } from 'material-ui/styles/colors';
//Component
import ScrumTasksTable from './ScrumTasksTable';
import moment from 'moment';

class ScrumPanel extends React.Component {
    constructor(props){
        super(props);
        this.openDatePicker=this.openDatePicker.bind(this);
        this.textFieldStyle = {
            underlineFocusStyle: {
                borderColor: colors.nexioOrange
            },
            style: {
                width:'100%'
            },
            textareaStyle:{
                fontSize:'14px'
            }
        };
    }

    formatDate(date) {
        let objectDate = new Date(date);

        let monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ];

        let day = objectDate.getDate();
        let monthIndex = objectDate.getMonth();
        let year = objectDate.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }

    openDatePicker(){
        this.dp.openDialog();
    }

    render(){
        
        const handleDatePickerBlur = (event) => {
            const date = event.target.value;
            if(!moment(date).isValid()) {
                this.props.onCalendarChange({ target: { value: new Date().toJSON() } });
            }
        };

        return(
                <div className="scrum-panel">
                    <div className="header-line">
                          <div className="column">
                            <label className="label-name">{EN.scrumManagementPage.scrumPanel.developer}</label>
                            <label className="label-name">Date:</label>
                          </div>
                          <div className="column column-right">
                            <label className="user-name">{this.props.scrumDate.username}</label>
                            <label className="label-date">
                                <DatePicker
                                    format="d MMM yyyy"
                                    placeholder={null}
                                    value={this.props.scrumDate.date}
                                    labelFunc={this.formatDate}
                                    onChange={this.props.onCalendarChange}
                                    onBlur={handleDatePickerBlur} />
                            </label>
                          </div>
                    </div>
                    <ScrumTasksTable rows={this.props.scrumDate.taskProgress} onSliderChange={this.props.onProgressChange}/>
                    <div className="scrum-notes">
                        <label className="label-header-name">{EN.scrumManagementPage.scrumPanel.scrumNotes}</label>
                        <div className="scrum-rows">
                            <div className="col-md-3">
                                <label className="label-name">{EN.scrumManagementPage.scrumPanel.yesterdayTasks}</label>
                            </div>
                            <div className="col-md-9">
                                <TextField
                                        id="yesterdayWork"
                                        value={this.props.scrumDate.yesterdayWork}
                                        onChange={(event, value) => this.props.onFieldChange('yesterdayWork', value)}
                                        multiLine style={this.textFieldStyle.style}
                                        textareaStyle={this.textFieldStyle.textareaStyle}
                                        underlineFocusStyle={this.textFieldStyle.underlineFocusStyle}/>
                            </div>
                        </div>
                        <div className="scrum-rows">
                            <div className="col-md-3">
                                <label className="label-name">{EN.scrumManagementPage.scrumPanel.todayTasks}</label>
                            </div>
                            <div className="col-md-9">
                                <TextField
                                        id="todayWork"
                                        value={this.props.scrumDate.todayWork}
                                        onChange={(event, value) => this.props.onFieldChange('todayWork', value)}
                                        multiLine style={this.textFieldStyle.style}
                                        textareaStyle={this.textFieldStyle.textareaStyle}
                                        underlineFocusStyle={this.textFieldStyle.underlineFocusStyle}/>
                            </div>
                        </div>
                        <div className="scrum-rows">
                            <div className="col-md-3">
                                <label className="label-name">{EN.scrumManagementPage.scrumPanel.risks}</label>
                            </div>
                            <div className="col-md-9">
                                <TextField
                                        id="risk"
                                        value={this.props.scrumDate.risk}
                                        onChange={(event, value) => this.props.onFieldChange('risk', value)}
                                        multiLine style={this.textFieldStyle.style}
                                        textareaStyle={this.textFieldStyle.textareaStyle}
                                        underlineFocusStyle={this.textFieldStyle.underlineFocusStyle}/>
                            </div>
                        </div>
                        <div className="scrum-rows">
                            <div className="col-md-3">
                                <label className="label-name">{EN.scrumManagementPage.scrumPanel.availability}</label>
                            </div>
                            <div className="col-md-6" style={{margin:'-7px 0px 0px -23px'}}>
                                <DropDownMenu
                                    value={this.props.scrumDate.availability}
                                    selectedMenuItemStyle={{color:this.props.scrumDate.availability == 0 ? 'green' : (this.props.scrumDate.availability == 1 ? 'yellow' : 'red')}}
                                    labelStyle={{color:this.props.scrumDate.availability == 0 ? 'green' : (this.props.scrumDate.availability == 1 ? 'yellow' : 'red')}}
                                    onChange={(event, value) => this.props.onFieldChange('availability', value)}>
                                    <MenuItem value={0} primaryText={EN.scrumManagementPage.scrumPanel.availabilityAvailable} style={{color:'green'}}/>
                                    <MenuItem value={1} primaryText={EN.scrumManagementPage.scrumPanel.availabilitySoon} style={{color:'yellow'}}/>
                                    <MenuItem value={2} primaryText={EN.scrumManagementPage.scrumPanel.availabilityBusy} style={{color:'red'}}/>
                                </DropDownMenu>
                            </div>
                            <div className="col-md-3">
                                    { this.formatDate(new Date(this.props.scrumDate.date)) == this.formatDate(new Date()) ? <RaisedButton label="Save" style={{float:'right'}} onClick={this.props.onSaveClick}/>:''}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
}

export default ScrumPanel;