import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import { host } from '../../../common/cfg';

const attachment = ({ name, guid, uploadDone, progress, isNewRequest, deleteAttachment }) => {
    let wrapperClass = '';
    let progressWrapper = null;
    const onDownloadReqest = async () => {
        const response = await axios.get(`${host}/api/holiday/DownloadAttachment`, {
            responseType: 'blob',
            params: { guid: guid }
        });
        if(response.status === 200 && response.statusText === "OK") {
            const fileName = /filename=(.+);/.exec(response.headers["content-disposition"])[1];
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = URL.createObjectURL(response.data);
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
    };
    let action = (
        <IconButton onClick={onDownloadReqest}>
            <GetAppIcon />
        </IconButton>
    );
    let fileName = (
        <Button className="special-handler" onClick={onDownloadReqest}>
            {name}
        </Button>
    );

    if (isNewRequest) {
        action = (
            <IconButton onClick={() => deleteAttachment(guid)}>
                <DeleteForeverIcon />
            </IconButton>
        );

        fileName = <div>{name}</div>;
        wrapperClass = " uploading-in-progress";

        if(!uploadDone)
        {
            const progressProps = {};
            if(progress)
            {
                progressProps.variant = "determinate",
                progressProps.value = progress;
            }

            progressWrapper = (
                <div className="progress-wrapper">
                    <LinearProgress {...progressProps} />
                </div>
            );
        }
    }
    const mdColSize = wrapperClass ? 12 : 8;
    const mdIcoSize = wrapperClass ? 1 : 2;

    return (
        <div className={`col-xs-12 col-md-${mdColSize} file-wrapper`}>
            <div className="row">
                <div className={`col-xs-10 col-md-${12 - mdIcoSize} filename${wrapperClass}`}>
                    {fileName}
                    {progressWrapper}
                </div>
                <div className={`col-xs-2 col-md-${mdIcoSize}`}>
                    {action}
                </div>
            </div>
        </div>
    );
};

export default attachment;