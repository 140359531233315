//React
import React from 'react';
import PropTypes from 'prop-types';
//MDL
import TextFieldIcon from 'material-ui-textfield-icon';
import SearchIcon from '@material-ui/icons/Search';
//styles
import {searchBar} from '../styles';
//translations
import {EN} from '../translations';

const translations = EN.common.searchBar;
const styles = searchBar;

const propTypes = {
  width: PropTypes.number,
  onSearch: PropTypes.func.isRequired
};

const defaultProps = {
  width: 400,
  onSearch: (evt, value) => {}
};

const searchBarIconStyle ={
  left: -12
};

const SearchBar = (props) => {

  return (
      <div style={{position: props.position? props.position :"absolute", width:props.width+'px'}} >
        <TextFieldIcon
          id="search-bar"
          disabled = {props.disabled ? true : false}
          icon={<SearchIcon nativeColor={'#464545'}/>}
          iconStyle = {searchBarIconStyle}
          fullWidth
          iconPosition="before"
          hintText={translations.hintText}
          style={styles.textAreaStyle.field}
          underlineFocusStyle={styles.textAreaStyle.underlineStyle}
          onChange={(evt, value) => props.onSearch(evt, value)}
         />
      </div>
  );
};

SearchBar.propTypes = propTypes;
SearchBar.defaultProps = defaultProps;

export default SearchBar;