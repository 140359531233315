import {host} from '../components/common/cfg';
import axios from 'axios';

export const saveScrum = (scrumReport) => {
    return (dispatch) => {
        let headers = {
            'Content-Type': 'application/json'
        };

        let data = scrumReport;

        return axios
            .post(`${host}/api/scrum/saveScrum`, data, { headers } )
            .then(response => {
                return response;
            });
    };
};

export const getEmployeesListForScrum = (teamId) => {
    return (dispatch, getState) => {
        let headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .get(`${host}/api/scrum/getUsersListForScrum`, { headers, params: { teamId } } )
            .then(response => {
                return response;
            });
    };
};

export const sendMail = (teamId) => {
    return (dispatch) => {

        let headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .post(`${host}/api/scrum/sendMail`, undefined, { headers, params: { teamId } } )
            .then(response => {
                return response;
            });
    };
};

export const getScrumReports = (userId, date) => {
    return (dispatch) => {

        let headers = {
            'Content-Type': 'application/json'
        };

        return axios
            .get(`${host}/api/scrum/getScrumReports?userId=${userId}` + (date? `&date=${date}` : ''), {headers} )
            .then(response => {
                return response;
            });
    };
};
