import React, { useMemo, useCallback, Fragment, useState, useEffect } from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { EN } from '../../common/translations';
import memoize from "memoize-one";
import { Constants } from '../../common/constants';
import MenuItem from '@material-ui/core/MenuItem';
import { closeDialog, saveHoliday, addHoliday, deleteHoliday, getUserHolidaysSummary, getSelectedReplacementPersonId, onAttachmentAdd, onAttachmentDelete, setHhistoryOfSearchingReplacements,clearErrorMessage} from '../../../store/Holidays';
import { fetchAllSupervisors } from '../../../store/Settings';
import holidayDialogValidation from '../helpers/holidayDialogValidation';
import FormDialog from '../../common/controls/formik/FormDialog';
import { FormTextField, FormDatePicker, FormTimePicker, FormReadOnlyField, FormButton } from '../../common/controls/formik/FormControls';
import { useConfirm } from '../../confirm/ConfirmDialog';
import HolidaySummary from '../HolidaySummary';
import moment from 'moment';
import { countDayOff } from '../../common/helpers/date';
import AttchamentList from './Attachments/FileList';
import SelectSearch from 'react-select-search';
import {getIn} from 'formik';

const typeList = [
    { key: Constants.HolidayTypes.HOLIDAY, text: "Holiday" },
    { key: Constants.HolidayTypes.UNPAID_HOLIDAY, text: "Unpaid Holiday" },
    { key: Constants.HolidayTypes.SICK_LEAVE, text: "Sick leave", forEmploymentOnly: true },
    { key: Constants.HolidayTypes.OTHERS, text: "Other holidays", forEmploymentOnly: true },
    { key: Constants.HolidayTypes.REMOTE, text: "Remote work" }
];

const deleteStyle = {
    color: "red"
};

const HolidayEditDialog = (props) => {
    const {
        isNew,
        open,
        requestForOthers,
        holiday,
        userName,
        workers,
        closeDialog,
        saveHoliday,
        addHoliday,
        getUserHolidaysSummary,
        deleteHoliday,
        supervisorfirstname,
        supervisorlastname,
        publicHolidays,
        userContractType,
        selectedReplacementPersonId,
        userId,
        editableDialog=true,
        requestForAnyWorker,
        errorMessage,
        clearErrorMessage

    } = props;
    
    const [boolDeleteHoliday, setBoolDeleteHoliday] = useState(false);

    const year = holiday.dateFrom != null ? new Date(holiday.dateFrom).getFullYear() : new Date().getFullYear();
    useEffect(() => {
        open && holiday.userId && !boolDeleteHoliday
            ? getUserHolidaysSummary(holiday.userId, holiday.id, year)
            : null;
    }, [holiday.id, year, open]);

    useEffect(() => {
        if (!props.allSupervisor.length > 0) {
            props.fetchAllSupervisors();
        }
        if (userId && !selectedReplacementPersonId > 0) {
            props.getSelectedReplacementPersonId(userId);
        }

        
        if(holiday.userId != null){
            setSelectedWorker(holiday.userId.toString());
        }
        else{
            setSelectedWorker("0");
        }

        setDaysNumber(countDayOff(holiday.dateFrom, holiday.dateTo, publicHolidays));
        
    }, [open]);

    const [currentHolidays, setDaysNumber] = useState(0);
    
    const workerList = useCallback(() => {
        return workers.map((x) => (
            {
                name: `${x.firstname} ${x.lastname} ${requestForAnyWorker ? `(${x.email})` : ''}`,
                value: `${x.id}`
            }
        ));
    }, [workers]); 

    const handleTypeChange = useCallback((e, formik) => {
        e.target.value === Constants.HolidayTypes.REMOTE && formik.setFieldValue('dateTo', formik.values.dateFrom);
        e.target.value !== Constants.HolidayTypes.REMOTE && (formik.setFieldValue('timeFrom', null) || formik.setFieldValue('timeTo', null));
    }
        , []);

    const handleDateFromChange = (e, formik, publicHolidays) => {
        if(errorMessageHelper(errorMessage))
            clearErrorMessage();
            
        let yearDateFrom = e.target.value !== null ? new Date(e.target.value).getFullYear() : null;

        if (yearDateFrom !== null && new Date(formik.values.dateFrom).getFullYear() !== yearDateFrom)
            getUserHolidaysSummary(formik.values.userId, formik.values.id, yearDateFrom);

        formik.values.type === Constants.HolidayTypes.REMOTE && formik.setFieldValue('dateTo', e.target.value);
        if (formik.values.dateTo === null || formik.values.dateTo < e.target.value) {
            formik.setFieldValue('dateTo', e.target.value);
        }
        let dateTo = formik.values.dateTo == null ? e.target.value : formik.values.dateTo;
        setDaysNumber(countDayOff(e.target.value, dateTo, publicHolidays));
    };

    const handleDateToChange = (e, formik) => {
        if(errorMessageHelper(errorMessage))
            clearErrorMessage();
        
        setDaysNumber(countDayOff(formik.values.dateFrom, e.target.value, publicHolidays));
    };

    const handleTimeFromChange = (e, formik) => {
        if (formik.values.type === Constants.HolidayTypes.REMOTE) {
            let timeTo = getTimeTo(e.target.value);
            formik.setFieldValue('timeTo', timeTo);
        }
    };

    const handleTimeBlur = (field, value, formik) => {
        if (formik.values.type === Constants.HolidayTypes.REMOTE) {
            const timeValue = moment(value, 'HH:mm').format("HH:mm");
            formik.setFieldValue(field, timeValue);
            if (field === 'timeFrom') {
                let timeTo = getTimeTo(timeValue);
                formik.setFieldValue('timeTo', timeTo);
            }
        }
    };

    const handleTimeToChange = useCallback((e, formik) =>
        formik.values.type === Constants.HolidayTypes.REMOTE && formik.setFieldValue('timeTo', e.target.value), []);

    const validate = useCallback((values) => holidayDialogValidation(values, requestForOthers, publicHolidays),
     [requestForOthers, publicHolidays]);

    const confirmDelete = useConfirm({ content: "Are you sure you want to delete current holiday?" });

    const isAttachmentsUploadFinished = () => {
        const data = props.holiday;
        const attachments = data.attachments || [];
        const notFinished = attachments.filter(x => !x.uploadDone).length;
        return !notFinished;
    };

    
    const isDisabledEdit = (holiday) => (!requestForOthers && (
        holiday.status === Constants.HolidayStatuses.REJECTED ||
        holiday.status === Constants.HolidayStatuses.ACCEPTED))
        || !editableDialog;
    

    const handleDeleteHoliday = useCallback(
        (holiday) => confirmDelete(() => {
            deleteHoliday(holiday);
            setBoolDeleteHoliday(true);
        }),
        [confirmDelete, deleteHoliday]);


    const actions = (formik) => {
        let deleteButton = null;
        let submitButton = null;
        const uploadFinished = isAttachmentsUploadFinished();
        const disabledButtons = !uploadFinished;
        
        if (!isDisabledEdit(formik.values)) {
            deleteButton = (
                <FormButton onClick={handleDeleteHoliday} style={deleteStyle} disabled={disabledButtons}>
                    {EN.common.delete}
                </FormButton>
            );
            submitButton = (
                <FormButton type="submit" color="primary" variant="contained" disabled={disabledButtons}>
                    {EN.common.submit}
                </FormButton>
            );
        }

        if (isNew)
            deleteButton = null;

        return (
            <>
                {deleteButton}
                <FormButton onClick={closeDialog}>{EN.common.cancel}</FormButton>
                {submitButton}
            </>);

    };

    const getTimeTo = (timeFrom) => {
        if (!timeFrom)
            return null;
        let timeTo = moment(timeFrom, 'HH:mm').add(8, 'hours').format("HH:mm");
        return timeTo;
    };

    const getComment = (formik) => {
        if (!requestForOthers)
            return formik.values.comment;
    
        const applicant = workers.find(x => x.id == formik.values.userId);
        return applicant && `Wniosek w imieniu ${applicant.firstname + ' ' + applicant.lastname} został wystawiony przez ${userName}`;
    };

    const onAddHoliday = (holiday, { setSubmitting, setErrors }) => {
        const newHoliday = { ...holiday, replacementPersonId: `${selectedReplacingPersonId}` };
        
        if (props.onRefreshProjectIssues) {
            const holidayDateFrom = new Date(holiday.dateFrom);
            const date = moment({ month: holidayDateFrom.getMonth(), year: holidayDateFrom.getFullYear() });
            const startDate = date.startOf('month').format('YYYY-MM-DD');
            const endDate = date.endOf('month').format('YYYY-MM-DD');
            const addHolidayCallback = () => {
                props.onRefreshProjectIssues(props.selectedProjectId,startDate, endDate,holidayDateFrom.getMonth()),
                props.onRefreshRemoteWork(holidayDateFrom.getMonth()+1, holidayDateFrom.getFullYear());
            };
            addHoliday(newHoliday, { setSubmitting, setErrors }, addHolidayCallback);
        }
        else{
            addHoliday(newHoliday, { setSubmitting, setErrors });
        }
    };

    const LeaveTypeDropDown = (formik) => {
        const availableTypes = typeList.map((item) => {
            const otherEmployee = requestForOthers ?
                workers.find(user => user.id === formik.values.userId) :
                null;

            if (item.forEmploymentOnly &&
                ((!requestForOthers && userContractType !== Constants.ContractTypes.Employment) ||
                    (otherEmployee && otherEmployee.contractName !== Constants.ContractTypes.Employment))) {
                return;
            }

            return <MenuItem key={item.key} value={item.key}>{item.text}</MenuItem>;
        });

        return (<FormTextField select label="Type"
            name="type" required
            readOnly={isDisabledEdit(formik.values)}
            onChange={handleTypeChange}
        >
            {availableTypes}
        </FormTextField>);
    };

    const [selectedReplacingPersonId, setSelectedReplacingPersonId] = useState("0");

    useEffect(() => {
        if (props.isSupervisor && holiday.id === 0 && holiday.status === Constants.HolidayStatuses.NEW && selectedReplacementPersonId && !holiday.replacementPersonId)
            holiday.replacementPersonId = selectedReplacementPersonId;

        if (!holiday.replacementPersonId)
            holiday.replacementPersonId = Constants.HolidayReplacement.NONE_ID;

        setSelectedReplacingPersonId(`${holiday.replacementPersonId}`);
    }, [selectedReplacementPersonId]);


    const handleSelectReplacingPersonOnChange = useCallback((e) => {
        if (e) {
            const eventId = parseInt(e.value, 10);
            const selectedId = Number.isInteger(eventId) ? eventId : 0;

            holiday.replacementPersonId = selectedId;
            setSelectedReplacingPersonId(e.value);

            if(selectedId > 0)
                props.setHhistoryOfSearchingReplacements(selectedId);
            
        }
    });

    const SelectSearchReplacementDropDown = (formik) => {
        if (!props.isSupervisor
            || formik.values.type === Constants.HolidayTypes.REMOTE
            || requestForAnyWorker) 
            return null;

        const replacementDropDownOptions = () => {
            if(!props.allSupervisor || props.allSupervisor.length === 0)
                return [];

            const dropdownOptionCallback = ({ id, login, firstname, lastname }) => ({
                id: `${id}|${login}`,
                name: id ? `${firstname} ${lastname} (${login})` : `${firstname} ${lastname}`,
                value: `${id}`
            });
            const fromHistory = props.allSupervisor
                .filter(x => props.historyOfSearchingReplacements.includes(x.id))
                .map(dropdownOptionCallback);
            const restOfSupervisors = props.allSupervisor
                .filter(x => !props.historyOfSearchingReplacements.includes(x.id) && x.id !== 0)
                .map(dropdownOptionCallback);
            const empty = dropdownOptionCallback(props.allSupervisor.find(x => x.id === 0));
                
            return [
                empty,
                { type: "group", name: EN.holidaysPage.replacementGroups.lastSelected, items: fromHistory },
                { type: "group", name: EN.holidaysPage.replacementGroups.others, items: restOfSupervisors },
            ];
        };

        const labelText = selectedReplacingPersonId === '0' ? EN.holidaysPage.selectReplacement : EN.holidaysPage.selectedReplacement;

        if(isDisabledEdit(formik.values)) {
            const { name, login } = props.allSupervisor.find(x => x.id === parseInt(selectedReplacingPersonId));
            const displayName = name.length && login.length ? `${name} (${login})` : "";

            return (
                <FormTextField
                    name="replacement"
                    label={EN.holidaysPage.selectedReplacement}
                    readOnly
                    value={displayName}
                />
            );
        }

        return (
            <div className="HolidayDialogEditSelectSearch">
                <label className="HolidayDialogEditSelectSearchLabel">{labelText}</label>
                <SelectSearch
                    options={replacementDropDownOptions()}
                    value={`${selectedReplacingPersonId}`}
                    name="supervisiors"
                    onChange={handleSelectReplacingPersonOnChange}
                />
            </div>
        );
    };

   const [selectedWorker, setSelectedWorker] = useState('0');
    
    const handleApplicantFormFieldChange = (e, formik) => {
        const year = formik.values.dateFrom != null ? new Date(formik.values.dateFrom).getFullYear() : new Date().getFullYear();

        setSelectedWorker(e.value);
        formik.setFieldValue("userId", parseInt(e.value, 10));
        getUserHolidaysSummary(e.value, formik.values.id, year);

        if(errorMessageHelper(errorMessage))
            clearErrorMessage();
    };

    const ApplicantFormField = (formik) => {
        if(requestForOthers){
            const errors = getIn(formik.touched, "userId") && getIn(formik.errors, "userId");
            return(
                <div className={`HolidayDialogEditSelectSearch ${errors ? 'HolidayDialogEditSelectSearch--validate-error' : ''}`}>
                    <label className={`HolidayDialogEditSelectSearchLabel ${errors ? 'HolidayDialogEditSelectSearchLabel--validate-error' : ''}`}>Applicant*</label>
                    
                    <SelectSearch
                        options={workerList()}
                        value={selectedWorker}
                        name="userId"
                        onChange={(selected) => handleApplicantFormFieldChange(selected, formik)}
                    />
                    {errors && 
                        <div className="HolidayDialogEditSelectSearch__validation-text">
                            <span>{errors}</span>
                        </div>
                    }
                </div>
            );
        }
        return <FormReadOnlyField label="Applicant" value={userName} />;
    };
    

    const HoursFormField = () => {
        let input = null;

        if (requestForOthers)
            input = <FormReadOnlyField label={EN.timesheetPage.manageDaysPanel.hours} value="8 hour(s)" />;
        return input;
    };

    const DateTimePickers = (formik) => {
        let timers = null;
        let pickers = null;

        pickers = (<>
                <FormDatePicker label={EN.holidaysPage.dateFrom} required name="dateFrom" sm={6}
                error={getIn(formik.touched, "dateFrom") && getIn(formik.errors, "dateFrom") }
                disabled={isDisabledEdit(formik.values)}
                onChange={(e, formik) => handleDateFromChange(e, formik, publicHolidays)}
                helperText={getIn(formik.errors, "dateFrom")} />

                <FormDatePicker label={EN.holidaysPage.dateTo} required name="dateTo" sm={6}
                error={getIn(formik.touched, "dateTo") && getIn(formik.errors, "dateTo") }
                onChange={(e, formik) => handleDateToChange(e, formik, publicHolidays)}
                disabled={formik.values.type === Constants.HolidayTypes.REMOTE || isDisabledEdit(formik.values)}
                helperText= {getIn(formik.errors, "dateTo")} />

                { errorMessageHelper(errorMessage) &&
                    <div className="HolidayDatePicker-invalidText">
                        <span>{errorMessage}</span>
                    </div> }
        </>);

        if (formik.values.type === Constants.HolidayTypes.REMOTE) {
            timers = (<>
                <FormTimePicker label={EN.holidaysPage.timeFrom} required
                    readOnly={isDisabledEdit(formik.values)}
                    name="timeFrom" sm={6}
                    onChange={(e, formik) => handleTimeFromChange(e, formik)}
                    onBlur={(e) => handleTimeBlur('timeFrom', e.target.value, formik)}
                    error={getIn(formik.touched, "timeFrom") && getIn(formik.errors, "timeFrom") }
                    helperText= {getIn(formik.errors, "timeFrom")} />
                    
                <FormTimePicker label={EN.holidaysPage.timeTo} required
                    readOnly={isDisabledEdit(formik.values)}
                    name="timeTo" sm={6}
                    onChange={handleTimeToChange}
                    onBlur={(e) => handleTimeBlur('timeTo', e.target.value, formik)} 
                    error={getIn(formik.touched, "timeTo") && getIn(formik.errors, "timeTo") }
                    helperText= {getIn(formik.errors, "timeTo")}/>
            </>);
        }

        return (<>
            {pickers}
            {timers}
        </>);
    };

    const AcceptedByFormField = () =>{
        let formFieldValude = isNew 
            ? (supervisorfirstname ? supervisorfirstname + " " + supervisorlastname : EN.holidaysPage.system)
            : (holiday.userAcceptedId ? holiday.userAcceptedFirstName + ' ' + holiday.userAcceptedLastName : holiday.acceptedBy);

        return <FormReadOnlyField label={EN.holidaysPage.accepted} value={formFieldValude}/>;
    }

    const errorMessageHelper = (error) => {
        return [EN.holidaysPage.holidaysAlreadyReportedWarning, EN.holidaysPage.workHoursReportedWarning, EN.holidaysPage.doesEmployedWarning].includes(error);
    };

    const HolidaySummaryField = (formik) => {
        if ((formik.values.type === Constants.HolidayTypes.HOLIDAY && formik.values.status !== Constants.HolidayStatuses.REJECTED)) {
            let _username = requestForOthers ?
                (workers.find(x => x.id == formik.values.userId) != null ?
                    workers.find(x => x.id == formik.values.userId).firstname
                    : "")
                : "Your";

      let dateFrom = new Date(formik.values.dateFrom);
      let dateTo = new Date(formik.values.dateTo);
      if (dateFrom.getFullYear() !== dateTo.getFullYear()) {
        let sendDate = dateFrom.getFullYear() !== new Date().getFullYear()? dateFrom : dateTo.getFullYear() !== new Date().getFullYear()
        ? dateTo : new Date().getFullYear();
        let userId = formik.values.userId, id = formik.values.id;
        getUserHolidaysSummary(userId, id, sendDate.getFullYear(), true);
      }

      return (
        <div className="holiday-Summary-Display">
          {dateFrom.getFullYear() !== dateTo.getFullYear() ? (
            <HolidaySummary
              currentHolidays={currentHolidays}
              status={formik.values.status}
              username={_username}
              isPrevYear={true}
            />
          ) : null}

          <HolidaySummary
            currentHolidays={currentHolidays}
            status={formik.values.status}
            username={_username}
          />
        </div>
      );
    }
  };

    const CommentField = (formik) => {
        if (!isNew || requestForOthers)
            return (
              <FormTextField
                multiline
                label={EN.holidaysPage.comment}
                name={"comment"}
                readOnly={!requestForOthers || isDisabledEdit(formik.values)}
                value={getComment(formik)}
              />
            );
    };

    const AttachmentList = formik => {
        return (
            <AttchamentList
                isNewRequest={holiday && holiday.status == Constants.HolidayStatuses.NEW}
                list={holiday.attachments || []}
                addAttachment={props.onAttachmentAdd}
                deleteAttachment={props.onAttachmentDelete}
                holidayType={formik.values.type} />
        );
    };

    const otherAbsencesInfo = $formik => {
        const holidayType = $formik.values.type;

        if (holidayType === Constants.HolidayTypes.OTHERS)
            return (
                <div style={{ padding: 8, fontSize: '1.4rem' }}>
                    <span>UWAGA! Inne urlopy jakie są możliwe na umowę o pracę to:</span>
                    <ul>
                        <li>Urlop okolicznościowy</li>
                        <li>Urlop ojcowski/macierzyński</li>
                        <li>Opieka nad dzieckiem (art. 188 kp)</li>
                        <li>Wolne z innych tytułów (np. oddanie krwi, wezwanie do sądu itp.)</li>
                    </ul>
                    <span>
                        <u>
                            Każdy z wyżej wymienionych dni wolnych wymaga dostarczenia oświadczenia w formie skanu wniosku lub stosownego dokumentu
                            potwierdzającego prawo do nabycia dnia wolnego z ww. tytułów. Skan dołączasz przez TMS podczas raportowania dnia wolnego.
                        </u>
                    </span>
                </div>
            );
        return null;
    };

    const onSubmitClicked = (data, event) => {
        if(data.type === Constants.HolidayTypes.OTHERS && holiday.attachments.length === 0) {
            event.setSubmitting(false);
            return;
        }          
        return isNew ? onAddHoliday(data, event) : saveHoliday(data, event);
    };

    useEffect(() => {
        setDaysNumber(countDayOff(props.holiday.dateFrom, props.holiday.dateTo, publicHolidays));
    }, [props.holiday.dateFrom, props.holiday.dateTo]);

    return (
        <FormDialog actions={actions}
            open={open}
            initialValues={holiday}
            onClose={closeDialog}
            onSubmit={onSubmitClicked}
            validate={validate}>
            {(formik) => (<>
                {LeaveTypeDropDown(formik)}
                {otherAbsencesInfo(formik)}
                {ApplicantFormField(formik)}
                {HoursFormField()}
                {DateTimePickers(formik)}
                <FormTextField label={EN.holidaysPage.request} name="request" multiline readOnly={isDisabledEdit(formik.values)} />
                <FormTextField label={EN.holidaysPage.status} name={"status"} readOnly />
                {AcceptedByFormField()}
                {SelectSearchReplacementDropDown(formik)}
                {CommentField(formik)}
                {AttachmentList(formik)}
                {HolidaySummaryField(formik)}
                    </>)}
        </FormDialog>
    );
};

const emptyHoliday = {};


const getWorkers = memoize((workers, supervisorWorkerIds, requestForAnyWorker) =>{
    if(!requestForAnyWorker){
        return supervisorWorkerIds.map(x => workers[x]);
    }
    else{
        return Object.values(workers);
    }
});
 

function mapStateToProps(state) {
    return {
        isNew: state.holidays.dialogId === 0,
        open: state.holidays.dialogOpen,
        editableDialog: state.holidays.editableDialog,
        requestForOthers: state.holidays.forOthersHoliday,
        holiday: state.holidays.data[state.holidays.dialogId] || emptyHoliday,
        supervisorfirstname: state.holidays.forOthersHoliday ? state.login.user.firstname : state.login.user.supervisorfirstname,
        supervisorlastname: state.holidays.forOthersHoliday ? state.login.user.lastname : state.login.user.supervisorlastname,
        userName: state.login.user.firstname + ' ' + state.login.user.lastname,
        workers: getWorkers(state.holidays.workers, state.holidayPanel.workerIds, state.holidays.requestForAnyWorker),
        publicHolidays: state.publicHolidays.wtPublicHolidays,
        userContractType: state.login.user.contractName,
        allSupervisor: state.settings.allSupervisor,
        selectedReplacementPersonId: state.holidays.selectedReplacementPersonId,
        userId: state.login.user.id,
        historyOfSearchingReplacements: state.holidays.historyOfSearchingReplacements,
        filteredWorkers: state.holidays.filteredWorkers,
        requestForAnyWorker: state.holidays.requestForAnyWorker,
        selectedProjectId: state.selectedProject.id,
        errorMessage: state.holidays.errorMessage,
        isSupervisor: (s => {
            try {
                return !!s.login.user.userWorkgroups.find(x => x.name === Constants.Roles.supervisor);
            }
            catch (e) {
                return false;
            }
        })(state),
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        closeDialog,
        saveHoliday,
        addHoliday,
        deleteHoliday,
        getUserHolidaysSummary,
        fetchAllSupervisors,
        getSelectedReplacementPersonId,
        onAttachmentAdd,
        onAttachmentDelete,
        setHhistoryOfSearchingReplacements,
        clearErrorMessage
    }, dispatch);
}
export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HolidayEditDialog);
