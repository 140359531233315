import React, { Component } from 'react';
import { connect } from 'react-redux';
import PendingApplication from './pendingApplication/pendingApplication';
import SettledEmployees from './settledEmployees/settledEmployees';
import UnsettledEmployees from './unsettledEmployees/unsettledEmployees';
import "./teamTimesheetMain.scss";

const renderPendingApplication = (isSelected) => {
    return isSelected
        ? <PendingApplication/>
        : null;
};

const renderSettledEmployees = (isSelected) => {
    return isSelected
        ? <SettledEmployees/>
        : null;
};

const renderUnsettledEmployees = (isSelected) => {
    return isSelected
        ? <UnsettledEmployees/>
        : null;
};

class TeamTimesheetMain extends Component {
    render() {
        const {pendingApplication, settledEmployees, unsettledEmployees } = this.props.buttons;

        return (<div className="teamTimeSheet-main">
                {renderPendingApplication(pendingApplication.isSelected)}
                {renderSettledEmployees(settledEmployees.isSelected)}
                {renderUnsettledEmployees(unsettledEmployees.isSelected)}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    buttons: state.teamTimesheet.buttons,
});

export default connect(mapStateToProps)(TeamTimesheetMain);
