import {host} from '../components/common/cfg';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

const types = {
    GET_TIME_ENTRIES: 'GET_TIME_ENTRIES',
    SET_ISSUES: 'SET_ISSUES'
};

/** ACTIONS START */
export const onGetTimeEntries = (username, startDate, endDate) => {
    return (dispatch) => {

        let headers = {
            'headers': {
                'Content-Type': 'application/json;',
                // 'Accept': 'application/json',
            }
        };

        // let data = JSON.stringify({username, startDate, endDate});

        return axios
            .get(`${host}/api/timesheet?username=${username}&startDate=${startDate}&endDate=${endDate}`, headers)
            .then(response => {
                return response;
            });
    };
};
/** ACTIONS END */

/** REDUCER START */
export const timesheets = (state, action = {}) => {
    switch (action.type) {
        default:
            return state || {};
    }
};
/** REDUCER END */
