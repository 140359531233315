import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
//components
import Select from '../../../../common/controls/Select';
import MenuItem from '@material-ui/core/MenuItem';
//
import {EN} from '../../../../common/translations';
//styles
import './viewSelect.scss';

export const routes = [
    {to: "/workTimeReport", text: EN.sideMenu.hr.workTime.main},
    {to: "/workTimeIrregular", text: EN.sideMenu.hr.workTime.irregular}
];

const viewChanger = ({selected, values}) => {

    let SelectedIndex = values.findIndex(element => element.text === selected);
    if(SelectedIndex === -1)
    {
        throw Error("selected item not found in values array");
    }

    const createListItems = () => {
        if(values && values.length)
        {
            return values.map((item, index) => {

                const LinkTo = (routeValue, props) => {
                    return (
                        <Link to={routeValue} tabIndex="0" {...props} />
                    );
                };

                return (
                    <MenuItem
                        value={index}
                        key={`link_${index}`}
                        component={(props) => LinkTo(item.to, props)}
                        className="drop-down-list-item-link"
                    >
                        {item.text}
                    </MenuItem>
                );
            });
        }
    };


    return (
        <div className="viewSelect">
            <Select value={SelectedIndex}>
                {createListItems()}
            </Select>
        </div>
    );
};

viewChanger.propTypes = {
    selected: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
    }).isRequired).isRequired
};

export default viewChanger;