import { host } from '../components/common/cfg';
import axios from 'axios';
import _ from 'lodash';

export const types = {
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    CLOSE_NOTIFICATIONS_CLICK: 'CLOSE_NOTIFICATIONS_CLICK',
    OPEN_NOTIFICATIONS_CLICK: 'OPEN_NOTIFICATIONS_CLICK',
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    HIDE_NOTIFICATION: 'CLOSE_NOTIFICATION',
    SET_SELECTED_NOTIFICATION: 'SET_SELECTED_NOTIFICATION',
    SET_NOTIFICATION_COUNT: 'SET_NOTIFICATION_COUNT'
};

/** ACTIONS START */
export const onSetNotifications = (notification) => ({ type: types.SET_NOTIFICATIONS, data: notification });
export const openNotificationsHandler = () => ({ type: types.OPEN_NOTIFICATIONS_CLICK });
export const onCloseNotificationClick = () => ({ type: types.CLOSE_NOTIFICATIONS_CLICK });
export const onShowNotification = (notificationId, columnId) => ({ type: types.SHOW_NOTIFICATION, data: { notificationId, columnId } });
export const onHideNotification = () => ({ type: types.HIDE_NOTIFICATION });
export const onSelectHandler = (selectedNotifications) => ({ type: types.SET_SELECTED_NOTIFICATION, data: selectedNotifications });
export const onNotificationCountChange = (notificationCount) => ({ type: types.SET_NOTIFICATION_COUNT, data: notificationCount });

export const onNotificationsRequest = () => {
    return (dispatch) => {

        let headers = {
            'headers': {
                'Content-Type': 'application/json;text/plain;text/json',
                'Accept': 'application/json'
            }
        };

        return axios
            .get(`${host}/api/notification/get`, headers)
            .then(response => {
                localStorage.setItem('notifications', JSON.stringify(response.data));
                dispatch(onSetNotifications(response.data));
            });
    };
};

export const onNotificationsDelete = (selectedNotifications) => {
    if (selectedNotifications.length === 0) return;
    return (dispatch) => {

        let headers = {
            'headers': {
                'Content-Type': 'application/json',
            }
        };

        return axios
            .post(`${host}/api/notification/delete`, selectedNotifications, { headers })
            .then(response => {
                localStorage.setItem('notifications', JSON.stringify(response.data));
                dispatch(onSetNotifications(response.data));
            });
    };
};

/** REDUCER START */
const initialState = {
    notifications: [],
    unreadNotifications: [],
    areNotificationsOpened: false,
    showNotification: false,
    notificationRow: -1,
    selectedNotifications: [],
    notificationCount: {
        requestsToAccept: 0
    }
};

export const notificationStore = (state = initialState, action) => {
    let user = null;
    switch (action.type) {
        case types.SET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.data,
                unreadNotifications: _.filter(action.data, ['isRead', false])
            };
        }
        case types.CLOSE_NOTIFICATIONS_CLICK:
            return {
                ...state,
                areNotificationsOpened: false
            };
        case types.OPEN_NOTIFICATIONS_CLICK:
            return {
                ...state,
                areNotificationsOpened: true
            };
        case types.SHOW_NOTIFICATION:
            // to selecting notifications
            return {
                ...state,
                showNotification: true,
                areNotificationsOpened: false,
                notificationRow: action.data.notificationId
            };
        case types.HIDE_NOTIFICATION:
            return {
                ...state,
                showNotification: false,
                areNotificationsOpened: true,
            };
        case types.SET_SELECTED_NOTIFICATION:
            return {
                ...state,
                selectedNotifications: action.data
            };
        case types.SET_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: {
                    ...state.notificationCount,
                    ...action.data.notificationCount
                }
            };
        default:
            return state;
    }
};
/** REDUCER END */
