import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { FlatButton } from 'material-ui';
import TextField from 'material-ui/TextField/TextField';
import { List } from '@material-ui/core';
import Checkbox from 'material-ui/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from 'material-ui/IconButton';
import FilterDialogItems from './FilterDialogItems';

const ItemsWrapper = (props) => (
    <div className="filter-dialog-list-parent-container">
        <div className="filter-dialog-list-container">
            <List className="filter-dialog-list">
                {props.children}
            </List>
        </div>
    </div>
);

export default class FilterDialog extends React.Component {
    state = {
        searchText: '',
        selectedItems: []
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps.isOpen && this.props.isOpen) ||
            (prevProps.activeItems.length !== this.props.activeItems.length)) {
                this.updateSelectedItems();
        }
    }

    updateSelectedItems = () => this.setState({ selectedItems: [...this.props.activeItems] });

    handleCancelClick = () => {
        this.setState({ searchText: '' });
        this.props.onCancelClick();
    }

    handleOkClick = () => {
        this.setState({ searchText: '' });
        this.props.onOkClick([...this.state.selectedItems]);
    }

    handleClearClick = () => {
        this.setState({ searchText: '' });
        this.props.onOkClick(this.props.items.map(x => x.id));
    }

    handleCheckboxChange = (id, newValue) => {
        if (newValue) {
            this.setState(st => ({ selectedItems: [...st.selectedItems, id] }));
        } else {
            this.setState(st => ({ selectedItems: st.selectedItems.filter(x => x !== id) }));
        }
    }

    handleCheckAll = () => {
        if (this.isCheckedAll()) {
            this.setState({
                selectedItems: []
            });
        } else {
            this.setState({
                selectedItems: this.props.items.map(x => x.id)
            });
        }
    }

    isCheckedAll = () => this.state.selectedItems.length === this.props.items.length;

    render() {
        const { listFilterComponent } = this.props;

        const listFilterElement = !listFilterComponent ? null : (
            <div style={{ marginBottom: '20px' }}>
                {listFilterComponent}
            </div>
        );

        const result = (
            <Dialog
                open={this.props.isOpen}
            >
                <DialogContent>
                    <form>
                        {listFilterElement}
                        <div className="filter-dialog-search-text">
                            <TextField value={this.state.searchText} hintText="Search" onChange={e => this.setState({ searchText: e.target.value })} fullWidth />
                            {
                                this.state.searchText &&
                                <IconButton onClick={() => this.setState({ searchText: '' })}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        </div>
                        <Checkbox checked={this.isCheckedAll()} onCheck={this.handleCheckAll} label="Select all" />
                        <ItemsWrapper>
                            <FilterDialogItems
                                items={this.props.items}
                                searchText={this.state.searchText}
                                selectedItems={this.state.selectedItems}
                                handleCheckboxChange={this.handleCheckboxChange}
                            />
                        </ItemsWrapper>
                    </form>
                </DialogContent>
                <DialogActions >
                    <FlatButton onClick={this.handleClearClick} secondary>Clear filters</FlatButton>
                    <FlatButton onClick={this.handleCancelClick} secondary>Cancel</FlatButton>
                    <FlatButton disabled={this.state.selectedItems.length === 0} onClick={this.handleOkClick} primary>OK</FlatButton>
                </DialogActions>
            </Dialog>
        );
        return result;
    }
}
