import PropTypes from 'prop-types';

const userStruct = PropTypes.shape({
    contractName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    supervisorName: PropTypes.string.isRequired,
    timeEntries: PropTypes.array
});

export default userStruct;