import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import queryString from 'query-string';

import ButtonBase from '@material-ui/core/ButtonBase';
import FolderOpen from '@material-ui/icons/FolderOpen';

import UserMonth from './UserMonth';
import CalendarHeader from '../Presentational/CalendarHeader';
import CalendarControls from '../../../timeSheet/calendarPanel/CalendarControls';
import HolidayApprovalDialog from '../../Dialogs/HolidayApprovalDialog';
import HideableSection from '../../../common/partials/HideableSection';
import PanelHolidaysTable from './PanelHolidaysTable';
import HolidayEditDialog from '../../Dialogs/HolidayEditDialog';
import HolidaysHistoryDialog from '../../Dialogs/HolidaysHistoryDialog';
import SupervisorBreadcrumbs from './SupervisorBreadcrumbs';
import { HolidayStyles as styles } from '../../HolidayStyles';
import { EN } from '../../../common/translations';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { fetchCalendarHolidays, addSupervisor, fetchCalendarHolidaysUser, fetchActiveReplacementsForUser, changeSupervisiorSelected, fetchTableData } from '../../../../store/HolidayPanel';
import { onGetPublicHolidays } from '../../../../store/WtHolidays';
import { fetchSupervisedEmployeesTimesheets } from '../../../../store/SupervisedEmployees';
import SelectSearch from 'react-select-search';
import Legend from '../Presentational/Legend';
import './PanelContainer.scss';
import WarningDialog from '../../../common/WarningDialog';
import { openAddDialog, fetchWorkers } from '../../../../store/Holidays';
import { dateToApi } from '../../../common/helpers/date';
import VerifyDialog from '../../../hr/verifyDialog/verifyDialog';
import HolidaySummaryDialog from '../../Dialogs/HolidaySummaryDialog';

class PanelContainer extends React.PureComponent {
    state = {
        calendarOpen: true,
        listOpen: true,
        newSupervisior: null,
        noEmployeesWarningDialogOpened: false,
        verifyDialogOpen: false,
        selectedUserId: null,
    };
    //eslint-disable-next-line
    firstLoading = true;

    fetchSupervisedEmployees(id) {
        let { month, year } = queryString.parse(this.props.location.search);

        const startDate = dateToApi(new Date(year || this.props.year, month || this.props.month, 1));
        const endDate = dateToApi(new Date(year || this.props.year, (month || this.props.month) + 1, 0));
        this.props.fetchSupervisedEmployeesTimesheets(startDate, endDate, id);
    }

    componentDidMount() {
        let { month, year, holidayId } = queryString.parse(this.props.location.search);
        if (month && year) {
            month = parseInt(month) - 1;
            year = parseInt(year);
        }
        if (holidayId)
            holidayId = parseInt(holidayId);

        const startDate = dateToApi(new Date(year || this.props.year, month || this.props.month, 1));
        const endDate = dateToApi(new Date(year || this.props.year, (month || this.props.month) + 1, 0));

        this.props.fetchWorkers();
        this.props.fetchSupervisedEmployeesTimesheets(startDate, endDate, this.props.userId);
        this.props.fetchActiveReplacementsForUser(this.props.userId);
        this.props.onGetPublicHolidays();
        this.props.fetchCalendarHolidays(month || this.props.month, year || this.props.year, holidayId, true);
        this.handleSelectOnChange({
            id: this.props.userId,
            name: `${this.props.user.firstname} ${this.props.user.lastname}`,
            value: this.getWorkerSelectedValue(this.props.user)
        });
    }

    componentDidUpdate(prevProps) {
        const { loadedWorkers, workerIds, supervisiorsHolidays, userId, allCounts } = this.props;

        if (this.firstLoading && loadedWorkers === userId) {
            const replacements = supervisiorsHolidays.filter(x => x.id !== userId);
            if (replacements.length && workerIds.length === 0) {
                this.handleSelectOnChange(replacements[0]);
            }
            
            this.firstLoading = false;
        }
        if (prevProps.allCounts != allCounts) {
            this.eventAddNotificationCount();
        }
    }

    getWorkerSelectedValue = worker => `${this.props.user.firstname}${this.props.user.lastname}(${this.props.user.username})`;

    eventAddNotificationCount = () => {
        const selectValues = [...document.querySelectorAll(".searchPanel ul>li.select-search-box__option.select-search-box__row")];
        
        if(!selectValues.length) {
            setTimeout(() => {
                this.eventAddNotificationCount();
            }, 400);
            return;
        }

        this.props.supervisiorsHolidays.map((worker, indexWorker) => {
            this.props.allCounts.map(user => {
                if (worker.id === user.id) {
                    let text = selectValues[indexWorker].innerHTML;
                    const re = /(.*)<div class="notification"><div class="circle">\d+<\/div><\/div>/;
                    const match = text.match(re);
                    if (match) {
                        text = match[1];
                    }
                    if (user.count > 0) {
                        selectValues[indexWorker].innerHTML = `${text}<div class="notification"><div class="circle">${user.count}</div></div>`;
                    }
                    else {
                        selectValues[indexWorker].innerHTML = `${text}`;
                    }
                }
            });
        });
    }

    onCalendarChange = (month, year) => {
        const supervisiorOn = this.state.newSupervisior ? this.state.newSupervisior : this.props.userId;
        const startDate = dateToApi(new Date(year, month, 1));
        const endDate = dateToApi(new Date(year, month + 1, 0));
        this.props.fetchSupervisedEmployeesTimesheets(startDate, endDate, this.props.loadedWorkers);
        this.props.fetchCalendarHolidays(month, year, true);
        this.props.fetchWorkers(supervisiorOn);
    }

    onCalendarToggle = () => {
        this.setState({ calendarOpen: !this.state.calendarOpen });
    }

    onListToggle = () => {
        this.setState({ listOpen: !this.state.listOpen });
    }

    switchSupervisorView = id => {
        this.props.addSupervisor(id);
        this.props.fetchWorkers(this.props.userId);
        this.props.fetchTableData(undefined, undefined, id, false);
        this.props.fetchCalendarHolidays(this.props.month, this.props.year, null, true);
        this.fetchSupervisedEmployees(id);
    }

    getWorkerDescription = (worker) => {
        const { isSupervisor, firstname, lastname, id, supervisorid } = worker;
        const loggedUserIsTheDeputyForTheSupervisor = this.props.userId !== supervisorid && !!this.props.supervisiorsHolidays.find(x => x.id === supervisorid);
        const isSelectedOtherSupervisor = this.getWorkerSelectedValue(this.props.user) !== this.props.firstSupervisiorSelected;
        const maxNameLength = 24;

        let fullName = firstname + " " + lastname;
        let shortenedName = (firstname + " " + lastname).substring(0, maxNameLength + 1) + '...';

        if (!isSupervisor || (loggedUserIsTheDeputyForTheSupervisor && isSelectedOtherSupervisor)) 
            return (
                <div style={{ verticalAlign: "middle", cursor: "pointer" }} onClick={() => this.onUserClicked(id)}>
                    <span className="worker-button">{fullName.length <= maxNameLength ? fullName : shortenedName}</span>
                </div>
            );

        return (
            <ButtonBase disableRipple>
                <FolderOpen onClick={() => this.switchSupervisorView(id)} className="worker-button"/>
                <div style={{ verticalAlign: "middle", cursor: "pointer" }} onClick={() => this.onUserClicked(id)}>
                    <span className="worker-name worker-button">{fullName.length <= maxNameLength ? fullName : shortenedName}</span>
                </div>
            </ButtonBase>
        );
    }

    handleSelectOnChange = (value, state, props) => {
        let { month, year, holidayId } = queryString.parse(this.props.location.search);
        if (month && year) {
            month = parseInt(month) - 1;
            year = parseInt(year);
        }
        if (holidayId)
            holidayId = parseInt(holidayId);

        this.props.fetchWorkers(this.props.userId);
        this.props.changeSupervisiorSelected(value);
        this.props.fetchTableData(undefined, undefined, value.id, false);
        this.props.fetchCalendarHolidays(month || this.props.month, year || this.props.year, holidayId, true);
        this.fetchSupervisedEmployees(value.id);
        this.eventAddNotificationCount();
        this.setState({
            newSupervisior: value.id
        });
    }

    handleAddAbsenceClick = () => {
        if(this.props.workerIds && this.props.workerIds.length > 0){
            this.props.openAddDialog(true);
        }
        else{
            this.setState({noEmployeesWarningDialogOpened: true});
        }
    }

    onVerifyDialogClose = () => {
        this.setState({ verifyDialogOpen: false });
    }

    onUserClicked = (userID) => 
    {
        this.setState({ verifyDialogOpen: true, selectedUserId: userID });
    }

    render() {
        const calendarOpen = this.state.calendarOpen || this.props.openHolidayId > 0;
        let notifactionCircle = null;

        if (this.props.firstSupervisiorSelected.length > 0) {

            const selectedUserNotifaction = () => {
                const userValue = this.props.firstSupervisiorSelected;
                const takeUser = this.props.supervisiorsHolidays.filter(item => item.value === userValue);
                
                if(!takeUser.length)
                    return 0;
                
                const takeNotifactionUser = this.props.allCounts.filter(item => item.id === takeUser[0].id);
                if(!takeNotifactionUser.length)
                    return 0;

                return takeNotifactionUser[0].count;
            };

            notifactionCircle = selectedUserNotifaction() > 0 ? (
                <div className="notifactionActiveUser">
                    <div className="notificationSelected">
                        <div className="circle">
                            {selectedUserNotifaction()}
                        </div>
                    </div>
                </div>
            ) : null;
        }
//this.props.firstSupervisiorSelected.length > 0 &&
        const selectSearch = this.props.supervisiorsHolidays.length > 1 ? (
            <>
                <div className="searchPanel displayInlineBlock">
                    <SelectSearch
                        options={this.props.supervisiorsHolidays}
                        value={this.props.firstSupervisiorSelected}
                        name="supervisiors"
                        placeholder="Select User Workers"
                        onChange={this.handleSelectOnChange}
                    />
                    <div className="arrow">
                        <div className="arrowPosition">
                            <ArrowDropDownIcon />
                        </div>
                    </div>
                </div>
                {notifactionCircle}
            </>
        ) : null;

        const verifyDialogProps = {
            selectedMonth: this.props.month,
            selectedYear: this.props.year,
            show: this.state.verifyDialogOpen,
            userRef: this.props.supervisedEmployeesTimesheets.find(t => t.id === this.state.selectedUserId),
            closeHandler: this.onVerifyDialogClose,
        };

        return (
            <div className="calendar-panel">
                <div className="centerSearch">
                    {selectSearch}
                </div>
                <WarningDialog 
                    open={this.state.noEmployeesWarningDialogOpened} 
                    onClose={() => this.setState({noEmployeesWarningDialogOpened: false})}
                    content={EN.holidaysPage.noEmployeesWarningDialog.content}
                    buttonText={EN.holidaysPage.noEmployeesWarningDialog.buttonText}
                />
                <HolidaySummaryDialog />
                <HolidayApprovalDialog />
                <HolidaysHistoryDialog />
                <HolidayEditDialog />
                <HideableSection text="Leave calendar" onToggle={this.onCalendarToggle} in={calendarOpen}>
                    <div className="calendarlegend-wrapper">
                        <div className="calendar-wrapper calendar-panel">
                            <SupervisorBreadcrumbs />
                            <div className="calendar">
                                <div className="calendar-controls">
                                    <CalendarControls
                                        year={this.props.year}
                                        month={this.props.month}
                                        onChange={this.onCalendarChange}
                                    />
                                </div>

                                <CalendarHeader
                                    selectedYear={this.props.year}
                                    selectedMonth={this.props.month}
                                    holidaySummary={true} />
                                <UserMonth
                                    disableDayInteraction
                                    key={this.props.userId}
                                    workerId={this.props.userId}
                                    index={1}
                                     />
                                {this.props.workerIds.map((id, index) => {
                                    return ([
                                        (<UserMonth
                                            getWorkerDescription={this.getWorkerDescription}
                                            disableDayInteraction={!this.props.canClickOnCalendar}
                                            key={id}
                                            workerId={id}
                                            index={index}
                                            enableButton={true} />),
                                    ]);
                                })}
                            </div>
                            <Legend />
                        </div>
                    </div>
                </HideableSection>
                <HideableSection text="Leave requests list" onToggle={this.onListToggle} in={this.state.listOpen}>
                    <PanelHolidaysTable handleAddAbsenceClick={this.handleAddAbsenceClick} addAbsenceButtonVisible={this.props.canClickOnCalendar}/>
                </ HideableSection>
                <VerifyDialog {...verifyDialogProps} />
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.login.user,
        userId: state.login.user.id,
        workerIds: state.holidayPanel.workerIds,
        workers: state.holidays.workers,
        year: state.holidayPanel.year,
        month: state.holidayPanel.month,
        openHolidayId: state.holidayPanel.openHolidayId,
        canClickOnCalendar: state.holidayPanel.supervisorIds.length == 1,
        notificationCount: state.holidays.notificationCount,
        supervisorIds: state.holidayPanel.supervisorIds,
        supervisiorsHolidays: state.holidayPanel.supervisiorsHolidays,
        firstSupervisiorSelected: state.holidayPanel.firstSupervisiorSelected,
        loadedWorkers: state.holidayPanel.loadedWorkers,
        allCounts: state.holidays.allCounts,
        supervisedEmployeesTimesheets: state.supervisedEmployees.supervisedEmployeesTimesheets
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        fetchCalendarHolidays,
        fetchCalendarHolidaysUser,
        onGetPublicHolidays,
        addSupervisor,
        fetchWorkers,
        fetchActiveReplacementsForUser,
        changeSupervisiorSelected,
        fetchTableData,
        openAddDialog,
        fetchSupervisedEmployeesTimesheets
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(PanelContainer);
