import React, { useEffect } from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import userManager from './user-manager';
import { store } from '../index';
import setHeaderAuthToken from '../components/login/helpers/setHeaderAuthToken';
import { onLoadingStart, onLoadingEnd } from '../store/Preloader';
import { onLoginRequest, setTokens } from '../store/Login';
import { onAddFlashMessage, onClearFlashMessages } from '../store/FlashMessage';

//Common
import { EN } from '../components/common/translations';
import { httpReqErrorHandler } from '../components/common/helpers/errorHandler';

const translations = EN.loginPage;

class SignedIn extends React.Component {

  componentDidMount() {
    this.setOidc();
  }

  setOidc = () => {
    const state = store.getState();
    if(!state.login.loginData?.access_token){
      this.props.history.push({ pathname: '/login' });
    } else {
      setHeaderAuthToken(state.login.loginData.access_token);
      setTokens(state.login.loginData.access_token, state.login.loginData.refresh_token);
      this.loadLoginUser(state);
    }
  };

  loadLoginUser = (state) => {
    this.props.onClearFlashMessages();
    this.setState({ errors: {} });

    this.props.onLoadingStart(`${translations.preloader}`);
    this.props.onLoginRequest('')
      .then(() => {
        let returnUrl = sessionStorage.getItem('returnUrl');
        sessionStorage.removeItem('returnUrl');
        this.props.onLoadingEnd();
        this.props.history.push({ pathname: returnUrl ? returnUrl : '/timesheet' });
      }, (reject) => {
        let errors = {};
        this.props.onLoadingEnd();
        this.props.onAddFlashMessage(httpReqErrorHandler(reject));
        this.setState({ errors: errors });
        this.props.history.push({ pathname: '/login' });
      });
  }

  render() {
    return (
      <div/>
    );
  }
}

function mapStateToProps(state) {
  return { login: state.login };
}

function mapDispatchToProps(dispatch) {
  return Redux.bindActionCreators({
    onLoadingStart,
    onLoadingEnd,
    onAddFlashMessage,
    onClearFlashMessages,
    onLoginRequest
  }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(SignedIn);
