import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {EN} from '../common/translations';

const translations = EN.flashMessages;

const propTypes = {
    message: PropTypes.object,
    onDelete: PropTypes.func
};

class FlashMessage extends React.Component{

    constructor(props) {
        super(props);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    onDeleteClick() {
        this.props.onDelete(this.props.message.id);
    }

    render(){

        let {id, type, text} = this.props.message;

        return(
            <div className={classnames('alert', {
                'alert-success': type == 'success',
                'alert-danger': type == 'error'
            })}>
               {type == 'error' ? `${translations.error} ${text && text.length > 0 ? `: ${text}` : ''}` : text }
                <button onClick={this.onDeleteClick} className="close"><span>&times;</span></button>
            </div>
        );
    }
}

FlashMessage.propTypes = propTypes;

export default FlashMessage;