const types = {
    SIDE_MENU_BTN_CLICK: 'SIDE_MENU_BTN_CLICK',
};

/** ACTIONS START */
export const onSideMenuBtnClick = () => ({type: types.SIDE_MENU_BTN_CLICK});
/** ACTIONS END */

// /** REDUCER START */
const initialState={
    isOpen: false
};

export const sideMenu = (state = initialState, action) => {
    let sideMenu = null;
    switch (action.type) {
        case types.SIDE_MENU_BTN_CLICK:

            return {
                isOpen: !state.isOpen
            };

        default:
            return state;
    }

};
/** REDUCER END */

