import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { fetchTableData, showApproveDialog, approveHoliday } from '../../../../store/HolidayPanel';
import memoize from "memoize-one";
import { Constants } from '../../../common/constants';
import Pagination from '../../../common/partials/pagination/pagination';
import { EN } from '../../../common/translations';
import DataTable from '../../../common/DataTable';
import { HolidayPanelStyles, HolidayStyles } from '../../HolidayStyles';
import PanelFilters from './PanelFilters';
import IconButton from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import History from '@material-ui/icons/History';
import { openAddDialog, openHistoryDialog } from "../../../../store/Holidays";
import { countDayOff } from '../../../common/helpers/date';
import AttachmentIcon from '../../../common/holidays/AttachmentIcon';

class PanelHolidaysTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onApproveClick = (e, id) => {
            e.stopPropagation();
            this.props.approveHoliday(id, '', 0);
            this.props.getFetchActiveAppliesCounts(this.props.userId);
        };
        this.onHistoryClick = (e, id) => {
            e.stopPropagation();
            this.props.openHistoryDialog(id);
        };
        this.updatePaging = this.updatePaging.bind(this);
    }

    componentDidMount() {
        this.props.fetchTableData(undefined, undefined, this.props.loadedWorkers ? this.props.loadedWorkers : this.props.userId);
    }

    updatePaging(page) {
        this.props.fetchTableData(page, undefined, this.props.loadedWorkers ? this.props.loadedWorkers : this.props.userId);
    }

    couldBeOpened = supervisorId => this.props.supervisiorsHolidays
        .map(x => x.id)
        .includes(supervisorId);
    

    onCellClicked = ({id, currentUserSupervisorId}) => {
        if (!this.couldBeOpened(currentUserSupervisorId))
            return;

        this.props.showApproveDialog(id);
    }

    getColumns = () => {
        return [
            {
                header: EN.holidaysPage.type,
                valueProperty: 'type',
                getValue: holidayRequest => <AttachmentIcon holidayRequest={holidayRequest} text={x => x.type} />
            },
            {
                header: EN.holidaysPage.applicant,
                getValue: (row) => row.userFirstName + " " + row.userLastName
            },
            {
                header: EN.holidaysPage.days,
                valueProperty: 'daysCount'
            },
            {
                header: EN.holidaysPage.dateFrom,
                getValue: (row) => row.dateFrom.substring(0, 10) + (row.type === 'Remote work' ? ' ' + (row.timeFrom != null ? row.timeFrom : '') : '')
            },
            {
                header: EN.holidaysPage.dateTo,
                getValue: (row) => row.dateTo.substring(0, 10) + (row.type === 'Remote work' ? ' ' + (row.timeTo != null ? row.timeTo : '') : '')
            },
            {
                header: EN.holidaysPage.request,
                getValue: (row) => row.request
            },
            {
                header: EN.holidaysPage.status,
                valueProperty: 'status'
            },
            {
                valueProperty: 'comment',
                header: EN.holidaysPage.comment
            },
            {
                header: EN.holidaysPage.history,
                getValue: (row) => (
                    <>
                        <IconButton onClick={(e) => this.onHistoryClick(e, row.id)} color="primary">
                            <History />
                        </IconButton>
                    </>
                )
            },
            {
                header:
                (
                    this.props.addAbsenceButtonVisible &&
                    <Tooltip placement="bottom-start" title="Add absence">
                        <IconButton
                            onClick={this.props.handleAddAbsenceClick}
                            type="button"
                            style={{ position: "absolute", right: 5, top: 3 }}
                            color="primary">
                            <AddBox />
                        </IconButton>
                    </Tooltip>
                    
                ),
                getValue: (row) => (
                    <>
                        {row.status === Constants.HolidayStatuses.NEW && this.couldBeOpened(row.currentUserSupervisorId) ?
                            (<Button variant="contained" color="primary" onClick={(e) => this.onApproveClick(e, row.id)}>
                                Approve
                            </Button>) : ''
                        }
                    </>
                )
            }
        ];
    }

    render() {
        return (
            <div>
                <PanelFilters />
                <DataTable
                    columns={this.getColumns()}
                    cellStyle={HolidayStyles.headerColStyle}
                    headerStyle={HolidayStyles.headerColStyle}
                    tableStyle={HolidayPanelStyles.table}
                    data={this.props.tableData}
                    onCellClick={this.onCellClicked} />

                <Pagination
                    onChange={this.updatePaging}
                    value={this.props.tablePage}
                    count={this.props.tableCount}
                />
            </div>);
    }
}

const getTableHolidays = (holidays, holidayIds, publicHolidays) => {
    return holidayIds
        .map(x => holidays[x])
        .filter(x => x !== undefined || x !== null)
        .map(x => ({ ...x, daysCount: countDayOff(x.dateFrom, x.dateTo, publicHolidays) }));
};

function mapStateToProps(state) {
    return {
        tablePage: state.holidayPanel.page,
        tableData: getTableHolidays(state.holidays.data, state.holidayPanel.tableIds, state.publicHolidays.wtPublicHolidays),
        tableCount: state.holidayPanel.pageCount,
        tableStatuses: state.holidayPanel.tableStatuses,
        workers: state.holidays.workers,
        userId: state.login.user.id,
        loadedWorkers: state.holidayPanel.loadedWorkers,
        supervisiorsHolidays: state.holidayPanel.supervisiorsHolidays,
    };
}

const mapDispatchToProps = {
    fetchTableData,
    showApproveDialog,
    openAddDialog,
    approveHoliday,
    openHistoryDialog,
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(PanelHolidaysTable);
