import {colors} from '../common/styles.js';

export const HolidayStyles = {
    headerColStyle: {
        color: 'white',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        maxWidth: 0,
        paddingRight: '0px'
    },
    tableBodyStyle: {
        textAlign: 'center'
    },
    title: {
        width: '100%', 
        textAlign: 'center', 
        fontSize: '20px'
    }
};

export const HolidayPanelStyles = {
    headerEmptyCol: {
        float: 'left',
        width: '200px',
        height: '20px',
    },
    headerSummaryCol: {
        float:'left',
        width: '40px',
        textAlign: 'center',
    },
    headerDay: {
        float: 'left',
        border: '1px solid white',
        width: '40px',
        textAlign: 'center'
    },
    headerWeekend: {
        float: 'left',
        border: '1px solid #aaa',
        width: '40px',
        color: '#999',
        textAlign: 'center'
    },
    dayStyles: {
        width: '40px',
        height: '25px',
        border: '1px solid #555',
        float: 'left',
    },
    clearBoth: {
        clear: 'both'
    },
    userFullNameCell: {
        float: 'left',
        width: '200px',
        textAlign: 'left',
        paddingRight: '10px',
    },
    table: {
        clear: 'both',
        position: 'relative',
        paddingTop: 4,
        tableLayout: 'auto'
    },
    buttonStyle: {
        color: colors.nexioOrange,
    },
    buttonDeleteStyle: {
        color: 'red'
    },
    buttonDeleteBackgroundStyle: {
        backgroundColor:  "red",
        '&:hover': {
            "background-color" : "black"
        }
    },
    largeIcon: {
        width: 30,
        height: 30,
    },
    collapseTitleCell: {
        float: 'left',
        width: '200px',
        textAlign: 'right',
    },
    pointerStyle: {   
      height: '25px',
      minHeight: '25px',
      cursor: 'pointer'
    },
    warning: {
        color: 'yellow', 
        fontSize: '10px', 
        width: '100%', 
        textAlign: 'center'
    },
    containerWidth: {
        width: 1700
    }
};