import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import * as ReactRedux from 'react-redux';
import { onAddFlashMessage } from '../../store/FlashMessage';
import { onLoginRequest } from '../../store/Login';
import { setAllTeamsAvailable, setAvailableTeams, disableTeamSelection } from '../../store/Teams';
import { EN } from '../common/translations';
import { hasAnyRole, getAvailableTeams } from '../common/helpers/authorization';

import _ from 'lodash';

function checkUserRolesAndGetTeams(user, routeRoles, teamRoles) {
    if (!user) {
        return [false, undefined];
    }
    if (!routeRoles && !teamRoles) {
        return [true, undefined];
    }
    if (routeRoles && hasAnyRole(user, routeRoles)) {
        return [true, undefined];
    }
    if (teamRoles) {
        const teams = getAvailableTeams(user, teamRoles);
        return [teams.length > 0, teams];
    }
    return [false, undefined];
}

export default function (ComposedComponnet, routeRoles, teamRoles) {

    const propTypes = {
        login: PropTypes.object,
        onAddFlashMessage: PropTypes.func
    };

    const AuthWrapper = (props) => {

        const user = props.login.user;
        useEffect(() => {
            if (!user && !props.login.initialUserFinished)
                props.onLoginRequest("");
        }, [user, props]);

        // Handle changing routes when teams change
        // Without this it would render with invalid selected team
        // which can cause invalid fetches
        const [previousTeams, setPreviousTeams] = useState(undefined);
        const [authorized, teams] = useMemo(
            () => {
                const newTeams = checkUserRolesAndGetTeams(user, routeRoles, teamRoles);
                return _.isEqual(newTeams, previousTeams) ? previousTeams : newTeams;
            },
            [user, routeRoles, teamRoles]);


        useEffect(() => {
            if (!teamRoles) {
                props.disableTeamSelection();
            } else if (teams) {
                props.setAvailableTeams(teams);
            } else {
                props.setAllTeamsAvailable();
            }
            setPreviousTeams(teams);
        }, [teamRoles, teams]);
        if (!props.login.isAuthenticated && props.login.initialUserFinished) {
            let returnUrl = `/login`;
            const pathname = document.location.pathname;
            if(pathname.length > 1){
                returnUrl = `${returnUrl}?returnUrl=${encodeURIComponent(pathname)}`;
            }
            return <Redirect to={returnUrl}  />;
        }


        if (!authorized && user) {
            return <Redirect to="/unauthorized" />;
        }
        return teams === previousTeams && user ? <ComposedComponnet {...props} /> : null;

    };

    AuthWrapper.propTypes = propTypes;

    const mapStateToProps = (state) => ({
        login: state.login
    });

    const mapDispatchToProps = {
        onAddFlashMessage,
        setAllTeamsAvailable,
        setAvailableTeams,
        disableTeamSelection,
        onLoginRequest
    };

    return ReactRedux.connect(
        mapStateToProps,
        mapDispatchToProps
    )(AuthWrapper);
}
