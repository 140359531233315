import React from 'react';
import { bindActionCreators } from 'redux';
import * as ReactRedux from 'react-redux';
import "./userProfile.scss";
import UserEditDialog from '../Employees/Dialogs/UserEditDialog/UserEditDialog';
import { userProfileActiveClick } from '../../store/NavigationBar';

class UserProfile extends React.Component {

    handleClickCancel = (e) => {
        this.props.userProfileActiveClick();
    }

    handleChangeAvatar = (event) =>{
        if(event.target.files.length && (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png")){
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({newAvatar: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    render() {
        let newUser = {};
        if(this.props.login.user){
            const dateYear = new Date().getFullYear();
            const holidaysSettings = [...this.props.login.user.holidaysSettingsResources];
            const thisYear = holidaysSettings.find(({ year }) => year === dateYear);
            const nextYear = holidaysSettings.find(({ year }) => year === dateYear + 1);

            newUser = {
                ...this.props.login.user,
                workgroups: this.props.login.user.workgroups,
                startingHolidaysCurrentYear: thisYear && thisYear.startingHolidays ? thisYear.startingHolidays : 0,
                prevYearHolidaysCurrentYear: thisYear && thisYear.prevYearHolidays ? thisYear.prevYearHolidays : 0,
                startingHolidaysNextYear: nextYear && nextYear.startingHolidays ? nextYear.startingHolidays : 0,
                prevYearHolidaysNextYear: nextYear && nextYear.prevYearHolidays ? nextYear.prevYearHolidays : 0,
                holidaysUsedCurrentYear: thisYear && thisYear.usedHolidays ? thisYear.usedHolidays : 0,
                holidaysPlannedCurrentYear: thisYear && thisYear.plannedHolidays ? thisYear.plannedHolidays : 0,
                holidaysPlannedNextYear: nextYear && nextYear.plannedHolidays ? thisYear.plannedHolidays : 0,
                displaySupervisorHistory: this.props.login.user.userSupervisorHistoryResource && this.props.login.user.userSupervisorHistoryResource.length > 0
            };
        }

        const userProfile = (this.props.login.user && this.props.userProfileActive) ?
            (<UserEditDialog
                isOpened={this.props.userProfileActive}
                onCancelClick={this.handleClickCancel}
                user={newUser}
                allWorkgroups={this.props.login.user.allWorkgroups}
                companiesData={[]}
                edited={false}
                canelClickText="Go Back"
                isUserProfile
            />) : <div />;
        return userProfile;
    }
}

const mapStateToProps = state => ({
    login: state.login,
    userProfileActive: state.navigationBar.userProfileActive,
});

const mapDispatchToProps = dispatchEvent => bindActionCreators({
    userProfileActiveClick,
}, dispatchEvent);

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(UserProfile);