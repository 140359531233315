//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import {host} from '../../common/cfg';
import {onSetNotifications} from '../../../store/Notifications';
import {onAddFlashMessage, onClearFlashMessages} from '../../../store/FlashMessage';
import {colors} from '../../common/styles';
import {httpReqErrorHandler} from '../../common/helpers/errorHandler';

class NotificationWindow extends React.Component {

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.notificationRow !== this.props.notificationRow && nextProps.notifications[nextProps.notificationRow]){
            let notificationId = nextProps.notifications[nextProps.notificationRow].id;
            if(!nextProps.notifications[nextProps.notificationRow].isRead)
                this.markNotificationAsRead(notificationId);
        }
    }

    markNotificationAsRead(notificationId){
            let headers = {
                'headers': {
                    'Content-Type': 'application/json;text/plain;text/json',
                    'Accept': 'application/json'
                }
            };
    
            axios
                .post(`${host}/api/notification/read?notificationId=${notificationId}`, headers)
                .then(response => {
                    localStorage.setItem('notifications', JSON.stringify(response.data));
                    this.props.onSetNotifications(response.data);
                })
                .catch(error => {
                    this
                        .props
                        .onAddFlashMessage(httpReqErrorHandler(error));
                    setTimeout(() => {
                        this
                            .props
                            .onClearFlashMessages();
                        }, 5000);
                });
    }

    render() {

        const {notifications, notificationRow, onCloseClick, isOpen} = this.props;

        const actions = [ < FlatButton key = "close" label = "Close" primary onClick = {
                    onCloseClick
            } />
        ];

        return (
            <Dialog
                title={notifications[notificationRow] !== undefined ? notifications[notificationRow].subject : null}
                actions={actions}
                modal={false}
                open={isOpen}
                bodyStyle={{paddingBottom: '0px'}}
                >
                <span style={{whiteSpace: "pre-wrap"}}>{notifications[notificationRow] !== undefined ? notifications[notificationRow].body : null}</span>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.notificationStore.showNotification,
        notificationRow: state.notificationStore.notificationRow
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onSetNotifications,
        onAddFlashMessage,
        onClearFlashMessages
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(NotificationWindow);