import React from 'react';
import PropTypes from 'prop-types';
//3rd party libs
import _ from 'lodash';
//translations
import {EN} from '../../../common/translations';
//components
import IssueListItem from '../../../timeSheet/issuesPanel/IssuesListItem';
import { colors } from '../../../common/styles';
import { Constants } from '../../../common/constants';

const translation = EN.timesheetPage.issuePanel;

// ISSUES PANEL START
const propTypes = {
    issues: PropTypes.array,
    selectedProject: PropTypes.object,
    dispatch: PropTypes.func

  };

const defaultProps = {
    issues: [],
    selectedProject: {},
    dispatch: undefined
  };

class IssuesPanel extends React.Component {

    constructor(props){
        super(props);

        this.state={
            issues:[]
        };
    }

    componentDidMount(){
        window.addEventListener("resize", this.onUpdateDimensions);
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.onUpdateDimensions);
    }

    onUpdateDimensions() {
        let windowWidth = window.innerWidth;
        let windowHeight = window.innerHeight;
        let toolbarHeight = 0;

        if(document.querySelector('.sortable-list-item.is-selected'))
        {
            let sortableListBox = document.querySelector('.sortable-list').getBoundingClientRect();
            let selectedProjectBox = document.querySelector('.sortable-list-item.is-selected').getBoundingClientRect();

            let issuesListBox = document.querySelector('.issues-list').getBoundingClientRect();
            let verticalDots = document.querySelector('.issues-list-bgnd');

            //in css marrgin top + margin-bottom
            let offsetFix = 8;
            let distance = (selectedProjectBox.top -issuesListBox.top) + offsetFix;

            if(distance > issuesListBox.height){
                verticalDots.style.height = distance+"px";
            }
            else{
                if(distance <= offsetFix)
                {
                    if(issuesListBox.height > (offsetFix*3 +1))
                    {
                        verticalDots.style.height = (issuesListBox.height - (Math.round(offsetFix*3)) )+"px";
                    }
                    else{
                        verticalDots.style.height = offsetFix +"px";
                    }
                }
                else
                {
                    verticalDots.style.height = (issuesListBox.height - ( Math.round(offsetFix*2 ) +1) )+"px";
                }
            }
        }
    }

    issueList = () => {
        const {reportData} = this.props;
        const issues = [];

        for (let issue in reportData)
        {
            const issueRef = (reportData.length
              ? reportData[issue]
              : reportData[issue][0]);
            const issueProps = {
                id: issueRef.issueId,
                text: issueRef.issueName,
                color: colors.nexioOrange,
                name: (issueRef.issueId == Constants.Issues.unpaidHolidayIssueId ?
                      `[${Constants.UnpaidHolidayProject.NAME}] ` + issueRef.issueName : (
                          !issueRef.projectName ? issueRef.issueName : `[${issueRef.projectName}] ` + issueRef.issueName
                        )
                      ),
                key: _.uniqueId(`issue_list_item_${issue}`)
            };

            const listItem = <IssueListItem {...issueProps} />;
            issues.push(listItem);
        }

        return issues;
    }

    render(){
        return(
            <div className="issues-panel">
                <div className="issues-header">
                    <div className="header-col-id">{translation.idColumn}</div>
                    <div className="header-col-issues" style={{float: 'right'}}>{translation.issueColumn}</div>
                </div>
                <div className="issues-list-box">
                    <ul className="issues-list">
                        {this.issueList()}
                    </ul>
                </div>
            </div>
        );
    }
}

IssuesPanel.propTypes = propTypes;
IssuesPanel.defaultProps = defaultProps;

// ISSUES PANEL END
export default IssuesPanel;