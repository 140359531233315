import validator from 'validator';
import lodash from 'lodash';
import {validateFunctions} from '../../common/helpers/validateFunctions';

export default function taskDialogValidation(data) {

    let errorsTask = {};

    if (!validateFunctions.isNumberOverZero(data.companyid)) {
        errorsTask.companyid = 'This field is required';
    }

    if (!validateFunctions.isStringNotEmpty(data.contractName)) {
        errorsTask.contractName = 'This field is required';
    }

    return {
        errorsTask,
        isValid: lodash.isEmpty(errorsTask)
    };
}
