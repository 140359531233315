import React, { useState, useMemo, useRef, useCallback, memo } from 'react';
import moment from 'moment';
import { DatePicker } from 'material-ui-pickers';
import { isoDateFormat } from '../helpers/date';
import TextField from '@material-ui/core/TextField';
import NexioTextField from './TextField';

const mask = isoDateFormat.split("").map(x => /[dDmMyY]/.test(x) ? /\d/: x);

// Datepicker operating on dates in format yyyy-mm-dd with keyboard support
// Operates on a string value. Doesn't perform input validation

const NexioDatePicker = (props) => {
    let { clearable, required, onChange, onBlur, value, inputRef, readOnly, ...rest } = props;
    clearable = clearable || false;
    const inputRefInner = useRef(null);
    inputRef = inputRef || inputRefInner;
    const tfProps = useRef({ value, onChange, inputRef, onBlur });
    tfProps.current = {value, onChange, inputRef, onBlur};

    //Initialized in this way, because recreating component loses focus.
    const TextFieldComponent = useMemo(() => function CalendarTextField(props) {
        return(
            <TextField
                fullWidth
                {...{...props, ...tfProps.current}}
            />);
    }, []);

    const handleDatePickerSelect = useCallback((date) => {
        if (!inputRef.current)
            return;
        inputRef.current.value = date ? moment(date).format(isoDateFormat) : '';
        onChange({ target: inputRef.current });
    }, [inputRef, onChange]);


    const datePickerDate = useMemo(() => {
        const date = moment(value, isoDateFormat);
        if (value && !value.includes('_') && date.isValid()) {
            return date.toDate();
        } else {
            return null;
        }
    }, [value]);

    if (readOnly) return <NexioTextField {...props} />;
    return (
        <DatePicker
            keyboard
            disableOpenOnEnter
            format={isoDateFormat}
            required={required}
            value={datePickerDate}
            onChange={handleDatePickerSelect}
            autoOk
            TextFieldComponent={TextFieldComponent}
            mask={value => value ? mask : []}
            clearable={clearable}
            {...rest}
        />);
};

export default memo(NexioDatePicker);