import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import _ from 'lodash';
import { getWorktimeReportData, getFilteringData } from '../../../store/HR';
import FilterDialog from './FilterDialog/FilterDialog';
import ReportTable from './Table/ReportTable';
import CalendarControls from './../../timeSheet/calendarPanel/CalendarControls';
import ExportToExcel from './../../timeSheet/calendarPanel/ExportToExcel';
import DetailsDialog from './DetailsDialog';
import ViewSelect, { routes } from '../IrregularWorkTime/header/viewSelect/viewSelect';
import moment from 'moment';
import { getDetailsData, getAllYears } from './helpers';
import UnitSelect from './UnitSelect';
import { Units } from './enums';
import { createParametrizedUrl } from '../../common/helpers/urls';
import { Constants } from '../../common/constants';
import Select from '../../common/controls/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '../../common/partials/pagination/pagination';
import { projectsListPanel } from '../../common/styles';

const getDefaultDate = () => {
    const date = moment().subtract(1, 'months');
    return {
        month: date.month(),
        year: date.year()
    };
};

class Report extends React.Component {

    state = {
        unit: Units.MD,
        cancelRequest: false,
        projectDialogOpen: false,
        projectActiveItems: [],
        userDialogOpen: false,
        userActiveItems: [],
        detailsDialogOpen: false,
        detailsDialogData: {},
        reportView: 'monthly',
        reportViewOptions: [
            { id: 0, value: 'yearly', name: 'Yearly' },
            { id: 1, value: 'monthly', name: 'Monthly' }
        ],
        yearValues: [],
        ...getDefaultDate(),
        selectedContracts: [],
        userDialogItemsFiltered: this.props.filteringData.activeUsers,
        userDialogItemsChanged: false,
        selectedPage: 1,
        pages: this.props.pages || 1,
    }

    componentDidMount() {
        this.fetchData();
        this.fetchFilters();
        this.setState({yearValues: getAllYears()});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.month !== this.state.month ||
            prevState.year !== this.state.year ||
            prevState.reportView !== this.state.reportView ||
            prevState.selectedPage !== this.state.selectedPage ||
            !_.isEqual(_.sortBy(prevState.userActiveItems), _.sortBy(this.state.userActiveItems)) ||
            !_.isEqual(_.sortBy(prevState.projectActiveItems), _.sortBy(this.state.projectActiveItems)))
                this.fetchData();
    }

    componentWillUnmount() {
        this.setState({
            cancelRequest: true
        });
    }

    fetchFilters = () => {
        this.props.getFilteringData(this.state.year, this.state.month + 1, this.state.reportView)
            .then(() => {
                this.setState({
                    projectActiveItems: this.props.filteringData.projects.map((x) => x.id),
                    userActiveItems: this.props.filteringData.activeUsers.map((x) => x.id),
                });
            });
    } 

    fetchData = () => {
        const { userActiveItems, projectActiveItems } = this.state;
        const { activeUsers, projects } = this.props.filteringData;

        let selectedUsers = [];
        let selectedProjects = [];

        if(!_.isEqual(userActiveItems, activeUsers.map(x => x.id)))
            selectedUsers = userActiveItems;

        if(!_.isEqual(projectActiveItems, projects.map(x => x.id)))
            selectedProjects = projectActiveItems;
        
        this.props.getWorktimeReportData(this.state.year, this.state.month + 1, this.state.reportView, this.state.selectedPage, selectedUsers, selectedProjects)
            .then(() => {
                if (!this.state.cancelRequest) {
                    this.setState({
                        pages: this.props.pages,
                    });
                }
            });
    }

    hanldeModalOkClick = (prefix, selectedItems) => {
        let filteredItems = this.state.userDialogItemsFiltered;
        let d = {};
        d[`${prefix}DialogOpen`] = false;
        d[`${prefix}ActiveItems`] = this.state.userDialogItemsChanged 
            && (filteredItems.length === selectedItems.length) ? filteredItems.map((x) => x.id) : selectedItems;

        this.setState(d, () => {
            if(prefix === 'user') {
                this.setState({
                    selectedPage: 1,
                    userDialogItemsChanged: false
                });
            }
        });
    }

    hanldeModalCancelClick = (prefix) => {
        let d = {};
        d[`${prefix}DialogOpen`] = false;
        this.setState(d);
    }

 getExcelUrl = () => {
    const { month, year, userActiveItems, projectActiveItems, unit } =
      this.state;
    return {
      url: createParametrizedUrl("/api/hr/generateExcel", {
        month: month + 1,
        year,
        unit,
      }),
      generateExcelHelper: {
        selectedProjects: _.join(projectActiveItems, "-"),
        selectedUsers: _.join(userActiveItems, "-"),
      },
    };
  };

    userItemsFilter = () => {
        const userFilterLabelId = "contract_filter";
        const listItems = Object.entries(Constants.ContractTypes).map(x => {
            const name = x[1];
            return <MenuItem key={`filter_${name}`} value={name}>{name}</MenuItem>;
        });
        const onSelectChanged = event => {
            const contracts = [...event.target.value];
            const allUsers = this.props.filteringData.activeUsers;
            const filteredUsers = contracts.length ?
                allUsers.filter(x => contracts.includes(x.contractName)) :
                allUsers;

            this.setState(prev => ({
                ...prev,
                userDialogItemsChanged:true,
                selectedContracts: contracts,
                userDialogItemsFiltered: filteredUsers,
            }));
        };

        return (
            <FormControl fullWidth>
                <InputLabel id={userFilterLabelId}>Contracts</InputLabel>
                <Select fullWidth multiple
                    labelid={userFilterLabelId}
                    value={this.state.selectedContracts}
                    onChange={onSelectChanged}
                >
                    {listItems}
                </Select>
            </FormControl>
        );
    }

    getUserItemsForModal = () => {
        const { activeUsers } = this.props.filteringData;
        const { userDialogItemsFiltered } = this.state;
        if (userDialogItemsFiltered.length)
            return userDialogItemsFiltered;
        return activeUsers;
    }

    getUserActiveItemsForModal = () =>{
        const filteredUsers = this.state.userDialogItemsFiltered;

        return filteredUsers.length && this.state.userDialogItemsChanged ? filteredUsers.map((x) => x.id) : this.state.userActiveItems;
    }

    handleChangeYear = value => {
        const newYear = parseInt(value.match(/\d+/));
        this.setState({
            year: newYear
        });
    }

    handleChangeReportView = value => {
        const viewReport = value;
        this.setState({
            reportView: viewReport
        });
    }

    render() {
        const { users, projectsTime, topBarHeight, overtimes, holidaysInYear } = this.props;
        const { projects} = this.props.filteringData;
        const { projectDialogOpen, month, year, userActiveItems, projectActiveItems, userDialogOpen, detailsDialogOpen, detailsDialogData, unit } = this.state;

        const searchYear = this.state.year > 0 && this.state.yearValues.length > 0 ? (
            <div>
                <Select value={`year${this.state.year}`} onChange={e => this.handleChangeYear(e.target.value)}>
                    {this.state.yearValues.map(x => <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem>)}
                </Select>
            </div>
        ) : null;

        return (
            <div className="disable-parent-overflow reports">
                <FilterDialog
                    isOpen={projectDialogOpen}
                    items={projects}
                    onOkClick={(selectedItems) => this.hanldeModalOkClick('project', selectedItems)}
                    activeItems={projectActiveItems}
                    onCancelClick={() => this.hanldeModalCancelClick('project')}
                />
                <div className="mainWrapper">
                    <div className="viewSelect selectPaddingTop">
                        <ViewSelect selected="Work time" values={routes} />
                        <Select value={this.state.reportView} onChange={e =>{this.setState({selectedPage:1}); this.handleChangeReportView(e.target.value);}}>
                            <MenuItem value="yearly">Yearly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                        </Select>
                    </div>
                    <div className="calendar-panel report-calendar-panel">
                        <div className="calendar">
                            <div className="calendar-controls">
                                <UnitSelect value={this.state.unit} onChange={(unit) =>  this.setState({ unit })} />
                                <CalendarControls reportView={this.state.reportView} month={month} year={year} onChange={(m, y) => this.setState({ month: m, year: y })} searchYear={searchYear} viewOrginal={false} />
                                <ExportToExcel
                                    getUrl={this.getExcelUrl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <FilterDialog
                    isOpen={userDialogOpen}
                    items={this.getUserItemsForModal()}
                    onOkClick={(selectedItems) => this.hanldeModalOkClick('user', selectedItems)}
                    activeItems={this.getUserActiveItemsForModal()}
                    onCancelClick={() => this.hanldeModalCancelClick('user')}
                    listFilterComponent={this.userItemsFilter()}
                />
                <ReportTable
                    topBarHeight={topBarHeight}
                    projects={projects}
                    projectActiveItems={projectActiveItems}
                    projectsTime={projectsTime}
                    users={users}
                    unit={unit}
                    overtimes={overtimes}
                    holidaysInYear={holidaysInYear}
                    userActiveItems={userActiveItems}
                    onUserDialogClick={() => this.setState({ userDialogOpen: true })}
                    onProjectDialogClick={() => this.setState({ projectDialogOpen: true })}
                    onHeaderClick={(data) => this.setState({ detailsDialogOpen: true, detailsDialogData: { ...data } })}
                    isYearly={this.state.reportView==='yearly'}
                />
                <Pagination 
                    value = {this.state.selectedPage}
                    count = {this.state.pages}
                    onChange={e => this.setState({ selectedPage: e })}
                />
                <DetailsDialog
                    isOpen={detailsDialogOpen}
                    data={getDetailsData(detailsDialogData, projects, projectsTime, users, overtimes, month, year)}
                    onOkClick={() => this.setState({ detailsDialogOpen: false })}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        users: state.hr.users,
        projects: state.hr.projects,
        projectsTime: state.hr.projectsTime,
        overtimes: state.hr.overtimes,
        holidaysInYear: state.hr.holidaysInYear,
        pages: state.hr.pages,
        filteringData: state.hr.filteringData,
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        getWorktimeReportData,
        getFilteringData
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Report);