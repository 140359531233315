import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { onNotificationCountChange } from '../../store/Notifications';
import actions from '../../store/teamTimeSheet/actions';
import { onUserRolesChange, onLogOut, getAccessToken } from '../../store/Login';
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { onAddAppliesCounts } from '../../store/Holidays';
import { host } from './cfg';
import { arrayToObject } from './helpers/utils';
import { getRefreshPromise } from '../../store/config/axiosconfig';
import { onAddFlashMessage } from '../../store/FlashMessage';
import { EN } from '../common/translations';

const CONNECTION_UNAUTHORIZED_ERROR = 'Error: Unauthorized';
const notificationConnection = new HubConnectionBuilder().withUrl(host + "/notificationCountHub", { accessTokenFactory: () => getAccessToken() }).configureLogging(LogLevel.Error).build();
const userRolesConnection = new HubConnectionBuilder().withUrl(host + "/userRolesHub", { accessTokenFactory: () => getAccessToken() }).configureLogging(LogLevel.Error).build();

class Singlr extends React.Component {

  state = {
  }

  reconnectInProgress = false;
  lostConnection = false;
  
  componentDidMount() {
    notificationConnection.on('notificationCountChange', (result) => {
      const notificationCount = arrayToObject(result, p => p.name, p => p.count);
      this.props.onNotificationCountChange({ notificationCount });
    });
    userRolesConnection.on('userRolesChange', (result) => {
      this.props.onUserRolesChange(JSON.parse(result));
    });
    notificationConnection.on('holidaysCountChange', (result) => {
      this.props.onAddAppliesCounts(result);
    });
    notificationConnection.on('teamTimesheetCountChange', (result) => {
      this.props.onTeamTimesheetCountChange(result);
    });
    notificationConnection.onclose(this.handleConnectionClose);
    userRolesConnection.onclose(this.handleConnectionClose);
    this.tryInit();
  }

  initSignalr = () => {
    return Promise.all([this.initNotificationConnection(), this.initUserRolesConnection()])
          .then(() =>{
            if (this.reconnectInProgress && this.lostConnection) {
              this.props.onAddFlashMessage({type: 'success', text: EN.flashMessages.connectionEstablished});
            }
          });
  }
  
  initNotificationConnection = () => !notificationConnection.connectionState ? notificationConnection.start({withCredentials: false}) : Promise.resolve();
  initUserRolesConnection = () => !userRolesConnection.connectionState ? userRolesConnection.start({withCredentials: false}) : Promise.resolve();

  shouldComponentUpdate(nextProps) {
    return nextProps.login.isAuthenticated != this.props.login.isAuthenticated;
  }

  componentDidUpdate() {
    this.tryInit();
  }

  tryInit = () => {
    if (this.props.login.isAuthenticated) {
      this.initSignalr();
    }
  }

  handleConnectionClose = () => {
    if(!this.reconnectInProgress){
      // connection can be closed in various situations, so when there is internet connection
      // then it will reconnect immediately, so we should not show this message
      // after 3 seconds of reconnecting we can assume there is connection issue
      this.setTimeout(function () {
          if (this.reconnectInProgress) {
              this.lostConnection = true;
              this.props.onAddFlashMessage({type: 'error', text: EN.flashMessages.connectionLost});
            }
      }.bind(this), 3000);
      this.reconnect();
    }
  }

  reconnect = () => {
    this.reconnectInProgress = true;
    this.initSignalr()
    .then(() => this.reconnectInProgress = false)
    .catch(err => {
      if(err == CONNECTION_UNAUTHORIZED_ERROR){
        getRefreshPromise()
        .then(() => 
          this.initSignalr().finally(() => {
            this.reconnectInProgress = false;
          })
        )
        .catch(this.props.onLogOut);
      }
      else{
        setTimeout(this.reconnect, 1000);
      }
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => (
  {
    login: state.login,
  });

function mapDispatchToProps(dispatch) {
  return Redux.bindActionCreators({
    onNotificationCountChange,
    onUserRolesChange,
    onAddAppliesCounts,
    onTeamTimesheetCountChange: actions.onTeamTimesheetCountChange,
    onLogOut,
    onAddFlashMessage
  }, dispatch);
}
export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Singlr);