import React, { useMemo, useEffect } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormButton } from '../../../common/controls/formik/FormControls';
import { EN, months } from '../../../common/translations';
import actions from '../../../../store/teamTimeSheet/actions';
import { acceptTeamTimesheet } from '../../../../store/teamTimeSheet/operations';
import { onHideComment, onShowComment } from '../../../../store/Comments';
import IssuePanel from '../../../hr/verifyDialog/IssuePanel/IssuePanel';
import CalendarReport from '../../../hr/verifyDialog/CalendarReport/CalendarReport';
import { red500 } from 'material-ui/styles/colors';
import memoize from 'memoize-one';
import FormDialog from '../../../common/controls/formik/FormDialog';
import "./tableDialog.scss";
import { onCLOSE_TABLE_DIALOG_TEAM_TIMESHEET } from "../../../../store/HR";

const getInitialValues = memoize((selectedApplicationId) => (selectedApplicationId));

const TableDialog = ({ month, year, userRef, tableDialog, closeTableDialog, onCLOSE_TABLE_DIALOG_TEAM_TIMESHEET, openRejectDialog, acceptTeamTimesheet, onShowComment, onHideComment, isAcceptable }) => {
    const actions = useMemo(() => [
        <FormButton key={2} color="default" variant="contained" onClick={closeTableDialog}>{EN.common.cancel}</FormButton>,
        <FormButton key={1} style={{ backgroundColor: red500, margin: 10 }} variant="contained" onClick={openRejectDialog}>{EN.common.reject}</FormButton>,
        (isAcceptable && <FormButton key={0} type="submit" color="secondary" variant="contained">{EN.common.accept}</FormButton>),
    ], [closeTableDialog]);

    const dialogClassName = userRef
        ? `dialog-wrapper${userRef.timeEntries.length ? "" : " noReport"}`
        : "dialog-wrapper noReport";

    const getMonthName = (month) => {
        return months[month];
    };

    useEffect(() => {
        return () => {onCLOSE_TABLE_DIALOG_TEAM_TIMESHEET();};
    }, []);
    const CalendarElement = () => {
        if (!userRef) return null;

        const issues = _.sortBy(_.uniqBy(userRef.timeEntries, 'issueId'), function (e) { return e.projectId });
        const grouppedProjects = _.mapValues(_.groupBy(userRef.timeEntries, "projectId"));

        const calendarProps = {
            reportData: grouppedProjects,
            userHolidays: userRef.userHolidays,
            selectedMonth: month,
            selectedYear: year,
            showComment: onShowComment,
            hideComment: onHideComment
        };

        if (userRef.timeEntries.length) {
            return (
                <div className="calendar" style={{height:'380px'}}>
                    <IssuePanel reportData={issues} />
                    <CalendarReport {...calendarProps} />
                </div>
            );
        } else {
            return (
                <div className="calendar">
                    <div className="pendingTable-info">
                        <h2>{EN.workTimePage.irregular.noReports}</h2>
                    </div>
                </div>
            );
        }
    };

    return (
      <FormDialog
        className={dialogClassName}
        open={tableDialog.open}
        initialValues={getInitialValues(tableDialog.selectedApplicationId)}
        actions={actions}
        onClose={closeTableDialog}
        onSubmit={acceptTeamTimesheet}>
        <div className="pendingTable-inner">
          <div className="person-name">
            <div>{getMonthName(month) + " " + year}</div>
            <div>{tableDialog ? tableDialog.employeeName : ""}</div>
          </div>
          {CalendarElement()}
        </div>
      </FormDialog>
    );
};

const mapStateToProps = state => ({
    tableDialog: state.teamTimesheet.tableDialog,
    userRef: state.hr.userRef,
    month: state.teamTimesheet.teamTimesheetDate.month,
    year: state.teamTimesheet.teamTimesheetDate.year
});

const mapDispatchToProps = dispatch => ({
  onCLOSE_TABLE_DIALOG_TEAM_TIMESHEET: () => dispatch(onCLOSE_TABLE_DIALOG_TEAM_TIMESHEET()),
  closeTableDialog: () => dispatch(actions.closeTableDialog()),
  openRejectDialog: () => dispatch(actions.openRejectDialog()),
  acceptTeamTimesheet: (selectedApplicationId) => dispatch(acceptTeamTimesheet(selectedApplicationId)),
  onHideComment,
  onShowComment,
});

export default connect(mapStateToProps, mapDispatchToProps)(TableDialog);