import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import memoize from "memoize-one";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import History from '@material-ui/icons/History';
import { ArrowBack} from '@material-ui/icons' ;


import { Link } from 'react-router-dom';
import DatePicker from '../../common/controls/DatePicker';

import { Constants } from '../../common/constants';
import { EN } from '../../common/translations';
import { fetchHrUserHolidays, showHistory } from '../../../store/HrUserHolidays';
import { openAddDialog } from '../../../store/Holidays';
import Select from '../../common/controls/Select';

import Tooltip from '@material-ui/core/Tooltip';
import AddBox from '@material-ui/icons/AddBox';

function getListFromObject(source) {
    const ret = [];
    for (let key of Object.keys(source)) {
        const status = source[key];
        ret.push(<MenuItem key={key} value={status}>{status}</MenuItem>);
    }
    return ret;
}

class HrUserHolidaysFilters extends React.PureComponent {
    constructor(props) {
        super(props);

        this.statusesList = getListFromObject(Constants.HolidayHRStatuses);
        this.typesList = getListFromObject(Constants.HolidayHRTypes);
        this.contractTypesList = getListFromObject(Constants.HolidayHRContractTypes);
        this.handleStatusesChange = this.handleStatusesChange.bind(this);
        this.handleTypesChange = this.handleTypesChange.bind(this);
        this.handleContractTypesChange = this.handleContractTypesChange.bind(this);
        this.handleDateFromChange = this.handleDateFromChange.bind(this);
        this.handleDateToChange = this.handleDateToChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getWorkersList = memoize(this.getWorkersList);
        this.state = { tableOptions: this.props.tableOptions, originalOptions: this.props.tableOptions };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tableOptions != state.originalOptions) {
            return { tableOptions: props.tableOptions, originalOptions: props.tableOptions };
        }
        return null;
    }

    handleEmployeeChange = (event) => {
        this.setState({ tableOptions: { ...this.state.tableOptions, employee: event.target.value}});
    }

    handleStatusesChange(event) {
        this.setState({ tableOptions: { ...this.state.tableOptions, statuses: event.target.value } });
    }

    handleTypesChange(event) {
        this.setState({ tableOptions: { ...this.state.tableOptions, types: event.target.value } });
    }

    handleContractTypesChange(event) {
        this.setState({ tableOptions: { ...this.state.tableOptions, contractTypes: event.target.value } });
    }

    handleDateFromChange(event) {
        this.setState({ tableOptions: { ...this.state.tableOptions, dateFrom: event.target.value } });
    }

    handleDateToChange(event) {
        this.setState({ tableOptions: { ...this.state.tableOptions, dateTo: event.target.value } });
    }


    handleSubmit(e) {
        e.preventDefault();
        this.props.fetchHrUserHolidays(1, this.state.tableOptions);
    }

    getWorkersList(workers) {
        return workers.map((x) => (<MenuItem key={x.id} value={x.id}>{x.firstname + ' ' + x.lastname}</MenuItem>));
    }

    onShowHistory = (event,history) =>{
        this.props.showHistory(history);
    }
    render() {
        return(
        <form onSubmit={this.handleSubmit}>
            <Grid container style={{flext:1, justifyContent: 'space-between', paddingLeft: '10px'}} >
                <Grid item lg={11} container spacing={16}>
                    <Grid item lg={2} sm={6} xs={12}>
                    <FormControl fullWidth>
                    <TextField
                        label={EN.hrUserHolidays.employee}
                        value={this.state.tableOptions.employee}
                        onChange={this.handleEmployeeChange}
                    />
                    </FormControl>
                    </Grid>
                    <Grid item lg={2} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>{EN.hrUserHolidays.contractType}</InputLabel>
                            <Select
                                value={this.state.tableOptions.contractTypes}
                                multiple
                                onChange={this.handleContractTypesChange}
                            >
                                {this.contractTypesList}
                            </Select>
                    </FormControl>
                    </Grid>
                    <Grid item lg={2} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>{EN.hrUserHolidays.holidayType}</InputLabel>
                            <Select
                                value={this.state.tableOptions.types}
                                multiple
                                onChange={this.handleTypesChange}
                            >
                                {this.typesList}
                            </Select>
                    </FormControl>
                    </Grid>
                    <Grid item lg={2} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>{EN.hrUserHolidays.status}</InputLabel>
                        <Select
                            value={this.state.tableOptions.statuses}
                            multiple
                            onChange={this.handleStatusesChange}
                        >
                            {this.statusesList}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item lg={4} sm={6} container spacing={16} xs={12}>
                        <Grid item xs={5}>
                            <DatePicker
                                placeholder={EN.hrUserHolidays.dateFrom}
                                value={this.state.tableOptions.dateFrom}
                                onChange={this.handleDateFromChange} />
                        </Grid>
                        <Grid item xs={5}>
                            <DatePicker
                                placeholder={EN.hrUserHolidays.dateTo}
                                value={this.state.tableOptions.dateTo}
                                onChange={this.handleDateToChange} />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                disabled={this.state.tableOptions === this.props.tableOptions}
                                color="primary"
                                type="submit">
                                <Search />
                            </IconButton>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid style={{alignItems: 'flex-end'}}>
                    {!this.props.tableOptions.showHistory &&
                        <Tooltip placement="bottom-start" title="Add absence">
                            <IconButton
                                onClick={() => this.props.openAddDialog(true, undefined, true)}
                                type="button"
                                color="primary">
                                <AddBox />
                            </IconButton>
                        </Tooltip>
                    }
                    {!this.props.tableOptions.showHistory ? 
                        <Link
                            onClick={(e) => this.onShowHistory(e,true)}
                            to="/hrHolidaysHistory">
                            <IconButton
                                color="primary"
                                type="submit">
                                <History/>
                            </IconButton>
                        </Link>
                    :
                        <Link
                            onClick={(e) => this.onShowHistory(e,false)}
                            to="/hrHolidays">
                            <IconButton
                                color="primary"
                                type="submit">
                                <ArrowBack/>
                            </IconButton>
                        </Link>
                    }
                </Grid>
            </Grid>
        </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        tableOptions: state.hrUserHolidays.tableOptions,
    };
}

const mapDispatchToProps = {
    fetchHrUserHolidays,
    showHistory,
    openAddDialog
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HrUserHolidaysFilters);
