import React, { Component } from 'react';
// redux + store + actions
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { getIrregularWorktimeReport } from '../../../store/HR';
//common
import { getDefaultDate } from '../../common/helpers/date';
import { Constants } from '../../common/constants';
//components
import Header from './header/header';
import Filters from './filters/filters';
import IrregularWorkTable from './table/table';
import VerifyDialog from '../verifyDialog/verifyDialog';
import filterTypes from './filters/filterTypes';
import { EN } from "../../common/translations";
import './irregularWorkTime.scss';

class IrregularWorkTime extends Component
{
    state = {
        ...getDefaultDate(),
        filtersTable: [
            { 
                type: filterTypes.TextField, 
                value: '', 
                name: Constants.IrregularWorkTime.LastName, 
                label: EN.workTimePage.irregular.filters.LastName 
            },
            { 
                type: filterTypes.Select, 
                value: '',  
                name: Constants.IrregularWorkTime.ContractName,
                label: EN.workTimePage.irregular.filters.ContractType,
                items: [{ value: '', text: "All" }].concat(Object.entries(Constants.ContractTypes).map(contract => ({
                    value: contract[0],
                    text: contract[1]
                })))
            },
            { type: filterTypes.Checkbox, active: true, name: "AllFilters" },
            { type: filterTypes.Checkbox, active: true, name: "UnreportedDays" },
            { type: filterTypes.Checkbox, active: true, name: "UnreportedMonth" },
            { type: filterTypes.Checkbox, active: true, name: "LessThan8HourPerDay" },
            { type: filterTypes.Checkbox, active: true, name: "OverThan8hourPerDay" },
        ],
        verify: false,
        selectedUser: -1,
        tablePageNo: 1,
        lastFocusedTextFieldName: ''
    }

    componentDidMount() 
    {
        this.getFilteredData();
    }

    /** Updates state of this component and dispach action to refresh table data
     * @param {object} updating object with new values
    */
    updateState = (updating, dispachAction = true) => 
    {
        this.setState(prevState => {
            return {
                ...prevState,
                ...updating
            };
        }, () => {
            if(dispachAction === true)
            {
                this.getFilteredData();
            }
        });
    }

    getFilteredData = () => 
    {
        const filtersArray = this.state.filtersTable;
        const AllFiltersIndex = filtersArray.findIndex(item => item.name === Constants.IrregularWorkTime.AllFilters);
        const AllFiltersRef = filtersArray[AllFiltersIndex];
        let filterTypes = null;

        if(AllFiltersRef.active)
        {
            filterTypes = AllFiltersRef.name;
        }
        else
        {
            filterTypes = filtersArray
                .filter(item => item.active === true)
                .map(item => item.name);
        }

        const reportParams = [
            this.state.tablePageNo,
            this.state.year,
            this.state.month + 1,
            filterTypes,
            filtersArray.find(item => item.name === Constants.IrregularWorkTime.LastName).value,
            filtersArray.find(item => item.name === Constants.IrregularWorkTime.ContractName).value
        ];
        
        this.props.getIrregularWorktimeReport(...reportParams);
    }

    onCalendarChanged = (month, year) => 
    {
        this.updateState({
            month: month,
            year: year
        });
    }

    onFilterChanged = (filterName, value) => 
    {
        let newFiltersTable = [...this.state.filtersTable];
        const thisFilterIndex = newFiltersTable.findIndex(item => item.name === filterName);
        
        if (newFiltersTable[thisFilterIndex].type === filterTypes.Select || newFiltersTable[thisFilterIndex].type === filterTypes.TextField) {
            newFiltersTable[thisFilterIndex].value = value;
        }
        else if(filterName === Constants.IrregularWorkTime.AllFilters)
        {
            const isNowAllActive = newFiltersTable.find(item => item.name === Constants.IrregularWorkTime.AllFilters).active;
            newFiltersTable = newFiltersTable.map(item => {
                if(item.type === filterTypes.Checkbox)
                    item.active = !isNowAllActive;

                return item;
            });
        }
        else 
        {
            const AllFiltersIndex = newFiltersTable.findIndex(item => item.name === Constants.IrregularWorkTime.AllFilters);
            const filterRef = newFiltersTable[thisFilterIndex];
            filterRef.active = !filterRef.active;

            const reducer = (prev, current) => {
                if (
                    current.name !== Constants.IrregularWorkTime.AllFilters &&
                    current.active === true &&
                    current.type === filterTypes.Checkbox
                )
                    prev++;
                return prev;
            };
            const sumActiveFilters = newFiltersTable.reduce(reducer, 0);
            const numberOfFilters = newFiltersTable.filter(filter => filter.type === filterTypes.Checkbox).length - 1;
            const AllFiltersRef = newFiltersTable[AllFiltersIndex];

            if (sumActiveFilters === numberOfFilters)
            {
                AllFiltersRef.active = true;
            }
            else
            {
                AllFiltersRef.active = false;
            }
        }        
        
        this.updateState({
            filtersTable: newFiltersTable,
            tablePageNo: 1,
            lastFocusedTextFieldName: newFiltersTable[thisFilterIndex].type === filterTypes.TextField && value ? filterName : ''
        });
    }

    onVerifyClicked = (userID) => 
    {
        this.updateState({
            verify: true,
            selectedUser: userID
        }, false);
    }

    onVerifyClose = () => 
    {
        this.updateState({
            verify: false,
            selectedUser: -1
        }, false);
    }

    onTablePageChanged = (newPage) =>
    {
        this.updateState({
            tablePageNo: newPage
        });
    }

    render()
    {
        const headerProps = {
            month: this.state.month,
            year: this.state.year,
            onCalendarChanged: this.onCalendarChanged
        };

        const filtersProps = {
            filters: this.state.filtersTable,
            onFilterChanged: this.onFilterChanged,
            lastFocusedTextFieldName: this.state.lastFocusedTextFieldName
        };

        const verifyDialogProps = {
            selectedMonth: this.state.month,
            selectedYear: this.state.year,
            show: this.state.verify,
            userRef: this.props.reportData.users.find(user => user.id === this.state.selectedUser),
            closeHandler: this.onVerifyClose,
        };

        const tableProps = {
            ...this.props.reportData,
            verifyHandler: this.onVerifyClicked, 
            TablePage: this.state.tablePageNo,
            pageUpdate: this.onTablePageChanged
        };
        
        return (
            <div className="irregular-work-time">
                <Header {...headerProps} />
                <Filters {...filtersProps} />
                <IrregularWorkTable {...tableProps} />
                <VerifyDialog {...verifyDialogProps} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        reportData: state.hr.irregularWorkTime
    };
};

const mapDispatchToProps = dispatch => {
    const actions = {
        getIrregularWorktimeReport
    };

    return Redux.bindActionCreators(actions, dispatch);
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(IrregularWorkTime);