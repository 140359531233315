import React from 'react';
import { ListItem } from 'material-ui';
import Checkbox from 'material-ui/Checkbox';

export class FilterDialogItem extends React.Component {
    render() {
        const { className, isChecked, children, handleCheckboxChange, id } = this.props;

        return (
            <ListItem
                leftCheckbox={<Checkbox checked={isChecked} onCheck={() => handleCheckboxChange(id, !isChecked)} />}
                className={className}
            >
                {children}
            </ListItem>
        );
    }
}