import { colors } from "../../../common/styles.js";

const searchableSelectStyles = {
  control: (provided) => ({
    ...provided,
    background: "none",
    border: "none",
    borderBottom: "1px solid #fff",
    borderRadius: "0px",
    boxShadow: "none",
    ":hover": {
      borderBottom: "1px solid #fff",
      boxShadow: "0px 1px #fff",
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
    ":active": {
      color: colors.nexioOrange,
    },
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "rgb(48, 48, 48)",
  }),
  option: (provided) => ({
    ...provided,
    padding: "15px 0px 15px 15px",
    backgroundColor: "none",
    color: "#fff",
    transition: "background-color 1ms",
    ":focus": {
      color: colors.nexioOrange,
    },
    ":hover": {
      backgroundColor: "#858585",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export default searchableSelectStyles;
