import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import apiMiddleware from './apiMiddleware';
import rootReducers from '../reducers';

/**
 * Create Store, here is where you apply all reducers, inital 
 * state and middleware: thunk
 * @param {Object} initialState 
 */
export default function initStore() {
    const store = createStore(
        rootReducers, 
        applyMiddleware(thunk, apiMiddleware)
        );

    return store;
}
