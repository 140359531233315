import PropTypes from 'prop-types';
import {colors} from '../../common/styles.js';
import { black } from 'material-ui/styles/colors';

const pricingFilterStyles = {
  //Text field style
  textField: {
    paddingLeft: "25px",
    marginTop: "-15px",
  },
  underlineStyle: {
    borderColor: colors.nexioOrange,
  },
  floatingLabelFocusStyle: {
    fontSize: 12,
    fontWeight: "normal",
    color: "white",
  },
  //Toggle style
  toggle: {
    marginTop: "10px",
    marginLeft: "25px",
    width: "170px",
  },
  thumbOff: {
    backgroundColor: "#d8d9d9",
  },
  trackOff: {
    backgroundColor: "#8b8c8d",
  },
  thumbSwitched: {
    backgroundColor: colors.nexioOrange,
  },
  trackSwitched: {
    backgroundColor: "#b36b00",
  },
  toggleLabel: {
    fontSize: "12px",
  },
  //Icons style
  largeIcon: {
    float: "right",
    marginRight: "30px",
    marginTop: "-60px",
    transform: "scale(1.8)",
  },

  cancleButton: {
    backgroundColor: "#b36b00",
    float: "right",
    marginRight: "30px",
    marginTop: "10px",
  },
};

export default pricingFilterStyles;