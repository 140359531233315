import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import OrangeText from '../../common/partials/orangeText';
import PropTypes from 'prop-types';
import {EN} from '../../common/translations';
import {colors} from '../../common/styles';
import VideoIcon from '@material-ui/icons/OndemandVideo';

class SupportDialog extends React.Component {
    render() {
        const actions = [ < FlatButton key = "close" label = "Close" primary onClick = {
                this.props.onCloseClick
            } />
        ];

        return (
            <Dialog
                title={EN.navigationBar.supportDialog.title}
                actions={actions}
                modal={false}
                open={this.props.isSupportOpen}
                contentStyle={{
                    maxWidth: '450px'
                }}
                bodyStyle={{paddingBottom: '0px'}}
                >
                <table className="supportTable">
                    <tbody>
                        <tr>
                            <td>
                                <OrangeText>{EN.navigationBar.supportDialog.email}: </OrangeText>
                            </td>
                            <td>
                                hr@nexio.pl
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <OrangeText>{EN.navigationBar.supportDialog.contact}: </OrangeText>
                            </td>
                            <td>
                                694-019-640
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <OrangeText>{EN.navigationBar.supportDialog.video}: </OrangeText>
                            </td>
                            <td>
                                <a href="https://www.youtube.com/watch?v=gcRxIqBgTio&feature=youtu.be" rel="noopener noreferrer" target="_blank" style={{color: 'rgba(255, 255, 255, 0.6)', textDecoration: 'none'}}>
                                    {EN.navigationBar.supportDialog.timesheetNexio}
                                    <VideoIcon style={{ height:'20px', verticalAlign: 'sub', color: colors.nexioOrange }} />
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td/>
                            <td>
                                <a href="https://www.youtube.com/watch?v=Pmu0Ki-FgV8" rel="noopener noreferrer" target="_blank" style={{color: 'rgba(255, 255, 255, 0.6)', textDecoration: 'none'}}>
                                    {EN.navigationBar.supportDialog.holidays}
                                    <VideoIcon style={{ height:'20px', verticalAlign: 'sub', color: colors.nexioOrange }} />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr className="divider" />
            </Dialog>
        );
    }
}

SupportDialog.propTypes = {
    onCloseClick: PropTypes.func,
    isSupportOpen: PropTypes.bool,
};

export default SupportDialog;