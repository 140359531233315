import React from 'react';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from '@material-ui/core/MenuItem';
import { Units } from './enums';
import Select from '../../common/controls/Select';

const labelStyle = {
  fontSize: "0.8em",
  width: '146px',
  height: '36px',
  opacity: 1,
  top: 0,
  textAlign: 'left'
};

const UnitSelect = (props) => {
  return (<div style={{ display: 'inline-block' }}>
    <Select value={props.value}
      onChange={(e) => props.onChange(e.target.value)}>
      <MenuItem value={Units.MD}>{Units.MD}</MenuItem>
      <MenuItem value={Units.Hours}>{Units.Hours}</MenuItem>
    </Select>
  </div>
  );
};

export default UnitSelect;


