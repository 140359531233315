import React, { useCallback, useState, useMemo, memo, useEffect } from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { EN } from '../../common/translations';
import { Constants } from '../../common/constants';
import { HolidayPanelStyles } from '../HolidayStyles';
import { useConfirm } from '../../confirm/ConfirmDialog';
import { FormButton } from '../../common/controls/formik/FormControls';
import { closeSummaryDialog } from '../../../store/Holidays';
import FormDialog from '../../common/controls/formik/FormDialog';
import HolidaySummary from '../HolidaySummary';

class HolidaySummaryDialog extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    actions = (props) => {
        return (
            <FormButton key={1} onClick={props.onClose}>{EN.common.close}</FormButton>
        );
    };

    render() {
        return (
            <>
                <FormDialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    actions={() => this.actions(this.props)}>
                     <HolidaySummary/>
                </FormDialog>
            </>
        )
    }
}

let emptyHoliday = {};
function mapStateToProps(state) {
    return {
        open: state.holidays.dialogSummaryOpen,
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onClose: closeSummaryDialog,
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HolidaySummaryDialog);