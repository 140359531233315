import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import {company} from '../common/cfg';

export const colors={
  nexioOrange : (company === "Microsec") ? "#65bf79" : '#FF9900',
  topMenuGrey : '#232323',
  lightLetters : '#ababab',
  sideMenuLetters : '#757575',
  isHoliday: '#49ad4d',
  onDemand: '#9f48cd',
  isSickLeave:'#0096ff',
  isBankHoliday: '#ff0000',
  lightBlue: '#0ce3ac',
  grey: '#797979',
  red: '#FF0000'
};

export const themeOptions = {
  palette: {
      type: 'dark',
      primary: {
          main: colors.nexioOrange,
          contrastText: '#fff'
      },
      secondary: {
          main: colors.lightBlue
      }
  },  
  typography: {
      useNextVariants: true,
      htmlFontSize: 10,
      button: {
          textTransform: 'none'
      }
  },
  overrides: {
    MuiTable: {
      root: { 
        backgroundColor: '#303030'
      },
    },
    MuiTableRow: {
      hover: {
        '&:hover': {
          backgroundColor: (company === "Microsec" ? "#408750" : "rgb(192, 132, 43)") + " !important"
        }
      }
    },
    MuiTableCell: {
      body: {
        borderBottomColor: '#222'
      }
    },
    MuiMenuItem: {
      root: {
        "&$selected": {
          "color": colors.nexioOrange,
          "backgroundColor": "transparent !important"
        }
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#303030'
      }
    },
    MuiModal: { //Compatibility with MUI v0. v1 dialogs will always be over v0 dialogs
      root: {
        zIndex: 2000,
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        fontFamily: "Lato",
        lineHeight: "20px",
        letterSpacing: "0px"
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtnSelected: {
        cursor: "context-menu",
      },
    },
  },
};

export const darkTheme = darkBaseTheme;

darkTheme.datePicker= {
  calendarTextColor:"rgba(255, 255, 255, 1)",
  calendarYearBackgroundColor:"#303030",
  color:colors.nexioOrange,
  headerColor:"rgba(255, 255, 255, 0.12)",
  selectColor:colors.nexioOrange,
  selectTextColor:"#303030",
  textColor:"#303030"
};

darkTheme.menuItem = {
  selectedTextColor: colors.nexioOrange
};

darkTheme.flatButton={
  buttonFilterColor:"#999999",
  color:"rgba(0, 0, 0, 0)",
  disabledTextColor:"rgba(255, 255, 255, 0.3)",
  fontSize:14,
  fontWeight:500,
  primaryTextColor:colors.nexioOrange,
  secondaryTextColor:"#ff4081",
  textColor:"rgba(255, 255, 255, 1)"
};

darkTheme.checkbox={
  boxColor:"rgba(255, 255, 255, 1)",
  checkedColor:colors.nexioOrange,
  disabledColor: "rgba(255, 255, 255, 0.3)",
  labelColor:"rgba(255, 255, 255, 1)",
  labelDisabledColor:"rgba(255, 255, 255, 0.3)",
  requiredColor: colors.nexioOrange
};

darkTheme.tableRow={
  hoverColor: (company === "Microsec") ? "#408750" : "rgb(192, 132, 43)"
};

export const inputFieldsStyles = {
  underlineStyle: {
    borderColor: colors.nexioOrange
  },
  floatingLabelStyle: {},
  floatingLabelFocusStyle: {
    color: colors.nexioOrange
  }
};

export const preloaderStyle = {
  nexio: {
    display: 'inline-block',
    position: 'relative',
    color: colors.nexioOrange
  }
};

export const navigationBarStyle = {
  titleBar: {
    backgroundColor: colors.topMenuGrey
  },

  textColor: {
    color: colors.lightLetters
  },

  helpIcon:{
    padding: '0px',
    width: 'inherit'
  },

  demoSpan:{
    fontSize: '17px',
    color: 'grey'
  }
};

export const sideMenuStyle = {
  width: 240,
  colors: {
    nexio: colors.nexioOrange,
    enabled: colors.sideMenuLetters,
    disabled: '#444444'
  },
  homeBtn: {
    display: 'none',
    color: colors.sideMenuLetters
  },
  projectsBtn: {
    color: colors.sideMenuLetters,
  },
  settingsBtn: {
    color: '#444444'
  },
  holidayBtn: {
    color: colors.sideMenuLetters
  }
};

export const projectsListPanel = {
  listItem: {
    backgroundColor: 'blue',
    width: '380px',
    height: '60px',
    marginBottom: '2px',
    listStyleType: 'none'
  },
  itemsList:{
    paddingLeft: '5px',
    direction: 'ltr',
    listStyleType: 'none'
  }
};

export const calendarPanel = {
  calendar:{
    header:{},
    body:{
      tableLetters:'#6e6e6e',
    }
  }
};

export const searchBar = {
  textAreaStyle: {
      // field: {
      //     width: 400,
      //     marginRight: 50
      // },
      floatingLabelFocusStyle: {
          fontSize: 12,
          fontWeight: 'normal',
          color: 'white'
      },
      underlineStyle: {
          borderColor: colors.nexioOrange
      }
  }
};
