import React, { useRef, useMemo, memo, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import ClearAdornment from './ClearAdornment';

// Standard Text control with ability to clear text

const NexioTextField = ({ 
    onChange, 
    InputProps, 
    inputProps, 
    inputRef, 
    value, 
    readOnly,
    type,
    onBlur,
    ...rest 
}) => {
    const ref = useRef(null);
    inputRef = inputRef || ref;
    //Fix number input on firefox
    const handleBlur = useMemo(
        () => type === "number" ? () => inputRef.current && (inputRef.current.value = value) : onBlur, 
        [onBlur, type, value, inputRef]);

    const handleClear = useCallback(() => {
        if (!inputRef.current) return;

        let target = inputRef.current;
        // Handle select with formik
        if (inputRef.current.node) {
            target = inputRef.current.node;
        }
        target.value = '';
        onChange({target}); 
        inputRef.current.focus();
    }, [inputRef, onChange]);

    InputProps = useMemo(() => 
        readOnly ? { ...InputProps, classes: { underline: 'input-readonly-underline' } } :
        value && !rest.disabled && !rest.required ? 
            { ...InputProps, 
                endAdornment: (<ClearAdornment onClick={handleClear}/>) 
            } : 
        InputProps,
        [InputProps, value, rest.disabled, rest.required, readOnly]);

    inputProps = useMemo(() => readOnly ? { ...inputProps, readOnly: true, tabIndex: -1, disabled: rest.disabled || !value } 
        : inputProps, 
        [inputProps, readOnly, rest.disabled, value, rest.select]);
    return (
        <TextField 
            fullWidth
            type={type}
            onBlur={handleBlur}
            onChange={onChange}
            inputRef={inputRef}
            value={value || ''}
            InputProps={InputProps}
            inputProps={inputProps}
            {...rest}/>);
};

export default memo(NexioTextField);