import React from 'react';
import PropTypes from 'prop-types';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';

const propTypes = {
    comments: PropTypes.string
};

const defaultProps = {
    comments: ""
};

class Comments extends React.Component {

    constructor(props){
        super(props);
    }

  render() {

    const {comments} = this.props;

    return (
     <div className="comments">
            <div className='content'>
                {comments}
            </div>
        </div>
    );
  }
}

Comments.propTypes = propTypes;
Comments.defaultProps = defaultProps;

function mapStateToProps(state) {
    return {
        comments: state.comments
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
      
    }, dispatch);
}

export default ReactRedux.connect(
    mapStateToProps,
    null
)(Comments);


