import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import memoize from "memoize-one";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Search from '@material-ui/icons/Search';
import DatePicker from '../../../common/controls/DatePicker';

import { Constants } from '../../../common/constants';
import { EN } from '../../../common/translations';
import { fetchTableData } from '../../../../store/HolidayPanel';
import Select from '../../../common/controls/Select';

function getListFromObject(source) {
    const ret = [];
    for (let key of Object.keys(source)) {
        const status = source[key];
        ret.push(<MenuItem key={key} value={status}>{status}</MenuItem>);
    }
    return ret;
}

class PanelFilters extends React.PureComponent {

    constructor(props) {
        super(props);

        this.statusesList = getListFromObject(Constants.HolidayStatuses);
        this.typesList = getListFromObject(Constants.HolidayTypes);
        this.getWorkersList = memoize(this.getWorkersList);
        this.state = { tableOptions: this.props.tableOptions, originalOptions: this.props.tableOptions };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tableOptions != state.originalOptions) {
            return { tableOptions: props.tableOptions, originalOptions: props.tableOptions };
        }
        return null;
    }

    handleStatusesChange = (event) => {
        this.setState({ tableOptions: { ...this.state.tableOptions, statuses: event.target.value } });
    }

    handleWorkersChange = (event) => {
        this.setState({ tableOptions: { ...this.state.tableOptions, workerIds: event.target.value } });
    }

    handleTypesChange = (event) => {
        this.setState({ tableOptions: { ...this.state.tableOptions, types: event.target.value } });
    }

    handleDateFromChange = (event) => {
        this.setState({ tableOptions: { ...this.state.tableOptions, dateFrom: event.target.value } });
    }

    handleDateToChange = (event) => {
        this.setState({ tableOptions: { ...this.state.tableOptions, dateTo: event.target.value } });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.fetchTableData(1, this.state.tableOptions, this.props.loadedWorkers);
    }

    getWorkersList(workers) {
        return workers.map((x) => (<MenuItem key={x.id} value={x.id}>{x.firstname + ' ' + x.lastname}</MenuItem>));
    }

    render() {
        return(
        <form onSubmit={this.handleSubmit}>
            <Grid container spacing={16}>
                <Grid item lg={3} sm={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel>{EN.holidaysPage.type}</InputLabel>
                        <Select
                            value={this.state.tableOptions.types}
                            multiple
                            onChange={this.handleTypesChange}
                        >
                            {this.typesList}
                        </Select>
                </FormControl>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel>{EN.holidaysPage.applicant}</InputLabel>
                    <Select
                        value={this.state.tableOptions.workerIds}
                        multiple
                        onChange={this.handleWorkersChange}
                    >
                        {this.getWorkersList(this.props.workers)}
                    </Select>
                </FormControl>
                </Grid>
                <Grid item lg={2} sm={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel>{EN.holidaysPage.status}</InputLabel>
                    <Select
                        value={this.state.tableOptions.statuses}
                        multiple
                        onChange={this.handleStatusesChange}
                    >
                        {this.statusesList}
                    </Select>
                </FormControl>
                </Grid>
                <Grid item lg={4} sm={6} container spacing={16} xs={12} style={{marginTop: '8px'}}>
                    <Grid item xs={5}>
                        <DatePicker
                            placeholder={EN.holidaysPage.dateFrom}
                            value={this.state.tableOptions.dateFrom}
                            onChange={this.handleDateFromChange} />
                    </Grid>
                    <Grid item xs={5}>
                        <DatePicker
                            placeholder={EN.holidaysPage.dateTo}
                            value={this.state.tableOptions.dateTo}
                            onChange={this.handleDateToChange} />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            disabled={this.state.tableOptions === this.props.tableOptions}
                            color="primary"
                            type="submit">
                            <Search />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
        );

    }
}


const getWorkers = memoize((workers, workerIds) =>
    workerIds.map(x => workers[x])
);

function mapStateToProps(state) {
    return {
        tableOptions: state.holidayPanel.tableOptions,
        workers: getWorkers(state.holidays.workers, state.holidayPanel.workerIds),
        loadedWorkers: state.holidayPanel.loadedWorkers,
    };
}

const mapDispatchToProps = {
    fetchTableData,
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(PanelFilters);
