import React from 'react';
import Button from '@material-ui/core/Button';
import AttachmentIcon from '@material-ui/icons/Attachment';

const uuidv4 = () =>
    ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => 
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );

const fileUpload = ({ addAttachment, setDisplayError }) => {
    /** maximum size of single attachment, in MiB (mebibytes) */
    const maxFileSize = 10;
    const uploadRef = React.createRef();
    const factoryClicked = () => {
        uploadRef.current.click();
    };

    const fileObject = fileName => ({
        guid: uuidv4(),
        name: fileName,
        isNew: true,
        uploadDone: false
    });

    const onFilesInputChange = async event => {
        const input = event.target;
        const files = input.files;
        const bytesToMebibytes = bytes => bytes / 1024 / 1024;
        let numberOfOmittedFiles = 0;

        if(files.length) 
        {
            for (let file of files)
            {
                if(bytesToMebibytes(file.size) > maxFileSize) 
                {
                    numberOfOmittedFiles++;
                    continue;
                }

                let fileObj = fileObject(file.name);
                addAttachment(fileObj, file);
            }
        }
        setDisplayError(numberOfOmittedFiles > 0);

        input.value = null;
    };
    
    return (
        <>
            <input type="file" hidden multiple ref={uploadRef} onChange={onFilesInputChange} />
            <Button className="add-attchaments" onClick={factoryClicked}>
                <AttachmentIcon />
                <span>Add an attachment</span>
            </Button>
        </>
    );
};
 
export default fileUpload;