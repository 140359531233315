import userManager from '../authentication/user-manager';

class AuthenticationService {
  signIn() {
    const returnUrl = new URLSearchParams(document.location.search).get('returnUrl');
    if(returnUrl){
      sessionStorage.setItem('returnUrl', returnUrl);
    }
    return userManager.signinRedirect();
  }

  signOut() {
    return userManager.signoutRedirect();
  }
}

export default new AuthenticationService();