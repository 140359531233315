import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../../store/teamTimeSheet/actions';
import { getSettledEmployees } from '../../../../store/teamTimeSheet/operations';
import Pagination from '../../../common/partials/pagination/pagination';
import CalendarControls from '../../../timeSheet/calendarPanel/CalendarControls';
import ExportToExcel from '../../../timeSheet/calendarPanel/ExportToExcel';
import { createParametrizedUrl } from '../../../common/helpers/urls';
// Material
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {Button, Switch, FormControlLabel } from '@material-ui/core';
import { EN } from '../../../common/translations';
import { TableStyles, HeaderStyles, SwitchStyles } from '../../style';
import DataTable from '../../../common/DataTable';
import TableDialog from '../dialog/tableDialog'
import RejectDialog from '../dialog/RejectDialog';
import { getWorktimeReportDetails } from '../../../../store/HR';
import { apiExcelsPackFromSettledEmployees, apiExcelPerApplication } from  '../../../common/cfg';
import { checkRolesAndTeamRoles } from '../../../common/helpers/authorization';
import { Constants } from '../../../common/constants';
import './settledEmployeesStyles.scss';

class SettledEmployees extends Component {
    constructor(props) {
        super(props);
        this.columns = this.getColumns();
        this.handleOnlyMyTeamChange = this.handleOnlyMyTeamChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmployeeChange = this.handleEmployeeChange.bind(this);
        this.state = { employee: '' };
    }

    componentDidMount() {
        this.props.getSettledEmployees(1, this.props.month, this.props.year, this.props.isOnlyMyTeamEnabled, this.state.employee);
    }
    
    getExcelsPackFromSettledEmployeesUrl = () => {
        const { month, year } =
          this.props;
        return {
          url: createParametrizedUrl(apiExcelsPackFromSettledEmployees, {
            month: month + 1,
            year
          })
        };
    };

    getExcelPerApplicationUrl = (selectedApplicationId) => {
        return {
            url: createParametrizedUrl(apiExcelPerApplication, {
                 selectedApplicationId: selectedApplicationId
                 })
        };
    }

    openTableDialogF(version, employeeId, employeeName, id) {
        this.props.getWorktimeReportDetails(employeeId, this.props.month, this.props.year);
        this.props.openTableDialog(version, employeeId, employeeName, id);
    }

    getColumns() {
        return [
            {
                header: EN.teamTimesheet.settledEmployees.employeeColumn,
                valueProperty: 'employee'
            },
            {
                header: EN.teamTimesheet.settledEmployees.versionColumn,
                valueProperty: 'version'
            },
            {
                header: EN.teamTimesheet.settledEmployees.postDateColumn,
                getValue: (row) => row.postDate.substring(0, 10)
            },
            {
                header: EN.teamTimesheet.settledEmployees.acceptedDateColumn,
                getValue: (row) => row.acceptDate.substring(0, 10)
            },
            {
                header: EN.teamTimesheet.settledEmployees.acceptedBy,
                getValue: (row) => row.acceptedBy
            },
            {
                header: (
                <>
                    <span style={{lineHeight:5}}>{EN.teamTimesheet.settledEmployees.downloadColumn}</span>
                    <ExportToExcel
                            getUrl={() => this.getExcelsPackFromSettledEmployeesUrl()}/>
                </>),
            
                getValue: row => (
                    <ExportToExcel
                        getUrl={() => this.getExcelPerApplicationUrl(row.id)}/>)
            },
            {
                header: EN.teamTimesheet.settledEmployees.actionColumn,
                getValue: row => (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.openTableDialogF(row.version,row.employeeId, row.employeeName, row.id)}>{EN.teamTimesheet.settledEmployees.view}
                        </Button>)
            },
        ];
    }

    handleOnlyMyTeamChange() {
        this.props.toggleOnlyMyTeamSwitch();
        this.props.getSettledEmployees(1, this.props.month, this.props.year, !this.props.isOnlyMyTeamEnabled, this.state.employee);
    }

    handleEmployeeChange(event) {
        const employee = event.target.value;
        this.setState({employee: employee});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.getSettledEmployees(1, this.props.month, this.props.year, this.props.isOnlyMyTeamEnabled, this.state.employee);
    }

    render() {
        const calendarControlsProps = {
            month: this.props.month,
            year: this.props.year,
            page: this.props.tablePage,
            onChange:  (month, year) => this.props.getSettledEmployees(this.props.tablePage, month, year, this.props.isOnlyMyTeamEnabled, this.state.employee),
        };
        const isSupervisorAndHr =checkRolesAndTeamRoles(this.props.user,[Constants.Roles.supervisor], null) 
            && checkRolesAndTeamRoles(this.props.user,[Constants.Roles.hr], null);
            
        return (
          <>
            <div className="row" style={SwitchStyles}>
                {isSupervisorAndHr && (
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    label={"Only my team"}
                    checked={this.props.isOnlyMyTeamEnabled}
                    onChange={this.handleOnlyMyTeamChange}
                  />
                )}
            </div>
            <div className='header-panel'>
                <form onSubmit={this.handleSubmit}>
                    <TextField 
                        className='header-panel-text-field' 
                        label={"Employee"}
                        onChange={this.handleEmployeeChange}
                        />
                </form>
                <h3>{EN.teamTimesheet.settledEmployees.title}</h3>
            </div>
            <div className="calendar-panel">
              <div className="calendar">
                <div className="calendar-controls">
                  <CalendarControls {...calendarControlsProps} />
                </div>
              </div>
            </div>
            <DataTable
              columns={this.columns}
              cellStyle={TableStyles.headerColStyle}
              headerStyle={TableStyles.headerColStyle}
              tableStyle={TableStyles.table}
              data={this.props.tableData}
            />
            <Pagination
              onChange={(page) =>
                this.props.getSettledEmployees(
                  page,
                  this.props.month,
                  this.props.year,
                  this.props.isOnlyMyTeamEnabled,
                  this.state.employee
                )
              }
              value={this.props.tablePage}
              count={this.props.tableCount}
            />
            {this.props.isOpen ? <TableDialog isAcceptable={false} /> : null}
            <RejectDialog />
          </>
        );
    }
}

const mapStateToProps = state => ({
    isOpen: state.teamTimesheet.tableDialog.open,
    tableData: state.teamTimesheet.settledEmployees.dataTable,
    tablePage: state.teamTimesheet.settledEmployees.page,
    tableCount: state.teamTimesheet.settledEmployees.count,
    year: state.teamTimesheet.teamTimesheetDate.year,
    month: state.teamTimesheet.teamTimesheetDate.month,
    isOnlyMyTeamEnabled: state.teamTimesheet.isOnlyMyTeamEnabled,
    user: state.login.user,
});

const mapDispatchToProps = dispatch => ({
    getSettledEmployees: (page, month, year, isOnlyMyTeamEnabled, employee) => dispatch(getSettledEmployees(page, month, year, isOnlyMyTeamEnabled, employee)),
    getWorktimeReportDetails: (userId, month, year) => dispatch(getWorktimeReportDetails(userId, month + 1, year)),
    toggleOnlyMyTeamSwitch: () => dispatch(actions.toggleOnlyMyTeamSwitch()),
    openTableDialog: (version, employeeId, employeeName, selectedApplicationId) => 
        dispatch(actions.openTableDialog(version, employeeId, employeeName, selectedApplicationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettledEmployees);