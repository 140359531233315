import React from 'react';
import Button from '@material-ui/core/Button';

const breadcrumbStyle = {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
};

function Breadcrumbs({children, onClick, ...rest}) {
    children = Array.isArray(children) ? children : [children];
    return (
        <div {...rest} style={breadcrumbStyle}>
            {children.map((c, i) => (
                <React.Fragment key={i}>
                    {i > 0 && (
                        <div style={{ fontSize: "1.4rem", margin: "0 4px" }}>
                            &gt;
                        </div>
                    )}
                    <Button onClick={() => onClick(i)}>
                        {c}
                    </Button>
                </React.Fragment>
                ))}
        </div>
    );
}

export default Breadcrumbs;