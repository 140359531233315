import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

const headCells = [{ id: "name", label: "E-mails" }];

const EnhancedTableHead = (props) => {
  const { onSelectAllClick, numSelected, rowCount } = props;
  const cells = headCells.map((headCell) => (
    <TableCell key={headCell.id} align="left" padding="none">
      {headCell.label}
    </TableCell>
  ));
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '50px' }} padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all emails" }}
          />
        </TableCell>
        {cells}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
