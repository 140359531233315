export function bisect(array, element, getValue = (x) => x) {
    let start = 0, end = array.length;
    while (end - start) {
        const mid = Math.floor((end + start) / 2);
        if (getValue(array[mid]) <= element) {
            start = mid + 1;
        } else {
            end = mid;
        }
    }
    return start;
}

export function closestElementIndex(array, value, getValue = (x) => x) {
    const iGreater = bisect(array, value, getValue);
    const iLesser = iGreater - 1;
    if (iGreater === array.length) 
        return iLesser;
    if (iLesser < 0) 
        return iGreater;
    return value - getValue(array[iLesser]) > getValue(array[iGreater]) - value 
        ? iGreater : iLesser; 
}

export function arrayToObject(array, propName, propValue) {
    let result = {};
    for(let item of array){
        let name = propName(item);
        name = name[0].toLowerCase() + name.substr(1);
        result[name] = propValue(item);
    }
    return result;
}