import React, { useRef, useCallback, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import _ from 'lodash';

const formStyle = { padding:24 };
const transitionProps = { mountOnEnter: true, unmountOnExit: true };
// Główna klasa dialogu formularza. 
// Konstrukcja oparta jest o bibliotekę Formik.
//
// Elementy formularza powinny być przekazywane przez dzieci opakowane w komponent
// Grid o odpowiedniej szerokości.
//
// Przyjmuje albo normalne dzieci albo funkcję przyjmującą FormikBag (jak element <Formik>),
// co pozwala na bardziej złożoną customizacje.
//
// Domyślnie onClose wywoływany jest na escapie i jak nie było modyfikacji to przy kliknięciu
// poza formularz.
//
// Analogicznie do konstrukcji komponentów MUI nieużywane propsy przechodzą do dialogu.

const FormDialog = ({ actions, initialValues, onSubmit, onClose, children, formikProps, gridProps, validate, ...rest }) => {
    const formikRef = useRef();
    const handleClose = useCallback((event, reason) => (reason != "backdropClick" || !formikRef.current.dirty) && 
        onClose(event, reason), []);

    const inner = (formik) => {
        formikRef.current = formik;
        const actionComponents = _.isFunction(actions) ? actions(formik) : actions;
        return (
            <Form>
                <DialogContent style={formStyle}>
                    <Grid container spacing={16} {...gridProps}>
                        {_.isFunction(children) ? children(formik) : children}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {actionComponents}
                </DialogActions>
            </Form>);
    };
    return (
        <Dialog fullWidth={initialValues?.dialogFullWidth} maxWidth={initialValues?.dialogMaxWidth} onClose={handleClose} TransitionProps={transitionProps} {...rest}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} {...formikProps}>
                {inner}
            </Formik>
        </Dialog>
    );
};

export default memo(FormDialog);