import actions from './actions';
import types  from './types';
import { API } from '../types';

export const getPendingApplication = (page, month, year) => {
    return (dispatch, getState) => {
        dispatch({
            type: API,
            payload: {
                url: 'teamTimesheet/GetPaginTeamTimesheetsToAccept',
                method: "GET",
                text: "Loading timesheet to accept",
                onSuccess: (response) => actions.onGetPendingApplication(response, page, month, year),
                params: {
                    skip: (page - 1) * types.PAGE_SIZE,
                    take: types.PAGE_SIZE,
                    year: year,
                    month: month+1,
                }
            }
        });
    };
};


export const getUnsendTimesheetProposals = (year, month)  => {
    return (dispatch, getState) => {
        dispatch({
            type: API,
            payload: {
                url: `teamTimesheet/getUnsendTimesheetProposals`,
                method: "GET",
                text: "Loading unsend timesheets",
                onSuccess: (response) => actions.onGetUnsendTimesheetProposals(response),
                params: {                   
                    year: year,
                    month: month
                }
            }
        });
    };
};

export const getSettledEmployees = (page, month, year, isOnlyMyTeamEnabled, employee) => {
    return (dispatch, getState) => {
        dispatch({
            type: API,
            payload: {
                url: 'teamTimesheet/GetPaginTeamTimesheetsAccepted',
                method: "GET",
                text: "Loading accepted timesheets",
                onSuccess: (response) => actions.onGetSettledEmployees(response, page, month, year),
                params: {
                    skip: (page - 1) * types.PAGE_SIZE,
                    take: types.PAGE_SIZE,
                    year: year,
                    month: month+1,
                    isOnlyMyTeamEnabled,
                    employee
                }
            }
        });
    };
};

export const getUnsettledEmployees = (page, month, year, isOnlyMyTeamEnabled) => {
    return (dispatch, getState) => {
        dispatch({
            type: API,
            payload: {
                url: 'teamTimesheet/GetPaginUnsettledEmployeesTimesheets',
                method: "GET",
                text: "Loading unsettled timesheets",
                onSuccess: (response) => actions.onGetUnsettledEmployees(response, page, month, year),
                params: {
                    skip: (page - 1) * types.PAGE_SIZE,
                    take: types.PAGE_SIZE,
                    year: year,
                    month: month+1,
                    isOnlyMyTeamEnabled
                }
            }
        });
    };
};

export const sendMail = (data, {setSubmitting, setErrors}) => dispatch => dispatch({   
    type: API,
    payload: {
        url: 'teamTimesheet/SendEmailUnsettledEmployees',
        method: "POST",
        data: data,
        text: "Sending mail",
        successText: "Mail sent",
        onSuccess: () => actions.onSendMailTeamTimesheet(),
        onFinish: () => setSubmitting(false)
    }
});



export const acceptTeamTimesheet = (selectedApplicationId) => ({
    type: API,
    payload: {
        url: 'teamTimesheet/SetTimesheetAccept',
        method: "POST",
        data: selectedApplicationId,
        text: "Accepting timesheet",
        successText: "Timesheet accepted",
        onSuccess: () => actions.onAcceptTeamTimesheet(selectedApplicationId),
    }
});

export const rejectTeamTimesheet = (data, {setSubmitting, setErrors}) => ({
    type: API,
    payload: {
        url: 'teamTimesheet/SetTimesheetReject',
        method: "POST",
        data: data,
        text: "Rejecting timesheet",
        successText: "Timesheet rejected",
        onSuccess: () => actions.onRejestTeamTimesheet(data),
        onFinish: () => setSubmitting(false)
    }
});

export const getTimesheetToAcceptCount = () => {
    return (dispatch) => {
        dispatch({
            type: API, payload: {
                url: 'teamTimesheet/getTimesheetToAcceptCount',
                method: "GET",
                text: "Loading",
                onSuccess: (response) => actions.onTeamTimesheetCountChange(response),
            }
        });
    };
};

export const getTimesheetCompleteness = (year, month, onSuccess) => dispatch =>
    dispatch({
        type: API, payload: {
            url: "teamTimesheet/validateTimesheet",
            method: "GET",
            text: "Loading",
            params: {year, month},
            onSuccess
        }
    });

export const sendTimesheetProposal = (year, month) => dispatch =>
    dispatch({
        type: API, payload: {
            url: "teamTimesheet/putTimesheetProposal",
            method: "PUT",
            text: "Sending timesheet proposal",
            params: {year, month},
            onSuccess: () => dispatch(getTimesheetProposals(year, month))
        }
    });

export const getTimesheetProposals = (year, month) => dispatch => 
    dispatch({
        type: API, payload: {
            url: "teamTimesheet/getTimesheetProposalsForEmployee",
            method: "GET",
            text: "Loading",
            params: {year, month},
            onSuccess: (response) => actions.onGetTimesheetProposals(response),
        }
    });


