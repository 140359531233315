import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import FormDialog from '../../../common/controls/formik/FormDialog';
import { FormTextField, FormButton } from '../../../common/controls/formik/FormControls';
import actions from '../../../../store/teamTimeSheet/actions';
import { rejectTeamTimesheet } from '../../../../store/teamTimeSheet/operations';
import { red500 } from 'material-ui/styles/colors';
import {EN} from '../../../common/translations.js';

const getInitialValues = memoize((applicationId, version, employeeName, userName, userLastname) => ({
    applicationId: applicationId,
    comment: "",
    version: version,
    employeeName: employeeName,
    rejectedBy: userName + " " + userLastname
}));


const RejectDialog = ({userName, userLastname, tableDialog, rejectDialog, closeRejectDialog, rejectTeamTimesheet}) => {
    const actions = useMemo(() => [
        <FormButton color="default" variant="contained" key={1} onClick={closeRejectDialog}>{EN.common.cancel}</FormButton>,
        <FormButton style={{backgroundColor: red500}} variant="contained" key={0} type="submit">{EN.common.reject}</FormButton>,
    ], [closeRejectDialog]);
    return (
        <FormDialog 
            open={rejectDialog.open} 
            initialValues={getInitialValues(tableDialog.selectedApplicationId, tableDialog.version, tableDialog.employeeName, userName, userLastname )} 
            actions={actions} 
            onClose={closeRejectDialog} 
            onSubmit={rejectTeamTimesheet}>
                <FormTextField label={EN.common.version} name="version" disabled />
                <FormTextField label={EN.common.employee} name="employeeName" disabled />
                <FormTextField label={EN.common.rejectedBy} name="rejectedBy" disabled />
                <FormTextField label={EN.common.comment} name="comment" autoFocus multiline required rows={10} rowsMax={100} />
        </FormDialog>
    );
};

const mapStateToProps = state => ({
    tableDialog: state.teamTimesheet.tableDialog,
    rejectDialog: state.teamTimesheet.rejectDialog,
    userName: state.login.user.firstname,
    userLastname: state.login.user.lastname,
});

const mapDispatchToProps = dispatch => ({
    closeRejectDialog: () => dispatch(actions.closeRejectDialog()),
    rejectTeamTimesheet: (data, obj) => dispatch(rejectTeamTimesheet(data, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectDialog);