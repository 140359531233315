import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import actions from '../../../store/teamTimeSheet/actions';
import "./teamTimesheetAside.scss";
import { Constants } from '../../common/constants';
import { checkRolesAndTeamRoles } from '../../common/helpers/authorization';

class TeamTimesheetAside extends Component {
    render() {
        const { pendingApplication, settledEmployees, unsettledEmployees, history, reports } = this.props.buttons;
        
        const hasSupervisorRole = checkRolesAndTeamRoles(this.props.user,[Constants.Roles.supervisor],null);

        return (
            <div className="teamTimeSheet-aside">
                <ButtonTeamTimesheet onClick={this.props.settledEmployeesClick} active={settledEmployees.isSelected} text={settledEmployees.name} disabled={false}/>
                <ButtonTeamTimesheet onClick={this.props.unsettledEmployeesClick} active={unsettledEmployees.isSelected} text={unsettledEmployees.name} disabled={false}/>
                {hasSupervisorRole ? <ButtonTeamTimesheet onClick={this.props.pendingApplicationClick} active={pendingApplication.isSelected} text={pendingApplication.name} disabled={false} />
                : <ButtonTeamTimesheet active={pendingApplication.isSelected} text={history.name} disabled/>}
                <ButtonTeamTimesheet active={history.isSelected} text={history.name} disabled/>
                <ButtonTeamTimesheet active={reports.isSelected} text={reports.name} disabled/>
            </div>
        );
    }
}

const ButtonTeamTimesheet = (props) => {
    return (
        <Button
            className="btn-arrowIcon" 
            variant={props.active === true ? "contained" : "outlined"} 
            color={props.active === true ? "primary" : "default"}
            disabled={props.disabled}
            onClick={props.onClick}>
                {props.text}
                <ArrowForwardIosIcon />
        </Button>
    );
};

const mapStateToProps = state => ({
    buttons: state.teamTimesheet.buttons,
    user: state.login.user
});

const mapDispatchToProps = dispatch => ({
    pendingApplicationClick: () => dispatch(actions.pendingApplicationClick()),
    settledEmployeesClick: () => dispatch(actions.settledEmployeesClick()),
    unsettledEmployeesClick: () => dispatch(actions.unsettledEmployeesClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamTimesheetAside);