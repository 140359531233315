import { API } from './types';
import axios from 'axios';
import {host} from '../components/common/cfg';

const types={
    ON_FETCH_WTHOLIDAYS: "ON_FETCH_WTHOLIDAYS",
    ON_START_FETCH_WTHOLIDAYS: "ON_START_FETCH_WTHOLIDAYS",
    ON_FETCH_WTHOLIDAYS_ERROR: "ON_FETCH_WTHOLIDAYS_ERROR"
};

export const onGetPublicHolidays = () => {
    return (dispatch, getState) => {
        const state = getState();
        if(state.publicHolidays.loading || state.publicHolidays.loaded) return;
        let headers = {
            'headers': {
                'Content-Type': 'application/json;',
                'Accept': 'application/json'
            }
        };
        dispatch({type: types.ON_START_FETCH_WTHOLIDAYS});

        return axios
            .get(`${host}/api/holiday/publicHolidays`, headers)
            .then(response => {
                dispatch({type: types.ON_FETCH_WTHOLIDAYS, response});
            })
            .catch(_error => dispatch({type: types.ON_FETCH_WTHOLIDAYS_ERROR}));
    };
};


const initialState = {
    wtPublicHolidays: new Set(),
    loaded: false,
    loading: false
};

export function publicHolidays(state = initialState, action) {
    switch(action.type) {
        case types.ON_FETCH_WTHOLIDAYS:
            return {
                ...state,
                wtPublicHolidays: new Set(action.response.data.map(x => x.holiday.substring(0,10)))
            };

        case types.ON_START_FETCH_WTHOLIDAYS:
            return {
                ...state,
                loaded: true
            };

        default:
            return state;
    }
}
