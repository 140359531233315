import authenticationService from '../services/authentication-service';

const SIGN_IN_START = 'SIGN_IN_START';


  export const signIn = () => {
    return (dispatch) => {
      dispatch({
        type: SIGN_IN_START,
        promise: authenticationService.signIn()
      });
    };
  };

  const initialState = {
    isLoading: false,
    data: null,
    error: null,
    promise: null
  };

  export function reducerSignIn(state =initialState, action)
  {
    const { type } = action;
    switch(type){
      case SIGN_IN_START:
        return{
           ...state,
          promise: action.promise
       };
       default:
        return state;
    }
  }

export default reducerSignIn;
