import React, { Component } from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dateFormat, dateToIso } from '../../../common/helpers/date';
import { daysSelcetionMode } from './cfg';
import { getHolidayColor } from '../../../holidays/Panel/Presentational/HolidayColors';
import { Constants } from '../../../common/constants';
import { displayHours, isDecimal } from '../helpers/helpers';

const propTypes = {
  day: PropTypes.object,
  selectedDay: PropTypes.object,
  selectedProject: PropTypes.object,
  selectionEnabled: PropTypes.bool,
  selectMode: PropTypes.string,
  selectDay: PropTypes.func.isRequired,
  selectRange: PropTypes.func.isRequired,
  showComments: PropTypes.func.isRequired,
  hideComments: PropTypes.func.isRequired,
  onDaySelectStart: PropTypes.func.isRequired,
  onDaySelectEnd: PropTypes.func.isRequired,
  isTimesheetSent: PropTypes.bool,
};

const defaultProps = {

};

const BodyDay = (props) => {
  const {
    day,
    day: {
      name,
      month,
      date,
      isCurrentMonth,
      isNextMonth,
      isPrevMonth,
      isToday,
      isSelected,
      number,
      issue,
      dayId,
      weekNumber,
      hours,
      comments,
      commentsColor
    },
    selectedDay,
    selectedProject,
    selectionEnabled,
    selectMode,
    selectDay,
    selectRange,
    showComments,
    hideComments,
    onDaySelectStart,
    onDaySelectEnd,
    publicHolidays,
    hasHolidayRequestEnabled,
    isProject,
    isTimesheetSent
  } = props;

  const style = {
    color: (hours > 0  || (day.issue.id === Constants.Issues.unpaidHolidayIssueId && dayId !== -1)) && !isProject ? selectedProject.color : 'inherit',
    visibility: (hours > 0 || (day.issue.id === Constants.Issues.unpaidHolidayIssueId && dayId !== -1)) ? 'visible' : 'hidden',
  };

  const highlightColor = "lightskyblue";

  const commentIndicator = {
    visibility: (comments !== "") ? 'visible' : 'hidden',
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 5px 5px 0',
    borderColor: `transparent ${commentsColor} transparent transparent`
  };

  let isHovered = false;
  let prevColor = null;
  let prevIssueColor = null;

  const convertFrom = date.format(dateFormat).toString().split("/");
  const convertedDate = new Date(convertFrom[2], convertFrom[1] - 1, convertFrom[0]);
  const dayOfWeek = convertedDate.getDay();

  const setNewDayOfWeek = () => {
    const convertFrom = date.format(dateFormat).toString().split("/");
    const convertedDate = new Date(convertFrom[2], convertFrom[1] - 1, convertFrom[0]);
    const week = convertedDate.getDay() === 0 ? weekNumber - 1 : weekNumber;
    const day = convertedDate.getDay() === 0 ? 7 : convertedDate.getDay();
    return {
      week,
      day
    };
  };

  const onClick = (evt) => {
    if (selectMode === daysSelcetionMode.PAINT || selectMode === daysSelcetionMode.WINDOWS) {
      selectDay(day);
    }
  };

  const onMouseOver = (evt) => {
    toggleHighlight(true);

    if (!isWorkingDay(evt) && isHolidayIssue()) return;
    prevColor = evt.currentTarget.style.borderColor;

    if (selectionEnabled &&
      selectedDay.issueId === issue.id &&
      selectMode !== daysSelcetionMode.WINDOWS) {
      selectRange(day, evt.currentTarget);
    }
    else {
      if (comments && comments !== "")
        showComments(comments);

      if (!isProject && !isTimesheetSent) {
        evt.currentTarget.style.borderColor = selectedProject.color;
        for (let i = 0; i < evt.currentTarget.childNodes.length; i++) {
          if (evt.currentTarget.childNodes[i].className == "day-content") {
            evt.currentTarget.childNodes[i].style.color = "white";
            break;
          }
        }
      }
    }
  };

  const onMouseOut = (evt) => {
    toggleHighlight(false);
    
    if (selectionEnabled &&
      selectedDay.issueId === issue.id &&
      selectMode !== daysSelcetionMode.WINDOWS) {
      //ESLint 
    }
    else {
      if (comments && comments !== "")
        hideComments();
      if (!isProject) {
        evt.currentTarget.style.borderColor = prevColor;

        for (let i = 0; i < evt.currentTarget.childNodes.length; i++) {
          if (evt.currentTarget.childNodes[i].className == "day-content") {
            evt.currentTarget.childNodes[i].style.color = selectedProject.color;
            break;
          }
        }
      }
    }
  };

  const onMouseDown = (evt) => {
    toggleHighlight(false);

    if (selectMode === daysSelcetionMode.DRAG && !isTimesheetSent && (isWorkingDay(evt) || !isHolidayIssue())){
      onDaySelectStart(day);
    }
  };

  const onMouseUp = (evt) => {
    toggleHighlight(false);
   
    if (selectMode === daysSelcetionMode.DRAG && !isTimesheetSent) {
      onDaySelectEnd(day);
    }
  };

  const isHolidayIssue = () => {
    const dayIssueId = day.issue.id;
    
    return dayIssueId === Constants.Issues.unpaidHolidayIssueId 
        || dayIssueId === Constants.Issues.holidayIssueId;
  };

  const isWorkingDay = (evt) => {   
    if (isWeekendDay(day) || 
        evt.currentTarget.classList.contains('publicHoliday')) {
      return false;
    }
    return true;
  };
  
  const isWeekendDay = (value) => {
    const dayName = value.name;
    return dayName === 'Sun' || dayName === 'Sat';
  };

  const toggleHighlight = (isHighlighted) => {
    if(isHighlighted){
      document.querySelector(`.headerday-num-${number}`).style.color = highlightColor;    
      let issuerow = document.querySelector(`.issue-row-id-${issue.id}`)    
      if(issuerow){
        prevIssueColor = issuerow.style.color;
        issuerow.style.color = highlightColor;
      }
    }
    else{
      document.querySelector(`.headerday-num-${number}`).style = style;
      let issuerow = document.querySelector(`.issue-row-id-${issue.id}`)
      if(issuerow){
        issuerow.style.color = prevIssueColor;
      }
    }
  }

  let holiday;
  if (day.issue.projectId === Constants.Issues.holidayProjectId) {  
    const currentDate = day.date.format().substr(0, 10);
    holiday = day.issue.userHolidays
      ? day.issue.userHolidays.find(holiday => currentDate >= holiday.dateFrom.substr(0, 10) && currentDate <= holiday.dateTo.substr(0, 10)) : null; 
  }

  const dayStyle = {
    background: holiday ? getHolidayColor(holiday.type, holiday.status, false) : null,
    fontSize: isDecimal(hours) ? "8px" : "11px",
    paddingTop: isDecimal(hours) && "3px",
    verticalAlign: 'top',
  }
  
  return (
    <div
      data-day={new Date(day.date).getTime() + issue.id}
      data-sum-week-num={setNewDayOfWeek().week}
      data-sum-day-of-week={setNewDayOfWeek().day}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      className={
        "day week-num-" + weekNumber + " day-num-" + number + (isToday ? " today" : "") + (publicHolidays.has(dateToIso(day.date.toDate()).substr(0, 10)) ? " publicHoliday" : "")
        + (isCurrentMonth ? " current-month " : " different-month ")
        + (isNextMonth ? " next-month" : "")
        + (isPrevMonth ? " prev-month" : "")
      }
      style={dayStyle}
    >
      <div className="day-comment-indicator" style={commentIndicator} />
      <div className="day-content" style={style}>{displayHours(hours)}</div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    publicHolidays: state.publicHolidays.wtPublicHolidays
  };
}

function mapDispatchToProps(dispatch) {
  return Redux.bindActionCreators({
  }, dispatch);
}

BodyDay.propTypes = propTypes;
BodyDay.defaultProps = defaultProps;

export default ReactRedux.connect(
  mapStateToProps,
  mapDispatchToProps
)(BodyDay);