import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = {
    button: {
        backgroundColor: "red",
        '&:hover': {
            backgroundColor: "#8B0000"
        }
    }
};

const RejectHolidayTableButton = ({classes, children, ...rest}) => (
    <Button variant="contained" color="primary" {...rest} className={classes.button}>{children}</Button>
);
        
export default withStyles(styles)(RejectHolidayTableButton);