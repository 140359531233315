import _ from 'lodash';
import { API } from './types';
import { Constants } from '../components/common/constants';
import { types as holidayTypes, updateCalendar, getFetchActiveAppliesCounts } from './Holidays';
import { fetchSupervisedEmployeesTimesheets } from '../store/SupervisedEmployees';
import { dateToApi } from '../components/common/helpers/date';

export const types = {
    ON_FETCH_TABLE_DATA: "HOLIDAY_PANEL_ON_FETCH_TABLE_DATA",
    ON_FETCH_WORKERS: "HOLIDAY_PANEL_ON_FETCH_WORKERS",
    ON_FETCH_CALENDAR_DATA: "HOLIDAY_PANEL_ON_FETCH_CALENDAR_DATA",
    CHANGE_OPEN_HOLIDAY: "HOLIDAY_PANEL_CHANGE_OPEN_HOLIDAY",
    APPROVE_HOLIDAY: "HOLIDAY_PANEL_APPROVE_HOLIDAY",
    REJECT_HOLIDAY: "HOLIDAY_PANEL_REJECT_HOLIDAY",
    ON_ADDED_SUPERVISOR: "HOLIDAY_PANEL_ON_ADDED_SUPERVISOR",
    ON_GO_BACK_TO_SUPERVISOR: "HOLIDAY_PANEL_ON_GO_BACK_TO_SUPERVISOR",
    ON_FETCH_SUPERVISIOR: "ON_FETCH_SUPERVISIOR",
    ON_FETCH_ACTIVE_REPLACEMENTS_FOR_USER: "ON_FETCH_ACTIVE_REPLACEMENTS_FOR_USER",
    CHANGE_SUPERVISIOR_SELECTED: "CHANGE_SUPERVISIOR_SELECTED",
};

const PAGE_SIZE = 10;

export function showApproveDialog(holidayId) {
    return (dispatch, getState) => {
        const state = getState();
        const holiday = state.holidays.data[holidayId];
        if (holiday) {
            const dateFrom = new Date(holiday.dateFrom.substr(0, 10));
            const dateTo = new Date(holiday.dateTo.substr(0, 10));
            const month = state.holidayPanel.month;
            const year = state.holidayPanel.year;
            let shouldFetch = dateFrom > new Date(year, month + 1, 0) || new Date(year, month, 1) > dateTo;
            if (state.holidayPanel.supervisorIds.length > 1) {
                shouldFetch = true;
                dispatch(onGoBackToSupervisor(0));
            }
            if (shouldFetch)
                dispatch(fetchCalendarHolidays(dateFrom.getMonth(), dateFrom.getFullYear()));
            dispatch(changeOpenHoliday(holidayId));
        }
    };
}

function changeOpenHoliday(holidayId) {
    return { type: types.CHANGE_OPEN_HOLIDAY, holidayId };
}

export function hideApproveDialog() {
    return { type: types.CHANGE_OPEN_HOLIDAY, holidayId: 0 };
}

function onApproveHoliday(response) {
    return { type: types.APPROVE_HOLIDAY, response };
}

export function approveHoliday(id, comment, replacementPersonId) {
    return (dispatch, getState) => {
        const state = getState();
        const userId = state.login.user.id;
        const holiday = { ...state.holidays.data[id], comment, replacementPersonId };
        dispatch({
            type: API, payload: {
                data: holiday,
                url: 'holiday/approveHolidayRequest',
                method: "POST",
                text: "Approving leave request",
                successText: "Leave request approved",
                onSuccess: (response) => {
                    dispatch(onApproveHoliday(response));
                    dispatch(getFetchActiveAppliesCounts(userId));
                }
            }
        });
    };
}

function onRejectHoliday(response) {
    return { type: types.REJECT_HOLIDAY, response };
}

export function rejectHoliday(id, comment) {
    return (dispatch, getState) => {
        const state = getState();
        const holiday = { ...state.holidays.data[id], comment };
        const userId = state.login.user.id;
        dispatch({
            type: API, payload: {
                data: holiday,
                url: 'holiday/rejectHolidayRequest',
                method: "POST",
                text: "Rejecting leave request",
                successText: "Leave request rejected",
                onSuccess: (response) => {
                    dispatch(onRejectHoliday(response));
                    dispatch(getFetchActiveAppliesCounts(userId));
                }
            }
        });
    };
}

export function fetchCalendarHolidays(month, year, holidayId, fetchWorkers) {
    return (dispatch, getState) => {
        let { supervisorIds, loadedWorkers } = getState().holidayPanel;
        if (supervisorIds.length == 0) {
            dispatch(onAddedSupervisor(getState().login.user.id));
            supervisorIds = getState().holidayPanel.supervisorIds;
        }
        const supervisorId = supervisorIds[supervisorIds.length - 1];
        dispatch({
            type: API, payload: {
                params: { month, year, supervisorIds: [supervisorId] },
                url: 'holiday/getSupervisorPanel',
                method: "GET",
                text: "Loading leave calendar",
                onSuccess: (response) => onFetchCalendarData(response, month, year, holidayId),
            }
        });
        if(fetchWorkers)
            dispatch(fetchCalendarHolidaysUser(supervisorId));
    };
}

export const fetchCalendarHolidaysUser = supervisorId => ({
    type: API, 
    payload: {
        url: 'holiday/getWorkers',
        method: "GET",
        text: "Loading workers",
        params: { supervisorIds: [supervisorId] },
        onSuccess: (response) => onFetchWorkers(response, supervisorId)
    }
});

export function fetchActiveReplacementsForUser(userId) {
    return (dispatch, getState) => {
        dispatch({
            type: API, payload: {
                url: 'holiday/GetActiveReplacementsForUser',
                method: "GET",
                text: "Loading workers",
                params: { userId: userId },
                onSuccess: (response) => onActiveReplacementsForUser(response, userId)
            }
        });
    };
}

function onFetchCalendarData(response, month, year, holidayId) {
    return { type: types.ON_FETCH_CALENDAR_DATA, response, month, year, holidayId };
}

export function goBackToSupervisor(tableIndex) {
    return (dispatch, getState) => {
        const { month, year, supervisorIds } = getState().holidayPanel;
        if (tableIndex === supervisorIds.length - 1)
            return;
        dispatch(onGoBackToSupervisor(tableIndex));
        dispatch(fetchTableData(undefined, undefined, supervisorIds[tableIndex], false));
        dispatch(fetchCalendarHolidays(month, year, null, true));
        dispatch(fetchSupervisedEmployeesTimesheets(dateToApi(new Date(year, month, 1)), dateToApi(new Date(year, month + 1, 0)), supervisorIds[tableIndex]));
    };
}

export const addSupervisor = supervisorId => dispatch => dispatch(onAddedSupervisor(supervisorId));

function onAddedSupervisor(supervisorId) {
    return { type: types.ON_ADDED_SUPERVISOR, supervisorId };
}

function onGoBackToSupervisor(tableIndex) {
    return { type: types.ON_GO_BACK_TO_SUPERVISOR, tableIndex };
}

function onFetchWorkers(response, supervisorId) {
    return { type: types.ON_FETCH_WORKERS, response, supervisorId };
}

function onFetchSupervisior(response, supervisorId) {
    return { type: types.ON_FETCH_SUPERVISIOR, response, supervisorId };
}

export function changeSupervisiorSelected(value) {
    return { type: types.CHANGE_SUPERVISIOR_SELECTED, value };
}


function onActiveReplacementsForUser(response, userId) {
    return { type: types.ON_FETCH_ACTIVE_REPLACEMENTS_FOR_USER, response, userId };
}

export function fetchTableData(newPage, newOptions, userId, valid = true) {
    return (dispatch, getState) => {
        let { page, tableOptions, tableLoaded } = getState().holidayPanel;
        newPage = newPage || page;
        newOptions = newOptions || tableOptions;

        if (valid) {
            if (newPage === page && newOptions === tableOptions && tableLoaded)
                return;
        }
        dispatch({
            type: API, payload: {
                url: 'holiday/getHolidayRequestsForSupervisor',
                method: "GET",
                text: "Loading holidays table",
                onSuccess: (response) => onFetchTableData(response, newPage, newOptions),
                params: {
                    start: (newPage - 1) * PAGE_SIZE,
                    count: PAGE_SIZE,
                    ...newOptions,
                    userId: userId
                }
            }
        });
    };
}

function onFetchTableData(response, page, tableOptions) {
    return { type: types.ON_FETCH_TABLE_DATA, response, page, tableOptions };
}

const initialState = {
    workerIds: [],
    calendarIds: {},
    tableIds: [],
    tableOptions: {
        types: [],
        workerIds: [],
        statuses: [Constants.HolidayStatuses.NEW],
        dateFrom: null,
        dateTo: null,
    },
    tableLoaded: false,
    calendarLoaded: false,
    page: 1,
    pageCount: 1,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    supervisorIds: [],
    supervisiorIdsHolidays: [],
    loadedWorkers: undefined,
    openHolidayId: 0,
    supervisiorsHolidays: [],
    firstSupervisiorSelected: '',
    notifactionUsers: [
        { id: 177, count: 5 },
        { id: 42, count: 0 },
        { id: 68, count: 2 },
    ]
};

export function getHolidaysByWorkerIds(newHolidays, originalIds) {
    let ret = {};
    for (let h of newHolidays) {
        ret[h.userId] = ret[h.userId] || [];
        ret[h.userId].push(h.id);
    }
    for (let key in ret) {
        if (_.isEqual(ret[key], originalIds[key])) {
            ret[key] = originalIds[key] || [];
        }
    }
    return ret;
}

export function holidayPanel(state = initialState, action) {
    switch (action.type) {
        case types.ON_FETCH_TABLE_DATA:
            return {
                ...state,
                tableIds: action.response.tableData.map(x => x.id),
                tableOptions: action.tableOptions,
                page: action.page,
                pageCount: Math.ceil((action.response.count) / PAGE_SIZE),
                tableLoaded: true
            };

        case types.ON_FETCH_WORKERS:
            return {
                ...state,
                loadedWorkers: action.supervisorId,
                workerIds: action.response.map(x => x.id)
            };

        case types.ON_FETCH_ACTIVE_REPLACEMENTS_FOR_USER:
            const supervisiors = [...action.response.activeReplacements];
            let supervisiorsUsers = [];
            supervisiors.map(item => {
                const user = {
                    id: item.replacementId,
                    name: `${item.firstname} ${item.lastname}`,
                    value: `${item.firstname}${item.lastname}(${item.login})`
                };
                supervisiorsUsers = [
                    ...supervisiorsUsers,
                    user
                ];
            });
            const selectedUser = supervisiors.filter(item => item.replacementId === action.userId);
            const userValue = `${selectedUser[0].firstname}${selectedUser[0].lastname}(${selectedUser[0].login})`;
            return {
                ...state,
                supervisiorsHolidays: supervisiorsUsers,
            };


        case types.ON_FETCH_SUPERVISIOR:
            return {
                ...state,
                supervisorIds: [action.supervisorId]
            };

        case types.CHANGE_SUPERVISIOR_SELECTED:
            return {
                ...state,
                firstSupervisiorSelected: action.value.value,
                supervisorIds: [action.value.id]
            };




        case types.ON_FETCH_CALENDAR_DATA:
            return {
                ...state,
                year: action.year,
                month: action.month,
                calendarLoaded: true,
                openHolidayId: action.holidayId || state.openHolidayId,
                calendarIds: getHolidaysByWorkerIds(action.response, state.calendarIds)
            };

        case types.CHANGE_OPEN_HOLIDAY:
            return {
                ...state,
                openHolidayId: action.holidayId
            };

        case types.APPROVE_HOLIDAY:
            return {
                ...state,
                openHolidayId: 0,
            };

        case types.REJECT_HOLIDAY:
            return {
                ...state,
                openHolidayId: 0,
            };
        case holidayTypes.SAVE_HOLIDAY:
            return {
                ...state,
                calendarIds: updateCalendar(state.calendarIds, action.response, state.year, state.month),
            };
        case types.ON_ADDED_SUPERVISOR:
            return {
                ...state,
                supervisorIds: [
                    ...state.supervisorIds, 
                    action.supervisorId
                ]
            };
        case types.ON_GO_BACK_TO_SUPERVISOR:
            return {
                ...state,
                supervisorIds: state.supervisorIds.slice(0, action.tableIndex + 1)
            };

        default:
            return state;
    }
}
