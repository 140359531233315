import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { FlatButton, CardText } from 'material-ui';
import _ from 'lodash';
import { DetailsTable } from './Table/DetailsTable';
import { Units } from './enums';
import UnitSelect from './UnitSelect';
import { Grid } from '@material-ui/core';
import { formatValue, getUserRedmineUrl } from './helpers';
import VerifyDialog from '../verifyDialog/verifyDialog';
import { getWorktimeReportDetails } from '../../../store/HR';

class DetailsDialog extends React.Component {
    state = {
        unit: Units.MD,
        verifyDialogProps: {
            verify: false,
            userRef: null
        }
    }

    onVerifyClose = (closeAll = true) => {
        const verifyDialogProps = { ...this.state.verifyDialogProps };
        verifyDialogProps.verify = false;
        this.setState({ verifyDialogProps });
        if (closeAll) {
            this.props.onOkClick();
        }
    }

    onOkClick = () => {
        this.onVerifyClose();
        this.props.onOkClick();
    }

    onVerifyOpen = () => {
        let additionalData = this.props.data.additional;
        if (additionalData?.userId) {
            this.props.getWorktimeReportDetails(additionalData.userId, additionalData.month + 1, additionalData.year);
        }
        let verifyDialogProps = {
            selectedMonth: additionalData?.month,
            selectedYear: additionalData?.year,
            verify: true,
            userRef: this.props.userRef,
            closeHandler: this.onVerifyClose,
        };
        this.setState({ verifyDialogProps });
    }

    render() {
        const additionalData = this.props.data.additional;
        const verifyDialogProps = {
            selectedMonth: additionalData?.month,
            selectedYear: additionalData?.year,
            show: this.state.verifyDialogProps.verify,
            userRef: this.props.userRef,
            closeHandler: this.onVerifyClose,
            goBackBtn: true
        };

        const result = (
            <Dialog
                open={this.props.isOpen}
            >
                <DialogContent>
                    <Grid container justify="space-between" direction="row">
                        <Grid item xs={4}>
                            <UnitSelect value={this.state.unit} onChange={(unit) => this.setState({ unit })} />
                        </Grid>
                        {additionalData && <Grid item xs={5}>
                            <span>
                                <FlatButton primary onClick={this.onVerifyOpen}>Timesheet</FlatButton>
                                <VerifyDialog {...verifyDialogProps} />
                            </span>
                            <a href={getUserRedmineUrl(additionalData.month, additionalData.year, additionalData.userId)} rel="noopener noreferrer" target="_blank" style={{ width: '100%', color: 'rgba(255, 255, 255, 0.6)', textDecoration: 'none' }}>
                                <FlatButton primary >Go to Redmine</FlatButton>
                            </a>
                        </Grid>
                        }
                    </Grid>
                    <DetailsTable shorten={!!additionalData} data={this.props.data} unit={this.state.unit} />
                    {!!additionalData && additionalData.overtime > 0 && <AdditionalData unit={this.state.unit} overtime={additionalData.overtime} />}
                </DialogContent>
                <DialogActions>
                    <FlatButton onClick={this.onOkClick} primary>OK</FlatButton>
                </DialogActions>
            </Dialog>
        );
        return result;
    }
}

const additionalStyle = {
    height: '285px',
    paddingTop: '30px'
};

const AdditionalData = (props) => {

    return <div style={{ paddingTop: '30px' }}>Employee overtime: {formatValue(props.overtime, props.unit)} {props.unit}</div>;
};

const mapStateToProps = state => {
    return {
        userRef: state.hr.userRef
    };
};

const mapDispatchToProps = dispatch => {
    const actions = {
        getWorktimeReportDetails
    };

    return Redux.bindActionCreators(actions, dispatch);
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(DetailsDialog);