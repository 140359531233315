import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import FormDialog from '../../../common/controls/formik/FormDialog';
import { FormTextField, FormButton } from '../../../common/controls/formik/FormControls';
import actions from '../../../../store/teamTimeSheet/actions';
import { sendMail } from '../../../../store/teamTimeSheet/operations';
import {EN} from '../../../common/translations.js';

const getInitialValues = memoize((selectedApplicationId, sendToAll, month, year) => ({
    subject: "",
    body: "",
    selectedApplicationId: selectedApplicationId,
    sendToAll: sendToAll,
    month: month + 1,
    year: year
}));

const SendMailDialog = ({ mailDialog, month, year, closeMailDialog, sendMail }) => {
    const actions = useMemo(() => [
    <FormButton key={1} onClick={closeMailDialog}>{EN.common.cancel}</FormButton>,
        <FormButton key={0} type="submit" color="primary" variant="contained">{EN.common.send}</FormButton>
    ], [closeMailDialog]);
    return (
        <FormDialog 
            open={mailDialog.open} 
            initialValues={getInitialValues(mailDialog.selectedApplicationId, mailDialog.sendToAll, month, year)} 
            actions={actions} 
            onClose={closeMailDialog} 
            onSubmit={sendMail}>          
            <FormTextField label={"To"} name="to" disabled value={mailDialog.sendToAll === true ? EN.common.all : mailDialog.selectedEmployee}/>
            <FormTextField label={EN.common.subject} name="subject" autoFocus required/>
            <FormTextField label={EN.common.content} name="body" multiline required rows={10} rowsMax={100}/>
        </FormDialog>
    );
};

const mapStateToProps = state => ({
    mailDialog: state.teamTimesheet.unsettledEmployeesMailDialog,
    month: state.teamTimesheet.teamTimesheetDate.month,
    year: state.teamTimesheet.teamTimesheetDate.year,
});

const mapDispatchToProps = {
    closeMailDialog: actions.closeMailDialog,
    sendMail
};


export default connect(mapStateToProps, mapDispatchToProps)(SendMailDialog);