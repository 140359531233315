import moment from "moment";

export function range(start, stop, step) {
    if (stop == null) {
      stop = start || 0;
      start = 0;
    }
    step = step || 1;
  
    const length = Math.max(Math.ceil((stop - start) / step), 0);
    const range = Array(length);
  
    for (let idx = 0; idx < length; idx++, start += step) {
      range[idx] = start;
    }
  
    return range;
  }

export const displayHours = (totalHours) => (totalHours !== undefined) ? String(totalHours).slice(0, 5) : 0;

export const isDecimal = (value) => value % 1;

export const displayDateLastUnsubmitedTimesheet = (date) => moment(date,'YYYY-MM-DD').format('MMMM YYYY');