const EventListenerMode = {capture: true};


// Handles drag event by subscribing to document
// 
export function handleGlobalDrag({handleMouseMove, handleMouseUp, handleCleanup}) {

    function cleanup(){
        handleCleanup && handleCleanup();
        document.removeEventListener('mouseup', mouseUpHandler, EventListenerMode);
        document.removeEventListener('mousemove', mouseMoveHandler, EventListenerMode);
        window.removeEventListener('blur', blurHandler, EventListenerMode);
    }
    function mouseUpHandler(e) {
        handleMouseUp && handleMouseUp(e);
        cleanup();
    }
    function mouseMoveHandler(e) {
        handleMouseMove && handleMouseMove(e);
        e.stopPropagation();
    }
    function blurHandler() {
        cleanup();
    }
    document.addEventListener('mouseup', mouseUpHandler, EventListenerMode);
    document.addEventListener('mousemove', mouseMoveHandler, EventListenerMode);
    // document.onBlur doesn't get called in Chrome
    window.addEventListener('blur', blurHandler, EventListenerMode);
} 