import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { EN } from "../../../common/translations";
import { InputLabel } from "@material-ui/core";
import SearchableSelect from "./SearchableSelect";

const ChangeSupervisorDialog = ({
  onCloseClick,
  onSubmitClick,
  isOpen,
  supervisors,
}) => {
  const [supervisor, setSupervisor] = useState(supervisors[0].id);

  const handleSubmit = () => {
    onSubmitClick(supervisor);
  };

  const handleSupervisorChange = (selectedSupervisor) => {
    setSupervisor(selectedSupervisor.id);
  };

  const options = supervisors.map((supervisor) => ({
    id: supervisor.id,
    label: supervisor.firstname + " " + supervisor.lastname,
  }));

  const actions = [
    <FlatButton key="close" label="Close" primary onClick={onCloseClick} />,
    <FlatButton key="submit" label="Submit" primary onClick={handleSubmit} />,
  ];
  return (
    <Dialog
      title={EN.employeesPage.changeSupervisorDialog.dialogTitle}
      actions={actions}
      modal={false}
      open={isOpen}
      contentStyle={{
        maxWidth: "512px",
      }}
      bodyStyle={{ paddingBottom: "0px" }}
    >
      <>
        <SearchableSelect options={options} onChange={handleSupervisorChange} />
        <InputLabel error style={{ margin: "15px" }}>
          {EN.employeesPage.changeSupervisorDialog.warningMessage}
        </InputLabel>
      </>
    </Dialog>
  );
};

ChangeSupervisorDialog.propTypes = {
  onCloseClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ChangeSupervisorDialog;
