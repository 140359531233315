import {onLoadingStart, onLoadingEnd, onLoadingError, onLoadingPending} from './Preloader';
import {onAddFlashMessage, onDeleteFlashMessage, onClearFlashMessages} from './FlashMessage';
import {host} from '../components/common/cfg';
import axios from 'axios';
import {httpReqErrorHandler} from '../components/common/helpers/errorHandler';
import shortId from 'shortid';
import _ from 'lodash';
import { API } from './types';
import jwtDecode from 'jwt-decode';
import { onLogOut } from './Login';

function arrayToGetParameters(array, name) {
    let ret = {};
    for (let i = 0; i < array.length; i++) {
        ret[`${name}[${i}]`] = array[i];
    }
    return ret;
}

// Handles requests, displaying errors and loading overlay with spinner
const apiMiddleware = ({ dispatch }) => next => action => {
    next(action);
    if (action.type != API)
        return;
    let {
        url,                // Endpoint address, relative to $HOST/api/
        method,             // http method (GET, POST, DELETE etc.)
        params,             // URI string params
        data,               // Post data
        onProgress,         // Function that will be called on http request progress changed, e.g. for calculation progress of upload
        onSuccess,          // Function accepting response. If redux action is returned, it will be dispatched
        onFinish,           // Function run on either success or error
        onError,            // Function accepting error
        onValidationError,  // Function accepting response with validation error
        text,               // Loading text. If not provided there will be no overlay while loading data
        successText,         // Success text, shown on green bar. If not provided it won't be shown
        errorText           // Error text, shown on red bar. If not provided it won't be shown
    } = action.payload;

    // Allow for onSuccess, onFinish and onError to be either redux (and thunk) actions or normal functions
    const conditionalDispatch = (action) => 
        action && (_.isFunction(action) || action.type !== undefined) ? dispatch(action) : action;

    const request = {
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        url: `${host}/api/${url}`,
        method,
        timeout: 180000,
        onUploadProgress: e => _.isFunction(onProgress) ? conditionalDispatch(onProgress(e)) : undefined
    };

    if (params) {
        params = { ...params };
        for (let prop in params) {
            if (Array.isArray(params[prop])) {
                const arrayData = arrayToGetParameters(params[prop], prop);
                delete params[prop];
                Object.assign(params, arrayData);
            }
        }
    }

    if (data) {
        if (method.toUpperCase() == "GET" || method.toUpperCase() === "DELETE") {
            throw new Error("Can't add request data to get or delete method");
        }
        request.headers['Content-Type'] = 'application/json;text/plain;text/json';
    } 

    request.data = data;
    request.params = params;

    text && dispatch(onLoadingStart(text));
    let notificationId = shortId.generate();
    axios.request(request)
        .then((response) => {
            text && dispatch(onLoadingEnd());
            onSuccess && conditionalDispatch(onSuccess(response.data));
            onFinish && conditionalDispatch(onFinish);
            if (successText) {
                dispatch(onAddFlashMessage({type: 'success', text: successText, id: notificationId}));
                setTimeout(() => { 
                    dispatch(onDeleteFlashMessage(notificationId));
                }, 5000); 
            }
        })
        .catch((error) => {
            onFinish && conditionalDispatch(onFinish);
            onError && conditionalDispatch(onError(error));
            dispatch(onLoadingEnd());
            if (error.response && error.response.status === 401) {
                dispatch(onLogOut());
                return;
            }
            if (error.response && error.response.status === 422 && onValidationError) {
                conditionalDispatch(onValidationError(error));
            }
            else {
                if(errorText)
                    dispatch(onAddFlashMessage({type: 'error', text: errorText, id: notificationId}));
                else
                    dispatch(onAddFlashMessage({...httpReqErrorHandler(error), id: notificationId}));
            }
            setTimeout(() => { 
                dispatch(onDeleteFlashMessage(notificationId));
            }, 5000); 
        });

};

export default apiMiddleware;