const types = {
    HELP_BTN_CLICK: 'HELP_BTN_CLICK',
    CLOSE_BTN_CLICK: 'CLOSE_BTN_CLICK',
    USER_PROFILE_ACTIVE: 'USER_PROFILE_ACTIVE'
};

/** ACTIONS START */
export const onHelpBtnClick = () => ({type: types.HELP_BTN_CLICK});
export const onCloseBtnClick = () => ({type: types.CLOSE_BTN_CLICK});
export const userProfileActiveClick = () => ({type: types.USER_PROFILE_ACTIVE});
/** ACTIONS END */

// /** REDUCER START */
const initialState={
    isSupportOpen: false,
    userProfileActive: false,
};

export const navigationBar = (state = initialState, action) => {
    switch (action.type) {
        case types.HELP_BTN_CLICK:
            return {...state,
                isSupportOpen: true
            };
        case types.CLOSE_BTN_CLICK:
            return {...state,
                isSupportOpen: false
            };
        case types.USER_PROFILE_ACTIVE:
            return {...state,
                userProfileActive: !state.userProfileActive
            };
        default:
            return state;
    }
};
/** REDUCER END */
