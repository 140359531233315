import React, { Component } from 'react';
import ChangePassword from './ChangePassword';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';

const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%'
};

class ResetPassword extends Component {
    render()
    {
        return (
            <div style={style}>
                <Typography align="center" color="primary" variant="h4"><br/>Reset password</Typography>
                <ChangePassword isReset hash={queryString.parse(this.props.location.search).hash}/>
            </div>);
    }
}

export default ResetPassword;