import React from 'react';
import { FilterDialogItem } from './FilterDialogItem';

const getColoredText = (smallText, actualText, search) => {
	const len = search.length;
	const firstLetter = smallText.indexOf(search);
	return { a: actualText.substring(0, firstLetter), b: <span className="searched-text">{actualText.substr(firstLetter, len)}</span>, c: actualText.substr(firstLetter + len) };
};


export default class FilterDialogItems extends React.PureComponent {
	render() {
		const { items, searchText, selectedItems, handleCheckboxChange } = this.props;
		if (searchText === '') {
			const r = items.map((x) => {
				const isChecked = selectedItems.includes(x.id);
				return (
					<FilterDialogItem key={`proj_items_${x.id}`} id={x.id} className="listItem" isChecked={isChecked} handleCheckboxChange={() => handleCheckboxChange(x.id, !isChecked)}>
						{x.name}
					</FilterDialogItem>
				);
			});
			return r;
		}
		const search = searchText.toLowerCase();
		const result = items.map(x => ({ ...x, smallName: x.name.toLowerCase() }))
			.filter(x => x.smallName.includes(search))
			.map((x, i) => {
				const text = getColoredText(x.smallName, x.name, search);
				const isChecked = selectedItems.includes(x.id);
				return (
					<FilterDialogItem key={`proj_items_${x.id}`} id={x.id} className="listItem" isChecked={isChecked} handleCheckboxChange={() => handleCheckboxChange(x.id, !isChecked)}>
						{text.a}
						{text.b}
						{text.c}
					</FilterDialogItem>
				);
			});
		return result;
	}
}
