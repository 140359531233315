/*React Libs*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
//Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
/*Store*/
import {onLoadingStart, onLoadingEnd, onLoadingError} from '../../store/Preloader';
import {onAddFlashMessage, onClearFlashMessages} from '../../store/FlashMessage';
import {getAllChangeLogs, addChangeLog, deleteAllChangeLog, setChangeLog, downloadFileById} from '../../store/ChangeLog';
/*Common*/
import {httpReqErrorHandler} from '../common/helpers/errorHandler';
/*Material*/
import Button from '@material-ui/core/Button';
/*Custom*/
import ChangeLogEditModal from './ChangeLogEditModal';
import changeLogStyles from './changeLogStyles';
import DataTable from '../common/DataTable';
import { EN } from '../common/translations';
import './changeLog.scss';


const columns = [
    {
        header: EN.changeLogs.date,
        getValue: ({ addedDate }) => <span style={{whiteSpace: 'nowrap'}}>{moment.utc(addedDate).local().format("YYYY-MM-DD")}</span>
    },
    {
        header: EN.changeLogs.subject,
        getValue: ({ title }) => title ? title.length > 50 ? title.substring(0,50) + "..." : title : ''
    },
    {
        header: EN.changeLogs.description,
        getValue: ({ description }) => description ? description.length > 200 ? description.substring(0,200) + "..." : description : ''
    },
    {
        header: EN.changeLogs.file,
        getValue: ({ filename, id }) => filename ? <div className="download-div" onClick={e => downloadFile(e, id)}>{filename}</div> : ''
    }
];

const downloadFile = (e, id) => {
    e.stopPropagation();
    downloadFileById(id);
};

const propTypes = {
    onLoadingStart: PropTypes.func,
    onLoadingEnd: PropTypes.func,
    onLoadingError: PropTypes.func,
    onAddFlashMessage: PropTypes.func,
    onClearFlashMessages: PropTypes.func,
    getAllChangeLogs: PropTypes.func,
    addChangeLog: PropTypes.func,
    deleteAllChangeLog: PropTypes.func,
    setChangeLog: PropTypes.func
};

class ChangeLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            height: '100%',
            isOpen: false,
            dataToEdit: {},
            tableData: [],
            selectedFile: null
        };

        this.onCloseDialog = () => {
            this.setState({isOpen: false, dataToEdit: {}, selectedFile: null});
        };

        this.onSubmitDialog = (changeLog, selectedFile, fileDeleted) => {
            this.props.onLoadingStart('Saving change log');
            this.addChangeLogs(changeLog, selectedFile, fileDeleted);
            this.setState({isOpen: false});
        };

        this.onDeleteDialog = (changeLogId) => {
            this.props.onLoadingStart('Deleting Change Logs');
            this.deleteChangeLogs(changeLogId);
            this.setState({isOpen: false});
        };

        this.onCellClick = (rowData) => {
            this.setState({isOpen: true, dataToEdit: rowData, snackOpen: false});
        };

        this.onAddClick = () => {
            this.setState({isOpen: true, dataToEdit: { title:'', description:''}, selectedFile: null});
        };

        this.getChangeLogs = () => {
            this.props.onLoadingStart('Loading change logs');

            this.props.getAllChangeLogs()
                .then(response => {
                    this.setState({tableData: response.data});
                    this.props.onLoadingEnd();
                },
                (reject) => {
                    this.props.onLoadingError(reject.response ? reject.response.data.error : reject);
                    this.props.onAddFlashMessage(httpReqErrorHandler(reject));
                    setTimeout(() => {this.props.onClearFlashMessages();}, 5000);
                });
        };

        this.addChangeLogs = (changeLog, file, fileDeleted) => {
            this.props.addChangeLog(changeLog, file, fileDeleted)
                .then(response => {
                    this.props.onLoadingEnd();
                    this.props.setChangeLog(response.data);
                    this.getChangeLogs();
                },
                (reject) => {
                    this.props.onLoadingError(reject.response ? reject.response.data.error : reject);
                    this.props.onAddFlashMessage(httpReqErrorHandler(reject));
                    setTimeout(() => {this.props.onClearFlashMessages();}, 5000);
                });
        };

        this.deleteChangeLogs = (changeLogId) => {
            this.props.deleteAllChangeLog(changeLogId)
                .then(response => {
                    this.props.onLoadingEnd();
                    this.props.setChangeLog(response.data);
                    this.getChangeLogs();
                },
                (reject) => {
                    this.props.onLoadingError(reject.response ? reject.response.data.error : reject);
                    this.props.onAddFlashMessage(httpReqErrorHandler(reject));
                    setTimeout(() => {this.props.onClearFlashMessages();}, 5000);
                });
        };
    }

    componentDidMount() {
        this.getChangeLogs();
    }

    render() {

        const { tableData, isOpen, dataToEdit, selectedFile } = this.state;

        const addButton = (
            <div style={changeLogStyles.addBtn}>
                <span style={{float:'right'}}>
                    <Button color="primary" onClick={() => this.onAddClick()} variant="contained">Add</Button>
                    <div style={{width:15, display:"inline-block"}} />
                </span>
            </div>);
    
        return (
            <div style={changeLogStyles.tableDivStyle}>
                <ChangeLogEditModal
                    isOpen={isOpen}
                    onCancelClick={this.onCloseDialog}
                    onSubmitClick={this.onSubmitDialog}
                    onDeleteClick={this.onDeleteDialog}
                    changeLogs={dataToEdit}
                    selectedFile={selectedFile}
                    />
                    {addButton}
                <DataTable 
                    columns={columns}
                    cellStyle={changeLogStyles.changeLogColStyle}
                    headerStyle={changeLogStyles.headerColStyle}
                    tableStyle={{clear: 'both', position:'relative', paddingTop:4, tableLayout: 'auto'}}
                    data={tableData}
                    onCellClick={this.onCellClick} />
            </div>
        );
    }
}

ChangeLog.propTypes = propTypes;

function mapStateToProps(state) {
    return {
        login: state.login,
        changeLogs: state.changeLogs
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onLoadingStart,
        onLoadingEnd,
        onLoadingError,
        onAddFlashMessage,
        onClearFlashMessages,
        getAllChangeLogs,
        addChangeLog,
        deleteAllChangeLog,
        setChangeLog
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLog);