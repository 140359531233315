import * as ReactRedux from 'react-redux';
import { UserMonthBase } from './UserMonth';
import { openAddDialog } from '../../../../store/Holidays';
import { dateToIso } from '../../../common/helpers/date';

function mapStateToProps(state, {workerId}) {
    return {
        worker: state.holidays.workers[workerId],
        holidays: state.holidays.data,
        workerHolidayIds: state.holidays.calendarIds[workerId],
        year: state.holidays.year,
        month: state.holidays.month,
        enableSelection: workerId === state.login.user.id,
        enableButton: false,
    };
}

function mapDispatchToProps(dispatch, {workerId}) {
    return {
        onDaysSelect: (start, end) => dispatch(openAddDialog(false, {dateFrom: dateToIso(start), dateTo: dateToIso(end), userId: workerId})) 
    };
}
        
export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(UserMonthBase);
