import React, { useMemo } from 'react';
import * as ReactRedux from 'react-redux';
import { EN } from '../common/translations';
import { Constants } from '../common/constants';

const HolidaySummary = (props) => {
  return (
    <div className="holiday-summary-panel">
      <label className="summary-color">{props.workerName} - {EN.holidaysPage.holidaysSummaryHeader} {props.isPrevYear ?  props.yearPrev : props.year}</label>
      <hr className="hr" />
      <div>
        <label className="summary-left">{EN.timesheetPage.summaryHrPanel.holidaysTotal}</label> <label>{props.isPrevYear ? props.holidaysTotalPrev : props.holidaysTotal}</label>
      </div>
      <div>
        <label className="summary-left">{EN.timesheetPage.summaryHrPanel.holidaysUsed}</label> <label>{props.isPrevYear ? props.usedHolidaysPrev : props.usedHolidays }</label>
      </div>
      <div>
        <label className="summary-left">{EN.timesheetPage.summaryHrPanel.holidaysPlanned}</label> <label>{props.isPrevYear ? props.plannedHolidaysPrev : props.plannedHolidays}</label>
      </div>
      <hr className="hr" />
      <div>
        <label className="summary-left">{EN.employeesPage.availableHolidays}</label> <label>{props.isPrevYear ? props.holidaysTotalPrev - props.usedHolidaysPrev - props.plannedHolidaysPrev : props.holidaysTotal - props.usedHolidays - props.plannedHolidays}</label>
      </div>
      <div>
        <label className="summary-left">{EN.timesheetPage.summaryHrPanel.holidaysCurrent}</label> <label>{props.currentHolidays === 0 ? props.currentHolidays : -props.currentHolidays}</label>
      </div>
      <hr className="hr" />
      <div>
        <label className="summary-left">{EN.timesheetPage.summaryHrPanel.holidaysRemaining}</label> <label>{props.holidaysTotal - props.usedHolidays - props.plannedHolidays - (props.status === Constants.HolidayStatuses.NEW ? props.currentHolidays : 0)}</label>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    workerName: state.holidays.selectedUserName,
    yearPrev: state.holidays.selectedSummaryUser != null ? state.holidays.selectedSummaryUser.year : new Date().getFullYear(),
    holidaysTotalPrev:state.holidays.selectedSummaryUser != null ? state.holidays.selectedSummaryUser.prevYearHolidays + state.holidays.selectedSummaryUser.startingHolidays : 0,
    usedHolidaysPrev: state.holidays.selectedSummaryUser != null ? state.holidays.selectedSummaryUser.usedHolidays : 0,
    plannedHolidaysPrev: state.holidays.selectedSummaryUser != null ? state.holidays.selectedSummaryUser.plannedHolidays: 0,

    year: state.holidays.selectedUser != null ? state.holidays.selectedUser.year : new Date().getFullYear(),
    holidaysTotal: state.holidays.selectedUser != null ? state.holidays.selectedUser.prevYearHolidays + state.holidays.selectedUser.startingHolidays : 0,
    usedHolidays: state.holidays.selectedUser != null ? state.holidays.selectedUser.usedHolidays : 0,
    plannedHolidays: state.holidays.selectedUser != null ? state.holidays.selectedUser.plannedHolidays : 0,
  };
}

export default ReactRedux.connect(mapStateToProps)(HolidaySummary);