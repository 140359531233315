import { host } from '../components/common/cfg';
import axios from 'axios';
import { API } from './types';

const types = {
    GET_FILES: 'GET_FILES',
    GET_REMOTE_WORK_DAY: 'GET_REMOTE_WORK_DAY'
};

// ASYNC ACTIONS

export const fetch_get_file = () => {
    return dispatch => {
        let headers = {
            'headers': {
                'Content-Type': 'application/json;',
                'Accept': 'application/json'
            }
        };

        axios.get(`${host}/api/timesheet/getfiles`, headers)
            .then(response => {
                dispatch(getFiles(response.data));
            });
    };
};

export const fetch_get_remote_work_days = (month, year) => dispatch => { 
    dispatch({
        type: API,
        payload: {
            text: "Loading remote work days",
            url: "holiday/getRemoteDays",
            method: "GET",
            onSuccess: response => getRemoteWorkDay(response),
            params: {
                month: month,
                year: year
            }
        }
    });
};

// END ASYNC ACTIONS

//ACTIONS

export const getFiles = files => {
    return {
        type: types.GET_FILES,
        files: files
    };
};

export const getRemoteWorkDay = data => {
    return {
        type: types.GET_REMOTE_WORK_DAY,
        data: data
    };
};

// END ACTIONS


//REDUX

const initialState = {
    files: [],
    workDay: []
};

export const timesheetStateActions = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_FILES:
            return {
                ...state,
                files: action.files
            };
        case types.GET_REMOTE_WORK_DAY:
            return {
                ...state,
                workDay: action.data
            };

    }
    return state;
};

  //END REDUX