import React, { useState, useEffect } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAccessToken, getRefreshToken, onLogin, onLoginRequest } from '../../store/Login';
import { withRouter } from 'react-router';
import { onLoadingStart, onLoadingEnd } from '../../store/Preloader';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';

const Login = (props) => {

    const {initialUserFinished, isAuthenticated} = props.login;
    const [initialCheck, setInitialCheck] = useState(false);
    const credentialTokens = getAccessToken() || getRefreshToken();

    useEffect(() => {
        if(!credentialTokens){
            setInitialCheck(true);
        }

        if(!initialUserFinished && credentialTokens){
            props.onLoadingStart();
            props.onLoginRequest('');
        }
    }, []);

    useEffect(() => {
        if(initialUserFinished){
            props.onLoadingEnd();
            setInitialCheck(true);
        }
    }, [initialUserFinished]);

    if(initialCheck){
        if(isAuthenticated){
            return <Redirect to={{pathname: '/timesheet'}}/>;
        }
        else{
            return <LoginForm/>;
        }
    }
    else{
        return <></>;
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onLogin,
        onLoadingStart,
        onLoadingEnd,
        onLoginRequest
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        login: state.login,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));