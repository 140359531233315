/*React Libs*/
import React from 'react';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
//MDL
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';

//styles
import {colors} from '../../common/styles';

//translations
import {EN} from '../../common/translations';

const buttonStyle = {
    color: colors.nexioOrange
};

const textAreaStyle = {
    field: {
        width: 500
    },
    floatingLabelFocusStyle: {
        fontSize: 12,
        fontWeight: 'normal',
        color: 'white'
    },
    underlineStyle: {
        borderColor: colors.nexioOrange
    }
};

const selectedColor = {
    color: colors.nexioOrange
};

class AddEditModal extends React.Component {
    constructor(props){
        super(props);
        this.state={
            notification: this.props.notification,
            originalNotifiction: this.props.notification,
            contractTypes: [],
            groupId: -1
        };
        this.onTextFieldChange = (field, value) => {
            let notificationCopy = {
                ...this.state.notification
            };
            notificationCopy[field] = value;
            this.setState({notification: notificationCopy});
        };
    }
    
    static getDerivedStateFromProps(props, state){
        if (props.notification != state.originalNotifiction) {
            return {originalNotifiction: props.notification, notification: props.notification};
        }
        return null;
    }

    render() {
        let actions = [ < FlatButton key = "Cancel" label = "Cancel" primary
            onClick = {(e) => {
                this.props.onCancelClick(e);
                this.setState({notification: this.state.originalNotifiction});
            }}
            style = {
                buttonStyle
            } />, < FlatButton key = "Submit" label = "Submit" primary
            onClick = {
                () => this.props.onSubmitClick(this.state.notification, this.state.groupId)
            }
            style = {
                buttonStyle
            } />
        ];

        if(this.state.notification.id !== undefined){
            actions.unshift(< FlatButton key = "Delete" label = "Delete" style={{color: 'red'}}
            onClick = {
                () => this.props.onDeleteClick(this.state.notification.id)
            } />);
        }

        let selectList = null;
        // possibility to choose a target group
        // if(this.state.notification.id === undefined && this.props.login.user.isEvaluator){
        //     selectList = (
        //         <div>
        //             <div className="form-group-item label">{EN.notifications.group}:</div>
        //             <div className="mdl-text-area comments">
        //                 <SelectField
        //                     value={this.state.groupId}
        //                     onChange={(event, index, value) => this.setState({groupId: value})}
        //                     selectedMenuItemStyle={selectedColor}>
        //                     <MenuItem value={1} primaryText="Clientele analysts"/>
        //                     <MenuItem value={2} primaryText="Clientele developers"/>
        //                     <MenuItem value={3} primaryText="Clientele evaluators"/>
        //                     <MenuItem value={4} primaryText="Clientele scrum masters"/>
        //                 </SelectField>
        //             </div>
        //         </div>
        //     );
        // }
        // else{
        //     selectList = null;
        // }

        return (
            <Dialog
                title={EN.notifications.title}
                modal={false}
                actions={actions}
                open={this.props.isOpened}
                autoScrollBodyContent
                onRequestClose={this.handleClose}
                >
                <form>
                    <div className="form-group">
                        <div className="form-group-item">
                            {Object.keys(this.state.notification).map((key, index) => {
                                if(this.props.fieldsToIgnore.indexOf(key) !== -1) return;
                                return (<div key={index}>
                                    <div className="form-group-item label">{EN.notifications[key]}:</div>
                                    <div className="mdl-text-area comments">
                                        <TextField
                                            multiLine
                                            style={textAreaStyle.field}
                                            floatingLabelStyle={textAreaStyle.floatingLabelFocusStyle}
                                            underlineFocusStyle={textAreaStyle.underlineStyle}
                                            defaultValue={this.state.notification[key]}
                                            onChange={(event, value) => this.onTextFieldChange(key, value)}
                                            id={key}/>
                                    </div>
                                </div>);
                            })}
                        </div>
                        {selectList}
                    </div>
                </form>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {login: state.login};
}

export default ReactRedux.connect(mapStateToProps, null)(AddEditModal);