import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import './selectReplacingPerson.scss';
import Select from '../../common/controls/Select';

const selectReplacingPersons = ({ selected, values, handleSelectOnChange }) => {


    let SelectedIndex = values.findIndex(element => element.text === selected);
    if (SelectedIndex === -1) {
        throw Error("selected item not found in values array");
    }



    const createListItems = () => {
        if (values && values.length) {
            return values.map((item, index) => {

                return (
                    <MenuItem
                        value={index}
                        key={`link_${index}`}
                        className="drop-down-list-item-link"
                    >
                        {item.text}
                    </MenuItem>
                );
            });
        }
    };


    return (
        <div className="relacingSelect">
            <Select
                disableUnderline
                value={SelectedIndex}
                onChange={handleSelectOnChange}
            >
                {createListItems()}
            </Select>
        </div>
    );
};

selectReplacingPersons.propTypes = {
    selected: PropTypes.string.isRequired,
};

export default selectReplacingPersons;