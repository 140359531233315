import _ from 'lodash';
import { API } from './types';
import { addObjectsWithCheck } from '../components/common/helpers/storeUtils';

export const types = {
  ON_GET_WORKTIME_REPORT: "ON_GET_WORKTIME_REPORT",
  ON_GET_IRREGULAR_WORKTIME_REPORT: "ON_GET_IRREGULAR_WORKTIME_REPORT",
  ON_GET_WORKTIME_DETAILS: "ON_GET_WORKTIME_DETAILS",
  ON_GET_FILTERING_DATA: "ON_GET_FILTERING_DATA",
  CLOSE_TABLE_DIALOG_TEAM_TIMESHEET: "CLOSE_TABLE_DIALOG_TEAM_TIMESHEET",
};

const onGetReportData = (response) => ({ type: types.ON_GET_WORKTIME_REPORT, response });
const onGetIrregularReportData = response => ({ type: types.ON_GET_IRREGULAR_WORKTIME_REPORT, response });
const onGetWorktimeReportDetails = response => ({ type: types.ON_GET_WORKTIME_DETAILS, response });
const onGetFilteringData = response => ({ type: types.ON_GET_FILTERING_DATA, response });
export const onCLOSE_TABLE_DIALOG_TEAM_TIMESHEET = () => ({ type: types.CLOSE_TABLE_DIALOG_TEAM_TIMESHEET });

export const getFilteringData = (year, month, reportView) =>
    dispatch =>
        new Promise(resolve => {
            const data = {
                year
            };
            if (reportView === 'monthly') {
                data.month = month;
            }
            dispatch({
                type: API,
                payload: {
                    method: "GET",
                    url: "hr/filteringData",
                    text: "Loading Report Data",
                    onSuccess: (response) => {
                        dispatch(onGetFilteringData(response));
                        resolve();
                    },
                    params: data
                }
            });
        });

export const getWorktimeReportData = (year, month, reportView, page, selectedUsers, selectedProjects) =>
    (dispatch, getState) =>
        new Promise((resolve) => {
            const data = {
                year,
                page,
                selectedUsers,
                selectedProjects,
            };
            if (reportView === 'monthly') {
                data.month = month;
            }
            dispatch({
                type: API, payload: {
                    url: 'hr/worktimeReport',
                    method: "POST",
                    text: "Loading Report Data",
                    onSuccess: (response) => {
                        dispatch(onGetReportData(response));
                        resolve();
                    },
                    data: data
                }
            });
        });

export const getIrregularWorktimeReport = (page, year, month, filters, lastName, contractName) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: API,
                payload: {
                    url: "hr/irregularWorkTimeReport",
                    method: "GET",
                    text: "Loading Report Data ...",
                    onSuccess: response => {
                        dispatch(onGetIrregularReportData(response));
                        resolve();
                    },
                    params: {
                        page,
                        year,
                        month,
                        filters,
                        lastName,
                        contractName
                    }
                }
            });
        });
    };
};
export const getWorktimeReportDetails = (userId, month, year) =>
    (dispatch, getState) =>
        new Promise((resolve) => {
            let data = {
                userId,
                month,
                year
            };

            dispatch({
                type: API, payload: {
                    url: 'hr/worktimeReportDetails',
                    method: "GET",
                    text: "Loading User Timesheet",
                    onSuccess: (response) => {
                        dispatch(onGetWorktimeReportDetails(response));
                        resolve();
                    },
                    params: data
                }
            });
        });

const initialState = {
    projects: [],
    users: [],
    projectsTime: {},
    irregularWorkTime: {
        users: [],
        count: 1
    },
    holidaysInYear: [],
    filteringData: {
        activeUsers: [],
        projects: []
    },
    pages: 0
};

export function hr(state = initialState, action) {
    let response;
    switch (action.type) {
        case types.ON_GET_WORKTIME_REPORT:
            return {
                ...state,
                ...action.response,
            };
        case types.ON_GET_IRREGULAR_WORKTIME_REPORT:
            response = action.response;
            return {
                ...state,
                irregularWorkTime: {
                    users: [...response.users],
                    count: response.count
                }
            };
        case types.ON_GET_WORKTIME_DETAILS:
            response = action.response;
            return {
                ...state,
                userRef: response
            };
        case types.CLOSE_TABLE_DIALOG_TEAM_TIMESHEET:
            return {
                ...state,
               userRef: {
                   contractName: "",
                    id: 0,
                    name: "",
                    supervisorName: "",
                    timeEntries:  [],
                    userHolidays: [],
               }
            };
        case types.ON_GET_FILTERING_DATA:
            return {
                ...state,
                filteringData: action.response
            };
        default:
            return state;
    }
}
