import React, { useState, useEffect } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { EN } from '../common/translations';
import { sendResetEmail, clearResetEmailMesage } from '../../store/Login';
import { onLoadingStart, onLoadingEnd } from '../../store/Preloader';

const translations = EN.loginPage;

const ForgotPassword = (props) => {

    const [email, setEmail] = useState('');


    useEffect(() => props.clearResetEmailMesage(), []);
    useEffect(() => {
        if (props.login.emailPasswordResetMessage) {
            props.onLoadingEnd();
        }
    }, [props.login.emailPasswordResetMessage]);

    const sendEmail = (e) => {
        e.preventDefault();
        props.onLoadingStart(`${translations.preloader}`);
        props.sendResetEmail(email);
    };


    return (
        <form onSubmit={sendEmail}>
            <h3 style={{ textAlign: 'center' }}>Reset password</h3>
            {props.login.emailPasswordResetMessage && <div className="error-message">
                {props.login.emailPasswordResetMessage}
            </div>}
            <div className="form-group">
                <label htmlFor={translations.email}>{translations.email}</label>
                <div>
                    <input id={translations.email} onChange={(e) => setEmail(e.target.value)} placeholder={translations.email} />
                </div>
            </div>
            <div className="form-group">
                <button type="submit">{translations.sendEmail}</button>
            </div>
        </form>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendResetEmail,
        clearResetEmailMesage,
        onLoadingStart,
        onLoadingEnd
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        login: state.login,
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);