export const TableStyles = {
    headerColStyle: {
        color: 'white',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        maxWidth: 0,
        paddingRight: '0px'
    },
    table: {
        clear: 'both',
        position: 'relative',
        paddingTop: 4,
        tableLayout: 'auto'
    },
};

export const HeaderStyles = {
    color: 'white',
    textAlign: 'center',
    marginTop: '0px'
};

export const SwitchStyles = {
    marginLeft: '15px'
}
