import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Assignment from '@material-ui/icons/Assignment';
import EmployeesList from './EmployeesList';
import UserTasksDialog from './UserTasksDialog';

const SupervisedEmployees = () => {
    const [dialogData, setDialogData] = useState(null);
    const closeDialog = useCallback(() => setDialogData(null), [setDialogData]);
    return (<>
        <UserTasksDialog onClose={closeDialog} data={dialogData} open={dialogData !== null}/>
        <EmployeesList>
            {(data) => (<>
                <ListItemText primaryTypographyProps={{noWrap:true}}>
                    {data.firstname + ' ' + data.lastname}
                </ListItemText>
                <Button size="small" onClick={e => e.stopPropagation() || setDialogData(data)}>
                   {<Assignment fontSize="small"/>}
                    Tasks
                </Button>
            </>)}
        </EmployeesList>
    </>);
};

export default SupervisedEmployees;