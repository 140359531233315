import React from 'react';
import PropTypes from 'prop-types';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import '../app.scss';

import FlashMessage from './FlashMessage';
import {onAddFlashMessage,onClearFlashMessages,onDeleteFlashMessage} from '../../store/FlashMessage';

const propTypes = {
    messages: PropTypes.array,
    onDeleteFlashMessage: PropTypes.func
};

class FlashMessagesList extends React.Component {
    render() {

        const flashMessageComp = this.props.messages.map( msg => {
            return(
                <FlashMessage key={msg.id} message={msg} onDelete={this.props.onDeleteFlashMessage}/>
            );
        });

        return (
            <div className="flash-message">
            <div className='alert alert-danger'>
               Uwaga! Od 01.08.2024 należy logować czas tylko i wyłącznie w <a className="alert-link" href="https://tms2.nexio.pl">TMS 2.0</a>.
            </div>
                {flashMessageComp}
            </div>
        );
    }
}

FlashMessagesList.propTypes = propTypes;

const mapStateToProps = (state) => {
    return ({
        messages: state.flashMessages
    });
};

function mapDispatchToProps(dispatch){
    return Redux.bindActionCreators({
        onDeleteFlashMessage
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(FlashMessagesList);