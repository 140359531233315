import { combineReducers } from 'redux';
import { reducer as oidc } from 'redux-oidc';
import { preloader } from './store/Preloader';
import { flashMessages } from './store/FlashMessage';
//import { chat } from './store/Chat';
import { login, types as loginTypes } from './store/Login';
import { sideMenu } from './store/SideMenu';
import { timesheets } from "./store/Timesheet";
import { projects, selectedProject } from './store/Projects';
import { issues, selectedIssue } from './store/Issuses';
import { issuesNew } from './store/IssuesNew';
import { hrIssues, hrIssuesAvaiable } from './store/HumanRes';
import { selectedRange, selectedDay, selectedDays, selectedDate, dateLastUnsubmitedTimesheet } from './store/Calendar';
import { comments } from './store/Comments';
import { navigationBar } from './store/NavigationBar';
import { notificationStore } from './store/Notifications';
import { intranetNotificationStore } from './store/IntranetNotifications';
import { holidayPanel } from './store/HolidayPanel';
import { holidays } from './store/Holidays';
import { changeLogStore } from './store/ChangeLog';
import { settings } from './store/Settings';
import { settingsValues } from './store/SettingsValues';
import { selectedFile, files } from './store/Files';
import { userTasks } from './store/UserTasks';
import { supervisedEmployees } from './store/SupervisedEmployees';
import { employees } from './store/Employees';
import { publicHolidays } from './store/WtHolidays';
import { teams } from './store/Teams';
import { hr } from './store/HR';
import { hrUserHolidays } from './store/HrUserHolidays';
import { reducerSignIn } from './store/sign-in.js';
import { reducerSignOut } from './store/sign-out.js';
import { timesheetStateActions } from './store/TimesheetStateActions';
import teamTimesheet from './store/teamTimeSheet';
import whitelist from './store/Whitelist';

const appReducer = combineReducers({
    // routing: routerReducer,
    preloader, //shorthand property name accesible in store
    flashMessages,
    //chat,
    login,
    sideMenu,
    timesheets,
    projects,
    selectedProject,
    issues,
    issuesNew,
    hrIssues,
    hrIssuesAvaiable,
    selectedIssue,
    selectedRange,
    selectedDay,
    selectedDays,
    selectedDate,
    dateLastUnsubmitedTimesheet,
    comments,
    navigationBar,
    notificationStore,
    intranetNotificationStore,
    selectedFile,
    changeLogStore,
    settings,
    settingsValues,
    files,
    holidayPanel,
    holidays,
    userTasks,
    supervisedEmployees,
    employees,
    publicHolidays,
    teams,
    hr,
    hrUserHolidays,
    reducerSignIn,
    reducerSignOut,
    oidc,
    timesheetStateActions,
    teamTimesheet,
    whitelist,
});

const rootReducer = (state, action) => {
    if (action.type === loginTypes.LOGOUT_USER) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default rootReducer;
