import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddBox";
import { colors } from "../common/styles.js";

const useToolbarStyles = (theme) => ({
  root: {
    paddingLeft: 2,
    paddingRight: 1,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: colors.nexioOrange,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: colors.nexioOrange,
        },
  title: {
    flex: "1 1 100%",
  },
});

const EnhancedTableToolbar = (props) => {
  const { classes, numSelected, headerTitle, onDeleteHandler, onAddHandler, disabled } =
    props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {headerTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={onDeleteHandler}
            disabled={disabled}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Add">
          <IconButton
            aria-label="add"
            color="primary"
            onClick={onAddHandler}
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  headerTitle: PropTypes.string,
  onDeleteHandler: PropTypes.func,
  onAddHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withStyles(useToolbarStyles)(EnhancedTableToolbar);
