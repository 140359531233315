const types = {
    SHOW_COMMENT: 'SHOW_COMMENT',
    HIDE_COMMENT: 'HIDE_COMMENT'
};

/** ACTIONS START */
export const onShowComment = (comments) => ({type: types.SHOW_COMMENT, comments});
export const onHideComment = () => ({type: types.HIDE_COMMENT});
/** ACTIONS END */

/** REDUCER START */
export const comments = (state ="", action) => {
    let newState = null;

    switch (action.type) {

        case types.SHOW_COMMENT:
            return ""+action.comments;

        case types.HIDE_COMMENT:

            newState = "";
            return newState;

        default:
            return state;
    }
};
/** REDUCER END */
