import React from 'react';
import PropTypes from 'prop-types';
//3rd party libs
import {Tooltip} from 'react-tippy';
import _ from 'lodash';
//components
import Description from './Description';

const propTypesIssueListItem = {
    id: PropTypes.number, 
    text: PropTypes.string, 
    color: PropTypes.string, 
    name: PropTypes.string
  };
  
const IssueListItem = (props) => {   
    function cutText(text, tLength){
        let chunks = [];

        for (let i = 0; i < text.length ; i += tLength) {
            chunks.push({
                index:i,
                text:text.substring(i, i + tLength)
            });
        }
        return chunks;
    }

    const issueBorderStyle= {
        borderBottom: '1px dotted '+ props.color
    };

    const renderIssueName = () =>{
        const tLength = 3;

        if((props.name.length > (4*tLength))){
           return(
                <Tooltip  
                    title={props.name}
                    position="top"
                    trigger="mouseenter"
                    >
                    <div className={"issue-content issue-name" + " issue-row-id-" + props.id} style={{color: props.color}}>
                        {cutText(props.name, (tLength * 3)).map( item => {
                            return <Description key={_.uniqueId('desc_')} index={item.id} text={item.text}/>;
                        })}
                    </div>
                </Tooltip>
           ); 
        }
        else{
            return <div className={"issue-content issue-name" + " issue-row-id-" + props.id} style={{color: props.color, float: 'right'}}>{props.name}</div>;
        }
    };

    return (
        <li className="issue-list-item">
            <div className="issue-content selection-dotts" style={issueBorderStyle}/>
            <div className="issue-content issue-id" style={{color: props.color}}>{props.id < 0 ? '' : '# '+ props.id}</div>
            {renderIssueName()}
        </li>
    );
};

IssueListItem.propTypes = propTypesIssueListItem;

export default IssueListItem;