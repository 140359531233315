import { join } from 'lodash';

export const redmineIssueUrl = (redmineId) =>
    "http://redmine.nexio.pl/issues/" + redmineId;

export const jiraTaskUrl = (jiraId) => {
    if (jiraId)
        if (jiraId.startsWith("PLDCLDEV")) {
            return "https://helpdeskce.in.gtsce.com/browse/" + jiraId;
        } else if (jiraId.startsWith("CL")) {
            return "https://solutioncenter/browse/" + jiraId;
        }
};

export const createParametrizedUrl = (baseUrl, params) => {
    let url = baseUrl;
    if (!baseUrl.startsWith('/'))
        url = '/' + baseUrl;
    if (!params)
        return baseUrl;
    url += '?';
    const stringParams = Object.entries(params).map(x => `${x[0]}=${x[1]}`);
    return url + join(stringParams, '&');
};