import { API } from './types';
import { onAddFlashMessage, onDeleteFlashMessage, onClearFlashMessages } from './FlashMessage';
import shortId from 'shortid';
import axios from 'axios';

const types = {
    ON_FETCH_SUPERVISOR_SETTINGS: "SETTINGS_ON_FETCH_SUPERVISOR_SETTINGS",
    ON_FETCH_ALL_SUPERVISOR: "ON_FETCH_ALL_SUPERVISOR"
};

export const postTeamsTestMessage = (url) =>{
    return (dispatch) => {
        dispatch({
            type: API,
            payload: {
                method: "Post",
                url: "settings/SendTeamsTMSTestMessage",
                text: "Posting test message",
                data: {webhook: url},
            }
        });
    };
};

export const postSlackTestMessage = (url) => (dispatch) => {
    const notificationId = shortId.generate();
    if (!url) {
        dispatch(onAddFlashMessage({ type: 'error', text: 'Fill URL', id: notificationId}));
        setTimeout(() => {
            dispatch(onDeleteFlashMessage(notificationId));
        }, 5000);
        return;
    }
    axios({
        url,
        method:'POST',
        data: JSON.stringify({ text: "TMS test message"}),
        transformRequest(data, headers) { delete headers.post['Content-Type']; delete headers.common['Authorization']; return data; }
    })
    .then((response) => {
        dispatch(onAddFlashMessage({type: 'success', text: "Message send", id: notificationId}));
        setTimeout(() => {
            dispatch(onDeleteFlashMessage(notificationId));
        }, 5000);
    })
    .catch((error) => {
        dispatch(onAddFlashMessage({ type: 'error', text: error.message, id: notificationId}));
        setTimeout(() => {
            dispatch(onDeleteFlashMessage(notificationId));
        }, 5000);
    });
};

export function fetchSupervisorSettings() {
    return (dispatch) => {
        dispatch({
            type: API,
            payload: {
                method: "GET",
                url: "settings/supervisorSettings",
                text: "Loading supervisor settings",
                onSuccess: (response) => ({ type: types.ON_FETCH_SUPERVISOR_SETTINGS, response })
            }
        });
    };
}

export function fetchAllSupervisors() {
    return (dispatch) => {
        dispatch({
            type: API,
            payload: {
                method: "GET",
                url: "user/getAllSupervisorsIdNames",
                text: "Loading all supervisors",
                onSuccess: (response) => ({ type: types.ON_FETCH_ALL_SUPERVISOR, response })
            }
        });
    };
}

export function updateSupervisorSettings(data) {
    return (dispatch) => {
        dispatch({
            type: API,
            payload: {
                method: "Post",
                url: "settings/supervisorSettings",
                text: "Updating settings",
                data,
                onSuccess: (response) => ({ type: types.ON_FETCH_SUPERVISOR_SETTINGS, response })
            }
        });
    };
}

const initialState = {
    selectedReplacingPerson: 0,
    supervisorSettings: {},
    allSupervisor: [],
    counter: {
        thirdCounter: 0,
        secondCounter: 0,
        firstCounter: 0,
    },
};

export function settings(state = initialState, action) {
    switch (action.type) {
        case types.ON_FETCH_SUPERVISOR_SETTINGS:
            let actualCounter = state.counter;
            let newCounter = `${action.response.supervisorWorktimeReminderDays}`;
            newCounter = newCounter.length ===3 ? newCounter : newCounter.length === 2 ? `0${newCounter}` : newCounter.length === 1 ? `00${newCounter}` : '000';
            actualCounter.thirdCounter = Number(newCounter[0]);
            actualCounter.secondCounter = Number(newCounter[1]);
            actualCounter.firstCounter = Number(newCounter[2]);

            return {
                ...state,
                supervisorSettings: action.response,
                selectedReplacingPerson: action.response.replacementPersonId
            };
        case types.ON_FETCH_ALL_SUPERVISOR:
            const responseUsers = action.response;
            const newArray = responseUsers.map((user, index) => {
                const newTab = {
                    ...newTab,
                    id: user.id,
                    name: `${user.firstname} ${user.lastname}`,
                    value: `${user.firstname}${user.lastname}(${user.login})`,
                    login: user.login,
                    firstname: user.firstname,
                    lastname: user.lastname
                };
                return newTab;
            });
            return {
                ...state,
                allSupervisor: newArray
            };
        default:
            return state;
    }
}