import { API } from './types';
import { addObjectsWithCheck } from '../components/common/helpers/storeUtils';

// Ogólne założenia
// Strona używająca teamy definiowana jest w src/routes.js jako 3 parametr do AuthWrapper
// Auth wrapper automatycznie ustawia dostępne teamy dostępne pod availableTeamIds
// Jeżeli availableTeamIds nie istnieje, to należy użyć po prostu ids, znaczy to,
// że użytkownik ma dostępn do wszystkich teamów.


const types = {
    ON_FETCH_TEAMS: "TEAMS_ON_FETCH_TEAMS",
    ON_FETCH_TEAM: "TEAMS_ON_FETCH_TEAM",
    ON_FETCH_ACTIVE_USERS: "TEAMS_ON_FETCH_ACTIVE_USERS",
    ON_ADD_TEAM: "TEAMS_ON_ADD_TEAM",
    ON_DELETE_TEAM: "TEAMS_ON_DELETE_TEAM",
    SET_ALL_TEAMS_AVAILABLE: "TEAMS_SET_ALL_AVAILABLE",
    SET_AVAILABLE_TEAMS: "TEAMS_SET_AVAILABLE",
    DISABLE_TEAM_SELECTION: "TEAMS_DISABLE_TEAM_SELECTION",
    SELECT_TEAM: "TEAMS_SELECT_TEAM",
    ON_SEND_MAIL: "TEAMS_ON_SEND_MAIL",
    CHANGE_OPEN_MAIL_DIALOG: "TEAMS_OPEN_MAIL_DIALOD"
};

export function fetchTeams(forceReload = false) {
    return (dispatch, getState) => {
        if (!forceReload && getState().teams.ids !== emptyArray) {
            return;
        }
        dispatch({
            type: API,
            payload: {
                method: "GET",
                url: "teams/getTeams",
                text: "Loading teams",
                onSuccess: (response) => ({type: types.ON_FETCH_TEAMS, response})
            }
        });
    };
}

export function fetchTeam(id) {
    return (dispatch, getState) => {
        dispatch({
            type: API,
            payload: {
                method: "GET",
                url: "teams/getTeam",
                text: "Loading team",
                params: { teamId: id },
                onSuccess: (response) => ({ type: types.ON_FETCH_TEAM, response, id })
            }
        });
    };
}

export function fetchActiveUsers() {
    return (dispatch, getState) => {
        if (getState().teams.activeUsers.length > 0) {
            return;
        }
        dispatch({
            type: API,
            payload: {
                method: "GET",
                url: "teams/activeUsers",
                text: "Loading active users",
                onSuccess: (response) => ({ type: types.ON_FETCH_ACTIVE_USERS, response })
            }
        });
    };
}


export function updateTeam(id, userData) {
    return {
        type: API,
        payload: {
            method: "POST",
            url: `teams/updateTeam`,
            text: "Saving team",
            successText: "Team updated",
            data: userData,
            params: { teamId: id },
            onSuccess: (response) => ({ type: types.ON_FETCH_TEAM, response, id })
        }
    };
}

export function addTeam(name) {
    return {
        type: API,
        payload: {
            method: "POST",
            url: `teams/addTeam`,
            text: "Adding team",
            successText: "Team added",
            data: { name },
            onSuccess: (response) => ({ type: types.ON_ADD_TEAM, response })
        }
    };
}

export const deleteSelectedTeam = () => (dispatch, getState) => {
    const { selectedTeam: id } = getState().teams;
    dispatch({
        type: API,
        payload: {
            method: "DELETE",
            url: `teams/deleteTeam`,
            text: "Deleting team",
            successText: "Team deleted",
            params: { id },
            onSuccess: (response) => ({ type: types.ON_DELETE_TEAM, id })
        }
    });
};

export function setAllTeamsAvailable() {
    return { type: types.SET_ALL_TEAMS_AVAILABLE };
}

export function setAvailableTeams(teams) {
    return { type: types.SET_AVAILABLE_TEAMS, teams };
}

export function disableTeamSelection() {
    return { type: types.DISABLE_TEAM_SELECTION };
}

export function selectTeam(id) {
    return { type: types.SELECT_TEAM, id };
}

export function setMailDialogOpen(open = true) {
    return { type: types.CHANGE_OPEN_MAIL_DIALOG, open };
}


export const sendTeamMail = (data, {setSubmitting, setErrors}) => ({
    type: API,
    payload: {
        method: "POST",
        url: `teams/sendMail`,
        text: "Sending team mail",
        successText: "Mail send",
        data: data,
        onSuccess: (response) => ({ type: types.ON_SEND_MAIL }),
        onFinish: () => setSubmitting(false)
    }
});



const emptyArray = []; // for caching
const initialState = {
    selectedTeam: undefined,
    availableTeamIds: undefined,
    canSelectTeam: false,
    ids: emptyArray,
    teams: {},
    teamUsers: {},
    showTeam: true,
    activeUsers: [],
    mailDialogOpen: false,
};

export const teams = (state = initialState, action) => {
    switch(action.type) {
        case types.ON_FETCH_TEAMS:
            return {
                ...state,
                ids: action.response.map(x => x.id),
                teams: addObjectsWithCheck(state.teams, action.response),
                selectedTeam: state.selectedTeam || action.response[0].id
            };
        case types.ON_FETCH_TEAM:
            return {
                ...state,
                teamUsers: { ...state.teamUsers, [action.id]: action.response.userResources }
            };
        case types.ON_FETCH_ACTIVE_USERS:
            return {
                ...state,
                activeUsers: action.response
            };
        case types.ON_ADD_TEAM:
            return {
                ...state,
                teams: addObjectsWithCheck(state.teams, [action.response]),
                ids: state.ids.concat([action.response.id]),
                selectedTeam: action.response.id
            };
        case types.ON_DELETE_TEAM:
            const ids = state.ids.filter(id => id != action.id);
            return {
                ...state,
                ids: ids,
                selectedTeam: (state.availableTeamIds || ids)[0]
            };
        case types.SET_ALL_TEAMS_AVAILABLE:
            return {
                ...state,
                canSelectTeam: true,
                availableTeamIds: undefined
            };
        case types.SET_AVAILABLE_TEAMS:
            return {
                ...state,
                canSelectTeam: true,
                availableTeamIds: action.teams,
                selectedTeam: action.teams.includes(state.selectedTeam) ? state.selectedTeam : action.teams[0]
            };
        case types.DISABLE_TEAM_SELECTION:
            return {
                ...state,
                canSelectTeam: false,
                availableTeamIds: undefined
            };
        case types.SELECT_TEAM:
            return {
                ...state,
                selectedTeam: action.id
            };
        case types.CHANGE_OPEN_MAIL_DIALOG:
            return {
                ...state,
                mailDialogOpen: action.open
            };
        case types.ON_SEND_MAIL:
            return {
                ...state,
                mailDialogOpen: false
            };
        default:
            return state;
    }
};