import React from 'react';
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import Pagination from '../common/partials/pagination/pagination';
import { EN } from '../common/translations';
import DataTable from '../common/DataTable';
import { HolidayPanelStyles, HolidayStyles } from './HolidayStyles';
import { fetchTableHistoryData } from '../../store/Holidays';

class HolidaysHistoryTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.columns = this.getColumns();
    }

    componentDidMount() {
        this.refreshTableHistoryData();
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.showInitial !== this.props.showInitial){
            this.refreshTableHistoryData();
        }
    }

    refreshTableHistoryData(){
        this.props.fetchTableHistoryData(1, this.props.holidayRequestId, this.props.showInitial);
    }

    getColumns() {
        return [
            {
                header: EN.holidaysPage.changeDate,
                getValue: (row) => row.changeDate?.replace('T','  ').replace('t','  ')
            },
            {
                header: EN.holidaysPage.fieldName,
                getValue: (row) => row.fieldName
            },

            {
                header: EN.holidaysPage.newValue,
                getValue: (row) => row.newValue
            },

            {
                header: EN.holidaysPage.previousValue,
                getValue: (row) => row.previousValue
            },

            {
                header: EN.holidaysPage.historyAction,
                getValue: (row) => row.historyAction
            },

            {
                header: EN.holidaysPage.message,
                getValue: (row) => row.message
            },

            {
                header: EN.holidaysPage.changedByName,
                getValue: (row) => row.changedByName
            },
        ];
    }

    render() {
        return (
        <>
            <DataTable
                columns={this.columns}
                cellStyle={HolidayStyles.headerColStyle}
                headerStyle={HolidayStyles.headerColStyle}
                tableStyle={HolidayPanelStyles.table}
                data={this.props.tableData}
            />

            <Pagination
                onChange={(page) => this.props.fetchTableHistoryData(page , this.props.holidayRequestId, this.props.showInitial)}
                value={this.props.tablePage}
                count={this.props.tableCount} />
        </>);
    }
}

function mapStateToProps(state) {
    return {
        tablePage: state.holidays.historyPage,
        tableData: state.holidays.historyData,
        tableCount: state.holidays.historyPageCount,
        holidayRequestId: state.holidays.dialogHistoryId,
        showInitial: state.holidays.dialogHistoryShowInitial,
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        fetchTableHistoryData,
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(HolidaysHistoryTable);
