import types from './types';

const onAcceptTeamTimesheet = (response) => ({ type:types.ACCEPT_TEAM_TIMESHEET, response });
const onGetPendingApplication = (response, page, month, year) => ({ type: types.GET_PENDING_APPLICATION_TABLE, response, page, month, year });
const onGetSettledEmployees = (response, page, month, year) => ({ type: types.GET_SETTLED_EMPLOYEES_TABLE, response, page, month, year });
const onGetTimesheetProposals = (response) => ({ type:types.GET_TIMESHEET_PROPOSALS, response });
const onGetUnsettledEmployees = (response, page, month, year) => ({ type: types.GET_UNSETTLED_EMPLOYEES_TABLE, response, page, month, year });
const onGetUnsendTimesheetProposals = (response) => ({ type:types.GET_UNUSED_TIMESHEET_PROPOSAL, response });
const onRejectTeamTimesheet = (response) => ({ type:types.REJECT_TEAM_TIMESHEET, response });
const onSendMailTeamTimesheet = (response) => ({ type:types.ON_SEND_MAIL_TEAM_TIMESHEET, response });

const closeMailDialog = () => ({ type: types.CHANGE_MAIL_DIALOG, open: false, selectedApplication: 0, sendToAll: false, selectedEmployee: "" });

const closeRejectDialog = () =>
    ({type: types.CLOSE_REJECT_DIALOG_TEAM_TIMESHEET});

const closeTableDialog = () => 
    ({type: types.CHANGE_TABLE_DIALOG_TEAM_TIMESHEET, open: false, data:{}, version: "", employeeId: 0, employeeName: "", selectedApplicationId: 0});

const onTeamTimesheetCountChange = (response) => ({ type: types.TEAM_TIMESHEET_COUNT_CHANGE, response});

const openMailDialog = (selectedApplicationId, sendToAll, selectedEmployee) => ({ type: types.CHANGE_MAIL_DIALOG, open: true, selectedApplicationId, sendToAll, selectedEmployee });

const openRejectDialog = (version, employee, rejectBy) =>
    ({type: types.OPEN_REJECT_DIALOG_TEAM_TIMESHEET, version, employee, rejectBy});

const openTableDialog = (version, employeeId, employeeName, selectedApplicationId) => 
    ({ type: types.CHANGE_TABLE_DIALOG_TEAM_TIMESHEET, open: true, data:{}, version, employeeId, employeeName, selectedApplicationId });

const openSettledEmployeesDialog = (version, employeeId, employeeName, selectedApplicationId) =>
    ({ type: types.SETTLED_EMPLOYEES_DIALOG, open: true, data:{}, version, employeeId, employeeName, selectedApplicationId });

const closeSettledEmployeesDialog = (version, employeeId, employeeName, selectedApplicationId) =>
    ({ type: types.SETTLED_EMPLOYEES_DIALOG, open: false, data:{}, version, employeeId, employeeName, selectedApplicationId });

const pendingApplicationClick = () => ({ type: types.PENDING_APPLICATION_CLICK });

const settledEmployeesClick = () => ({ type: types.SETTLED_EMPLOYEES_CLICK });

const unsettledEmployeesClick = () => ({ type: types.UNSETTLED_EMPLOYEES_CLICK });

const toggleOnlyMyTeamSwitch = () => ({ type: types.TOGGLE_ONLY_MY_TEAM_SWITCH });

export default {
    closeMailDialog,
    closeRejectDialog,
    closeTableDialog,
    onAcceptTeamTimesheet,
    onGetPendingApplication,
    onGetSettledEmployees,
    onGetTimesheetProposals,
    onGetUnsettledEmployees,
    onGetUnsendTimesheetProposals,
    onRejestTeamTimesheet: onRejectTeamTimesheet,
    onSendMailTeamTimesheet,
    onTeamTimesheetCountChange,
    openMailDialog,
    openRejectDialog,
    openTableDialog,
    pendingApplicationClick,
    settledEmployeesClick,
    unsettledEmployeesClick,
    openSettledEmployeesDialog,
    closeSettledEmployeesDialog,
    toggleOnlyMyTeamSwitch
};

