/*React Libs*/
import React from 'react';
//Redux
import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
//MDL
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import avatar from '../../../../assets/user.svg';
import CreateIcon from '@material-ui/icons/Create';
import MenuItem from '@material-ui/core/MenuItem';
import FormDialog from '../../../common/controls/formik/FormDialog';
import { FormDatePicker, FormTextField, FormButton } from '../../../common/controls/formik/FormControls';
//styles
import './UserEditDialog.scss';
//translations
import { EN } from '../../../common/translations';
//Other
import moment from 'moment';
import classnames from 'classnames';
import { onGetWorkgroups, onPutUserImage, fetchAvatar, deleteUserImage } from '../../../../store/Employees';
import SearchInput from './SearchInput';
import { validateFunctions } from '../../../common/helpers/validateFunctions';
import { Constants } from '../../../common/constants';

const translations = EN.employeesPage;

class UserEditDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            originalUser: this.props.user,
            workgroups: props.allWorkgroups || [],
            workgroupsValue: this.workgroupsAdapter(this.props.user.userWorkgroups),
            disabledInputs: true,
            joiningDate: this.props.user.joinDate || '',
            newAvatar: null,
            cancelButton: false,
            isFirstEmployment: this.props.user.isFirstEmployment || false,
            currentView: 'profile',
            isNewUser: false,
            editImage: false,
            HolidayPool: Constants.Pool.regularAmount
        };
    }

    componentDidMount() {
        this.props.fetchAvatar(this.state.user.id || 0, true);
        this.validateState();
    }

    validateState = () => {
        if (!this.state.user.password && !this.state.user.id) {
            this.setState(prevState => ({
                disabledInputs: false,
                isNewUser: true,
                user: { ...prevState.user, status: 1, password: Math.random().toString(36).slice(-8) }
            }));
        }
    }

    workgroupsAdapter = (workgroups) => {
        if (workgroups && workgroups.length) {
            return workgroups.map(({ id }) => id);
        } else {
            return [];
        }
    }

    handleInputOnChange = (e) => {
        this.setState(prevState => ({
            user: { ...prevState.user, [e.target.name]: e.target.value }
        }));
    }

    handleSupervisorOnChange = ({ id, firstname, lastname, login }) => {
        let supervisorData = id !== 0 ?
            { supervisorid: id, supervisorfirstname: firstname, supervisorlastname: lastname, supervisorlogin: login } :
            { supervisorid: 0, supervisorfirstname: '', supervisorlastname: '', supervisorlogin: '' };

        this.setState(prevState => ({
            user: { ...prevState.user, ...supervisorData }
        }));
    }

    handleChangeEditProfile = () => {
        this.setState(prevState => ({
            disabledInputs: !prevState.disabledInputs,
        }));
    }

    handleChangeDate = (event) => {
        if (!this.state.user.contractName) {
            this.setState({
                joiningDate: event.target.value
            });
        }
        else if (this.state.HolidayPool == Constants.Pool.firstEmployment) {
            this.setState(prevState => ({
                joiningDate: event.target.value,
                user: { ...prevState.user, startingHolidaysCurrentYear: '0' }
            }));
        }
        else if (this.state.user.contractName && event.target.value == '') {
            this.setState(prevState => ({
                joiningDate: event.target.value,
                user: { ...prevState.user, startingHolidaysCurrentYear: '' }
            }));
        }
        else {
            this.setState(prevState => ({
                joiningDate: event.target.value,
                user: {
                    ...prevState.user,
                    startingHolidaysCurrentYear: this.calculateHolidayPool(parseInt(this.state.HolidayPool), this.state.user.contractName, event.target.value).toString()
                }
            }
            ));
        }
    };

    handleCompanyOnChange = (event) => {
        const { id } = this.props.companiesData.find(el => el.name === event.target.value);
        this.setState(prevState => ({
            user: { ...prevState.user, companyname: event.target.value, companyid: id }
        }));
    }

    handleHolidayPoolOnChange = (event) => {
        if (this.state.joiningDate == '') {
            if (event.target.value == Constants.Pool.regularAmount) {
                this.setState(prevState => ({
                    ...prevState, user: { ...prevState.user, startingHolidaysCurrentYear: "" },
                    HolidayPool: Constants.Pool.regularAmount
                }));
            }
            else if (event.target.value == Constants.Pool.longAmount) {
                this.setState(prevState => ({
                    ...prevState, user: { ...prevState.user, startingHolidaysCurrentYear: "" },
                    HolidayPool: Constants.Pool.longAmount
                }));
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    user: { ...prevState.user, startingHolidaysCurrentYear: '0' },
                    HolidayPool: Constants.Pool.firstEmployment
                }));
            }
        }
        else {
            if (event.target.value == Constants.Pool.regularAmount) {
                this.setState(prevState => ({
                    ...prevState,
                    user: { ...prevState.user, startingHolidaysCurrentYear: this.calculateHolidayPool(20, this.state.user.contractName).toString() },
                    HolidayPool: Constants.Pool.regularAmount
                }));
            }
            else if (event.target.value == Constants.Pool.longAmount) {
                this.setState(prevState => ({
                    ...prevState,
                    user: { ...prevState.user, startingHolidaysCurrentYear: this.calculateHolidayPool(26, this.state.user.contractName).toString() },
                    HolidayPool: Constants.Pool.longAmount
                }));
            }
            else {
                this.setState(prevState => ({
                    ...prevState, user: { ...prevState.user, startingHolidaysCurrentYear: '0' },
                    HolidayPool: Constants.Pool.firstEmployment
                }));
            }
        }
    }

    calculateHolidayPool = (holidayPool, contractType, joiningdate) => {
        const date1 = moment(joiningdate ? joiningdate : this.state.joiningDate);
        const date2 = moment().endOf('year');
        const Difference_In_Days = date2.diff(date1, 'days');

        if (contractType == Constants.ContractTypes.Employment) {
            return Math.ceil((Difference_In_Days / 365) * holidayPool);
        }
        return Math.floor((Difference_In_Days / 365) * holidayPool);
    }

    handleContractOnChange = (event) => {
        if ((event.target.value == Constants.ContractTypes.Employment || event.target.value == Constants.ContractTypes.Civil) && this.state.joiningDate == '') {
            this.setState(prevState => ({
                user: { ...prevState.user, contractName: event.target.value, startingHolidaysCurrentYear: '' },
                HolidayPool: Constants.Pool.regularAmount
            }));
        }

        else if (this.state.user.contractName == Constants.ContractTypes.Employment && event.target.value == Constants.ContractTypes.Civil && this.state.HolidayPool == Constants.Pool.firstEmployment) {
            if (this.state.joiningDate == '') {
                this.setState(prevState => ({
                    user: { ...prevState.user, contractName: event.target.value, startingHolidaysCurrentYear: '' },
                    HolidayPool: Constants.Pool.regularAmount
                }));
            } else {
                const amountOfDays = this.calculateHolidayPool(parseInt(Constants.Pool.regularAmount), event.target.value);
                this.setState(prevState => ({
                    user: { ...prevState.user, contractName: event.target.value, startingHolidaysCurrentYear: amountOfDays },
                    HolidayPool: Constants.Pool.regularAmount
                }));
            }
        }

        else if (event.target.value == Constants.ContractTypes.Employment || event.target.value == Constants.ContractTypes.Civil) {
            const amountOfDays = this.calculateHolidayPool(parseInt(Constants.Pool.regularAmount), event.target.value);
            this.setState(prevState => ({
                user: { ...prevState.user, contractName: event.target.value, startingHolidaysCurrentYear: amountOfDays }
                , HolidayPool: Constants.Pool.regularAmount
            }));
        }

        else if (event.target.value == Constants.ContractTypes.B2B) {
            const amountOfDays = this.calculateHolidayPool(parseInt(Constants.Pool.regularAmount), event.target.value);
            this.setState(prevState => ({
                user: { ...prevState.user, contractName: event.target.value, startingHolidaysCurrentYear: amountOfDays },
            }));
        }

        else if (event.target.value == Constants.ContractTypes.Civil) {
            const amountOfDays = this.calculateHolidayPool(parseInt(Constants.Pool.regularAmount), event.target.value);
            this.setState(prevState => ({
                user: { ...prevState.user, contractName: event.target.value, startingHolidaysCurrentYear: amountOfDays },
            }));
        }
    }

    handleClickCancel = (e, formik) => {
        this.setState(prevState => ({
            user: prevState.originalUser,
            disabledInputs: true
        }));
        formik.resetForm();
    }

    handleClickSubmit = () => {
        const {
            id,
            username,
            password,
            jobPosition,
            firstname,
            lastname,
            email,
            startingHolidaysCurrentYear,
            prevYearHolidaysCurrentYear,
            startingHolidaysNextYear,
            prevYearHolidaysNextYear,
            contractName,
            companyid,
            supervisorid,
            status
        } = this.state.user;

        const workgroups = this.state.workgroupsValue;

        const joinDate = this.state.joiningDate ? new Date(this.state.joiningDate) : '';

        const isFirstEmployment = this.state.isFirstEmployment && this.state.user.contractName === Constants.ContractTypes.Employment;

        const request = {
            id,
            username,
            password,
            jobPosition,
            firstname,
            lastname,
            email,
            startingHolidaysCurrentYear,
            prevYearHolidaysCurrentYear,
            startingHolidaysNextYear,
            prevYearHolidaysNextYear,
            contractName,
            companyid,
            supervisorid,
            workgroups,
            joinDate,
            status,
            isFirstEmployment
        };

        this.setState({
            disabledInputs: true
        }, () => this.props.onSubmitClick(request));
    }

    handleSelectChangeWork = (event) => {
        this.setState({
            workgroupsValue: event.target.value,
        });
    }

    handleBackToUsers = (e) => {
        this.props.onCancelClick(e);
        this.setState({
            disabledInputs: true,
            user: {},
            cancelButton: true
        });
    }

    handleChangeStatus = (status) => {
        if (!this.state.disabledInputs) {
            let newUser = this.state.user;
            newUser.status = status;

            this.setState({
                user: newUser
            });
        }
    }

    handleChangeAvatar = (event) => {
        if (event.target.files.length && (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png")) {
            let reader = new FileReader();
            reader.onload = (e) => {
                const data = {
                    file: e.target.result,
                };
                this.props.onPutUserImage(data);
                this.setState({ newAvatar: e.target.result, editImage: false });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    handleDeleteImage = () => {
        this.props.deleteUserImage(this.state.user.id || 0);
        this.setState({
            editImage: false,
            newAvatar: null,
        });
    };

    handleChangeView = (value) => {
        this.setState({ currentView: value });
    };

    handleClickEditImage = () => {
        this.setState(prevState => ({
            editImage: !prevState.editImage
        }));
    }

    handleBackEditImage = () => {
        this.setState({
            editImage: false
        });
    };

    onFirstEmploymentChanged = () => {
        this.setState(prevState => ({
            isFirstEmployment: !prevState.isFirstEmployment
        }));
    };

    validate = (data) => {
        let errors = {};

        if (!validateFunctions.isStringNotEmpty(data.firstname)) {
            errors.firstname = 'This field is required';
        }
        if (!validateFunctions.isStringNotEmpty(data.lastname)) {
            errors.lastname = 'This field is required';
        }
        if (!validateFunctions.isStringNotEmpty(data.email)) {
            errors.email = 'This field is required';
        }
        else if (!validateFunctions.validateEmail(data.email)) {
            errors.email = 'Invalid email address';
        }
        if (!validateFunctions.isStringNotEmpty(data.contractName)) {
            errors.contractName = 'This field is required';
        }
        if (!validateFunctions.isStringNotEmpty(data.companyname)) {
            errors.companyname = 'This field is required';
        }
        if (this.state.user.contractName === 'Employment' && this.state.isFirstEmployment && !validateFunctions.isStringNotEmpty(data.joiningDate)) {
            errors.joiningDate = 'This field is required';
        }
        if (!validateFunctions.isDateStringValid(data.joiningDate)) {
            errors.joiningDate = 'Date is invalid';
        }
        return errors;
    }

    createSupervisiorsList = () => this.props
        .supervisorsList
        .map(x => ({
            id: x.id,
            name: `${x.firstname} ${x.lastname}`,
            value: `${x.firstname}${x.lastname}(${x.username})`,
            login: x.username,
            firstname: x.firstname,
            lastname: x.lastname
        }));

    MenuEditItem = () => {
        return (<div className={`menuEditedImage ${this.props.userEditAvatar === null && this.state.newAvatar === null ? "menuEditedImageElementOne" : ""}`}>
            <div className="menuEditedImageElement" >
                <input type="file" id="file" accept="image/*" onChangeCapture={this.handleChangeAvatar} />
                <label htmlFor="file">{translations.buttons.addAvatar}</label>
            </div>
            {this.props.userEditAvatar || this.state.newAvatar ? <div className="menuEditedImageElement" onClick={this.handleDeleteImage}>
                {translations.buttons.removeAvatar}
            </div> : null}
        </div>);
    }

    render() {
        const inputProps = this.state.disabledInputs ? ({
            disableUnderline: true
        }) : undefined;
        const fullName = `${this.state.user.firstname || ''} ${this.state.user.lastname || ''}`;
        const joiningDatePicker = (this.state.disabledInputs ? <FormTextField name="joiningDate" value={this.state.joiningDate ? moment(this.state.joiningDate).format('YYYY-MM-DD') : '-'} disabled InputProps={inputProps} /> :
            <FormDatePicker clearable label={null} name="joiningDate" value={this.state.joiningDate} onChange={this.handleChangeDate} />
        );
        const newCompaniesData = this.props.companiesData.map(item => {
            const newItem = {
                id: item.id,
                value: item.name,
                name: item.name
            };
            return newItem;
        });

        const contractsType = [
            {
                id: 1,
                value: translations.employment,
                name: translations.employment
            },
            {
                id: 2,
                value: translations.b2b,
                name: translations.b2b
            },
            {
                id: 3,
                value: translations.civil,
                name: translations.civil
            }
        ];

        const HolidayPoolOptions = [
            {
                id: 1,
                value: 0,
                name: Constants.Pool.firstEmployment
            },
            {
                id: 2,
                value: 20,
                name: Constants.Pool.regularAmount
            },
            {
                id: 3,
                value: 26,
                name: Constants.Pool.longAmount
            }
        ];

        const HolidayPoolMenuItems = () => {
            if (this.state.user.contractName == Constants.ContractTypes.Employment) {
                return (HolidayPoolOptions.map(({ id, name }) => (
                    <MenuItem key={id} value={name}>{name}</MenuItem>
                )));
            }
            return (HolidayPoolOptions.slice(1).map(({ id, name }) => (
                <MenuItem key={id} value={name}>{name}</MenuItem>
            )));
        };

        const HolidaysInThisYear = () => {
            if (this.state.user.contractName && this.state.joiningDate == '' && this.state.HolidayPool != Constants.Pool.firstEmployment) {
                return (<FormTextField
                    error
                    value={this.state.user.startingHolidaysCurrentYear}
                    name="startingHolidaysCurrentYear"
                    onChange={this.handleInputOnChange}
                    disabled={this.state.disabledInputs}
                    InputProps={inputProps}
                    helperText="Enter joining date to automatically calculate holidays for year" />);
            }
            return (<FormTextField
                value={this.state.user.startingHolidaysCurrentYear}
                name="startingHolidaysCurrentYear"
                onChange={this.handleInputOnChange}
                disabled={this.state.disabledInputs}
                InputProps={inputProps}
            />);
        };

        const employeeHasHolidayPool = this.state.user.contractName === Constants.ContractTypes.Employment;

        let workgroupsInputValue = '-';

        if (this.state.workgroupsValue) {
            const valueWorkgroupValue = this.state.workgroupsValue.map(index => {
                const { name } = this.state.workgroups.find(el => el.id === index);
                return name;
            });
            workgroupsInputValue = valueWorkgroupValue.join(", ");
        }

        const menuEditImage = this.state.editImage && <this.MenuEditItem/>;

        const content = this.props.isOpened && (
            <FormDialog
                open={this.props.isOpened}
                fullWidth
                maxWidth={false}
                className={this.props.isUserProfile ? "user-profile-dialog" : ""}
                classes={{ paperScrollPaper: 'dialogWrapper' }}
                validate={this.validate}
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    contractName: '',
                    companyname: '',
                    joiningDate: this.state.joiningDate,
                    ...this.state.user
                }}
                onClose={this.handleBackToUsers}
                onSubmit={this.handleClickSubmit}
            >{(formik) => (
                <div className="dialogContentMarginBottom">
                    <div className="userEditDialog">
                        <div className="row">
                            <div className="col-md-4 col-12 userSpecification">
                                <div className="userAvatar">
                                    <div className="userAvatarImage">
                                        <img src={this.state.newAvatar || this.props.userEditAvatar || avatar} className="img-fluid" />
                                    </div>
                                    {this.props.login.user.id === this.state.user.id &&
                                        (
                                            <div className="editImageButtonContent">
                                                <div className="editImageButton" onClick={this.handleClickEditImage}>
                                                    <CreateIcon />
                                                </div>
                                                {menuEditImage}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="fullNameClass">
                                    {fullName}
                                </div>
                                {!this.state.disabledInputs ? (
                                    <div className="userInformationColumJob">
                                        <FormTextField value={this.state.user.jobPosition} placeholder="Job position" name="jobPosition" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} InputProps={inputProps} />
                                    </div>
                                ) : (
                                    <div className="jobPositionClass">
                                        {this.state.user.jobPosition}
                                    </div>
                                )}
                                <div className="personalInformation">
                                    {translations.personalInformation}
                                </div>
                                <div className="positionRelative">
                                    <div className="textInformationStyle">
                                        {!this.state.user.id && <div className="rowInformation">
                                            <div className="informationColumn">
                                                {translations.username}:
                                            </div>
                                            <div className="userInformationColumn">
                                                <FormTextField readOnly value={this.state.user.email} name="login" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} InputProps={inputProps} />
                                            </div>
                                        </div>}
                                        <div className="rowInformation">
                                            <div className="informationColumn">
                                                {translations.firstname}:
                                            </div>
                                            <div className="userInformationColumn">
                                                <FormTextField value={this.state.user.firstname} name="firstname" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} InputProps={inputProps} />
                                            </div>
                                        </div>
                                        <div className="rowInformation">
                                            <div className="informationColumn">
                                                {translations.lastname}:
                                            </div>
                                            <div className="userInformationColumn">
                                                <FormTextField value={this.state.user.lastname} name="lastname" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} InputProps={inputProps} />
                                            </div>
                                        </div>
                                        <div className="rowInformation">
                                            <div className="informationColumn">
                                                {translations.email}:
                                            </div>
                                            <div className="userInformationColumn">
                                                <FormTextField value={this.state.user.email} name="email" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} InputProps={inputProps} />
                                            </div>
                                        </div>

                                        {!this.state.user.id && <div className="rowInformation">
                                            <div className="informationColumn">
                                                {translations.password}:
                                            </div>
                                            <div className="userInformationColumn">
                                                <FormTextField value={this.state.user.password} name="password" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} readOnly InputProps={inputProps} />
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 col-12">
                                <div className="profileButtons marginTopButtons">
                                    <FormButton type="button" onClick={() => this.handleChangeView('profile')} className={classnames(this.state.currentView === 'profile' ? "profileButtonActive" : "profileButtonInactive", 'buttonProfile')}>
                                        {translations.profile}
                                    </FormButton>
                                    {!this.state.isNewUser ? (<FormButton type="button" onClick={() => this.handleChangeView('history')} className={this.state.currentView === 'history' ? "profileButtonActive" : "profileButtonInactive"}>
                                        {translations.history}
                                    </FormButton>) : null}
                                </div>
                                {this.state.currentView === 'profile' ?
                                    (<>
                                        <div className="otherInformation">
                                            {translations.jobDetails}
                                        </div>
                                        <div className="positionRelative">
                                            <div className="textInformationStyle">
                                                <div className="rowInformation">
                                                    <div className="informationColumnRight">
                                                        {translations.filters.contractType}:
                                                    </div>
                                                    <div className="userInformationColumn">
                                                        <FormTextField
                                                            select={!this.state.disabledInputs}
                                                            required
                                                            name="contractName"
                                                            value={this.state.user.contractName}
                                                            onChange={this.handleContractOnChange}
                                                            disabled={this.state.disabledInputs}
                                                            InputProps={inputProps}
                                                        >
                                                            {contractsType.map(({ id, value, name }) => (
                                                                <MenuItem key={id} value={value}>{name}</MenuItem>
                                                            ))}
                                                        </FormTextField>
                                                    </div>
                                                </div>
                                                <div className="rowInformation">
                                                    <div className="informationColumnRight">
                                                        {translations.company}:
                                                    </div>
                                                    <div className="userInformationColumn">
                                                        <FormTextField
                                                            select={!this.state.disabledInputs}
                                                            required
                                                            name="companyname"
                                                            value={this.state.user.companyname}
                                                            onChange={this.handleCompanyOnChange}
                                                            disabled={this.state.disabledInputs}
                                                            InputProps={inputProps}
                                                        >
                                                            {newCompaniesData.map(({ id, value, name }) => (
                                                                <MenuItem key={id} value={value}>{name}</MenuItem>
                                                            ))}
                                                        </FormTextField>
                                                    </div>
                                                </div>
                                                <div className="rowInformation">
                                                    <div className="informationColumnRight">
                                                        {translations.supervisor}:
                                                    </div>
                                                    <div className="userInformationColumn">
                                                        <SearchInput
                                                            disabledInputs={this.state.disabledInputs}
                                                            InputProps={inputProps}
                                                            options={this.createSupervisiorsList()}
                                                            handleChange={this.handleSupervisorOnChange}
                                                            handleInputOnChange={this.handleInputOnChange}
                                                            valueSearch={this.state.user.supervisorid !== 0 ? `${this.state.user.supervisorfirstname}${this.state.user.supervisorlastname}(${this.state.user.supervisorlogin})` : ''}
                                                            valueInput={this.state.user.supervisorfirstname && this.state.user.supervisorlastname ? `${this.state.user.supervisorfirstname} ${this.state.user.supervisorlastname}` : '-'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="rowInformation">
                                                    <div className="informationColumnRight">
                                                        {translations.workgroup}:
                                                    </div>
                                                    <div className="userInformationColumn">
                                                        {!this.state.disabledInputs ?
                                                            <FormTextField
                                                                name="workgroups"
                                                                select
                                                                multiple
                                                                required
                                                                value={this.state.workgroupsValue}
                                                                onChange={this.handleSelectChangeWork}
                                                                InputProps={inputProps}
                                                            >
                                                                {this.state.workgroups.map(({ id, name }) => (
                                                                    <MenuItem key={id} value={id}>{name}</MenuItem>
                                                                ))}
                                                            </FormTextField> :
                                                            <div className="workgroupsInfo">
                                                                {workgroupsInputValue.length ? workgroupsInputValue : '-'}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="rowInformation">
                                                    <div className="informationColumnRight">
                                                        {translations.joiningDate}:
                                                    </div>
                                                    <div className="userInformationColumn">
                                                        {joiningDatePicker}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="otherInformation">
                                            {translations.holidays}
                                        </div>
                                        <div className="positionRelative">
                                            <div className="textInformationStyle">
                                                {this.props.edited ? (
                                                    <>
                                                        <div className="rowInformation">
                                                            {this.props.edited && !this.state.disabledInputs && employeeHasHolidayPool ? (<div className="rowInformation">
                                                                <div className="informationColumnRight">
                                                                    Annual Holiday Pool
                                                                </div>
                                                                <div className="userInformationColumn">
                                                                    <FormTextField
                                                                        select={!this.state.disabledInputs}
                                                                        required
                                                                        name="HolidayPool"
                                                                        value={this.state.HolidayPool}
                                                                        onChange={this.handleHolidayPoolOnChange}
                                                                        disabled={this.state.disabledInputs}
                                                                        InputProps={inputProps}
                                                                    >
                                                                        {HolidayPoolMenuItems()}
                                                                    </FormTextField>
                                                                </div>
                                                            </div>) : null}

                                                        </div>
                                                        <div className="rowInformation">
                                                            <div className="informationColumnRight">
                                                                {`${translations.defaultHolidays} ${(new Date).getFullYear()}`}
                                                            </div>

                                                            <div className="userInformationColumn">
                                                                {HolidaysInThisYear()}
                                                            </div>
                                                        </div>
                                                        {!this.state.isNewUser ? (<div className="rowInformation">
                                                            <div className="informationColumnRight">
                                                                {`${translations.prevYearHolidays} ${(new Date).getFullYear() - 1}`}
                                                            </div>
                                                            <div className="userInformationColumn">
                                                                <FormTextField value={this.state.user.prevYearHolidaysCurrentYear} name="prevYearHolidaysCurrentYear" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} InputProps={inputProps} />
                                                            </div>
                                                        </div>) : null}
                                                        <div className="rowInformation">
                                                            <div className="informationColumnRight">
                                                                {`${translations.defaultHolidays} ${(new Date).getFullYear() + 1}`}
                                                            </div>
                                                            <div className="userInformationColumn">
                                                                <FormTextField value={this.state.user.startingHolidaysNextYear} name="startingHolidaysNextYear" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} InputProps={inputProps} />
                                                            </div>
                                                        </div>
                                                        {!this.state.isNewUser ? (<div className="rowInformation">
                                                            <div className="informationColumnRight">
                                                                {`${translations.prevYearHolidays} ${(new Date).getFullYear()}`}
                                                            </div>
                                                            <div className="userInformationColumn">
                                                                <FormTextField value={this.state.user.prevYearHolidaysNextYear} name="prevYearHolidaysNextYear" onChange={this.handleInputOnChange} disabled={this.state.disabledInputs} InputProps={inputProps} />
                                                            </div>
                                                        </div>) : null}
                                                    </>
                                                ) :
                                                    (<>
                                                        <div className="rowInformation">
                                                            <div className="informationColumnRight">
                                                                {translations.holidaysTotal}
                                                            </div>
                                                            <div className="userInformationColumn">
                                                                <div>{this.state.user.prevYearHolidaysCurrentYear + this.state.user.startingHolidaysCurrentYear}</div>
                                                            </div>
                                                        </div>
                                                        <div className="rowInformation">
                                                            <div className="informationColumnRight">
                                                                {translations.holidaysUsed}
                                                            </div>
                                                            <div className="userInformationColumn">
                                                                <div>{this.state.user.holidaysUsedCurrentYear}</div>
                                                            </div>
                                                        </div>
                                                        <div className="rowInformation">
                                                            <div className="informationColumnRight">
                                                                {translations.holidaysPlanned}
                                                            </div>
                                                            <div className="userInformationColumn">
                                                                <div>{this.state.user.holidaysPlannedCurrentYear + this.state.user.holidaysPlannedNextYear}</div>
                                                            </div>
                                                        </div>
                                                        <div className="rowInformation">
                                                            <div className="informationColumnRight">
                                                                {translations.holidaysLeft}
                                                            </div>
                                                            <div className="userInformationColumn">
                                                                <div>{this.state.user.prevYearHolidaysCurrentYear + this.state.user.startingHolidaysCurrentYear - (this.state.user.holidaysPlannedCurrentYear + this.state.user.holidaysPlannedNextYear) - this.state.user.holidaysUsedCurrentYear}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    )}
                                            </div>
                                        </div>
                                        {this.props.edited ? (<div className="rowInformationActive">
                                            <div className="textInformationStyle">
                                                <div className="profileButtons">
                                                    <div className="informationColumnRightActive">
                                                        {translations.filters.status}:
                                                    </div>
                                                    <FormButton disabled={this.state.disabledInputs} type="button" className={this.state.user.status === 1 ? "profileButtonActive" : "profileButtonInactive"} onClick={() => this.handleChangeStatus(Constants.UserStatuses.ACTIVE)}>
                                                        {translations.userActive}
                                                    </FormButton>
                                                    <FormButton disabled={this.state.disabledInputs} type="button" className={this.state.user.status !== 1 ? "profileButtonActive" : "profileButtonInactive"} onClick={() => this.handleChangeStatus(Constants.UserStatuses.INACTIVE)}>
                                                        {translations.userInactive}
                                                    </FormButton>
                                                </div>
                                            </div>
                                        </div>) : null}
                                    </>) :
                                    (<>
                                        <div className="supervisorHistory">
                                            <div className="supervisorPeriod">
                                                <div className="supervisorTitle">
                                                    {translations.period}
                                                </div>
                                                <div className="supervisorPeriodList">
                                                    {this.state.user.displaySupervisorHistory ?
                                                        (
                                                            <ul>
                                                                {this.state.user.userSupervisorHistoryResource.map((el, index) =>
                                                                    (<li className="supervisorListElement" key={index}>
                                                                        <div style={{display : 'inline-block'}} className={el.dateFrom ? '' : 'emptySupervisorDate'}>{el.dateFrom ? moment.utc(el.dateFrom).local().format('DD-MM-YYYY'): ''}
                                                                        </div> - {el.dateTo ? moment.utc(el.dateTo).local().format('DD-MM-YYYY') : 'PRESENT'}</li>))
                                                                }
                                                            </ul>
                                                        ) : <div className="supervisorListElement"> - </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="supervisorName">
                                                <div className="supervisorTitle">
                                                    {translations.supervisor}
                                                </div>
                                                <div className="supervisorNameList">
                                                    {this.state.user.displaySupervisorHistory ? (
                                                        this.state.user.userSupervisorHistoryResource.map((el, index) =>
                                                            (<div className="supervisorListElement" key={index}>{el.firstName} {el.lastName}</div>))
                                                    ) : <div className="supervisorListElement"> - </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                }
                            </div>
                        </div>
                        {((this.state.disabledInputs && this.props.isAdmin && this.props.edited) || ((!this.state.user.id) && this.state.disabledInputs && this.props.edited)) && <div className="editProfileButton">
                            <button type="button" onClick={this.handleChangeEditProfile}>
                                {translations.buttons.edit}
                            </button>
                        </div>}
                        <div className="backToUsers" onClick={this.handleBackToUsers}>
                            <ArrowBackIosIcon /> {this.props.canelClickText}
                        </div>
                        {!this.state.disabledInputs && <div className="actionsProfileButton">
                            <FormButton type="button" color="primary" variant="contained" onClick={this.state.isNewUser ? this.handleBackToUsers : e => this.handleClickCancel(e, formik)}>
                                {translations.buttons.cancel}
                            </FormButton>
                            <FormButton type="submit" color="primary" variant="contained">
                                {translations.buttons.save}
                            </FormButton>
                        </div>}

                    </div>
                </div>
            )}
            </FormDialog>
        );
        return content;
    }
}

function mapStateToProps(state) {
    return {
        login: state.login,
        userId: state.login.user.id,

        userInfo: state.login.loginData,
        userEditAvatar: state.employees.userEditAvatar,
        isAdmin: state.login.user.roles.includes(Constants.Roles.admin),
        supervisorsList: state.employees.supervisorsList,
    };
}

function mapDispatchToProps(dispatch) {
    return Redux.bindActionCreators({
        onGetWorkgroups,
        onPutUserImage,
        deleteUserImage,
        fetchAvatar
    }, dispatch);
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(UserEditDialog);