import { createUserManager } from 'redux-oidc';
import * as Cfg from '../components/common/cfg';

const userManagerConfig = {
  authority: Cfg.authorityCfg,
  client_id: Cfg.clientId,
  redirect_uri: Cfg.redirectUri,
  post_logout_redirect_uri: Cfg.postLogoutRedirectUri,
  response_type: Cfg.responseTypeToken + ' ' + Cfg.responseTypeIdToken,
  scope: Cfg.scopeOpenId + ' ' + Cfg.scopeProfile + ' ' + Cfg.scopeApiTms,
  loadUserInfo: Cfg.loadUserInfo,
  monitorSession: Cfg.monitorSession
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
